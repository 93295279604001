import styled from 'styled-components';

export const NoDataWrapper = styled.div`
    width:100%;
    height:80%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`;

export const NoDataImageWrapper = styled.div`
 .image-icon-wrapper img {
    height:190px !important;
    width:225px !important;
}
`;

export const NoDataText = styled.h3`

    font-weight: 500;
    font-size: 29px;
    text-align: center;
    color: #74BCA9;
`;
