import React from 'react';

export default function SupportDocIcon() {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75659 6.59961H10.2324V8.13209H3.75659V6.59961Z" />
      <path d="M3.70776 18.2656H6.82209V19.7981H3.70776V18.2656Z" />
      <path d="M3.70776 14.3848H6.77256V15.8924H3.70776V14.3848Z" />
      <path d="M3.73218 10.7021H8.35414V12.1358H3.73218V10.7021Z" />
      <path d="M18.9578 11.4929V4.12776L14.83 0H0V23.9258H13.8164C14.1131 23.9751 14.4344 24 14.731 24C18.5621 24 21.7011 20.8857 21.7011 17.0299C21.7009 14.7808 20.6134 12.7788 18.9574 11.4935L18.9578 11.4929ZM14.3852 1.21082L17.7219 4.54759H14.3852V1.21082ZM1.40899 22.5412V1.3841H13.273V5.7094H17.5983V10.6526C16.7333 10.2571 15.7692 10.0347 14.7559 10.0347C10.9248 10.0347 7.78586 13.149 7.78586 17.0048C7.78586 19.2541 8.87331 21.2561 10.5293 22.5412H1.40899ZM14.7312 22.6896C11.5922 22.6896 9.04658 20.1439 9.04658 17.005C9.04658 13.8661 11.5923 11.3204 14.7312 11.3204C17.8701 11.3204 20.4158 13.8661 20.4158 17.005C20.3911 20.1438 17.8452 22.6896 14.7312 22.6896Z" />
      <path d="M14.7313 13.5938C13.4954 13.5938 12.5068 14.5823 12.5068 15.8182H13.7673C13.7673 15.2991 14.1875 14.8543 14.7313 14.8543C15.2503 14.8543 15.6952 15.2744 15.6952 15.8182C15.6952 16.3619 15.275 16.7821 14.7313 16.7821H14.0887V18.5863H15.3492L15.349 17.9437C16.2635 17.6718 16.9308 16.8068 16.9308 15.8182C16.9557 14.5823 15.9423 13.5938 14.7313 13.5938H14.7313Z" />
      <path d="M14.0884 19.3779H15.3489V20.4654H14.0884V19.3779Z" />
    </svg>
  );
}
