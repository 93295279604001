import React from 'react';
import propTypes from 'prop-types';

export default function AdminRosterIcon({ active }) {
  return (
    <svg width="30" height="30" viewBox="0 0 19 19" fill={active ? '#E86B55' : '#74BCA9'} xmlns="http://www.w3.org/2000/svg">
      <path d="M9.29488 4.00663C9.02246 4.00663 8.81416 4.21492 8.81416 4.48734V7.77272C8.15716 7.98101 7.69238 8.59013 7.69238 9.29517C7.69238 10.1765 8.4135 10.8978 9.29505 10.8978C10.0162 10.8978 10.6251 10.4331 10.8175 9.77606H14.1027C14.3751 9.77606 14.5834 9.56777 14.5834 9.29534C14.5834 9.02293 14.3751 8.81463 14.1027 8.81463L10.8173 8.81446C10.6571 8.31768 10.2725 7.91701 9.77555 7.77269L9.77571 4.48748C9.77571 4.2149 9.56743 4.00659 9.29483 4.00659L9.29488 4.00663ZM9.93599 9.295C9.93599 9.64761 9.6475 9.93611 9.29488 9.93611C8.94225 9.93611 8.65377 9.64762 8.65377 9.295C8.65377 8.94238 8.94225 8.65389 9.29488 8.65389C9.64749 8.65406 9.93599 8.94254 9.93599 9.295Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M9.29503 0C4.16693 0 0 4.16672 0 9.29503C0 14.4233 4.16672 18.5901 9.29503 18.5901C11.7791 18.5901 14.1191 17.6285 15.8495 15.8817L15.8656 15.8656V17.0515C15.8656 17.324 16.0739 17.5323 16.3463 17.5323C16.6187 17.5323 16.827 17.324 16.827 17.0515L16.8272 14.7601C16.8272 14.4877 16.6189 14.2794 16.3465 14.2794L14.0386 14.2792C13.7662 14.2792 13.5579 14.4875 13.5579 14.7599C13.5579 15.0323 13.7661 15.2406 14.0386 15.2406H15.1123C13.5579 16.7793 11.4904 17.6287 9.29508 17.6287C4.69542 17.6287 0.961643 13.8946 0.961643 9.29529C0.961643 4.69564 4.69573 0.96186 9.29508 0.96186C13.8947 0.96186 17.6285 4.69594 17.6285 9.29529C17.6285 9.56771 17.8368 9.77601 18.1092 9.77601C18.3816 9.77601 18.5899 9.56772 18.5899 9.29529C18.5903 4.16676 14.4075 0.000259749 9.2949 0.000259749L9.29503 0Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M18.2852 11.7631C18.2852 12.0551 18.0485 12.2918 17.7565 12.2918C17.4643 12.2918 17.2275 12.0551 17.2275 11.7631C17.2275 11.4708 17.4642 11.2341 17.7565 11.2341C18.0485 11.2341 18.2852 11.4708 18.2852 11.7631Z" fill={active ? '#E86B55' : '#74BCA9'} />
    </svg>

  );
}

AdminRosterIcon.propTypes = {
  active: propTypes.bool.isRequired
};
