/** @format */
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FaCheck,
  FaTimes
} from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai';
import AddIcon from '../assets/logo/add_icon.svg';
import { checkIfAdminOnly, checkIfHouseAdminOnly, getUserDetails } from '../helpers/utils';
import styles from '../assets/styles/notesTable.module.css';

const EditableCell = ({
  value: initialValue,
  column,
  row,
  editable,
  setEditable,
  addLineItem,
  noOfRows,
  addNewRow,
  cancelLineItem,
  updateLineItem,
  isTodayNotes
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // set initialValue for notes which had no start and end time earlier
  if (column.id === 'startTime' || column.id === 'endTime') {
    if (value === null) {
      setValue('');
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (column.id === 'edit') {
    if (editable === row.id && !row.original.createByFirstName) {
      return (
        <>
          <button
            type="button"
            className={`${styles['confirm-btn']} btn`}
            tabIndex="0"
            onClick={() => {
              addLineItem(row);
              setEditable('');
            }}
          >
            <FaCheck color="#E86B55" />
          </button>
        </>
      );
    }
    if (editable === row.id && row.original.createByFirstName) {
      return (
        <>
          <button
            type="button"
            className={`${styles['confirm-btn']} btn`}
            tabIndex="0"
            onClick={() => {
              updateLineItem(row);
              setEditable('');
            }}
          >
            <FaCheck color="#E86B55" />
          </button>
        </>
      );
    }
    return <div />;
  }
  if (column.id === 'delete') {
    if (editable === row.id && !row.original.createByFirstName) {
      return (
        <button
          type="button"
          className={`${styles['cancel-btn']} btn`}
          tabIndex="0"
          onClick={() => {
            cancelLineItem(row);
            setEditable('');
          }}
        >
          <FaTimes color="#E86B55" />
        </button>
      );
    }
    if (editable === row.id && row.original.createByFirstName) {
      return (
        <button
          type="button"
          className={`${styles['cancel-btn']} btn`}
          tabIndex="0"
          onClick={() => {
            setEditable('');
          }}
        >
          <FaTimes color="#E86B55" />
        </button>
      );
    }
    // if no note is there, hide edit icon from first row
    if (isTodayNotes && !row.original.createByEmail) {
      return <div />
    }
    
    if (isTodayNotes && !checkIfAdminOnly() && (
      checkIfHouseAdminOnly() ||
      row.original.createByEmail === getUserDetails().email)) {
      return (
        <button
          type="button"
          className={`${styles['edit-btn']} btn`}
          tabIndex="0"
          onClick={() => {
            setEditable(row.id);
          }}
        >
          <AiOutlineEdit color="#E86B55" />
        </button>
      );
    }
    return <div />;
  }

  if (column.id === 'new') {
    return (
      <>
        { parseInt(row.id, 10) === noOfRows - 1
          && editable !== row.id
          && isTodayNotes
          && !checkIfAdminOnly() ? (
            <button
              type="button"
              className={`${styles['add-btn']} btn`}
              tabIndex="0"
              onClick={() => {
                addNewRow(row.id);
              }}
            >
              <img src={AddIcon} alt="add icon" />
            </button>
          ) : null}
      </>
    );
  }

  if (column.id === 'note') {
    if (editable === row.id && !row.original.createByFirstName) {
      return(
        <textarea
          rows="4"
          name={`${row.id}${column.id}`}
          className="form-control input-sm"
          value={value}
          onChange={onChange}
        />)
    }
    if (editable === row.id && row.original.createByFirstName){
      return(
        <textarea
          rows="4"
          name={`${row.id}${column.id}`}
          className="form-control input-sm"
          value={value}
          onChange={onChange}
        />)
    }
    return <div><pre>{value}</pre></div>
  }
  // this will render the input start time field for add note
  if (column.id === 'startTime') {
    if (editable === row.id && !row.original.createByFirstName) {
      return(
        <input
          type="time"
          name={`${row.id}${column.id}`}
          className="form-control input-sm"
          value={(value && value.length > 8) ? value.split(' ')[1] : value}
          onChange={onChange}
        />)
    }
    // this will render the input start time field for update note
    if (editable === row.id && row.original.createByFirstName){
      return(
        <input
          type="time"
          data-date={value}
          name={`${row.id}${column.id}`}
          className="form-control input-sm"
          value={(value && value.length > 8) ? value.split(' ')[1] : value}
          onChange={onChange}
        />)
    }
    // this will render the corresponding time in non-editable form
    return <div>{value ? moment(value).format('LT') : value}</div>
  }

  if (column.id === 'endTime') {
    if (editable === row.id && !row.original.createByFirstName) {
      return(
        <input
          type="time"
          name={`${row.id}${column.id}`}
          className="form-control input-sm"
          value={(value && value.length > 8) ? value.split(' ')[1] : value}
          onChange={onChange}
        />)
    }
    if (editable === row.id && row.original.createByFirstName){
      return(
        <input
          type="time"
          name={`${row.id}${column.id}`}
          className="form-control input-sm"
          value={(value && value.length > 8) ? value.split(' ')[1] : value}
          onChange={onChange}
        />)
    }
    return <div>{value ? moment(value).format('LT') : value}</div>
  }
 
  if (column.id === 'createdAt') {
    if (editable === row.id) {
      return <div name={`${row.id}${column.id}`}>{value}</div>;
    }
    return <div>{value}</div>;
  }

   // this will render all other columns other than edit in non-editable form
   if (editable !== row.id && column.id !== 'edit') {
    return <div>{value}</div>;
  }

  // If there is no value available for any column, it will render empty div
  return (
   <div />
  );
};

EditableCell.propTypes = {
  row: PropTypes.shape.isRequired,
  editable: PropTypes.string.isRequired,
  noOfRows: PropTypes.number.isRequired,
  addNewRow: PropTypes.func.isRequired,
  setEditable: PropTypes.func.isRequired,
  addLineItem: PropTypes.func.isRequired,
  cancelLineItem: PropTypes.func.isRequired,
  updateLineItem: PropTypes.func.isRequired,
  column: PropTypes.shape.isRequired,
  value: PropTypes.string.isRequired,
  isTodayNotes: PropTypes.bool.isRequired
};

export default EditableCell;
