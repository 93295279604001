import React from 'react';

export default function RosterAllocate() {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9407 6.80762L14.9598 7.82677V3.56697C14.9598 2.09945 13.7573 0.896914 12.2898 0.896914H3.85168V0.000168118L12.3104 0C14.2672 0 15.8569 1.58975 15.8569 3.54644V7.80624L16.876 6.78709L17.5078 7.4189L15.7345 9.1922C15.653 9.27374 15.5307 9.31442 15.4287 9.31442C15.3268 9.31442 15.2044 9.27374 15.1229 9.1922L13.3496 7.4189L13.9407 6.80762Z" fill="#E86B55" />
      <path d="M0.000136375 6.8076L1.77344 5.03429C1.95686 4.85087 2.22182 4.85087 2.40524 5.03429L4.17855 6.8076L3.54675 7.4394L2.5276 6.42025V10.6801C2.5276 12.1476 3.73014 13.3501 5.19766 13.3501L13.6357 13.3503V14.247H5.19766C3.24092 14.247 1.65122 12.6573 1.65122 10.7006V6.40011L0.632067 7.41925L0.000136375 6.8076Z" fill="#E86B55" />
      <path d="M12.3919 8.92723C12.3919 9.68139 11.9027 10.3132 11.3321 10.3132H6.35899C5.78822 10.3132 5.29917 9.68139 5.29917 8.92723V8.33614C5.29917 8.15272 5.40105 7.98965 5.56412 7.88777L6.84821 7.15392C7.07248 7.0317 7.15402 6.74623 7.07248 6.52212C6.92975 6.17563 6.84821 5.78827 6.84821 5.36043C6.84821 4.4432 7.05198 3.19995 8.84562 3.19995C10.6393 3.19995 10.843 4.42284 10.843 5.36043C10.843 5.78846 10.7615 6.17562 10.6188 6.52212C10.5169 6.76673 10.6188 7.03169 10.843 7.15392L12.1475 7.90808C12.3106 7.98962 12.4124 8.17303 12.4124 8.35645L12.4126 8.92723H12.3919ZM8.84544 4.07638C7.96905 4.07638 7.74478 4.34133 7.74478 5.34012C7.74478 6.11463 8.13196 6.90951 8.84544 6.90951C9.55876 6.90951 9.9461 6.09432 9.9461 5.34012C9.9461 4.34133 9.72184 4.07638 8.84544 4.07638ZM11.2506 8.41765L9.98682 7.70434C9.84408 7.6228 9.68101 7.6228 9.51809 7.684C9.31433 7.76553 9.06971 7.82673 8.82508 7.82673C8.58045 7.82673 8.35635 7.78604 8.13208 7.684C7.98935 7.6228 7.80593 7.6228 7.66335 7.70434L6.39962 8.41765C6.23654 8.49919 6.13467 8.68261 6.13467 8.86603V8.92722C6.13467 9.19218 6.37928 9.41644 6.64424 9.41644H10.9653C11.2302 9.41644 11.4749 9.21268 11.4749 8.92722V8.90688V8.84569C11.5155 8.66227 11.4137 8.49919 11.2506 8.41766L11.2506 8.41765Z" fill="#E86B55" />
    </svg>
  );
}
