import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { OverlayTrigger } from 'react-bootstrap';
import CustomModal from '../../CustomModal';
import {
  FormWrapper, IconContainer, ModalClientName, StyledSelect
} from './index.style';
import AddRosterWhite from '../../../assets/logo/roster_add_icon_white.svg';
import tickIcon from '../../../assets/logo/success-tick.svg';
import deleteIcon from '../../../assets/logo/close_cross.svg';

const sharedShiftOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];
const ClientShiftModal = ({
  selectedModal,
  setSelectedModal,
  clearNewClientShift,
  selectedClient,
  addNewClientShift,
  renderToolTip,
  handleAddNewClientShift,
  selectedClientShifts,
  clientShiftUpdate,
  handleNewClientShift,
  newClientShift,
  handleCreateNewClientShift,
  setSelectedClientShifts,
  handleEditClientShift,
  customSelectStyles
}) => (
  <CustomModal
    open={selectedModal === 'edit-shift'}
    setClose={() => {
      setSelectedModal(null);
      clearNewClientShift();
      setSelectedClientShifts([]);
    }}
    title="Client Shift"
    width="850px"
    onSubmit={() => {
      setSelectedModal(null);
      setSelectedClientShifts([]);
    }}
    operationName="Done"
    disableCancel
  >
    <ModalClientName>
      {selectedClient && selectedClient.name}
      {' '}
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderToolTip('Add new shift')}
      >
        <button type="button" className="action" onClick={() => handleAddNewClientShift()}>
          <img width="20px" height="20px" src={AddRosterWhite} alt="add-icon" />
        </button>
      </OverlayTrigger>
    </ModalClientName>
    <div>
      <FormWrapper>
        {selectedClientShifts.length > 0 || addNewClientShift ? (
          <table>
            <thead>
              <tr>
                <th>Shift Name</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Shared Shift</th>
              </tr>
            </thead>
            <tbody>
              {selectedClientShifts && selectedClientShifts.length > 0
                                && selectedClientShifts.map((item, index) => (
                                  <tr key={`shift-${index}`}>
                                    <td>
                                      <input
                                        type="text"
                                        className="table-input"
                                        value={item.name}
                                        name="name"
                                        onChange={(e) => handleEditClientShift(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="time"
                                        className="table-input"
                                        value={item.startTime}
                                        name="startTime"
                                        onChange={(e) => handleEditClientShift(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="time"
                                        className="table-input"
                                        value={item.endTime}
                                        name="endTime"
                                        onChange={(e) => handleEditClientShift(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <StyledSelect
                                        value={sharedShiftOptions
                                          .filter((shift) => shift.value === item.sharedShift)}
                                        name="sharedShift"
                                        onChange={(e) => handleEditClientShift({
                                          target: {
                                            value: e.value, name: 'sharedShift'
                                          }
                                        }, index)}
                                        placeholder="Shared shift"
                                        styles={customSelectStyles}
                                        options={sharedShiftOptions}
                                      />
                                    </td>
                                    <td>
                                      <IconContainer onClick={() => { clientShiftUpdate('update', item); }}><img src={tickIcon} alt="edit-icon" /></IconContainer>
                                      <div className="delete-btn" onClick={() => clientShiftUpdate('delete', item)}><img src={deleteIcon} width="25px" height="25px" alt="delete-icon" /></div>
                                    </td>
                                  </tr>
                                ))}
              {addNewClientShift ? (
                <tr key="add-new-client-shift">
                  <td>
                    <input
                      type="text"
                      className="table-input"
                      value={newClientShift.name}
                      name="name"
                      onChange={handleNewClientShift}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      className="table-input"
                      value={moment(newClientShift.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                      name="startTime"
                      placeholder="Enter time"
                      onChange={handleNewClientShift}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      className="table-input"
                      value={moment(newClientShift.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                      name="endTime"
                      placeholder="Enter time"
                      onChange={handleNewClientShift}
                    />
                  </td>
                  <td>
                    <StyledSelect
                      value={sharedShiftOptions
                        .filter((shift) => shift.value === newClientShift.sharedShift)}
                      name="sharedShift"
                      onChange={(e) => handleNewClientShift({
                        target: {
                          value: e.value, name: 'sharedShift'
                        }
                      })}
                      placeholder="Shared shift"
                      styles={customSelectStyles}
                      options={sharedShiftOptions}
                    />
                  </td>
                  <td>
                    <IconContainer onClick={() => handleCreateNewClientShift()}><img src={tickIcon} alt="edit-icon" /></IconContainer>
                    <div className="delete-btn" onClick={() => clearNewClientShift()}><img src={deleteIcon} width="25px" height="25px" alt="delete-icon" /></div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        ) : <h4>No Shifts</h4>}
      </FormWrapper>
    </div>
  </CustomModal>
);

ClientShiftModal.propTypes = {
  selectedModal: PropTypes.string.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  clearNewClientShift: PropTypes.func.isRequired,
  selectedClient: PropTypes.shape.isRequired,
  addNewClientShift: PropTypes.bool.isRequired,
  renderToolTip: PropTypes.func.isRequired,
  handleAddNewClientShift: PropTypes.func.isRequired,
  selectedClientShifts: PropTypes.shape.isRequired,
  clientShiftUpdate: PropTypes.func.isRequired,
  handleNewClientShift: PropTypes.func.isRequired,
  newClientShift: PropTypes.shape.isRequired,
  handleCreateNewClientShift: PropTypes.func.isRequired,
  setSelectedClientShifts: PropTypes.func.isRequired,
  handleEditClientShift: PropTypes.func.isRequired,
  customSelectStyles: PropTypes.shape.isRequired
};

export default ClientShiftModal;
