import React from 'react';

export default function IncidentIcon() {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.900267 2.96161V21.1409C0.900267 22.2208 1.80197 23.1 2.90019 23.1H18.1001C19.1983 23.1 20.1 22.2208 20.1 21.1409V2.85936C20.1 1.7795 19.1983 0.900256 18.1001 0.900256L2.90019 0.9C1.80197 0.9 0.9 1.77951 0.9 2.85936V2.96162L0.900267 2.96161ZM6.06396 5.51312L6.0638 5.51316L6.06527 5.51872C6.29912 6.40088 7.10004 6.98752 7.99866 6.98752H13.0018C13.9329 6.98752 14.735 6.36891 14.9361 5.48431L14.9361 5.48432L14.9365 5.48236L15.585 2.33951H18.1001C18.3953 2.33951 18.6335 2.57561 18.6335 2.8593V2.93631L18.6337 2.93637V21.1409C18.6337 21.4246 18.3956 21.6607 18.1003 21.6607L2.90041 21.6604C2.60515 21.6604 2.36701 21.4243 2.36701 21.1406V2.8591C2.36701 2.57542 2.60514 2.33931 2.90041 2.33931H5.4153L6.06396 5.51312ZM7.49425 5.19139L6.88695 2.33957H14.0824L13.5056 5.16196C13.4532 5.39092 13.2437 5.548 13.0018 5.548H7.99841C7.75414 5.548 7.54561 5.38881 7.49425 5.19139Z" />
      <path d="M4 11.9H3.9V12V13V13.1H4H12H12.1V13V12V11.9H12H4Z" />
      <path d="M4 15.9H3.9V16V17V17.1H4H17H17.1V17V16V15.9H17H4Z" />
    </svg>
  );
}
