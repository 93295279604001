/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

export const BreadCrumb = styled.div`
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const PageWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  img {
      width: 12px !important;
      height: auto;
    }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};    
  }
  
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const PageSubHeader = styled.div`
  position:relative;  
  display: flex;
  justify-content: flex-end;  
`;

export const Pagination = styled(ReactPaginate).attrs({
  // we can redefine classes here, if we want.
  activeClassName: 'active' // default to "disabled"
})`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;  
    max-width: 400px;  
    li a {    
      padding: 0.2rem 0.5rem;
      margin: 0.3rem;    
      cursor: pointer;
      text-decoration: none;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
      color: ${({ theme }) => theme.colors.od_secondary};
    }
    li.active a {
      background-color: ${({ theme }) => theme.colors.od_secondary};
      border-color: transparent;
      color: white;
      min-width: 32px;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  `;

export const PaginationRoaster = styled(Pagination)`
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;  
    max-width: 400px;
    font-size: 12px;
    
    li a {
      padding: 0;
      margin: 0.3rem;    
      cursor: pointer;
      text-decoration: none;
    }
`;
export const DetailsWrapper = styled.div`
    margin: 1rem 0 0;  
    height: 115vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 0.4em;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
    background: #88888861;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #88888861;
    }
`;

export const DetailBoxContainer = styled.div`
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  height: ${({ caller }) => (caller ? '13vw' : 'auto')};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
  background: #88888861;
  }

  ::-webkit-scrollbar-thumb:hover {
  background: #88888861;
  }
  .w-24 {
    flex: 0 0 23.5%;
  }
  .w-30 {
    flex: 0 0 30%;
  }
  .w-33 {
    flex: 0 0 32.5%;
  }
  .w-90 {
    flex: 0 0 91.5%;
  }
  .w-95 {
    flex: 0 0 98%;
  }
  .w-50 {
    flex: 0 0 45.5%;
  }
  .w-52 {
    flex: 0 0 49%;
  }
  .w-55 {
    flex: 0 0 52.5%;
  }
  .w-62 {
    flex: 0 0 58.5%;
  }
  .w-67 {
    flex: 0 0 65.5%;
  }
  .dot {
    display: inline-block;
    position: absolute;
    left: 1.3rem;
    top: 0.7rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.od_secondary};
  }
  .d-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .profile-image {
    margin-top: 10px;
    margin-left: 10px;
  }
  .flex {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0.5rem 0.8% 0.2rem 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border: 1px solid #dddddd;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
    .label {
      font-size: 18px;
      padding-left: 2rem;
      padding-top: 0.2rem;
      margin: 0 7px;
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
    .detail {
      font-size: 19px;
      margin: 0 7px;
      padding-left: 2rem;
      padding-bottom: 0.2rem;
      color: ${({ theme }) => theme.colors.od_text_color};
    }           
  }
  .flexDetails {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0.5rem 0.8% 0.2rem 0;
    // height: auto;
    width: 32.5%;
    word-wrap: break-word;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border: 1px solid #dddddd;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
    .label {
      font-size: 18px;
      padding-left: 2rem;
      padding-top: 0.2rem;
      margin: 0 7px;
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
    .detail {
      font-size: 19px;
      margin: 0 7px;
      padding-left: 2rem;
      padding-bottom: 0.2rem;
      color: ${({ theme }) => theme.colors.od_text_color};
    }           
  }
  .flexhealth {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0.5rem 0.8% 0.2rem 0;       
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
    .label {
      font-size: 18px;
      padding-left: 2rem;
      padding-top: 0.2rem;
      margin: 0 7px;
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
    .detail {
      font-size: 19px;
      margin: 0 7px;
      padding-left: 2rem;
      padding-bottom: 0.2rem;
      color: ${({ theme }) => theme.colors.od_text_color};
    }           
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};    
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const DetailsSub = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 13px 0 13px 26px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};    
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;
