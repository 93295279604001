/** @format */

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import {
  SearchInputWrapper,
  IconContainer,
  PageSubHeader,
  FormWrapper,
  BackButton
} from './index.style';
import editIcon from '../../assets/logo/edit.svg';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  TableWrapper, StyledTable, Th, Td, TableContainer
} from '../../styledComponents/common/table.style';
import {
  PageWrapper, PageHeader, Pagination
} from '../../styledComponents/common/page.style';
import deleteIcon from '../../assets/logo/close_cross.svg';
import { listExternalUser, updateExternalUser } from '../../services/result.services';
import CustomModal from '../CustomModal';
import { checkValidationEditExternalUsers } from '../../helpers/utils';
// import BreadCrumb from '../BreadCrumb/BreadCrumb';
import caretLeftSmallIcon from '../../assets/logo/caret_left_small.svg';
import caretRightSmallIcon from '../../assets/logo/caret_right_small.svg';
import caretRightIcon from '../../assets/logo/caret_right.svg';

const RosterExternalUsers = () => {
  const tableBodyRef = useRef('');
  const history = useHistory();
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [show, setShow] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [loading, setLoading] = useState(false);
  const [externalUsersList, setExternalUsersList] = useState(null);
  const [showCustomModalEditUser, setShowCustomModalEditUser] = useState(false);
  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [validationEditMsg, setValidationEditMsg] = useState('');
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 10;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setUserDetailsEdit((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const showEditUserModal = (user) => {
    setUserDetailsEdit(user && user);
    setShowCustomModalEditUser(true);
  };

  const getExternalUsersHandler = async (payload) => {
    setLoading(true);
    try {
      const resp = await listExternalUser(payload);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        const users = resp.data;
        const filteredResp = users.filter((user) => !user.deleted);
        setExternalUsersList(filteredResp.slice(0, PER_PAGE));
        setPageCount(Math.ceil(filteredResp.length / PER_PAGE));
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const listExternalUsersHandler = async (payload) => {
    setLoading(true);
    try {
      const resp = await listExternalUser(payload);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        const users = resp.data;
        const filteredResp = users.filter((user) => !user.deleted);
        setExternalUsersList(filteredResp);
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const handlePageChange = (data) => {
    const { selected } = data;
    setCurrentPage(selected);
    if (searchName) {
      listExternalUsersHandler({
        pageSize: PER_PAGE,
        pageNumber: selected,
        name: searchName
      });
    }
  };

  const handleEditExternalUser = async () => {
    if (!checkValidationEditExternalUsers(userDetailsEdit
      && userDetailsEdit, setValidationEditMsg)) return;
    setLoading(true);
    const resp = await updateExternalUser(userDetailsEdit.id, {
      firstName: userDetailsEdit?.firstName,
      lastName: userDetailsEdit?.lastName,
      phone: userDetailsEdit?.phone,
      agencyName: userDetailsEdit?.agencyName,
      agencyPhone: userDetailsEdit?.agencyPhone,
      deleted: false
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditUser(false);
      swal({
        title: 'User updated successfully!',
        icon: 'success',
        timer: 3000
      });

      await listExternalUsersHandler({
        pageNumber: 0,
        pageSize: 1000
      });
    } else {
      setLoading(false);
      setShowCustomModalEditUser(false);
      swal({
        title: 'User updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleDeleteExternalUser = async (id, userObj) => {
    const value = await swal({
      text: `Are you sure you want to delete ${userObj.firstName} ${userObj.lastName}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateExternalUser(id, userObj);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${userObj.firstName} ${userObj.lastName} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        await listExternalUsersHandler({
          pageNumber: 0,
          pageSize: 1000
        });
      } else {
        setLoading(false);
        swal({
          title: 'User deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    listExternalUsersHandler({
      pageSize: PER_PAGE,
      pageNumber: currentPage,
      name: searchName
    });
  }, [currentPage]);

  useEffect(() => {
    getExternalUsersHandler({
      pageNumber: 0,
      pageSize: 1000,
      name: searchName
    });
  }, [searchName]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getExternalUsersHandler({
      pageNumber: 0,
      pageSize: 1000
    });
  }, []);

  return (
    <>
      {/* <BreadCrumb
        links={[
          {
            name: 'Roster',
            active: false,
            link: '/admin/roster'
          },
          {
            name: 'External User',
            active: true
          }
        ]}
      /> */}

      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Roster </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> External User </span>
          </li>
        </ul>
      </BreadCrumb>
      <PageWrapper>
        <PageHeader>
          <h2>EXTERNAL USER</h2>
          <BackButton onClick={() => history.goBack()}>Back</BackButton>
        </PageHeader>
        <PageSubHeader>
          <SearchInputWrapper>
            <span>
              {' '}
              <FaSearch />
            </span>
            <input
              placeholder="Search by Name"
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              value={searchName}
            />
          </SearchInputWrapper>

          <div style={{ marginLeft: '250px' }}>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              previousLabel={<img className="caret-icon" src={caretLeftSmallIcon} alt="caret icon" />}
              nextLabel={<img className="caret-icon" src={caretRightSmallIcon} alt="caret icon" />}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
            />
          </div>
        </PageSubHeader>
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                <tr>
                  <Th width="40px">No.</Th>
                  <Th width="100px" style={{ paddingLeft: '5px' }}>Name</Th>
                  <Th width="100px" style={{ paddingLeft: '5px' }}>Phone</Th>
                  <Th width="100px">Agency Name</Th>
                  <Th width="103px">Agency Phone Number</Th>
                  <Th width="60px" style={{ paddingRight: '15px' }} align="center">Action</Th>
                </tr>
              </thead>
              <tbody ref={tableBodyRef}>
                {
                  externalUsersList && externalUsersList.map((user, index) => (
                    <tr key={user.id}>
                      <Td width="40px">{index + 1}</Td>
                      <Td width="100px">{`${user.firstName} ${user.lastName}`}</Td>
                      <Td width="100px" data-testid="user">{user.phone}</Td>
                      <Td width="100px" data-testid="user">{user.agencyName}</Td>
                      <Td width="100px" data-testid="user">{user.agencyPhone}</Td>
                      <Td width="60px" align="center">
                        <IconContainer onClick={() => showEditUserModal(user)}>
                          <img src={editIcon} alt="edit icon" />
                        </IconContainer>
                        <IconContainer
                          className="delete-btn"
                          onClick={() => handleDeleteExternalUser(user.id, {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            deleted: true
                          })}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </IconContainer>
                      </Td>
                    </tr>

                  ))
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        </TableContainer>
      </PageWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalEditUser}
        setClose={() => {
          setShowCustomModalEditUser(false);
        }}
        title="Update External User"
        onSubmit={handleEditExternalUser}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="fname">First Name</label>
              <input
                id="fname"
                required
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleEditChange}
                value={userDetailsEdit?.firstName}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="lname">Last Name</label>
              <input
                id="lname"
                required
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleEditChange}
                value={userDetailsEdit?.lastName}
              />
            </div>
            <div className="input-with-label bg">
              <label htmlFor="email">Phone</label>
              <input
                id="email"
                required
                type="phone"
                placeholder="Phone"
                name="phone"
                onChange={handleEditChange}
                value={userDetailsEdit?.phone}
              />
            </div>
            <div className="input-with-label bg">
              <label htmlFor="email">Agency Name</label>
              <input
                id="email"
                required
                type="agencyName"
                placeholder="Agency Name"
                name="agencyName"
                onChange={handleEditChange}
                value={userDetailsEdit?.agencyName}
              />
            </div>
            <div className="input-with-label bg">
              <label htmlFor="email">Agency Phone</label>
              <input
                id="email"
                required
                type="agencyPhone"
                placeholder="Agency Phone"
                name="agencyPhone"
                onChange={handleEditChange}
                value={userDetailsEdit?.agencyPhone}
              />
            </div>
          </FormWrapper>
          {validationEditMsg !== ''
          && <h6 className="m-3 text-danger">{validationEditMsg}</h6>}
        </div>
      </CustomModal>
    </>
  );
};

export default RosterExternalUsers;
