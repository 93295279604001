import React from 'react';
import propTypes from 'prop-types';

export default function AdminHome({ active }) {
  return (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.99">
        <path d="M8.63552 18.8765C8.26306 18.6535 8.17729 18.3178 8.18219 17.9012C8.1969 16.1418 8.18709 12.5642 8.18954 10.8049C8.18954 10.3736 8.45419 10.055 8.8438 10.006C9.29468 9.94967 9.67939 10.2952 9.68184 10.7681C9.68429 11.9247 9.68184 14.8995 9.68184 16.0536C9.68184 16.676 9.66469 17.2984 9.68919 17.9184C9.70634 18.33 9.59853 18.6559 9.23587 18.874C9.03248 18.8765 8.834 18.8765 8.63552 18.8765Z" fill={active ? '#E86B55' : '#74BCA9'} />
        <path d="M14.0901 18.8765C13.7176 18.6535 13.6319 18.3178 13.6368 17.9012C13.6515 16.1418 13.6417 12.5642 13.6441 10.8049C13.6441 10.3736 13.9088 10.055 14.2984 10.006C14.7493 9.94967 15.134 10.2952 15.1364 10.7681C15.1389 11.9247 15.1364 14.8995 15.1364 16.0536C15.1364 16.676 15.1193 17.2984 15.1438 17.9184C15.1609 18.33 15.0531 18.6559 14.6905 18.874C14.4871 18.8765 14.2886 18.8765 14.0901 18.8765Z" fill={active ? '#E86B55' : '#74BCA9'} />
        <path d="M15.0663 11.0526C14.8433 11.4251 14.5076 11.5108 14.091 11.5059C12.3316 11.4912 10.9038 11.5038 9.14446 11.5013C8.71319 11.5013 8.39463 11.2367 8.34563 10.8471C8.28927 10.3962 8.63477 10.0115 9.1077 10.009C10.2643 10.0066 11.4209 10.009 12.575 10.009C13.1974 10.009 13.4882 10.0234 14.1082 9.99892C14.5198 9.98177 14.8458 10.0896 15.0638 10.4522C15.0663 10.6556 15.0663 10.8541 15.0663 11.0526Z" fill={active ? '#E86B55' : '#74BCA9'} />
        <path d="M19.9716 5.63831L13.404 0.530307H13.4043C12.9613 0.186506 12.4181 0 11.8593 0C11.3005 0 10.7575 0.186506 10.3145 0.530307L3.74694 5.63831L0.370224 8.2604H0.370447C0.177715 8.39207 0.047367 8.59835 0.0106047 8.83048C-0.0261593 9.06237 0.0335537 9.29943 0.175929 9.48528C0.318307 9.67111 0.53065 9.78953 0.762358 9.81222C0.994306 9.83514 1.22514 9.76009 1.40029 9.60549L3.41786 8.08155V17.446C3.41786 18.1234 3.68456 18.7731 4.15961 19.252C4.63442 19.731 5.27856 20 5.95038 20H17.7685C18.44 20 19.0842 19.731 19.5593 19.252C20.0341 18.7731 20.301 18.1235 20.301 17.446V8.08155L22.3186 9.64794C22.5579 9.81176 22.8638 9.8403 23.129 9.72412C23.3941 9.60772 23.5819 9.36256 23.6263 9.07426C23.6708 8.78619 23.5659 8.49499 23.3484 8.30286L19.9716 5.63831ZM18.6127 17.4464C18.6127 17.6722 18.5236 17.8888 18.3654 18.0484C18.207 18.2081 17.9924 18.2978 17.7685 18.2978H5.95035C5.72642 18.2978 5.51163 18.2081 5.35342 18.0484C5.19501 17.8888 5.1061 17.6722 5.1061 17.4464V6.7284L11.3446 1.89278V1.893C11.4923 1.7784 11.6732 1.71616 11.8595 1.71616C12.0458 1.71616 12.2269 1.7784 12.3744 1.893L18.6129 6.72846L18.6127 17.4464Z" fill={active ? '#E86B55' : '#74BCA9'} />
      </g>
    </svg>

  );
}

AdminHome.propTypes = {
  active: propTypes.bool.isRequired
};
