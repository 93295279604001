import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import userPlusIcon from '../../../assets/logo/userPlus.svg';
// import userPlusIcon from '../../../assets/logo/userPlus.svg';
import closeIcon from '../../../assets/logo/close.svg';
import CustomModal from '../../CustomModal';
import {
  User,
  ExternalUser,
  TextDataDiv, AddExternalUser,
  LeftText,
  RightText,
  AddNew,
  AssignedUser,
  AssignedUserData,
  StyledSelectDropdown,
  SelectStyledTable,
  RepeatWrapper,
  Button
} from './index.style';
import {
  getExternalUser, createExternalUser, fetchConflictingUser, fetchUserUnavailability
} from '../../../services/result.services';
import { LoadingModal } from '../../AdminClientContacts/index.style';
import { checkValidationAddXuser } from '../../../helpers/utils';

const ClientAllocateUserModal = ({
  selectedModal,
  setSelectedModal,
  setAssignedUsers,
  setAvailableUserSelected,
  assignedUsers,
  availableUserSelected,
  addAssignedUser,
  deleteAssignedUser,
  selectedExternalUser,
  setSelectedExternalUser,
  customSelectStyles,
  selectedClientShift,
  selectedCurrentDate,
  initialAvailableUsers,
  saveButtonActive
}) => {
  const [startDate, setStartDate] = useState(selectedCurrentDate);
  const [endDate, setEndDate] = useState(selectedCurrentDate);
  const [repeat, setRepeat] = useState(false);
  const [checkExternalUserAdd, setCheckExternalUserAdd] = useState(false);
  const [addExternalUserDetails, setAddExternalUserDetails] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    agencyName: '',
    agencyPhone: ''
  });
  const [validationMsgAddXUser, setValidationMsgAddXUser] = useState('');

  const clearInput = () => {
    setAddExternalUserDetails({
      firstName: '',
      lastName: '',
      phone: '',
      agencyName: '',
      agencyPhone: ''
    });
  };

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddExternalUserDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCreateExternalUser = async () => {
    setLoading(true);
    const resp = await createExternalUser(addExternalUserDetails);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleAddExternalUser = async () => {
    if (!checkValidationAddXuser(addExternalUserDetails, setValidationMsgAddXUser)) return;
    setSelectedExternalUser('cancelExternalUser');
    setLoading(true);
    const resp = await getExternalUser(addExternalUserDetails.firstName,
      addExternalUserDetails.lastName);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setAvailableUserSelected([...availableUserSelected, {
        ...resp.data,
        userId: resp.data.id,
        userFirstName: resp.data.firstName,
        userLastName: resp.data.lastName
      }
      ]);
      clearInput();
      setCheckExternalUserAdd(true);
    } else {
      setLoading(false);
      await handleCreateExternalUser();
      setLoading(true);
      const getResp = await getExternalUser(addExternalUserDetails.firstName,
        addExternalUserDetails.lastName);
      if (!getResp) {
        setLoading(false);
        return;
      }
      if (getResp.status === 200) {
        setLoading(false);
        setAvailableUserSelected([...availableUserSelected, {
          ...getResp.data,
          userId: getResp.data.id,
          userFirstName: getResp.data.firstName,
          userLastName: getResp.data.lastName
        }
        ]);
        clearInput();
        setCheckExternalUserAdd(true);
      }
    }
  };

  const handleAdd = () => {
    const dates = {
      startDate: selectedCurrentDate,
      endDate: selectedCurrentDate
    };
    const arr = availableUserSelected.filter((x) => !initialAvailableUsers.some((y) => (y.userId
      === x.userId)));
    if (repeat) {
      addAssignedUser(arr, { startDate, endDate });
    } else {
      addAssignedUser(arr, dates);
    }
    setCheckExternalUserAdd(false);
    setStartDate(selectedCurrentDate);
    setEndDate(selectedCurrentDate);
  };

  const handleDelete = (user) => {
    const dates = {
      startDate: selectedCurrentDate,
      endDate: selectedCurrentDate
    };
    if (repeat) {
      deleteAssignedUser(user, { startDate, endDate });
    } else {
      deleteAssignedUser(user, dates);
    }
    setStartDate(selectedCurrentDate);
    setEndDate(selectedCurrentDate);
  };

  const handleUserUnavailability = async (e) => {
    const payload = {
      shiftId: selectedClientShift.shiftId,
      userId: e.userId
    };
    try {
      const resp = await fetchUserUnavailability(payload);
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        if (resp.data.length > 0) {
          swal({
            title: `User unavailable from
             ${moment(resp.data[0].unavailableStartTime).format('hh:mm a')} 
             to
             ${moment(resp.data[0].unavailableEndTime).format('hh:mm a')}`,
            icon: 'warning'
          });
        } else {
          setAvailableUserSelected([...availableUserSelected, e]);
        }
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const handleUserCheckAvailabilty = async (e) => {
    const payload = {
      shiftId: selectedClientShift.shiftId,
      userId: e.userId
    };
    try {
      const resp = await fetchConflictingUser(payload);
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        if (resp.data.length > 0) {
          swal({
            title: `User already allocated from
             ${moment(resp.data[0].startTime).format('hh:mm a')} 
             to
             ${moment(resp.data[0].endTime).format('hh:mm a')}`,
            icon: 'warning'
          });
        } else {
          handleUserUnavailability(e);
        }
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  useEffect(() => {
    if (!repeat) {
      setStartDate(selectedCurrentDate);
      setEndDate(selectedCurrentDate);
    }
  }, [repeat]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <CustomModal
      open={selectedModal === 'assign-user'}
      setClose={() => {
        setRepeat(false);
        setSelectedModal(null);
        setAssignedUsers([]);
        setAvailableUserSelected([]);
        clearInput();
      }}
      title="Allocate/Deallocate User"
      width="700px"
      onSubmit={() => handleAdd()}
      operationName="Save"
      isDisabled={saveButtonActive}
    >
      <TextDataDiv>
        <LeftText>{selectedClientShift?.clientName}</LeftText>
        <RightText>{moment(selectedClientShift?.date).format('DD-MMM YYYY')}</RightText>
      </TextDataDiv>
      <TextDataDiv marginTop>
        <LeftText>{selectedClientShift?.shiftClientShiftsName}</LeftText>
        <RightText>
          {moment(selectedClientShift?.shiftClientShiftsStartTime, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')}
          {' '}
          -
          {' '}
          {moment(selectedClientShift?.shiftClientShiftsEndTime, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')}
        </RightText>
      </TextDataDiv>
      {assignedUsers.length > 0 && (
        <AssignedUser>
          Assigned User
          <AssignedUserData>
            {availableUserSelected.map((user) => (
              <User>
                {user.userFirstName}
                {' '}
                {user.userLastName}
                                &nbsp;
                <img src={closeIcon} alt="close" width={15} role="presentation" onClick={() => handleDelete(user)} />
              </User>
            ))}
          </AssignedUserData>
        </AssignedUser>
      )}
      <AssignedUser>
        <AddNew>

          <div
            type="button"
            onClick={() => { setSelectedExternalUser('addExternalUser'); }}
            style={{ color: 'red' }}
          >
            <img src={userPlusIcon} alt="plus icon" />
            &nbsp;
            Add External User
          </div>
        </AddNew>
        {(selectedExternalUser === 'addExternalUser') ? (
          <ExternalUser>
            <div style={{ textAlign: 'right' }}>
              <Button type="button" onClick={() => setSelectedExternalUser('cancelExternalUser')}>
                <img src={closeIcon} alt="close icon" />
              </Button>
            </div>
            <AddExternalUser>

              <input
                label="input"
                name="firstName"
                placeholder="First Name *"
                value={addExternalUserDetails.firstName}
                onChange={handleChange}
                className={validationMsgAddXUser === 'firstName' && 'input-failed'}
              />

              <input
                label="input"
                name="lastName"
                placeholder="Last Name *"
                value={addExternalUserDetails.lastName}
                onChange={handleChange}
                className={validationMsgAddXUser === 'lastName' && 'input-failed'}
              />

              <input
                label="input"
                name="phone"
                placeholder="Phone Number"
                value={addExternalUserDetails.phone}
                onChange={handleChange}
                className={validationMsgAddXUser === 'phone' && 'input-failed'}
              />

            </AddExternalUser>
            <AddExternalUser>
              <input
                label="input"
                name="agencyName"
                placeholder="Agency Name"
                value={addExternalUserDetails.agencyName}
                onChange={handleChange}
              />

              <input
                label="input"
                name="agencyPhone"
                placeholder="Agency  Number"
                value={addExternalUserDetails.agencyPhone}
                onChange={handleChange}
              />

              <button
                type="button"
                style={{ width: '55%', height: '45px', marginTop: '8px' }}
                onClick={() => handleAddExternalUser()}
              >
                Add
              </button>
            </AddExternalUser>

          </ExternalUser>
        ) : !checkExternalUserAdd
          ? (
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Add User</label>
                <StyledSelectDropdown
                  getOptionLabel={(e) => (
                    <SelectStyledTable>
                      <tbody>
                        <tr>
                          <td>
                            {e.userFirstName}
                            {' '}
                            {e.userLastName}
                          </td>
                          <td>{e.time}</td>
                        </tr>
                      </tbody>
                    </SelectStyledTable>
                  )}
                  onChange={(e) => handleUserCheckAvailabilty(e)}
                  styles={customSelectStyles}
                  value=""
                  placeholder="Add User"
                  options={assignedUsers}
                />
              </div>
            </div>
          )
          : null}
        {
          (selectedExternalUser === 'cancelExternalUser') ? (
            null
          ) : null
        }

      </AssignedUser>
      {initialAvailableUsers && availableUserSelected.length > initialAvailableUsers.length ? (
        <RepeatWrapper>
          <button type="button" className="action" onClick={() => setRepeat(!repeat)}>Repeat</button>
          {repeat ? (
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  placeholder="Start Date"
                  value={startDate}
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="dob">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  placeholder="End Date"
                  value={endDate}
                  required
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          ) : null}
        </RepeatWrapper>
      ) : null}
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </CustomModal>
  );
};

ClientAllocateUserModal.propTypes = {
  selectedModal: PropTypes.string.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  setAssignedUsers: PropTypes.func.isRequired,
  setAvailableUserSelected: PropTypes.func.isRequired,
  assignedUsers: PropTypes.shape.isRequired,
  availableUserSelected: PropTypes.shape.isRequired,
  addAssignedUser: PropTypes.func.isRequired,
  deleteAssignedUser: PropTypes.func.isRequired,
  selectedExternalUser: PropTypes.shape.isRequired,
  setSelectedExternalUser: PropTypes.func.isRequired,
  customSelectStyles: PropTypes.shape.isRequired,
  selectedClientShift: PropTypes.shape.isRequired,
  selectedCurrentDate: PropTypes.string.isRequired,
  initialAvailableUsers: PropTypes.shape.isRequired,
  saveButtonActive: PropTypes.bool.isRequired
};

export default ClientAllocateUserModal;
