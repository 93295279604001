/** @format */

import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import editIcon from '../assets/logo/edit.svg';
import deleteIcon from '../assets/logo/close_cross.svg';
import dottedIcon from '../assets/logo/admin_dotted.svg';
import contactIcon from '../assets/logo/contact.svg';
import ImageContainer from './ImageContainer';
import {
  EditButton, EditIcon, RemoveButton, RemoveIcon
} from './HomeContent/index.style';

const settings = {
  className: 'slider variable-width',
  slidesToShow: 1,
  infinite: false,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false
};

const HouseSlider = ({
  list, sliderRef, handleEdit, getClientsByHouseHandler
}) => (
  <>
    <Slider {...settings} ref={sliderRef}>
      {list.map((ele) => (
        <div key={ele.id} className="box">
          <EditButton onClick={() => {
            handleEdit(ele);
          }}
          >
            <EditIcon src={editIcon} />
          </EditButton>
          <RemoveButton onClick={() => getClientsByHouseHandler(ele)}>
            <RemoveIcon src={deleteIcon} />
          </RemoveButton>
          <ImageContainer houseCode={ele.houseCode} />
          <div className="lower-part">
            <p className="icon-with-code">{ele.houseCode}</p>
            <p className="icon-with-text">
              <img
                className="contact-icon"
                src={contactIcon}
                alt="contact-icon"
              />
              <span>{ele.phone}</span>
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <p className="icon-with-client">
              Client
            </p>
            <img
              className="contact-icon"
              src={dottedIcon}
              alt="contact-icon"
            />
            <p className="icon-with-users">Users</p>
          </div>
          <div style={{ display: 'flex' }}>
            <p className="client-number">
              {ele.totalClients}
            </p>
            <img
              className="contact-icon"
              src={dottedIcon}
              alt="contact-icon"
            />
            <p className="users-number">{ele.totalUsers}</p>
          </div>
        </div>
      ))}
    </Slider>
  </>
);
HouseSlider.defaultProps = {
  list: []
};

HouseSlider.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  handleEdit: PropTypes.func.isRequired,
  getClientsByHouseHandler: PropTypes.func.isRequired,
  sliderRef: PropTypes.objectOf(PropTypes.any).isRequired
};

export default HouseSlider;
