/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import userIcon from '../../../../assets/logo/userIcon.svg';
import { NameBox, BackButton } from './index.style';

const MealTracker = ({
  title, mealDetails
}) => (
  <>
    <div className="first-heading">
      <span className="title">{title}</span>
      <input
        type="time"
        name="mealTime"
        value={mealDetails.mealTime}
        placeholder="Choose time"
        disabled
      />
    </div>
    <div className="second-heading">
      <textarea
        value={mealDetails.mealFood}
        name="mealFood"
        rows="3"
        placeholder="Enter food details"
        disabled
      />
    </div>
    <div className="third-heading">
      <textarea
        value={mealDetails.mealDrink}
        name="mealDrink"
        rows="3"
        placeholder="Enter drink details"
        disabled
      />
    </div>
    <div className="fourth-heading">
      <textarea
        value={mealDetails.mealComments}
        rows="3"
        name="mealComments"
        placeholder="Comments"
        disabled
      />
    </div>
    <div className="fifth-heading">
      {
        mealDetails.diaryId ? (
          <BackButton height="30px" type="button" disabled>
            Save
          </BackButton>
        ) : (
          <BackButton height="30px" type="button" disabled>
            Save
          </BackButton>
        )
      }

      <NameBox>
        <div className="icon-name-wrapper">
          <img className="icon" src={userIcon} alt="user icon" />
        </div>
        <span>
          { mealDetails.mealUpdatedBy }
        </span>
      </NameBox>
    </div>
  </>
);

MealTracker.propTypes = {
  mealDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired
};

export default MealTracker;
