/** @format */

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { login } from '../../services/result.services';
import upperCurve from '../../assets/logo/upper_curve.svg';
import lowerCurve from '../../assets/logo/lower_curve.svg';
import oneDoorWayLogo from '../../assets/logo/one_doorway_logo.png';
import disabledMan from '../../assets/images/disabled_man.png';
import emailLogo from '../../assets/logo/email_logo.svg';
import passwordLogo from '../../assets/logo/password_logo.svg';
import arrowIcon from '../../assets/logo/arrow.svg';
import {
  StyledLogin, FormContainer, Image, UpperCurve, LowerCurve, ErrorMsg, MainWrapper,
  InputRow, LoginTitle, Button, CredentialIcon, StyledHeading, MainImage
} from './index.style';

const Login = () => {
  const history = useHistory();
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [validityMsgForEmail, setValidityMsgForEmail] = useState('');
  const [validRespMsg, setValidRespMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const checkValidity = (name, value) => {
    setValidRespMsg('');
    if (name === 'email') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(value).toLowerCase())) {
        setValidityMsgForEmail('Please enter a valid email');
      } else {
        setValidityMsgForEmail('');
      }
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    checkValidity(name, value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const resp = await login(loginData);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const result = resp.data.roles.some(
        (role) => ['HOUSE ADMIN', 'ADMIN'].indexOf(role) > -1
      );
      if (result) {
        localStorage.setItem('token', JSON.stringify(resp.data.jwt));
        localStorage.setItem('user-details', JSON.stringify({
          roles: resp.data.roles,
          houseList: resp.data.houseCode,
          houseCode: '',
          email: loginData.email,
          firstName: resp.data.firstName,
          lastName: resp.data.lastName
        }));
        setLoading(false);
        history.push('/');
      } else {
        setLoading(false);
        setValidRespMsg('Admin Privileges required to login');
      }
    } else if (resp && resp.status === 403) {
      setLoading(false);
      setValidRespMsg('Invalid credentials');
    } else if (resp && resp.status !== 200) {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledLogin>
        <UpperCurve src={upperCurve} />
        <LowerCurve src={lowerCurve} />
        <Image src={oneDoorWayLogo} alt="one doorway logo" />
        <MainWrapper>
          <div>
            <StyledHeading>
              We just want people to be safe, feel heard and be cared for
            </StyledHeading>
            <MainImage src={disabledMan} />
          </div>
          <div className="form-part">
            <LoginTitle data-testid="login-title">
              Use your
              <br />
              <span>credentials to login</span>
            </LoginTitle>
            <FormContainer>
              <InputRow>
                <label htmlFor="email">
                  <CredentialIcon src={emailLogo} />
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={loginData.email}
                  name="email"
                  onChange={handleChange}
                  data-testid="email"
                />
              </InputRow>
              <InputRow>
                <label htmlFor="pass">
                  <CredentialIcon src={passwordLogo} />
                </label>
                <input
                  id="pass"
                  type="password"
                  placeholder="Password"
                  value={loginData.password}
                  name="password"
                  onChange={handleChange}
                  data-testid="password"
                />
              </InputRow>
              <Button
                type="submit"
                data-testid="submit"
                onClick={handleLogin}
                disabled={
                  loginData.email === ''
                  || loginData.password === ''
                  || validityMsgForEmail !== ''
                  || validRespMsg !== ''
                  || loading
                }
              >
                {
                  loading
                    ? <span><Spinner animation="border" variant="info" /></span>
                    : <img src={arrowIcon} alt="arrow icon" />
                }
              </Button>
              <p>
                <Link className="label" to="/forgotpassword">Forgot Password ?</Link>
              </p>
            </FormContainer>
            <ErrorMsg>
              {validityMsgForEmail}
            </ErrorMsg>
            <ErrorMsg
              data-testid="validity-text"
            >
              {validRespMsg}
            </ErrorMsg>
          </div>
        </MainWrapper>
      </StyledLogin>
    </>
  );
};

export default Login;
