/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import moment from 'moment';
import { isEqual } from 'lodash';
import {
  getUsersList,
  getIncident,
  getClientList,
  escalateTo,
  editIncident,
  getTypeList,
  getClassificationList,
  getCategoryList
} from '../../../services/result.services';
import { checkIfHouseAdminOnly } from '../../../helpers/utils';
import CustomModal from '../../CustomModal';
import SelectContainer from '../../SelectContainer';
import { LoadingModal } from '../../../styledComponents/common/loadingModal.style';
import {
  incidentObj
} from '../../../lookups/lookups';
import {
  IncidentWrapper, ButtonWrapper,
  SelectAdminWrapper
} from './index.style';
import { IncidentDetailBoxContainer } from '../../../styledComponents/common/incidentDetails.style';

const Incident = ({
  incidentId,
  incidentType,
  handleSelectedIndex,
  editReviewData,
  setIncident
}) => {
  const editable = !checkIfHouseAdminOnly();
  const [currentIncidentDetails, setCurrentIncidentDetails] = useState(incidentObj);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [escalatableAdmins, setEscalatableAdmins] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [incidentClientOptions, setIncidentClientOptions] = useState([]);
  const [incidentCategoryOptions, setIncidentCategoryOptions] = useState([]);
  const [incidentClassificationOptions, setIncidentClassificationOptions] = useState([]);
  const [incidentTypeOptions, setIncidentTypeOptions] = useState([]);
  const [incidentDetailsObj, setIncidentDetailsObj] = useState({});
  const [incidentStatus, setIncidentStatus] = useState('');
  const options = [
    { label: '', value: '' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'NA', value: 'NA' }
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getUsersHandler = async () => {
    const resp = await getUsersList();
    if (!resp) return;
    const filteredResp = resp.data.filter((user) => !user.deleted);
    if (resp.status === 200) {
      // eslint-disable-next-line
      const userList = filteredResp && filteredResp.map((ele) => {
        return { ...ele, name: `${ele.firstName} ${ele.lastName}`, value: ele.id };
      });
      const admins = userList.filter((user) => user.roleNames.includes('ADMIN'));
      setAdminList(admins);
    }
  };
  const getClientsHandler = async () => {
    const resp = await getClientList();
    if (!resp) return;
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((client) => !client.deleted);
      const clientList = filteredResp && filteredResp.map((ele) => ({
        id: ele.id,
        label: ele.name,
        value: ele.name,
        name: 'client'
      }));
      setIncidentClientOptions([...clientList]);
    }
  };
  const getCategoryHandler = async () => {
    const resp = await getCategoryList();
    if (!resp) return;
    if (resp.status === 200) {
      const categoryList = resp.data && resp.data.map((ele) => ({
        id: ele.id,
        label: ele.name,
        value: ele.name,
        name: 'category'
      }));
      setIncidentCategoryOptions([...categoryList]);
    }
  };
  const getTypesHandler = async () => {
    const resp = await getTypeList();
    if (!resp) return;
    if (resp.status === 200) {
      const typeList = resp.data && resp.data.map((ele) => ({
        id: ele.id,
        label: ele.name,
        value: ele.name,
        name: 'type'
      }));
      setIncidentTypeOptions([...typeList]);
    }
  };
  const getClassificationHandler = async () => {
    const resp = await getClassificationList();
    if (!resp) return;
    if (resp.status === 200) {
      const classificationList = resp.data && resp.data.map((ele) => ({
        id: ele.id,
        label: ele.name,
        value: ele.name,
        name: 'classification'
      }));
      setIncidentClassificationOptions([...classificationList]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'injuredFamilyName' && incidentStatus === 'RAISED') {
      setCurrentIncidentDetails((prevState) => ({
        ...prevState,
        [name]: value === '' ? null : value
      }));
    } else if (name === 'injuredGivenName' && incidentStatus === 'REVIEWED') {
      setCurrentIncidentDetails((prevState) => ({
        ...prevState,
        [name]: value === '' ? null : value
      }));
    } else {
      setCurrentIncidentDetails((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSelectDate = (selectedDate) => {
    setCurrentIncidentDetails((prevState) => ({
      ...prevState,
      dateOccurred: selectedDate
    }));
  };

  const handleSelect = (selected) => {
    const { name, value, id } = selected;
    setCurrentIncidentDetails((prevState) => ({
      ...prevState,
      [name]: { id, name: value }
    }));
  };

  const handleAdminChange = (e) => {
    const { value } = e.target;
    const parsedValue = JSON.parse(value);
    setSelectedAdmin(parsedValue.id);
  };

  const handleEscalateAdminList = (escalated) => {
    if (!escalated) {
      setEscalatableAdmins([...adminList]);
    } else {
      const adminsToBeEscalated = adminList.filter((ele) => escalated.email !== ele.email);
      setEscalatableAdmins([...adminsToBeEscalated]);
    }
  };

  const getIncidentDetails = async (id) => {
    setLoading(true);
    const resp = await getIncident(id);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      // setIncident(resp.data);
      const incident = resp.data;
      setIncidentStatus(incident.status);
      const date = new Date(
        incident.dateOccurred
      );
      let modifiedIncident;
      if (!incident.followUpResponsibility) {
        modifiedIncident = {
          ...incident,
          dateOccurred: date,
          followUpResponsibility: ''
        };
      } else {
        modifiedIncident = {
          ...incident,
          dateOccurred: date
        };
      }
      setIncident(modifiedIncident);
      setCurrentIncidentDetails(modifiedIncident);
      setIncidentDetailsObj(modifiedIncident);
    } else {
      setLoading(false);
    }
  };

  const escalateToAdmin = async () => {
    setLoading(true);
    const resp = await escalateTo(incidentId, selectedAdmin);
    if (!resp) {
      setLoading(false);
      setShowCustomModal(false);
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModal(false);
      swal({
        title: 'Escalated successfully!',
        icon: 'success',
        timer: 3000
      });
      setSelectedAdmin(null);
      getIncidentDetails(incidentId);
    } else {
      setLoading(false);
      setShowCustomModal(false);
      swal({
        title: 'Escalation failed, try again!',
        icon: 'error',
        timer: 3000
      });
      setSelectedAdmin(null);
    }
  };

  const handleSaveIncident = async () => {
    setLoading(true);
    if (!currentIncidentDetails.policeReport) {
      currentIncidentDetails.policeReport = 'No';
    }
    if (!currentIncidentDetails.reportableToNDIS) {
      currentIncidentDetails.reportableToNDIS = 'No';
    }
    if (!currentIncidentDetails.reportableToWorksafe) {
      currentIncidentDetails.reportableToWorksafe = 'No';
    }
    const modifiedIncidentDetails = {
      id: currentIncidentDetails.id,
      classificationId: currentIncidentDetails.classification.id,
      houseId: currentIncidentDetails.house?.id,
      typeId: currentIncidentDetails.type.id,
      categoryId: currentIncidentDetails.category.id,
      clientId: currentIncidentDetails.client?.id,
      status: currentIncidentDetails.status,
      description: currentIncidentDetails.description,
      dateOccurred: moment(currentIncidentDetails.dateOccurred).format('YYYY-MM-DD HH:mm:ss'),
      location: currentIncidentDetails.location,
      exactLocation: currentIncidentDetails.exactLocation,
      raisedFor: currentIncidentDetails.raisedFor,
      reportedBy: currentIncidentDetails.reportedBy,
      injuredGivenName: currentIncidentDetails.injuredGivenName,
      injuredFamilyName: currentIncidentDetails.injuredFamilyName,
      witnessName: currentIncidentDetails.witnessName,
      witnessDesignation: currentIncidentDetails.witnessDesignation,
      beforeIncident: currentIncidentDetails.beforeIncident,
      immediateAction: currentIncidentDetails.immediateAction,
      policeReport: currentIncidentDetails.policeReport,
      policeName: currentIncidentDetails.policeName,
      policeNumber: currentIncidentDetails.policeNumber,
      policeStation: currentIncidentDetails.policeStation,
      reportableToNDIS: currentIncidentDetails.reportableToNDIS,
      reportableToWorksafe: currentIncidentDetails.reportableToWorksafe
    };
    const resp = await editIncident(currentIncidentDetails.id, modifiedIncidentDetails);
    if (!resp) {
      setLoading(false);
      swal({
        title: 'Network error, try again!',
        icon: 'error',
        timer: 3000
      });
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Incident updated successfully!',
        icon: 'success',
        timer: 3000
      });
    } else {
      setLoading(false);
      swal({
        title: 'Incident updation failed, try again!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  useEffect(() => {
    getUsersHandler();
    getClientsHandler();
    getClassificationHandler();
    getCategoryHandler();
    getTypesHandler();
    getIncidentDetails(incidentId);
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <>
        {!editable
          && (
            <IncidentDetailBoxContainer>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Incident No.</p>
                <p className="detail">{currentIncidentDetails.id}</p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Category</p>
                <p className="detail">
                  {currentIncidentDetails.category.name}
                </p>
              </div>
              {
                incidentType === 'client'
                && (
                  <div className="flex w-33">
                    <span className="dot"> </span>
                    <p className="label">Client</p>
                    <p className="detail">
                      {currentIncidentDetails.client.name}
                    </p>
                  </div>
                )
              }
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Type</p>
                <p className="detail">{currentIncidentDetails.type.name}</p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Classification</p>
                <p className="detail">
                  {currentIncidentDetails.classification.name}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Description</p>
                <p className="detail">
                  {currentIncidentDetails.description}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Date/Time of occurrence</p>
                <p className="detail">
                  {moment(currentIncidentDetails.dateOccurred).format('DD/MM/YYYY, hh:mm:ss')}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Reporting Date</p>
                <p className="detail">
                  {moment(currentIncidentDetails.createdAt).format('DD/MM/YYYY')}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Location</p>
                <p className="detail">{currentIncidentDetails.location}</p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Exact Location</p>
                <p className="detail">
                  {currentIncidentDetails.exactLocation}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Reporting Person</p>
                <p className="detail">
                  {`${currentIncidentDetails.reportedBy.firstName} ${currentIncidentDetails.reportedBy.lastName}`}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Affected Person</p>
                <p className="detail">
                  {currentIncidentDetails.injuredGivenName}
                </p>
              </div>
              <div className="flex w-33">
                <span className="dot"> </span>
                <p className="label">Escalated To</p>
                <p className="detail">
                  {currentIncidentDetails.escalatedTo ? currentIncidentDetails.escalatedTo.email : 'Not Escalated'}
                </p>
              </div>
            </IncidentDetailBoxContainer>
          )}
        {editable
          && (
            <IncidentWrapper>
              <div className="w-100 flex">
                <p className="label">Incident Number</p>
                <p className="incident_num">{currentIncidentDetails.id}</p>
              </div>
              <div className={`flex ${incidentType === 'client' ? 'w-25' : 'w-33'}`}>
                <p className="label">Category</p>
                <SelectContainer
                  options={incidentCategoryOptions}
                  width="100%"
                  defaultValue={{
                    label: currentIncidentDetails.category.name,
                    value: currentIncidentDetails.category.name,
                    id: currentIncidentDetails.category.id
                  }}
                  onChange={handleSelect}
                />
              </div>
              <div className={`flex ${incidentType === 'client' ? 'w-25' : 'w-33'}`}>
                <p className="label">Type</p>
                <SelectContainer
                  options={incidentTypeOptions}
                  width="100%"
                  defaultValue={{
                    label: currentIncidentDetails.type.name,
                    value: currentIncidentDetails.type.name,
                    id: currentIncidentDetails.type.id
                  }}
                  onChange={handleSelect}
                />
              </div>
              <div className={`flex ${incidentType === 'client' ? 'w-25' : 'w-33'}`}>
                <p className="label">Classification</p>
                <SelectContainer
                  options={incidentClassificationOptions}
                  width="100%"
                  defaultValue={{
                    label: currentIncidentDetails.classification.name,
                    value: currentIncidentDetails.classification.name,
                    id: currentIncidentDetails.classification.id
                  }}
                  onChange={handleSelect}
                />
              </div>
              {
                incidentType === 'client'
                && (
                  <div className={`flex ${incidentType === 'client' ? 'w-25' : 'w-33'}`}>
                    <p className="label">Client Name</p>
                    <SelectContainer
                      options={incidentClientOptions}
                      width="100%"
                      defaultValue={{
                        label: currentIncidentDetails.client.name,
                        value: currentIncidentDetails.client.name,
                        id: currentIncidentDetails.client.id
                      }}
                      disabled
                      onChange={handleSelect}
                    />
                  </div>
                )
              }
              <div className="w-25 flex">
                <p className="label">Witness Name</p>
                <input
                  className="input"
                  type="text"
                  defaultValue={currentIncidentDetails.witnessName}
                  name="witnessName"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Witness Designation</p>
                <input
                  className="input"
                  type="text"
                  defaultValue={currentIncidentDetails.witnessDesignation}
                  name="witnessDesignation"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label"> Reportable to NDIS </p>
                <SelectContainer
                  options={options.filter((item) => item.value !== '')}
                  width="100%"
                  disabled
                  defaultValue={{
                    label: currentIncidentDetails.reportableToNDIS,
                    value: currentIncidentDetails.reportableToNDIS,
                    id: 1
                  }}
                  onChange={(e) => {
                    handleChange({ target: { name: 'reportableToNDIS', value: e.value } });
                  }}
                />
              </div>
              <div className="w-25 flex">
                <p className="label"> Reportable to Worksafe </p>
                <SelectContainer
                  options={options.filter((item) => item.value !== '')}
                  width="100%"
                  disabled
                  defaultValue={{
                    label: currentIncidentDetails.reportableToWorksafe,
                    value: currentIncidentDetails.reportableToWorksafe,
                    id: 1
                  }}
                  onChange={(e) => {
                    handleChange({ target: { name: 'reportableToWorksafe', value: e.value } });
                  }}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Before Incident</p>
                <textarea
                  className="input-like"
                  placeholder="beforeIncident"
                  rows="4"
                  defaultValue={currentIncidentDetails.beforeIncident}
                  name="beforeIncident"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Description</p>
                <textarea
                  className="input-like"
                  placeholder="description"
                  rows="4"
                  defaultValue={currentIncidentDetails.description}
                  name="description"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Immediate Action</p>
                <textarea
                  className="input-like"
                  placeholder="immediateAction"
                  rows="4"
                  defaultValue={currentIncidentDetails.immediateAction}
                  name="immediateAction"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Date and Time of Occurence</p>
                <DatePicker
                  timeFormat="HH:mm"
                  showTimeSelect
                  className="input w-100"
                  dateFormat="dd/MM/yyyy, HH:mm:ss"
                  placeholderText="Select date"
                  selected={currentIncidentDetails.dateOccurred}
                  onChange={(selectedDate) => handleSelectDate(selectedDate)}
                />
              </div>
              <div className="w-25 flex">
                <p className="label"> Police Report </p>
                <SelectContainer
                  options={options.filter((item) => item.value !== '')}
                  width="100%"
                  defaultValue={{
                    label: currentIncidentDetails.policeReport,
                    value: currentIncidentDetails.policeReport,
                    id: 1
                  }}
                  onChange={(e) => {
                    handleChange({ target: { name: 'policeReport', value: e.value } });
                  }}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Police Name</p>
                <input
                  className="input"
                  disabled={currentIncidentDetails.policeReport !== 'Yes'}
                  type="text"
                  value={currentIncidentDetails.policeReport === 'Yes' ? currentIncidentDetails.policeName : ' '}
                  name="policeName"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Police Number</p>
                <input
                  className="input"
                  disabled={currentIncidentDetails.policeReport !== 'Yes'}
                  type="text"
                  value={currentIncidentDetails.policeReport === 'Yes' ? currentIncidentDetails.policeNumber : ' '}
                  name="policeNumber"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Police Station</p>
                <input
                  className="input"
                  disabled={currentIncidentDetails.policeReport !== 'Yes'}
                  type="text"
                  value={currentIncidentDetails.policeReport === 'Yes' ? currentIncidentDetails.policeStation : ' '}
                  name="policeStation"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Reporting Date</p>
                <p className="input-like">
                  {moment(currentIncidentDetails.createdAt).format('DD/MM/YYYY')}
                </p>
              </div>
              <div className="w-25 flex">
                <p className="label">Location</p>
                <input
                  className="input"
                  type="text"
                  defaultValue={currentIncidentDetails.location}
                  name="location"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Exact Location</p>
                <input
                  className="input"
                  type="text"
                  value={currentIncidentDetails.exactLocation}
                  name="exactLocation"
                  onChange={handleChange}
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Reporting Person First Name</p>
                <p className="input-like">
                  {`${currentIncidentDetails.reportedBy.firstName}`}
                </p>
              </div>
              <div className="w-25 flex">
                <p className="label">Reporting Person Last Name</p>
                <p className="input-like">
                  {`${currentIncidentDetails.reportedBy.lastName}`}
                </p>
              </div>
              <div className="w-25 flex">
                <p className="label">Affected Person First Name</p>
                <input
                  className="input"
                  type="text"
                  value={currentIncidentDetails.injuredGivenName}
                  name="injuredGivenName"
                  onChange={handleChange}
                  placeholder="Given Name"
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Affected Person Last Name</p>
                <input
                  className="input"
                  type="text"
                  value={currentIncidentDetails.injuredFamilyName}
                  name="injuredFamilyName"
                  onChange={handleChange}
                  placeholder="Family Name"
                />
              </div>
              <div className="w-25 flex">
                <p className="label">Escalated To</p>
                <p className="input-like">
                  {currentIncidentDetails.escalatedTo ? currentIncidentDetails.escalatedTo.email : 'Not Escalated'}
                </p>
              </div>
            </IncidentWrapper>
          )}
      </>
      <ButtonWrapper>
        {checkIfHouseAdminOnly() && (
          <button
            type="button"
            className="escalate-btn"
            disabled={currentIncidentDetails.escalated}
            onClick={() => {
              setShowCustomModal(true);
              handleEscalateAdminList(currentIncidentDetails.escalatedTo);
            }}
          >
            Escalate
          </button>
        )}
        {editable
          ? (
            <button
              type="button"
              className="save-incident-btn"
              onClick={handleSaveIncident}
              disabled={!!isEqual(incidentDetailsObj, currentIncidentDetails)}
            >
              Save Incident
            </button>
          )
          : ''}
        {!Object.keys(editReviewData).length > 0
          ? <button type="button" className="review-btn" onClick={() => handleSelectedIndex(1)}> Review</button>
          : <button type="button" className="review-btn" onClick={() => handleSelectedIndex(1)}> Review</button>}
      </ButtonWrapper>
      <CustomModal
        open={showCustomModal}
        setClose={() => {
          setShowCustomModal(false);
          setSelectedAdmin(null);
        }}
        title="Escalation"
        onSubmit={escalateToAdmin}
        isDisabled={!selectedAdmin}
        operationName="Escalate"
      >
        <SelectAdminWrapper>
          <p>Escalate To</p>
          {escalatableAdmins.length < 1 && <h4>No admins available to escalate.</h4>}
          <select className="form-select" onChange={(event) => handleAdminChange(event)}>
            <option disabled selected value="null">Select Admin</option>
            {escalatableAdmins
              && escalatableAdmins.length > 0
              && escalatableAdmins.map((admin) => (
                <option
                  key={admin.id}
                  value={JSON.stringify({ id: admin.id, email: admin.email })}
                >
                  {admin.name}
                </option>
              ))}
          </select>
        </SelectAdminWrapper>
      </CustomModal>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

Incident.propTypes = {
  incidentId: PropTypes.shape.isRequired,
  handleSelectedIndex: PropTypes.func.isRequired,
  editReviewData: PropTypes.func.isRequired,
  setIncident: PropTypes.func.isRequired,
  incidentType: PropTypes.string.isRequired
};

export default Incident;
