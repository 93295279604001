import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  OverlayTrigger,
  Modal, Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import tickIcon from '../../assets/logo/success-tick.svg';
import {
  ResetIconContainer,
  ButtonRepeat,
  TooltipCustom,
  TableWrapper,
  StyledTable,
  Th,
  Td,
  TableContainer,
  StyledInputDiv,
  ModalClientName,
  IconContainer
} from './index.style';

import resetIcon from '../../assets/images/resetIcon.png';
import { createUserUnavailability, getListUserUnavailability, updateUserUnavailability } from '../../services/result.services';
import CustomModal from '../CustomModal';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';

const AdminRosterUnavailabilityTime = ({
  userId, selectedUser, open, setSelectedModal, setUserId
}) => {
  const date = new Date();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [userUnavailabilityList, setUserUnavailabilityList] = useState([]);
  const [errorState, setErrorState] = useState();
  const [state, setState] = useState({
    userId,
    dayOfWeek: 1,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });
  const [stateTuesday, setStateTuesday] = useState({
    userId,
    dayOfWeek: 2,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });
  const [stateWednesday, setStateWednesday] = useState({
    userId,
    dayOfWeek: 3,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });
  const [stateThursday, setStateThursday] = useState({
    userId,
    dayOfWeek: 4,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });
  const [stateFriday, setStateFriday] = useState({
    userId,
    dayOfWeek: 5,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });
  const [stateSaturday, setStateSaturday] = useState({
    userId,
    dayOfWeek: 6,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });
  const [stateSunday, setStateSunday] = useState({
    userId,
    dayOfWeek: 7,
    startTime: '00:00',
    endTime: '00:00',
    notes: ''
  });

  const renderTooltip = (props) => (
    <TooltipCustom className="tool">{props}</TooltipCustom>
  );
  const handleReset = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleResetTuesday = (e) => {
    e.preventDefault();
    setStateTuesday((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleResetWednesday = (e) => {
    e.preventDefault();
    setStateWednesday((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleResetThursday = (e) => {
    e.preventDefault();
    setStateThursday((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleResetFriday = (e) => {
    e.preventDefault();
    setStateFriday((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleResetSaturday = (e) => {
    e.preventDefault();
    setStateSaturday((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleResetSunday = (e) => {
    e.preventDefault();
    setStateSunday((prevState) => ({
      ...prevState,
      startTime: '00:00',
      endTime: '00:00',
      notes: ''
    }));
  };
  const handleRepeat = () => {
    setStateTuesday({ ...state, dayOfWeek: 2 });
    setStateWednesday({ ...state, dayOfWeek: 3 });
    setStateThursday({ ...state, dayOfWeek: 4 });
    setStateFriday({ ...state, dayOfWeek: 5 });
    setStateSaturday({ ...state, dayOfWeek: 6 });
    setStateSunday({ ...state, dayOfWeek: 7 });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseModal = () => {
    setSelectedModal(null);
    setUserId();
  };

  const createUnavailabilityTime = async () => {
    if (state.startTime === state.endTime) {
      setErrorState(1);
      return;
    } if (stateTuesday.startTime === stateTuesday.endTime) {
      setErrorState(2);
      return;
    } if (stateWednesday.startTime === stateWednesday.endTime) {
      setErrorState(3);
      return;
    } if (stateThursday.startTime === stateThursday.endTime) {
      setErrorState(4);
      return;
    } if (stateFriday.startTime === stateFriday.endTime) {
      setErrorState(5);
      return;
    } if (stateSaturday.startTime === stateSaturday.endTime) {
      setErrorState(6);
      return;
    } if (stateSunday.startTime === stateSunday.endTime) {
      setErrorState(7);
      return;
    }
    const unavailableCreateList = [
      state, stateTuesday, stateWednesday, stateThursday, stateFriday, stateSaturday, stateSunday
    ];
    unavailableCreateList.forEach(async (createData) => {
      const payload = {
        ...createData,
        userId,
        startTime: `${moment(date).format('YYYY-MM-DD')} ${createData.startTime}:00`,
        endTime: `${moment(date).format('YYYY-MM-DD')} ${createData.endTime}:00`
      };

      setLoading(true);
      try {
        const resp = await createUserUnavailability(payload);
        if (!resp) {
          setLoading(false);
          return;
        }
        if (resp.status === 200) {
          setLoading(false);
          setSelectedModal(null);
          setUserId();
          setUserUnavailabilityList([]);
          setErrorState();
        } else if (resp.status === 500) {
          setLoading(false);
          swal({
            title: 'Internal Server Error, Try again later',
            icon: 'error',
            timer: 3000
          });
        } else {
          setLoading(false);
        }
      } catch (error) {
        error.data = `Error msg is ${error.data}`;
      }
    });
  };

  const editList = async (list, id, day) => {
    if (list.startTime === list.endTime) {
      setErrorState(day);
      return;
    }
    setLoading(true);
    try {
      const newData = ({
        ...list,
        startTime: `${moment(date).format('YYYY-MM-DD')} ${list.startTime}:00`,
        endTime: `${moment(date).format('YYYY-MM-DD')} ${list.endTime}:00`
      });
      const resp = await updateUserUnavailability(id, newData);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        setErrorState();
      } else if (resp.status === 500) {
        setLoading(false);
        swal({
          title: 'Internal Server Error, Try again later',
          icon: 'error',
          timer: 3000
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  useEffect(() => {
    if (userUnavailabilityList.length > 0) {
      setState({
        userId,
        dayOfWeek: userUnavailabilityList[0].dayOfWeek,
        startTime: moment(userUnavailabilityList[0].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[0].endTime).format('HH:mm'),
        notes: userUnavailabilityList[0].notes
      });
      setStateTuesday({
        userId,
        dayOfWeek: userUnavailabilityList[1].dayOfWeek,
        startTime: moment(userUnavailabilityList[1].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[1].endTime).format('HH:mm'),
        notes: userUnavailabilityList[1].notes
      });
      setStateWednesday({
        userId,
        dayOfWeek: userUnavailabilityList[2].dayOfWeek,
        startTime: moment(userUnavailabilityList[2].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[2].endTime).format('HH:mm'),
        notes: userUnavailabilityList[2].notes
      });
      setStateThursday({
        userId,
        dayOfWeek: userUnavailabilityList[3].dayOfWeek,
        startTime: moment(userUnavailabilityList[3].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[3].endTime).format('HH:mm'),
        notes: userUnavailabilityList[3].notes
      });
      setStateFriday({
        userId,
        dayOfWeek: userUnavailabilityList[4].dayOfWeek,
        startTime: moment(userUnavailabilityList[4].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[4].endTime).format('HH:mm'),
        notes: userUnavailabilityList[4].notes
      });
      setStateSaturday({
        userId,
        dayOfWeek: userUnavailabilityList[5].dayOfWeek,
        startTime: moment(userUnavailabilityList[5].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[5].endTime).format('HH:mm'),
        notes: userUnavailabilityList[5].notes
      });
      setStateSunday({
        userId,
        dayOfWeek: userUnavailabilityList[6].dayOfWeek,
        startTime: moment(userUnavailabilityList[6].startTime).format('HH:mm'),
        endTime: moment(userUnavailabilityList[6].endTime).format('HH:mm'),
        notes: userUnavailabilityList[6].notes
      });
    } else {
      setState({
        userId,
        dayOfWeek: 1,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
      setStateTuesday({
        userId,
        dayOfWeek: 2,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
      setStateWednesday({
        userId,
        dayOfWeek: 3,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
      setStateThursday({
        userId,
        dayOfWeek: 4,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
      setStateFriday({
        userId,
        dayOfWeek: 5,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
      setStateSaturday({
        userId,
        dayOfWeek: 6,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
      setStateSunday({
        userId,
        dayOfWeek: 7,
        startTime: '00:00',
        endTime: '00:00',
        notes: ''
      });
    }
  }, [userUnavailabilityList]);

  useEffect(async () => {
    setLoading(true);
    try {
      const resp = await getListUserUnavailability(userId);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        resp.data.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
        setUserUnavailabilityList(resp.data);
      } else if (resp.status === 500) {
        setLoading(false);
        swal({
          title: 'Internal Server Error, Try again later',
          icon: 'error',
          timer: 3000
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  }, [userId]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);
  return (
    <>
      <CustomModal
        open={open}
        setClose={() => {
          setSelectedModal(null);
          setUserUnavailabilityList([]);
          setErrorState();
          setUserId();
        }}
        title="Unavailability Time"
        width="1200px"
        onSubmit={() => (userUnavailabilityList.length > 0
          ? handleCloseModal() : createUnavailabilityTime())}
        operationName={userUnavailabilityList.length > 0 ? 'Done' : 'Save'}
      >
        <div>
          <TableContainer>
            <TableWrapper>
              <ModalClientName noSeperator>
                {selectedUser}
              </ModalClientName>
              <StyledTable>
                <thead>
                  <tr>
                    <Th width="300px"> Day</Th>
                    <Th width="300px">Start Time</Th>
                    <Th width="300px" align="center">
                      End Time
                    </Th>
                    <Th width="300px" align="right">
                      Notes
                    </Th>
                    <Th width="550px" align="center">
                      Action
                    </Th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    <tr>
                      <Td width="100px">Monday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 1}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            required
                            value={state.startTime}
                            onChange={(e) => setState((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 1}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            required
                            value={state.endTime}
                            onChange={(e) => setState((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="notes"
                            required
                            type="text"
                            name="notes"
                            value={state.notes}
                            onChange={(e) => setState((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <>
                          <Td
                            width="50px"
                            onClick={() => {
                              editList(state, userUnavailabilityList[0].id, 1);
                            }}
                          >
                            <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>
                          </Td>
                          <Td width="100px" />
                        </>
                      ) : (
                        <>
                          <Td width="60px" align="left" onClick={handleReset}>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip('Reset')}
                            >
                              <div>
                                <ResetIconContainer src={resetIcon} alt="delete icon" />
                              </div>
                            </OverlayTrigger>
                          </Td>
                          <Td width="100px" onClick={handleRepeat}>
                            <ButtonRepeat>Repeat</ButtonRepeat>
                          </Td>
                        </>
                      )}
                    </tr>
                    <tr>
                      <Td width="100px">Tuesday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 2}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            value={stateTuesday.startTime}
                            onChange={(e) => setStateTuesday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 2}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            value={stateTuesday.endTime}
                            onChange={(e) => setStateTuesday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="notes"
                            required
                            type="text"
                            name="notes"
                            value={stateTuesday.notes}
                            onChange={(e) => setStateTuesday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <Td
                          width="50px"
                          onClick={() => {
                            editList(stateTuesday, userUnavailabilityList[1].id, 2);
                          }}
                        >
                          <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>
                        </Td>
                      ) : (
                        <Td width="60px" onClick={handleResetTuesday}>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip('Reset')}
                          >
                            <div>
                              <ResetIconContainer src={resetIcon} alt="delete icon" />
                            </div>
                          </OverlayTrigger>
                        </Td>
                      )}
                      <Td width="100px" />
                    </tr>
                    <tr>
                      <Td width="100px">Wednesday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 3}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            value={stateWednesday.startTime}
                            onChange={(e) => setStateWednesday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 3}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            value={stateWednesday.endTime}
                            onChange={(e) => setStateWednesday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="email"
                            required
                            type="text"
                            name="notes"
                            value={stateWednesday.notes}
                            onChange={(e) => setStateWednesday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <Td
                          width="50px"
                          onClick={() => {
                            editList(stateWednesday, userUnavailabilityList[2].id, 3);
                          }}
                        >
                          <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>

                        </Td>
                      ) : (
                        <Td width="60px" align="left" onClick={handleResetWednesday}>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip('Reset')}
                          >
                            <div>
                              <ResetIconContainer src={resetIcon} alt="delete icon" />
                            </div>
                          </OverlayTrigger>
                        </Td>
                      )}
                      <Td width="100px" />
                    </tr>
                    <tr>
                      <Td width="100px">Thursday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 4}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            value={stateThursday.startTime}
                            onChange={(e) => setStateThursday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 4}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            value={stateThursday.endTime}
                            onChange={(e) => setStateThursday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="email"
                            required
                            type="text"
                            name="notes"
                            value={stateThursday.notes}
                            onChange={(e) => setStateThursday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <Td
                          width="50px"
                          onClick={() => {
                            editList(stateThursday, userUnavailabilityList[3].id, 4);
                          }}
                        >
                          <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>

                        </Td>
                      ) : (
                        <Td width="60px" align="left" onClick={handleResetThursday}>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip('Reset')}
                          >
                            <div>
                              <ResetIconContainer src={resetIcon} alt="delete icon" />
                            </div>
                          </OverlayTrigger>
                        </Td>
                      )}
                      <Td width="100px" />
                    </tr>
                    <tr>
                      <Td width="100px">Friday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 5}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            value={stateFriday.startTime}
                            onChange={(e) => setStateFriday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 5}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            value={stateFriday.endTime}
                            onChange={(e) => setStateFriday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="email"
                            required
                            type="text"
                            name="notes"
                            value={stateFriday.notes}
                            onChange={(e) => setStateFriday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <Td
                          width="50px"
                          onClick={() => {
                            editList(stateFriday, userUnavailabilityList[4].id, 5);
                          }}
                        >
                          <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>

                        </Td>
                      ) : (
                        <Td width="60px" align="left" onClick={handleResetFriday}>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip('Reset')}
                          >
                            <div>
                              <ResetIconContainer src={resetIcon} alt="delete icon" />
                            </div>
                          </OverlayTrigger>
                        </Td>
                      )}
                      <Td width="100px" />
                    </tr>
                    <tr>
                      <Td width="100px">Saturday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 6}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            value={stateSaturday.startTime}
                            onChange={(e) => setStateSaturday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 6}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            value={stateSaturday.endTime}
                            onChange={(e) => setStateSaturday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="email"
                            required
                            type="text"
                            name="notes"
                            value={stateSaturday.notes}
                            onChange={(e) => setStateSaturday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <Td
                          width="50px"
                          onClick={() => {
                            editList(stateSaturday, userUnavailabilityList[5].id, 6);
                          }}
                        >
                          <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>

                        </Td>
                      ) : (
                        <Td width="60px" align="left" onClick={handleResetSaturday}>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip('Reset')}
                          >
                            <div>
                              <ResetIconContainer src={resetIcon} alt="delete icon" />
                            </div>
                          </OverlayTrigger>
                        </Td>
                      )}
                      <Td width="100px" />
                    </tr>
                    <tr>
                      <Td width="100px">Sunday</Td>
                      <Td width="200px">
                        <StyledInputDiv error={errorState === 7}>
                          <input
                            label="input"
                            type="time"
                            name="startTime"
                            value={stateSunday.startTime}
                            onChange={(e) => setStateSunday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        {' '}
                        <StyledInputDiv error={errorState === 7}>
                          <input
                            label="input"
                            type="time"
                            name="endTime"
                            value={stateSunday.endTime}
                            onChange={(e) => setStateSunday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </StyledInputDiv>
                      </Td>
                      <Td width="200px">
                        <div>
                          <textarea
                            id="email"
                            required
                            type="text"
                            name="notes"
                            value={stateSunday.notes}
                            onChange={(e) => setStateSunday((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }))}
                          />
                        </div>
                      </Td>
                      {userUnavailabilityList.length > 0 ? (
                        <Td
                          width="50px"
                          onClick={() => {
                            editList(stateSunday, userUnavailabilityList[6].id, 7);
                          }}
                        >
                          <IconContainer><img src={tickIcon} alt="edit-icon" /></IconContainer>

                        </Td>
                      ) : (
                        <Td width="60px" align="left" onClick={handleResetSunday}>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip('Reset')}
                          >
                            <div>
                              <ResetIconContainer src={resetIcon} alt="delete icon" />
                            </div>
                          </OverlayTrigger>
                        </Td>
                      )}
                      <Td width="100px" />
                    </tr>
                  </>
                </tbody>
              </StyledTable>
            </TableWrapper>
          </TableContainer>
          {errorState && (
            <div style={{ color: 'red' }}>
              Please enter different start time and end time
            </div>
          )}
        </div>
      </CustomModal>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

AdminRosterUnavailabilityTime.propTypes = {
  userId: PropTypes.number.isRequired,
  selectedUser: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired
};

export default AdminRosterUnavailabilityTime;
