/** @format */

import React, { useState, useEffect } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import swal from 'sweetalert';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  ClientReportsWrapper,
  ReviewWrapper,
  ShowReportsButtonWrapper,
  CaseNoteRecordsWrapper,
  FormWrapper,
  AddFolder
} from './index.style';
import SelectContainer from '../SelectContainer';
import AddIcon from '../../assets/logo/add_icon.svg';
import {
  getListCaseNotes,
  createCaseNote,
  updateCaseNote,
  listMiscellaneousNoteCategories
} from '../../services/result.services';
import {
  DetailsHeader, DetailsWrapper
} from '../../styledComponents/common/page.style';
import CaseNoteRecord from '../CaseNoteRecord/CaseNoteRecord';
import CustomModal from '../CustomModal';
import NoData from '../NoData';

const AdminClientNotes = ({ client }) => {
  const { id } = client;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [addCaseNoteFormData, setAddCaseNoteFormData] = useState({
    category: ''
  });
  const [category, setCategory] = useState({ name: '' });
  const [categoryList, setCategoryList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [listCaseNotes, setListCaseNotes] = useState([]);
  const [showCustomModalAddCaseNotes, setShowCustomModalAddCaseNotes] = useState(false);
  const [showCustomModalUpdateCaseNotes, setShowCustomModalUpdateCaseNotes] = useState(false);
  const [caseNoteObj, setCaseNoteObj] = useState({
    clientId: '',
    noteDate: '',
    subject: '',
    content: ''
  });
  const [validationMsgAddCaseNote, setValidationMsgAddCaseNote] = useState('');
  const [caseNoteEditObj, setCaseNoteEditObj] = useState({});
  const [validationMsgUpdateCaseNote, setValidationMsgUpdateCaseNote] = useState('');
  const [showCaseNotes, setShowCaseNotes] = useState(true);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = (link) => history.push(link);

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-case-notes') {
      setShowCustomModalAddCaseNotes(false);
    }
    if (operation === 'update-case-notes') {
      setShowCustomModalUpdateCaseNotes(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCaseNoteObj((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setCaseNoteEditObj((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdate = (obj) => {
    setShowCustomModalUpdateCaseNotes(true);
    const newObj = { ...obj, categoryId: obj.category?.id };
    setCaseNoteEditObj(newObj);
  };

  const handleCategoryChange = (event) => {
    setCategory({ name: event.value, id: event.id });
  };

  const handleAddCategoryChange = (selectedCategory) => {
    setCaseNoteObj((prevDetails) => ({
      ...prevDetails,
      category: { name: selectedCategory.value, id: selectedCategory.id }
    }));
  };

  const handleUpdateCategoryChange = (selectedCategory) => {
    setCaseNoteEditObj((prevDetails) => ({
      ...prevDetails,
      category: { name: selectedCategory.value, id: selectedCategory.id }
    }));
  };

  const resetAddCaseNote = () => {
    setCaseNoteObj({
      clientId: id,
      noteDate: '',
      subject: '',
      content: '',
      category: ''
    });
    setAddCaseNoteFormData({
      category: ''
    });
  };

  const checkAddCaseNotevalidation = () => {
    if (!caseNoteObj.category) {
      setValidationMsgAddCaseNote('Please select Category');
      return false;
    }
    if (!caseNoteObj.noteDate) {
      setValidationMsgAddCaseNote('Please select Note Date');
      return false;
    }
    if (!caseNoteObj.subject) {
      setValidationMsgAddCaseNote('Please enter subject');
      return false;
    }
    if (!caseNoteObj.content) {
      setValidationMsgAddCaseNote('Please enter content');
      return false;
    }
    return true;
  };

  const checkUpdateCaseNotevalidation = () => {
    if (caseNoteEditObj && !caseNoteEditObj.noteDate) {
      setValidationMsgUpdateCaseNote('Please select Note Date');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.subject) {
      setValidationMsgUpdateCaseNote('Please enter subject');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.content) {
      setValidationMsgUpdateCaseNote('Please enter content');
      return false;
    }
    if (caseNoteEditObj && !caseNoteEditObj.category) {
      setValidationMsgUpdateCaseNote('Please enter category');
      return false;
    }
    return true;
  };
  const handleAdd = () => {
    setShowCustomModalAddCaseNotes(true);
  };

  const getListCaseNotesHandler = async (payload) => {
    setLoading(true);
    const resp = await getListCaseNotes(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filterNotDeletedCaseNotes = resp.data.filter((ele) => !ele.deleted);
      setListCaseNotes(filterNotDeletedCaseNotes);
    } else {
      setLoading(false);
    }
  };

  const getCaseNotesWithParameters = () => {
    let payload = {
      pageNumber: 0,
      pageSize: 1000,
      clientId: id,
      category: category.name
    };
    if (startDate && endDate) {
      payload = {
        ...payload,
        start: startDate && moment(startDate).format('YYYY-MM-DD'),
        end: endDate && moment(endDate).format('YYYY-MM-DD')
      };
    }
    getListCaseNotesHandler(payload);
  };

  const handleShowReports = () => {
    setShowCaseNotes(true);
    getCaseNotesWithParameters();
  };

  const handleClearSelection = () => {
    setStartDate('');
    setEndDate('');
    setCategory({ name: '' });
    getListCaseNotesHandler({
      start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
      pageNumber: 0,
      pageSize: 10,
      clientId: id
    });
  };

  const handleAddCaseNoteHandler = async () => {
    if (!checkAddCaseNotevalidation()) return;
    setLoading(true);
    const resp = await createCaseNote({
      ...caseNoteObj,
      categoryId: caseNoteObj.category.id,
      clientId: id
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Case Note created successfully!',
        icon: 'success',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalAddCaseNotes(false);
      resetAddCaseNote();
      getListCaseNotesHandler({
        start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        pageNumber: 0,
        pageSize: 10,
        clientId: id
      });
    } else {
      setLoading(false);
      swal({
        title: 'Case Note creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalAddCaseNotes(false);
      resetAddCaseNote();
    }
  };

  const handleUpdateCaseNoteHandler = async () => {
    if (!checkUpdateCaseNotevalidation()) return;
    setLoading(true);
    const resp = await updateCaseNote(caseNoteEditObj && caseNoteEditObj.id, {
      ...caseNoteEditObj,
      categoryId: caseNoteEditObj.category.id
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Case Note updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setValidationMsgAddCaseNote('');
      setShowCustomModalUpdateCaseNotes(false);
      resetAddCaseNote();
      getListCaseNotesHandler({
        start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        pageNumber: 0,
        pageSize: 10,
        clientId: id
      });
    } else {
      setLoading(false);
      swal({
        title: 'Case Note updation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsgUpdateCaseNote('');
      setShowCustomModalUpdateCaseNotes(false);
      resetAddCaseNote();
    }
  };

  const handleDeleteCaseNote = async (obj) => {
    setCaseNoteEditObj(obj);
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateCaseNote(obj && obj.id, {
        ...obj,
        deleted: true,
        categoryId: obj.category.id
      });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Case Note deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getListCaseNotesHandler({
          start: startDate ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
          end: endDate ? moment(endDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
          pageNumber: 0,
          pageSize: 10,
          clientId: id
        });
      } else {
        setLoading(false);
        swal({
          title: 'Case Note deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const getCategories = async () => {
    const resp = await listMiscellaneousNoteCategories();
    if (!resp) {
      return;
    }
    if (resp.status === 200) {
      const categories = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'category'
      }));
      setCategoryList([...categories]);
    }
  };
  useEffect(() => {
    let payload = {
      pageNumber: 0,
      pageSize: 10,
      clientId: id
    };
    if (startDate && endDate) {
      payload = {
        ...payload,
        start: startDate && moment(startDate).format('YYYY-MM-DD'),
        end: endDate && moment(endDate).format('YYYY-MM-DD')
      };
    }
    getListCaseNotesHandler(payload);
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setCaseNoteObj({
      clientId: id,
      noteDate: '',
      subject: '',
      content: '',
      category: ''
    });
  }, [addCaseNoteFormData.category]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <DetailsHeader>
        <h2>Notes</h2>
        <button onClick={() => handleClick('/admin/clients')} type="button"> Back </button>
      </DetailsHeader>
      <DetailsWrapper>
        <ClientReportsWrapper>
          <div>
            <ReviewWrapper>
              <div className="review-row">

                <div className="w-25 mr">
                  <p className="label">
                    Select Category
                  </p>
                  <SelectContainer
                    options={categoryList}
                    placeholderText="Category"
                    className="select-container"
                    isMultiple={false}
                    onChange={handleCategoryChange}
                    defaultValue={{
                      label: category?.name,
                      value: category?.name,
                      id: category?.id
                    }}
                    disabled={!categoryList.length}
                  />
                </div>

                <div className="w-25 mr date-picker-wrapper">
                  <p className="label">
                    Select Start Date
                  </p>

                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="  Start date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>

                <div className="w-25 date-picker-wrapper">
                  <p className="label">
                    Select End Date
                  </p>

                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="  End date"
                    selected={startDate === null ? null : endDate}
                    onChange={(date) => setEndDate(date)}
                    disabled={!startDate}
                    minDate={startDate && startDate}
                  />
                </div>
                <ShowReportsButtonWrapper>
                  <button
                    type="button"
                    className="submit-btn"
                    onClick={handleClearSelection}
                  >
                    Clear Selection
                  </button>
                  <button
                    type="button"
                    className="submit-btn"
                    onClick={handleShowReports}
                  >
                    Show Notes
                  </button>
                </ShowReportsButtonWrapper>
              </div>
            </ReviewWrapper>
          </div>
          <AddFolder onClick={() => handleAdd()}>
            <img src={AddIcon} alt="add icon" />
          </AddFolder>
          {
          showCaseNotes
            ? (
              <CaseNoteRecordsWrapper>
                {
                  listCaseNotes.length === 0 && <NoData />
                }
                {
                  listCaseNotes.length > 0
                  && listCaseNotes.map((ele) => (
                    <CaseNoteRecord
                      date={ele.noteDate}
                      category={ele.category?.name}
                      // startTime={ele.startTime}
                      // endTime={ele.endTime}
                      subject={ele.subject}
                      content={ele.content}
                      openUpdateModal={() => handleUpdate(ele)}
                      deleteCaseNote={() => handleDeleteCaseNote(ele)}
                    />
                  ))
                }
              </CaseNoteRecordsWrapper>
            ) : null
        }
        </ClientReportsWrapper>
      </DetailsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>

      <CustomModal
        open={showCustomModalAddCaseNotes}
        setClose={() => {
          handleCloseCustomModal('add-case-notes');
        }}
        title="Add Case Notes"
        width="580px"
        operationName="Add"
        onSubmit={handleAddCaseNoteHandler}
      >
        <div>
          <FormWrapper>
            <div className="input-with-half-width">
              <label htmlFor="noteDate">Date</label>
              <input
                type="date"
                id="noteDate"
                placeholder="Date"
                name="noteDate"
                onChange={handleChange}
                value={caseNoteObj.noteDate}
                required
              />
            </div>
            <div className="input-with-half-width">
              <label htmlFor="Category">
                Select Category
              </label>
              <SelectContainer
                id="Category"
                options={categoryList.length > 0 && categoryList}
                placeholderText="Category"
                className="select-container"
                isMultiple={false}
                onChange={handleAddCategoryChange}
                defaultValue={{
                  label: caseNoteObj.category?.name,
                  value: caseNoteObj.category?.name,
                  id: category?.catId
                }}
                disabled={!categoryList.length > 0}
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                placeholder="Subject"
                name="subject"
                onChange={handleChange}
                value={caseNoteObj.subject}
                required
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="content">Content</label>
              <textarea
                rows="4"
                id="content"
                placeholder="Content"
                name="content"
                onChange={handleChange}
                value={caseNoteObj.content}
                required
              />
            </div>
          </FormWrapper>
          {validationMsgAddCaseNote !== '' && <h6 className="text-danger mt-3 ml-2" data-testid="validation-add-case">{validationMsgAddCaseNote}</h6>}
        </div>
      </CustomModal>

      <CustomModal
        open={showCustomModalUpdateCaseNotes}
        setClose={() => {
          handleCloseCustomModal('update-case-notes');
        }}
        title="Update Case Notes"
        width="580px"
        operationName="Update"
        onSubmit={handleUpdateCaseNoteHandler}
      >
        <div>
          <FormWrapper>
            <div className="input-with-half-width">
              <label htmlFor="noteDate">Date</label>
              <input
                type="date"
                id="noteDate"
                placeholder="Date"
                name="noteDate"
                onChange={handleUpdateChange}
                value={caseNoteEditObj && caseNoteEditObj.noteDate}
                required
              />
            </div>
            <div className="input-with-half-width">
              <label htmlFor="Category">
                Select Category
              </label>
              <SelectContainer
                id="Category"
                options={categoryList.length > 0 && categoryList}
                placeholderText="Category"
                className="select-container"
                isMultiple={false}
                onChange={handleUpdateCategoryChange}
                defaultValue={{
                  label: caseNoteEditObj.category?.name,
                  value: caseNoteEditObj.category?.name,
                  id: category?.catId
                }}
                disabled={!categoryList.length > 0}
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="subject">Subject</label>
              <input
                id="subject"
                placeholder="Subject"
                name="subject"
                onChange={handleUpdateChange}
                value={caseNoteEditObj && caseNoteEditObj.subject}
                required
              />
            </div>

            <div className="input-with-full-width">
              <label htmlFor="content">Content</label>
              <textarea
                rows="4"
                id="content"
                placeholder="Content"
                name="content"
                onChange={handleUpdateChange}
                value={caseNoteEditObj && caseNoteEditObj.content}
                required
              />
            </div>
          </FormWrapper>
          {validationMsgUpdateCaseNote !== '' && <h6 className="text-danger mt-3 ml-2">{validationMsgUpdateCaseNote}</h6>}
        </div>
      </CustomModal>

    </>
  );
};

AdminClientNotes.propTypes = {
  client: PropTypes.string.isRequired
};

export default AdminClientNotes;
