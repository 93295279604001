import jsPDF from 'jspdf';
import moment from 'moment';
import logoPic from '../../../../assets/logo/logo.png';

const generatePDFNight = (checklistData) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.addImage(logoPic, 'JPEG', 25, 5, 58, 58);
  doc.setFontSize(18);
  doc.setFont('helvetica');
  doc.text(
    'Nightly Tasks Checklist',
    275,
    35,
    null
  );
  doc.setFontSize(13);
  doc.setFont('helvetica bold');
  doc.text(
    'Client Name: ',
    30,
    80,
    null
  );
  doc.text(
    `${checklistData?.clientName}`,
    130,
    80,
    null
  );
  doc.text(
    'Date: ',
    647,
    80,
    null
  );
  doc.text(
    `${moment(checklistData?.reportDate).format('DD-MM-YYYY')}`,
    687,
    80,
    null
  );
  doc.roundedRect(
    30,
    90,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Clean and disinfect toilet',
    40,
    140,
    null
  );
  doc.line(
    380,
    90,
    380,
    200,
    null
  );
  doc.text(
    `${checklistData.cleanToiletBy !== '' ? 'Done' : ''}`,
    450,
    140,
    null
  );
  doc.text(
    `${checklistData.cleanToiletBy}`,
    700,
    160,
    null
  );
  doc.roundedRect(
    30,
    170,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Shower and bathroom cleaned – client / staff',
    35,
    210,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    200,
    380,
    250,
    null
  );
  doc.text(
    `${checklistData.cleanBathroomStaffBy !== '' ? 'Done' : ''}`,
    450,
    220,
    null
  );
  doc.text(
    `${checklistData.cleanBathroomStaffBy}`,
    700,
    240,
    null
  );
  doc.roundedRect(
    30,
    250,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Clean and tidy staff room / staffing areas',
    35,
    290,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    250,
    380,
    330,
    null
  );
  doc.text(
    `${checklistData.cleanBathroomClientBy !== '' ? 'Done' : ''}`,
    450,
    300,
    null
  );
  doc.text(
    `${checklistData.cleanBathroomClientBy}`,
    700,
    320,
    null
  );
  doc.roundedRect(
    30,
    330,
    740,
    80,
    null,
    null
  );
  doc.text(
    'General tidy on furniture, floors and benches',
    35,
    360,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    330,
    380,
    410,
    null
  );
  doc.text(
    `${checklistData.tidyFurnitureBy !== '' ? 'Done' : ''}`,
    450,
    380,
    null
  );
  doc.text(
    `${checklistData.tidyFurnitureBy}`,
    700,
    400,
    null
  );
  doc.roundedRect(
    30,
    410,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Floors vacuumed',
    35,
    460,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    410,
    380,
    490,
    null
  );
  doc.text(
    `${checklistData.vacuumFloorsBy !== '' ? 'Done' : ''}`,
    450,
    460,
    null
  );
  doc.text(
    `${checklistData.vacuumFloorsBy}`,
    700,
    480,
    null
  );
  doc.roundedRect(
    30,
    490,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Floors mopped',
    35,
    540,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    490,
    380,
    570,
    null
  );
  doc.text(
    `${checklistData.mopFloorsBy !== '' ? 'Done' : ''}`,
    450,
    540,
    null
  );
  doc.text(
    `${checklistData.mopFloorsBy}`,
    700,
    560,
    null
  );
  doc.roundedRect(
    30,
    570,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Put washing in the washing machine – Washing cycle',
    35,
    610,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    570,
    380,
    650,
    null
  );
  doc.text(
    `${checklistData.washingCycleBy !== '' ? 'Done' : ''}`,
    450,
    620,
    null
  );
  doc.text(
    `${checklistData.washingCycleBy}`,
    700,
    640,
    null
  );
  doc.roundedRect(
    30,
    650,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Placed clothes in the dryer – Drying cycle',
    35,
    690,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    650,
    380,
    730,
    null
  );
  doc.text(
    `${checklistData.dryingCycleBy !== '' ? 'Done' : ''}`,
    450,
    700,
    null
  );
  doc.text(
    `${checklistData.dryingCycleBy}`,
    700,
    720,
    null
  );
  doc.roundedRect(
    30,
    730,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Clothes folded and put away',
    35,
    780,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    730,
    380,
    810,
    null
  );
  doc.text(
    `${checklistData.foldClothesBy !== '' ? 'Done' : ''}`,
    450,
    780,
    null
  );
  doc.text(
    `${checklistData.foldClothesBy}`,
    700,
    800,
    null
  );
  doc.roundedRect(
    30,
    810,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Wash, dry and put away pots, dishes, cutlery and crockery',
    35,
    850,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    810,
    380,
    890,
    null
  );
  doc.text(
    `${checklistData.putAwayDishesBy !== '' ? 'Done' : ''}`,
    450,
    850,
    null
  );
  doc.text(
    `${checklistData.putAwayDishesBy}`,
    700,
    880,
    null
  );
  doc.roundedRect(
    30,
    890,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Tidy Staff Room',
    35,
    950,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    890,
    380,
    970,
    null
  );
  doc.text(
    `${checklistData.tidyStaffRoomBy !== '' ? 'Done' : ''}`,
    450,
    940,
    null
  );
  doc.text(
    `${checklistData.tidyStaffRoomBy}`,
    700,
    960,
    null
  );
  doc.roundedRect(
    30,
    970,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Charge electronics – phones, iPad etc',
    35,
    1010,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    970,
    380,
    1050,
    null
  );
  doc.text(
    `${checklistData.chargeElectronicsBy !== '' ? 'Done' : ''}`,
    450,
    1010,
    null
  );
  doc.text(
    `${checklistData.chargeElectronicsBy}`,
    700,
    1040,
    null
  );
  doc.addPage();
  doc.roundedRect(
    30,
    60,
    740,
    100,
    null,
    null
  );
  doc.text(
    'Completion of daily documents – Daily notes, communication book, bowel chart, food diary, behaviour escalation chart etc',
    35,
    90,
    {
      maxWidth: '350'
    }
  );
  doc.line(
    380,
    60,
    380,
    160,
    null
  );
  doc.text(
    `${checklistData.completedDailyReportsBy !== '' ? 'Done' : ''}`,
    450,
    120,
    null
  );
  doc.text(
    `${checklistData.completedDailyReportsBy}`,
    700,
    150,
    null
  );
  doc.roundedRect(
    30,
    160,
    740,
    100,
    null,
    null
  );
  doc.text(
    'Check medication chart and ensure each entry is completed, including date & signatures (complete incident report if missing)',
    35,
    190,
    {
      maxWidth: '350'
    }
  );
  doc.line(
    380,
    160,
    380,
    260,
    null
  );
  doc.text(
    `${checklistData.checkMedicationChartBy !== '' ? 'Done' : ''}`,
    450,
    220,
    null
  );
  doc.text(
    `${checklistData.checkMedicationChartBy}`,
    700,
    250,
    null
  );
  doc.roundedRect(
    30,
    260,
    740,
    100,
    null,
    null
  );
  doc.text(
    'Fill out any incident reports and email or message Manager regarding need for further follow up',
    35,
    290,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    260,
    380,
    360,
    null
  );
  doc.text(
    `${checklistData.fillIncidentReportBy !== '' ? 'Done' : ''}`,
    450,
    320,
    null
  );
  doc.text(
    `${checklistData.fillIncidentReportBy}`,
    700,
    350,
    null
  );
  doc.roundedRect(
    30,
    360,
    740,
    100,
    null,
    null
  );
  doc.text(
    'Check food in fridge – dispose of all out-of-date items & ensure all items are labelled appropriately',
    35,
    390,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    360,
    380,
    460,
    null
  );
  doc.text(
    `${checklistData.checkFridgeBy !== '' ? 'Done' : ''}`,
    450,
    420,
    null
  );
  doc.text(
    `${checklistData.checkFridgeBy}`,
    700,
    450,
    null
  );
  doc.roundedRect(
    30,
    460,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Empty and replace all inside rubbish bin bags, clean bins',
    35,
    490,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    460,
    380,
    540,
    null
  );
  doc.text(
    `${checklistData.emptyDustbinsBy !== '' ? 'Done' : ''}`,
    450,
    500,
    null
  );
  doc.text(
    `${checklistData.emptyDustbinsBy}`,
    700,
    530,
    null
  );
  doc.roundedRect(
    30,
    540,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Rubbish bins taken out / brought in',
    35,
    590,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    540,
    380,
    620,
    null
  );
  doc.text(
    `${checklistData.takeOutDustbinsBy !== '' ? 'Done' : ''}`,
    450,
    590,
    null
  );
  doc.text(
    `${checklistData.takeOutDustbinsBy}`,
    700,
    610,
    null
  );
  doc.roundedRect(
    30,
    620,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Vehicle – vacuum floor and wipe over all surfaces',
    35,
    660,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    620,
    380,
    700,
    null
  );
  doc.text(
    `${checklistData.vacuumVehicleBy !== '' ? 'Done' : ''}`,
    450,
    670,
    null
  );
  doc.text(
    `${checklistData.vacuumVehicleBy}`,
    700,
    690,
    null
  );
  doc.roundedRect(
    30,
    700,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Vehicle – ensure all items in vehicle checklist is located  and available within the vehicle',
    35,
    730,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    700,
    380,
    780,
    null
  );
  doc.text(
    `${checklistData.checklistVehicleBy !== '' ? 'Done' : ''}`,
    450,
    750,
    null
  );
  doc.text(
    `${checklistData.checklistVehicleBy}`,
    700,
    770,
    null
  );
  doc.roundedRect(
    30,
    780,
    740,
    80,
    null,
    null
  );
  doc.text(
    'Vehicle – Ensure child lock is on / activated',
    35,
    820,
    {
      maxWidth: '300'
    }
  );
  doc.line(
    380,
    780,
    380,
    860,
    null
  );
  doc.text(
    `${checklistData.childLockVehicleBy !== '' ? 'Done' : ''}`,
    450,
    830,
    null
  );
  doc.text(
    `${checklistData.childLockVehicleBy}`,
    700,
    850,
    null
  );

  // doc.output('dataurlnewwindow');
  doc.save(`NTC-Report-${checklistData?.clientName}`);
};

export default generatePDFNight;
