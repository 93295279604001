import React from 'react';

export default function EditShift() {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.531181 7.57945L0.408691 11.3839L4.2131 11.2614L11.7985 3.67596L8.11084 0L0.531181 7.57945ZM3.83967 10.3977L1.31316 10.4794L1.39486 7.95294L6.06297 3.28483L8.51358 5.72389L3.83967 10.3977ZM9.13183 5.10527L6.68701 2.66045L8.11079 1.23667L10.5556 3.68162L9.13183 5.10527Z" fill="#E86B55" />
      <path d="M0 12.376H11.9618V13.2512H0V12.376Z" fill="#E86B55" />
    </svg>

  );
}
