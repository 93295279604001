import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BsUpload } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { Modal, Spinner, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from 'sweetalert';
import editIcon from '../../assets/logo/edit.svg';
import plusIcon from '../../assets/logo/plus.svg';
import detailIcon from '../../assets/logo/detail.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import SelectContainer from '../SelectContainer';
import uploadImage from '../../assets/logo/upload.svg';
import {
  ODCarOptions, TravelProtocolOptions, budgetTypeOfCardOptions, budgetTypeOptions,
  ComprehensiveInsuranceOptions, RoadSideAssistanceCoveredOptions, TypeOptions, InvoiceToOptions
} from '../../lookups/lookups';
import {
  getClientTransport,
  updateClientTransport,
  createClientTransport,
  getClientDetails,
  getClient,
  getClientAllowance,
  createClientDetails,
  createClientAllowance,
  updateClientAllowance,
  updateClientDetails,
  uploadSupportDoc,
  uploadOtherDoc,
  getBudgetlyCard,
  createBudgetlyCard,
  updateBudgetlyCard
} from '../../services/result.services';
import CustomModal from '../CustomModal';
import {
  FinanceHeader,
  FinanceDetails,
  LoadingModal,
  FormWrapper,
  StyledSelect,
  Td,
  Th,
  TableWrapper,
  StyledTable,
  TableHeader,
  IconContainer,
  TooltipCustom,
  UploadButton
} from './index.style';
import { DetailsWrapper } from '../../styledComponents/common/page.style';

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? '#F8F1EB' : styles.color,
    '&:hover': { color: 'black', backgroundColor: '#F8F1EB' }
  })
};

const AdminClientFinance = ({ client }) => {
  const { id } = client;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [NDISDetails, setNDISDetails] = useState();
  const [uploadFile, setUploadFile] = useState(null);
  const [clientNDISDetails, setClientNDISDetails] = useState(null);
  const [clientTransport, setClientTransport] = useState();
  const [clientAllowance, setClientAllowance] = useState();
  const [clientDetails, setClientDetails] = useState();
  const [budgetlyCard, setBudgetlyCard] = useState();
  const [showCustomModalEditDetails, setShowCustomModalEditDetails] = useState(false);
  const [showCustomModalEditTransport, setShowCustomModalEditTransport] = useState(false);
  const [showCustomModalEditAllowance, setShowCustomModalEditAllowance] = useState(false);
  const [showCustomModalEditCard, setShowCustomModalEditCard] = useState(false);
  const [showCustomModalAddDetails, setShowCustomModalAddDetails] = useState(false);
  const [showCustomModalAddTransport, setShowCustomModalAddTransport] = useState(false);
  const [showCustomModalAddAllowance, setShowCustomModalAddAllowance] = useState(false);
  const [showCustomModalAddCard, setShowCustomModalAddCard] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const history = useHistory();
  const [labelUploadFileName, setLabelUploadFileName] = useState('');
  const [inputError, setInputError] = useState([]);
  const userName = JSON.parse(localStorage.getItem('user-details'));
  const [showCustomModalUploadPlan, setShowCustomModalUploadPlan] = useState(false);
  const [showCustomModalUploadPlanOther, setShowCustomModalUploadPlanOther] = useState(false);
  const handleUploadPlanModal = (user) => {
    setShowCustomModalUploadPlan(true);
    setNDISDetails(user);
  };
  const handleUploadPlanModalOther = (user) => {
    setShowCustomModalUploadPlanOther(true);
    setNDISDetails(user);
  };

  const [transportInfo, setTransportInfo] = useState({
    odCar: '',
    carRegistration: '',
    carRegExpiry: '',
    carModel: '',
    carMakeYear: '',
    isTravelProtocol: '',
    travelProtocol: '',
    cappedKMs: '',
    comprehensiveInsurance: '',
    insurancePolicyNumber: '',
    authorisedPerson: '',
    authorisedPersonContactNumber: '',
    roadSideAssistanceCovered: 'No',
    insuranceAgency: '',
    insuranceContactNumber: '',
    lastUploadedBy: userName.firstName
  });
  const [detailsInfo, setDetailsInfo] = useState({
    startDate: '',
    endDate: '',
    fundingType: '',
    level: '',
    lastUploadedBy: userName.firstName
  });
  const [allowanceInfo, setAllowanceInfo] = useState({
    type: '',
    clientContribution: '',
    odContribution: '',
    invoiceTo: '',
    email: '',
    lastUploadedBy: userName.firstName
  });
  const [budgetlyCardInfo, setBudgetlyCardInfo] = useState({
    typeOfCard: '',
    type: '',
    name: '',
    nameOnCard: '',
    cardNo: '',
    cvv: '',
    expiryDate: ''
  });
  const clearInput = () => {
    setTransportInfo({
      odCar: '',
      carRegistration: '',
      carRegExpiry: '',
      carModel: '',
      carMakeYear: '',
      isTravelProtocol: '',
      travelProtocol: '',
      cappedKMs: '',
      comprehensiveInsurance: '',
      insurancePolicyNumber: '',
      authorisedPerson: '',
      authorisedPersonContactNumber: '',
      roadSideAssistanceCovered: 'No',
      insuranceAgency: '',
      insuranceContactNumber: '',
      lastUploadedBy: userName.firstName
    });
    setDetailsInfo({
      startDate: '',
      endDate: '',
      fundingType: '',
      level: '',
      lastUploadedBy: userName.firstName
    });
    setAllowanceInfo({
      type: '',
      clientContribution: '',
      odContribution: '',
      invoiceTo: '',
      email: '',
      lastUploadedBy: userName.firstName
    });
    setBudgetlyCardInfo({
      typeOfCard: '',
      type: '',
      name: '',
      nameOnCard: '',
      cardNo: '',
      cvv: '',
      expiryDate: ''
    });
    setUploadFile(null);
    setLabelUploadFileName('');
  };
  const goToSupportDoc = (supportDocument) => {
    history.push('/supportDoc', { client, supportDocument });
  };
  const goToOtherDoc = (otherDocument) => {
    history.push('/otherDoc', { client, otherDocument });
  };
  const renderTooltip = (props) => (
    <TooltipCustom className="tool">
      {props}
    </TooltipCustom>
  );
  const handleCloseCustomModalOther = (operation) => {
    if (operation === 'upload-plan') {
      setShowCustomModalUploadPlanOther(false);
    }
  };
  const checkSelectFields = () => {
    const checkArray = ['type', 'invoiceTo', 'type', 'typeOfCard'];
    const errorItems = [];
    checkArray.forEach((item) => {
      if (!allowanceInfo[item]) {
        errorItems.push(item);
      }
    });
    checkArray.forEach((item) => {
      if (!budgetlyCardInfo[item]) {
        errorItems.push(item);
      }
    });
    return errorItems;
  };
  const handleSelect = (selected) => {
    const { name, value } = selected;
    setInputError(inputError.filter((item) => item !== name));
    setAllowanceInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setBudgetlyCardInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClick = (link) => history.push(link);
  const checkValidationTransport = () => {
    if (transportInfo.carRegistration === '') {
      setValidationMsg('Car Registration cannot be empty');
      return false;
    }
    if (transportInfo.carRegExpiry === '') {
      setValidationMsg('Car RegExpiry cannot be empty');
      return false;
    }
    if (transportInfo.carMakeYear === '') {
      setValidationMsg('Car Make Year cannot be empty');
      return false;
    }
    if (transportInfo.isTravelProtocol === '') {
      setValidationMsg('Travel Protocol cannot be empty');
      return false;
    }
    if (transportInfo.isTravelProtocol === 'Yes') {
      if (transportInfo.travelProtocol === '') {
        setValidationMsg('Travel Protocol cannot be empty');
        return false;
      }
    }
    if (transportInfo.comprehensiveInsurance === '') {
      setValidationMsg('Comprehensive Insurance cannot be empty');
      return false;
    }
    if (transportInfo.cappedKMs === '') {
      setValidationMsg('Capped KMs cannot be empty');
      return false;
    }
    if (transportInfo.comprehensiveInsurance === 'Yes') {
      if (transportInfo.roadSideAssistanceCovered === '') {
        setValidationMsg('Road Side Assistance Covered cannot be empty');
        return false;
      }
      if (transportInfo.insuranceContactNumber !== '') {
        const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
        if (!re.test(String(transportInfo.insuranceContactNumber))) {
          setValidationMsg('Enter a valid phone number');
          return false;
        }
      }
      if (transportInfo.authorisedPersonContactNumber !== '') {
        const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
        if (!re.test(String(transportInfo.authorisedPersonContactNumber))) {
          setValidationMsg('Enter a valid phone number');
          return false;
        }
      }
    }
    setValidationMsg('');
    return true;
  };
  const checkValidationDetails = () => {
    if (detailsInfo.startDate === '') {
      setValidationMsg('Start Date cannot be empty');
      return false;
    }
    if (detailsInfo.endDate === '') {
      setValidationMsg('End Date cannot be empty');
      return false;
    }
    if (detailsInfo.fundingType === '') {
      setValidationMsg('Funding cannot be empty');
      return false;
    }
    if (detailsInfo.level === '') {
      setValidationMsg('Level cannot be empty');
      return false;
    }
    setValidationMsg('');
    return true;
  };
  const checkValidationAllowance = () => {
    if (allowanceInfo.type === '') {
      setValidationMsg('Type cannot be empty');
      return false;
    }
    if (allowanceInfo.clientContribution === '') {
      const inputElement = document.getElementById('clientContribution');
      inputElement.style.border = '1px solid red';
      setValidationMsg('Client contribution cannot be empty');
      return false;
    }
    if (allowanceInfo.odContribution === '') {
      const inputElement = document.getElementById('odContribution');
      inputElement.style.border = '1px solid red';
      setValidationMsg('OD contribution cannot be empty');
      return false;
    }
    if (allowanceInfo.invoiceTo === '') {
      setValidationMsg('Invoice To cannot be empty');
      return false;
    }
    if (allowanceInfo.email === '') {
      const inputElement = document.getElementById('email');
      inputElement.style.border = '1px solid red';
      setValidationMsg('Email cannot be empty');
      return false;
    }
    if (allowanceInfo.email !== '') {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!re.test(String(allowanceInfo.email))) {
        setValidationMsg('Enter a valid Email Address');
        return false;
      }
    }
    setValidationMsg('');
    return true;
  };
  const checkValidationBudgetlyCard = () => {
    const selectFields = checkSelectFields(budgetlyCardInfo);
    let checkFlag = true;
    if (selectFields) {
      setInputError(selectFields);

      if (budgetlyCardInfo.type === '') {
        checkFlag = false;
      }
      if (budgetlyCardInfo.name === '') {
        const inputElement = document.getElementById('name');
        inputElement.style.border = '1px solid red';
        checkFlag = false;
      }
      if (budgetlyCardInfo.cardNo === '' || budgetlyCardInfo.cardNo?.toString().length !== 16) {
        setValidationMsg('Card Number should be 16 digits');
        const inputElement = document.getElementById('cardNo');
        inputElement.style.border = '1px solid red';
        checkFlag = false;
      }
      if (budgetlyCardInfo.cvv === '' || budgetlyCardInfo.cvv?.toString().length !== 3) {
        setValidationMsg('Cvv should be 3 digits');
        const inputElement = document.getElementById('cvv');
        inputElement.style.border = '1px solid red';
        checkFlag = false;
      }
      if (budgetlyCardInfo.expiryDate === '') {
        const inputElement = document.getElementById('expiryDate');
        inputElement.style.border = '1px solid red';
        checkFlag = false;
      }
      if (budgetlyCardInfo.typeOfCard === '') {
        checkFlag = false;
      }
    }
    return checkFlag;
  };
  const handleFileChange = (e) => {
    if (e.currentTarget) {
      setLabelUploadFileName(
        e.currentTarget.files[0].name || ''
      );
      setUploadFile(e.currentTarget.files[0]);
    }
  };
  const handleFileChangeOther = (e) => {
    if (e.currentTarget) {
      setLabelUploadFileName(
        e.currentTarget.files[0].name || ''
      );
      setUploadFile(e.currentTarget.files[0]);
    }
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'upload-plan') {
      setShowCustomModalUploadPlan(false);
    }
  };

  const getDetails = async () => {
    const respDetails = await getClientDetails(client.id);
    if (respDetails.status === 200) {
      setClientDetails(respDetails.data);
    }
    const respTransport = await getClientTransport(client.id);
    if (respTransport.status === 200) {
      setClientTransport(respTransport.data);
    }
    const respAllowance = await getClientAllowance(client.id);
    if (respAllowance.status === 200) {
      setClientAllowance(respAllowance.data);
    }
    const respBudgetlyCard = await getBudgetlyCard(client.id);
    if (respBudgetlyCard.status === 200) {
      setBudgetlyCard(respBudgetlyCard.data);
    }
  };
  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('clientName', new Blob([JSON.stringify(client.name)], { type: 'application/json' }));
    formData.append('clientNDISPlanId', new Blob([JSON.stringify(NDISDetails.id)], { type: 'application/json' }));
    const resp = await uploadSupportDoc(client.id, formData);
    if (!resp) {
      clearInput();
      setLoading(false);
    } else if (resp.status === 200) {
      clearInput();
      setLoading(false);
      swal({
        title: 'Uploaded Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      setShowCustomModalUploadPlan(false);
    } else {
      clearInput();
      setLoading(false);
      swal({
        title: 'Failed to upload',
        icon: 'error',
        timer: 3000
      });
    }
  };
  const handleEditTransport = async () => {
    if (!checkValidationTransport()) return;
    setLoading(true);
    const resp = await updateClientTransport(transportInfo.id, transportInfo);
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditTransport(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditTransport(false);
      swal({
        title: 'Client Transport Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditTransport(false);
      swal({
        title: 'Client Transport Updation Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleEditAllowance = async () => {
    if (!checkValidationAllowance()) return;
    setLoading(true);
    const resp = await updateClientAllowance(allowanceInfo.id, allowanceInfo);
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditAllowance(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditAllowance(false);
      swal({
        title: 'Client Allowance Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditAllowance(false);
      swal({
        title: 'Client Allowance Updation Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };

  function getDateString(dateStr) {
    if (dateStr.split('-').length > 2) {
      return dateStr;
    }
    return `${dateStr}-01`;
  }
  const handleEditCard = async () => {
    if (!checkValidationBudgetlyCard()) return;
    setLoading(true);
    const resp = await updateBudgetlyCard(budgetlyCardInfo.budgetlyCardId, {
      ...budgetlyCardInfo,
      expiryDate: getDateString(budgetlyCardInfo.expiryDate)
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditCard(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setValidationMsg('');
      setShowCustomModalEditCard(false);
      swal({
        title: 'Budgetly Card Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditCard(false);
      swal({
        title: 'Budgetly Card Updation Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleEditDetails = async () => {
    if (!checkValidationDetails()) return;
    setLoading(true);
    const resp = await updateClientDetails(detailsInfo.id, detailsInfo);
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditDetails(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditDetails(false);
      swal({
        title: 'Client Details Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditDetails(false);
      swal({
        title: 'Client Details Updation Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleAddTransport = async () => {
    if (!checkValidationTransport()) return;
    setLoading(true);
    const resp = await createClientTransport({
      ...transportInfo,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddTransport(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddTransport(false);
      swal({
        title: 'Client Transport Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddTransport(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleAddAllowance = async () => {
    if (!checkValidationAllowance()) return;
    setLoading(true);
    const resp = await createClientAllowance({
      ...allowanceInfo,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddAllowance(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddAllowance(false);
      swal({
        title: 'Client Allowance Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddAllowance(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleAddDetails = async () => {
    if (!checkValidationDetails()) return;
    setLoading(true);
    const resp = await createClientDetails({
      ...detailsInfo,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddDetails(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddDetails(false);
      swal({
        title: 'Client Details Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddDetails(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleAddCard = async () => {
    if (!checkValidationBudgetlyCard()) return;
    setLoading(true);
    const resp = await createBudgetlyCard({
      ...budgetlyCardInfo,
      expiryDate: `${budgetlyCardInfo.expiryDate}-01`,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddCard(false);
      return;
    }
    if (resp.status === 200) {
      setValidationMsg('');
      setLoading(false);
      setShowCustomModalAddCard(false);
      swal({
        title: 'Card Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddCard(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };

  const handleAdd = (caller) => {
    if (caller === 'clientDetails') {
      clearInput();
      setShowCustomModalAddDetails(true);
    }
    if (caller === 'clientTransport') {
      clearInput();
      setShowCustomModalAddTransport(true);
    }
    if (caller === 'clientAllowance') {
      setValidationMsg('');
      clearInput();
      setShowCustomModalAddAllowance(true);
    }
    if (caller === 'budgetlyCard') {
      clearInput();
      setValidationMsg('');
      setShowCustomModalAddCard(true);
    }
  };
  const handleEdit = (caller, item) => {
    if (caller === 'clientDetails') {
      clearInput();
      setDetailsInfo(item);
      setShowCustomModalEditDetails(true);
    }
    if (caller === 'clientTransport') {
      clearInput();
      setTransportInfo(item);
      setShowCustomModalEditTransport(true);
    }
    if (caller === 'clientAllowance') {
      setValidationMsg('');
      clearInput();
      setAllowanceInfo(item);
      setShowCustomModalEditAllowance(true);
    }
    if (caller === 'budgetlyCard') {
      clearInput();
      setBudgetlyCardInfo(item);
      setShowCustomModalEditCard(true);
    }
  };
  const handleModalClose = (caller) => {
    if (caller === 'details') {
      setShowCustomModalAddDetails(false);
      setShowCustomModalEditDetails(false);
    }
    if (caller === 'clientTransport') {
      setShowCustomModalEditTransport(false);
      setShowCustomModalAddTransport(false);
    }
    if (caller === 'clientAllowance') {
      setShowCustomModalEditAllowance(false);
      setShowCustomModalAddAllowance(false);
    }
    if (caller === 'budgetlyCard') {
      setShowCustomModalEditCard(false);
      setShowCustomModalAddCard(false);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'selected' && value !== 'Other') {
      setTransportInfo((prevState) => ({
        ...prevState,
        relation: value,
        selected: ''
      }));
    } else if (name === 'selected' && value === 'Other') {
      setTransportInfo((prevState) => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setTransportInfo((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleChangeDetails = (event) => {
    const { name, value } = event.target;
    setDetailsInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeAllowance = (event) => {
    const { name, value } = event.target;
    document.getElementById(name).style.border = '1px solid #F2E4D8';
    setAllowanceInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeBudgetlyCard = (event) => {
    const { name, value } = event.target;
    document.getElementById(name).style.border = '1px solid #F2E4D8';
    setBudgetlyCardInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDeleteTransport = async (transport) => {
    const value = await swal({
      text: `Are you sure you want to delete ${transport.carRegistration}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateClientTransport(transport.id, { ...transport, deleted: true, status: 'INACTIVE' });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${transport.carRegistration} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Transport deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleDeleteDetails = async (details) => {
    const value = await swal({
      text: 'Are you sure you want to delete ?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateClientDetails(details.id, { ...details, deleted: true, status: 'INACTIVE' });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Details deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Details deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleDeleteAllowance = async (allowance) => {
    const value = await swal({
      text: 'Are you sure you want to delete ?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateClientAllowance(allowance.id, { ...allowance, deleted: true, status: 'INACTIVE' });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Allowance deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Details deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleDeleteBudgetlyCard = async (cardDetails) => {
    const value = await swal({
      text: 'Are you sure you want to delete ?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateBudgetlyCard(cardDetails.budgetlyCardId, {
        ...cardDetails, deleted: true
      });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Card deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Details deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getClientHandler = async (clientId) => {
    setLoading(true);
    const resp = await getClient(clientId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setClientNDISDetails(resp.data);
    } else {
      setLoading(false);
    }
  };

  const handleUploadOtherDocument = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('clientName', new Blob([JSON.stringify(client.name)], { type: 'application/json' }));
    formData.append('clientNDISPlanId', new Blob([JSON.stringify(NDISDetails.id)], { type: 'application/json' }));
    const resp = await uploadOtherDoc(client.id, formData);
    if (!resp) {
      clearInput();
      setLoading(false);
    } else if (resp.status === 200) {
      clearInput();
      setLoading(false);
      swal({
        title: 'Uploaded!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      setShowCustomModalUploadPlanOther(false);
    } else {
      clearInput();
      setLoading(false);
      swal({
        title: 'Failed to upload',
        icon: 'error',
        timer: 3000
      });
    }
  };
  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);
  useEffect(() => {
    getDetails();
    getClientHandler(id);
  }, []);
  return (
    <>
      <FinanceHeader>
        <h2>Finance</h2>
        <button onClick={() => handleClick('/admin/clients')} type="button"> Back </button>
      </FinanceHeader>
      <DetailsWrapper>
        <FinanceDetails>
          <TableHeader>
            <h3>NDIS Details</h3>
            <div style={{ display: 'flex', height: '10px' }}>
              <h6 style={{ fontWeight: 'bold' }}>NDIS Number</h6>
              <h6>
                {' '}
              &nbsp;&nbsp;
                {clientNDISDetails && clientNDISDetails.ndisNumber}
              </h6>
            </div>
            <div style={{ display: 'flex', height: '10px' }}>
              <h6 style={{ fontWeight: 'bold', marginLeft: '-100px' }}>Date Of Birth</h6>
              <h6>
                {' '}
                {' '}
              &nbsp;&nbsp;
                {' '}
                {moment(clientNDISDetails && clientNDISDetails.dob).format('DD-MM-YYYY')}
              </h6>
            </div>
            <button type="button" onClick={() => handleAdd('clientDetails')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>

            <StyledTable>
              <thead>
                <tr>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Funding Type</Th>
                  <Th>Level</Th>
                  <Th>Support Documents</Th>
                  <Th>Other Documents</Th>
                  <Th>Action</Th>

                </tr>
              </thead>
              <tbody>
                {clientDetails
                                  && clientDetails.filter((item) => item.deleted !== true)
                                    .map((user) => (
                                      <tr key={user.id}>
                                        <Td>{moment(user.startDate).format('DD-MM-YYYY')}</Td>
                                        <Td>{moment(user.endDate).format('DD-MM-YYYY')}</Td>
                                        <Td>{user.fundingType}</Td>
                                        <Td>{user.level}</Td>
                                        <Td>

                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('Download')}
                                          >
                                            <a
                                              href={user.supportDocument}
                                              className={user.supportDocument === '' && 'disabledatag'}
                                            >
                                              <UploadButton
                                                disabled={
                                               user.supportDocument === ''
                                              }
                                              >

                                                {' '}
                                                <img
                                                  src={uploadImage}
                                                  alt="plan icon"
                                                />
                                              </UploadButton>
                                            </a>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('View')}
                                          >
                                            <UploadButton
                                              disabled={
                                              (user.supportDocument.includes('.xlsx')
                                              ) || (user.supportDocument.includes('.xls')
                                              ) || (user.supportDocument.includes('.docx')
                                              ) || (user.supportDocument === '')
                                              }
                                              onClick={() => goToSupportDoc(user)}
                                            >
                                              {' '}
                                              <img
                                                src={detailIcon}
                                                alt="plan icon"
                                              />
                                            </UploadButton>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('Upload')}
                                          >
                                            <UploadButton
                                              onClick={() => handleUploadPlanModal(user)}
                                            >

                                              <BsUpload />
                                            </UploadButton>
                                          </OverlayTrigger>
                                        </Td>

                                        <Td>
                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('Download')}
                                          >
                                            <a
                                              href={user.otherDocument}
                                              className={user.otherDocument === '' && 'disabledatag'}
                                            >
                                              <UploadButton
                                                disabled={
                                               user.supportDocument === ''
                                              }
                                              >

                                                {' '}
                                                <img
                                                  src={uploadImage}
                                                  alt="plan icon"
                                                />
                                              </UploadButton>
                                            </a>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('View')}
                                          >
                                            <UploadButton
                                              disabled={
                                              (user.otherDocument.includes('.xlsx')
                                              ) || (user.otherDocument.includes('.xls')
                                              ) || (user.otherDocument.includes('.docx')
                                              ) || (user.otherDocument === '')
                                              }
                                              onClick={() => goToOtherDoc(user)}
                                            >
                                              {' '}
                                              <img
                                                src={detailIcon}
                                                alt="plan icon"
                                              />
                                            </UploadButton>
                                          </OverlayTrigger>

                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('Upload')}
                                          >
                                            <UploadButton
                                              onClick={() => handleUploadPlanModalOther(user)}
                                            >

                                              <BsUpload />
                                            </UploadButton>
                                          </OverlayTrigger>
                                        </Td>

                                        <Td>
                                          <IconContainer
                                            onClick={() => handleEdit('clientDetails', user)}
                                          >
                                            <img src={editIcon} alt="edit icon" />
                                          </IconContainer>

                                          <IconContainer
                                            className="delete-btn"
                                            onClick={() => handleDeleteDetails(user)}
                                          >
                                            <img src={deleteIcon} alt="delete icon" />
                                          </IconContainer>
                                        </Td>
                                      </tr>
                                    ))}
              </tbody>
            </StyledTable>

          </TableWrapper>
        </FinanceDetails>
        <FinanceDetails>
          <TableHeader>
            <h3>Transport</h3>
            <button type="button" onClick={() => handleAdd('clientTransport')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <Th>OD Car</Th>
                  <Th>Car Registration</Th>
                  <Th>Car RegExpiry</Th>
                  <Th>Insurance Policy No</Th>
                  <Th>Insurance Agency</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {clientTransport
                                  && clientTransport.filter((item) => item.deleted !== true)
                                    .map((user) => (
                                      <tr key={user.id}>
                                        <Td>{user.odCar}</Td>
                                        <Td>{user.carRegistration}</Td>
                                        <Td>{user.carRegExpiry}</Td>
                                        <Td>{user.insurancePolicyNumber}</Td>
                                        <Td>{user.insuranceAgency}</Td>
                                        <Td>
                                          <IconContainer
                                            onClick={() => handleEdit('clientTransport', user)}
                                          >
                                            <img src={editIcon} alt="edit icon" />
                                          </IconContainer>
                                          <IconContainer
                                            className="delete-btn"
                                            onClick={() => handleDeleteTransport(user)}
                                          >
                                            <img src={deleteIcon} alt="delete icon" />
                                          </IconContainer>
                                        </Td>
                                      </tr>
                                    ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
        </FinanceDetails>
        <FinanceDetails>
          <TableHeader>
            <h3>Allowances</h3>
            <button type="button" onClick={() => handleAdd('clientAllowance')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>

            <StyledTable>
              <thead>
                <tr>
                  <Th>Type </Th>
                  <Th>Client Contribution</Th>
                  <Th>OD Contribution</Th>
                  <Th>Invoice To</Th>
                  <Th>Email</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {clientAllowance
                                  && clientAllowance.filter((item) => item.deleted !== true)
                                    .map((user) => (
                                      <tr key={user.id}>
                                        <Td>{user.type}</Td>
                                        <Td>{user.clientContribution}</Td>
                                        <Td>{user.odContribution}</Td>
                                        <Td>{user.invoiceTo}</Td>
                                        <Td>
                                          {user.email}
                                        </Td>
                                        <Td>
                                          <IconContainer
                                            onClick={() => handleEdit('clientAllowance', user)}
                                          >
                                            <img src={editIcon} alt="edit icon" />
                                          </IconContainer>

                                          <IconContainer
                                            className="delete-btn"
                                            onClick={() => handleDeleteAllowance(user)}
                                          >
                                            <img src={deleteIcon} alt="delete icon" />
                                          </IconContainer>
                                        </Td>
                                      </tr>
                                    ))}
              </tbody>
            </StyledTable>

          </TableWrapper>
        </FinanceDetails>
        <FinanceDetails>
          <TableHeader>
            <h3>Budgetly Card</h3>
            <button type="button" onClick={() => handleAdd('budgetlyCard')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>

            <StyledTable>
              <thead>
                <tr>
                  <Th>Type of Card</Th>
                  <Th>Type</Th>
                  <Th>Name</Th>
                  <Th>Card Number</Th>
                  <Th>Expiry Date</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {budgetlyCard
                                  && budgetlyCard
                                    .map((user) => (
                                      <tr key={user.clientId}>
                                        <Td>{user.typeOfCard}</Td>
                                        <Td>{user.type}</Td>
                                        <Td>{user.name}</Td>
                                        <Td>{user.cardNo}</Td>
                                        <Td>
                                          {moment(user.expiryDate).format('MMMM,YYYY')}
                                        </Td>
                                        <Td>
                                          <IconContainer
                                            onClick={() => handleEdit('budgetlyCard', user)}
                                          >
                                            <img src={editIcon} alt="edit icon" />
                                          </IconContainer>

                                          <IconContainer
                                            className="delete-btn"
                                            onClick={() => handleDeleteBudgetlyCard(user)}
                                          >
                                            <img src={deleteIcon} alt="delete icon" />
                                          </IconContainer>
                                        </Td>
                                      </tr>
                                    ))}
              </tbody>
            </StyledTable>

          </TableWrapper>
        </FinanceDetails>
      </DetailsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalUploadPlan}
        setClose={() => {
          handleCloseCustomModal('upload-plan');
          clearInput();
        }}
        title="Upload Support Document"
        width="680px"
        onSubmit={handleUpload}
        operationName="Upload"
        isDisabled={!uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <div className="icon-name-wrapper">
                <label htmlFor="uploadFile">
                  <BsUpload />
                  <input
                    type="file"
                    id="uploadFile"
                    name="uploadFile"
                    accept="image/png, image/jpeg, application/pdf"
                    hidden
                    onChange={handleFileChange}
                  />
                  <span className="ellipsis">
                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUploadPlanOther}
        setClose={() => {
          handleCloseCustomModalOther('upload-plan');
          clearInput();
        }}
        title="Upload Other Document"
        width="680px"
        onSubmit={handleUploadOtherDocument}
        operationName="Upload"
        isDisabled={!uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <div className="icon-name-wrapper">
                <label htmlFor="uploadFile">
                  <BsUpload />
                  <input
                    type="file"
                    id="uploadFile"
                    name="uploadFile"
                    accept="image/png, image/jpeg, application/pdf"
                    hidden
                    onChange={handleFileChangeOther}
                  />
                  <span className="ellipsis">
                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditDetails}
        setClose={() => {
          handleModalClose('details');
        }}
        title="Edit NDIS Details"
        width="680px"
        operationName="Update"
        onSubmit={handleEditDetails}
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="dob">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                defaultValue={detailsInfo && detailsInfo.startDate}
                required
                onChange={handleChangeDetails}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="dob">End Date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                placeholder="End Date"
                defaultValue={detailsInfo && detailsInfo.endDate}
                required
                onChange={handleChangeDetails}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Funding Type</label>
              <input
                id="fundingType"
                name="fundingType"
                defaultValue={detailsInfo && detailsInfo.fundingType}
                required
                type="text"
                placeholder="Funding Type"
                onChange={handleChangeDetails}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Level</label>
              <input
                id="level"
                required
                type="text"
                placeholder="Level"
                name="level"
                defaultValue={detailsInfo && detailsInfo.level}
                onChange={handleChangeDetails}
              />
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddDetails}
        setClose={() => {
          handleModalClose('details');
        }}
        title="Add NDIS Details"
        width="680px"
        operationName="Add"
        onSubmit={handleAddDetails}
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="dob">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                placeholder="Start Date"
                defaultValue={detailsInfo && detailsInfo.startDate}
                required
                onChange={handleChangeDetails}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="dob">End Date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                placeholder="End Date"
                defaultValue={detailsInfo && detailsInfo.endDate}
                required
                onChange={handleChangeDetails}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Funding Type</label>
              <input
                id="fundingType"
                name="fundingType"
                defaultValue={detailsInfo && detailsInfo.fundingType}
                required
                type="text"
                placeholder="Funding Type"
                onChange={handleChangeDetails}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Level</label>
              <input
                id="level"
                required
                type="text"
                placeholder="Level"
                name="level"
                defaultValue={detailsInfo && detailsInfo.level}
                onChange={handleChangeDetails}
              />
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditTransport}
        setClose={() => {
          handleModalClose('clientTransport');
        }}
        title="Edit Client Transport"
        width="680px"
        onSubmit={handleEditTransport}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">OD Car</label>
              <StyledSelect
                styles={customStyles}
                options={ODCarOptions}
                placeholder="OD Car"
                defaultValue={transportInfo && ODCarOptions
                  .filter((item) => item.value === transportInfo.odCar)}
                name="odCar"
                onChange={(event) => handleChange({ target: { name: 'odCar', value: event.value } })}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Car Registration</label>
              <input
                id="carRegistration"
                required
                type="text"
                placeholder="Car Registration"
                name="carRegistration"
                defaultValue={transportInfo && transportInfo.carRegistration}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="dob">Car Reg Expiry</label>
              <input
                type="date"
                id="carRegExpiry"
                placeholder="Car Reg Expiry"
                required
                name="carRegExpiry"
                defaultValue={transportInfo && transportInfo.carRegExpiry}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Car Model</label>
              <input
                id="carModel"
                required
                type="text"
                placeholder="Car Model"
                name="carModel"
                defaultValue={transportInfo && transportInfo.carModel}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Car Make Year</label>
              <input
                id="carMakeYear"
                required
                type="text"
                placeholder="Car Make Year"
                name="carMakeYear"
                defaultValue={transportInfo && transportInfo.carMakeYear}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Travel Protocol</label>
              <StyledSelect
                styles={customStyles}
                options={TravelProtocolOptions}
                placeholder="TravelProtocol"
                defaultValue={transportInfo && TravelProtocolOptions
                  .filter((item) => item.value === transportInfo.isTravelProtocol)}
                name="isTravelProtocol"
                onChange={(event) => handleChange({ target: { name: 'isTravelProtocol', value: event.value } })}
              />
            </div>
            {transportInfo.isTravelProtocol === 'Yes' ? (
              <>
                <div className="input-with-label">
                  <label htmlFor="name">Travel Protocol</label>
                  <input
                    id="travelProtocol"
                    required
                    type="text"
                    placeholder="Travel Protocol"
                    name="travelProtocol"
                    defaultValue={transportInfo && transportInfo.travelProtocol}
                    onChange={handleChange}
                  />
                </div>
              </>
            ) : null}
            <div className="input-with-label">
              <label htmlFor="name">Comprehensive Insurance</label>
              <StyledSelect
                styles={customStyles}
                options={ComprehensiveInsuranceOptions}
                placeholder="Comprehensive Insurance"
                defaultValue={transportInfo && ComprehensiveInsuranceOptions
                  .filter((item) => item.value === transportInfo.comprehensiveInsurance)}
                name="comprehensiveInsurance"
                onChange={(event) => handleChange({ target: { name: 'comprehensiveInsurance', value: event.value } })}
              />
            </div>
            {transportInfo.comprehensiveInsurance === 'Yes' ? (
              <>
                <div className="input-with-label">
                  <label htmlFor="name">Insurance Policy Number</label>
                  <input
                    id="insurancePolicyNumber"
                    required
                    type="text"
                    placeholder="Insurance Policy Number"
                    name="insurancePolicyNumber"
                    defaultValue={transportInfo && transportInfo.insurancePolicyNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Authorised Person</label>
                  <input
                    id="authorisedPerson"
                    required
                    type="text"
                    placeholder="Authorised Person"
                    name="authorisedPerson"
                    defaultValue={transportInfo && transportInfo.authorisedPerson}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Authorised Person Contact Number</label>
                  <input
                    id="authorisedPersonContactNumber"
                    required
                    type="text"
                    placeholder="Authorised Person Number"
                    name="authorisedPersonContactNumber"
                    defaultValue={transportInfo && transportInfo.authorisedPersonContactNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">RoadSide Assistance Covered</label>
                  <StyledSelect
                    styles={customStyles}
                    options={RoadSideAssistanceCoveredOptions}
                    placeholder="RoadSide Assistance Covered"
                    defaultValue={transportInfo && RoadSideAssistanceCoveredOptions
                      .filter((item) => item.value === transportInfo.roadSideAssistanceCovered)}
                    name="roadSideAssistanceCovered"
                    onChange={(event) => handleChange({ target: { name: 'roadSideAssistanceCovered', value: event.value } })}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Insurance Agency</label>
                  <input
                    id="insuranceAgency"
                    required
                    type="text"
                    placeholder="Insurance Agency"
                    name="insuranceAgency"
                    defaultValue={transportInfo && transportInfo.insuranceAgency}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Insurance Contact Number</label>
                  <input
                    id="insuranceContactNumber"
                    required
                    type="text"
                    placeholder="Insurance Contact Number"
                    name="insuranceContactNumber"
                    defaultValue={transportInfo && transportInfo.insuranceContactNumber}
                    onChange={handleChange}
                  />
                </div>
              </>
            ) : null}
            <div className="input-with-label">
              <label htmlFor="name">Capped KMs</label>
              <input
                id="cappedKMs"
                required
                type="text"
                placeholder="Capped KMs"
                name="cappedKMs"
                defaultValue={transportInfo && transportInfo.cappedKMs}
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddTransport}
        setClose={() => {
          handleModalClose('clientTransport');
        }}
        title="Add Client Transport"
        width="680px"
        onSubmit={handleAddTransport}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">OD Car</label>
              <StyledSelect
                styles={customStyles}
                options={ODCarOptions}
                placeholder="OD Car"
                defaultValue={transportInfo && ODCarOptions
                  .filter((item) => item.value === transportInfo.odCar)}
                name="odCar"
                onChange={(event) => handleChange({ target: { name: 'odCar', value: event.value } })}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Car Registration</label>
              <input
                id="carRegistration"
                required
                type="text"
                placeholder="Car Registration"
                name="carRegistration"
                defaultValue={transportInfo && transportInfo.carRegistration}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="dob">Car Reg Expiry</label>
              <input
                type="date"
                id="carRegExpiry"
                placeholder="Car Reg Expiry"
                required
                name="carRegExpiry"
                defaultValue={transportInfo && transportInfo.carRegExpiry}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Car Model</label>
              <input
                id="carModel"
                required
                type="text"
                placeholder="Car Model"
                name="carModel"
                defaultValue={transportInfo && transportInfo.carModel}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Car Make Year</label>
              <input
                id="carMakeYear"
                required
                type="text"
                placeholder="Car Make Year"
                name="carMakeYear"
                defaultValue={transportInfo && transportInfo.carMakeYear}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Travel Protocol</label>
              <StyledSelect
                styles={customStyles}
                options={TravelProtocolOptions}
                placeholder="TravelProtocol"
                defaultValue={transportInfo && TravelProtocolOptions
                  .filter((item) => item.value === transportInfo.isTravelProtocol)}
                name="isTravelProtocol"
                onChange={(event) => handleChange({ target: { name: 'isTravelProtocol', value: event.value } })}
              />
            </div>
            {transportInfo.isTravelProtocol === 'Yes' ? (
              <>
                <div className="input-with-label">
                  <label htmlFor="name">Travel Protocol</label>
                  <input
                    id="travelProtocol"
                    required
                    type="text"
                    placeholder="Text"
                    name="travelProtocol"
                    defaultValue={transportInfo && transportInfo.travelProtocol}
                    onChange={handleChange}
                  />
                </div>
              </>
            ) : null}
            <div className="input-with-label">
              <label htmlFor="name">Comprehensive Insurance</label>
              <StyledSelect
                styles={customStyles}
                options={ComprehensiveInsuranceOptions}
                placeholder="Comprehensive Insurance"
                defaultValue={transportInfo && ComprehensiveInsuranceOptions
                  .filter((item) => item.value === transportInfo.comprehensiveInsurance)}
                name="comprehensiveInsurance"
                onChange={(event) => handleChange({ target: { name: 'comprehensiveInsurance', value: event.value } })}
              />
            </div>
            {transportInfo.comprehensiveInsurance === 'Yes' ? (
              <>
                <div className="input-with-label">
                  <label htmlFor="name">Insurance Policy Number</label>
                  <input
                    id="insurancePolicyNumber"
                    required
                    type="text"
                    placeholder="Insurance Policy Number"
                    name="insurancePolicyNumber"
                    defaultValue={transportInfo && transportInfo.insurancePolicyNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Authorised Person</label>
                  <input
                    id="authorisedPerson"
                    required
                    type="text"
                    placeholder="Authorised Person"
                    name="authorisedPerson"
                    defaultValue={transportInfo && transportInfo.authorisedPerson}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Authorised Person Contact Number</label>
                  <input
                    id="authorisedPersonContactNumber"
                    required
                    type="text"
                    placeholder="Authorised Person Contact Number"
                    name="authorisedPersonContactNumber"
                    defaultValue={transportInfo && transportInfo.authorisedPersonContactNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">RoadSide Assistance Covered</label>
                  <StyledSelect
                    styles={customStyles}
                    options={RoadSideAssistanceCoveredOptions}
                    placeholder="RoadSide Assistance Covered"
                    defaultValue={transportInfo && RoadSideAssistanceCoveredOptions
                      .filter((item) => item.value === transportInfo.roadSideAssistanceCovered)}
                    name="roadSideAssistanceCovered"
                    onChange={(event) => handleChange({ target: { name: 'roadSideAssistanceCovered', value: event.value } })}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Insurance Agency</label>
                  <input
                    id="insuranceAgency"
                    required
                    type="text"
                    placeholder="Insurance Agency"
                    name="insuranceAgency"
                    defaultValue={transportInfo && transportInfo.insuranceAgency}
                    onChange={handleChange}
                  />
                </div>
                <div className="input-with-label">
                  <label htmlFor="name">Insurance Contact Number</label>
                  <input
                    id="insuranceContactNumber"
                    required
                    type="text"
                    placeholder="Insurance Contact Number"
                    name="insuranceContactNumber"
                    defaultValue={transportInfo && transportInfo.insuranceContactNumber}
                    onChange={handleChange}
                  />
                </div>
              </>
            ) : null}
            <div className="input-with-label">
              <label htmlFor="name">Capped KMs</label>
              <input
                id="cappedKMs"
                required
                type="text"
                placeholder="Capped KMs"
                name="cappedKMs"
                defaultValue={transportInfo && transportInfo.cappedKMs}
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddAllowance}
        setClose={() => {
          handleModalClose('clientAllowance');
        }}
        title="Add Client Allowance"
        width="680px"
        onSubmit={handleAddAllowance}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Type</label>
              <SelectContainer
                width="100%"
                options={TypeOptions}
                placeholder="Type"
                defaultValue={allowanceInfo && TypeOptions
                  .filter((item) => item.value === allowanceInfo.type)}
                name="type"
                onChange={(e) => {
                  handleSelect({ name: 'type', value: e.value });
                }}
                error={inputError.includes('type')}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Client Contribution</label>
              <input
                id="clientContribution"
                required
                type="text"
                placeholder="Client Contribution"
                name="clientContribution"
                defaultValue={allowanceInfo && allowanceInfo.clientContribution}
                onChange={handleChangeAllowance}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">OD Contribution</label>
              <input
                id="odContribution"
                required
                name="odContribution"
                type="text"
                placeholder="OD Contribution"
                defaultValue={allowanceInfo && allowanceInfo.odContribution}
                onChange={handleChangeAllowance}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Invoice To</label>
              <SelectContainer
                width="100%"
                options={InvoiceToOptions}
                placeholder="Invoice To"
                defaultValue={allowanceInfo && InvoiceToOptions
                  .filter((item) => item.value === allowanceInfo.invoiceTo)}
                name="invoiceTo"
                onChange={(e) => {
                  handleSelect({ name: 'invoiceTo', value: e.value });
                }}
                error={inputError.includes('invoiceTo')}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Email</label>
              <input
                id="email"
                name="email"
                required
                type="text"
                placeholder="Email"
                defaultValue={allowanceInfo && allowanceInfo.email}
                onChange={handleChangeAllowance}
              />
            </div>
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditAllowance}
        setClose={() => {
          handleModalClose('clientAllowance');
        }}
        title="Edit Client Allowance"
        width="680px"
        operationName="Update"
        onSubmit={handleEditAllowance}
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Type</label>
              <SelectContainer
                width="100%"
                options={TypeOptions}
                placeholder="Type"
                defaultValue={allowanceInfo && TypeOptions
                  .filter((item) => item.value === allowanceInfo.type)}
                name="type"
                onChange={(e) => {
                  handleSelect({ name: 'type', value: e.value });
                }}
                error={inputError.includes('type')}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Client Contribution</label>
              <input
                id="clientContribution"
                required
                type="text"
                placeholder="Client Contribution"
                name="clientContribution"
                defaultValue={allowanceInfo && allowanceInfo.clientContribution}
                onChange={handleChangeAllowance}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">OD Contribution</label>
              <input
                id="odContribution"
                required
                name="odContribution"
                type="text"
                placeholder="OD Contribution"
                defaultValue={allowanceInfo && allowanceInfo.odContribution}
                onChange={handleChangeAllowance}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Invoice To</label>
              <SelectContainer
                width="100%"
                options={InvoiceToOptions}
                placeholder="Invoice To"
                defaultValue={allowanceInfo && InvoiceToOptions
                  .filter((item) => item.value === allowanceInfo.invoiceTo)}
                name="invoiceTo"
                onChange={(e) => {
                  handleSelect({ name: 'invoiceTo', value: e.value });
                }}
                error={inputError.includes('invoiceTo')}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Email</label>
              <input
                id="email"
                name="email"
                required
                type="text"
                placeholder="Email"
                defaultValue={allowanceInfo && allowanceInfo.email}
                onChange={handleChangeAllowance}
              />
            </div>
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddCard}
        setClose={() => {
          handleModalClose('budgetlyCard');
          setValidationMsg('');
        }}
        title="Add Card"
        width="680px"
        onSubmit={handleAddCard}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Type of Card</label>
              <SelectContainer
                width="100%"
                options={budgetTypeOfCardOptions}
                defaultValue={budgetlyCardInfo && budgetTypeOfCardOptions
                  .filter((item) => item.value === budgetlyCardInfo.typeOfCard)}
                name="typeOfCard"
                onChange={(e) => {
                  handleSelect({ name: 'typeOfCard', value: e.value });
                }}
                error={inputError.includes('typeOfCard')}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Type</label>
              <SelectContainer
                id="cardType"
                width="100%"
                options={budgetTypeOptions}
                defaultValue={budgetlyCardInfo && budgetTypeOptions
                  .filter((item) => item.value === budgetlyCardInfo.type)}
                name="type"
                onChange={(e) => {
                  handleSelect({ name: 'type', value: e.value });
                }}
                error={inputError.includes('type')}

              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                type="text"
                placeholder="Name"
                name="name"
                defaultValue={budgetlyCardInfo.name}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Name on Card</label>
              <input
                id="nameOnCard"
                required
                type="text"
                placeholder="Name on Card"
                name="nameOnCard"
                defaultValue={budgetlyCardInfo.nameOnCard}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Card Number (16 digit)</label>
              <input
                id="cardNo"
                required
                type="text"
                placeholder="Card Number"
                name="cardNo"
                defaultValue={budgetlyCardInfo.cardNo}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">CVV Number (3 digit)</label>
              <input
                id="cvv"
                required
                type="text"
                placeholder="CVV Number"
                name="cvv"
                defaultValue={budgetlyCardInfo.cvv}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Expiry Year</label>
              <input
                id="expiryDate"
                required
                type="month"
                min="2022-01"
                placeholder="select"
                name="expiryDate"
                defaultValue={budgetlyCardInfo.expiryDate}
                onChange={handleChangeBudgetlyCard}
              />
            </div>

          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditCard}
        setClose={() => {
          handleModalClose('budgetlyCard');
          setValidationMsg('');
        }}
        title="Edit Budgetly Card"
        width="680px"
        onSubmit={handleEditCard}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Type of Card</label>
              <SelectContainer
                id="typeOfCard"
                width="100%"
                options={[
                  { label: 'Bank Card', value: 'BankCard' },
                  { label: 'Budgetly Card', value: 'BudgetlyCard' }
                ]}
                defaultValue={
                  budgetlyCardInfo.typeOfCard === 'BankCard'
                    ? { label: 'Bank Card', value: 'BankCard' }
                    : { label: 'Budgetly Card', value: 'BudgetlyCard' }
                }
                onChange={(e) => {
                  setBudgetlyCardInfo({
                    ...budgetlyCardInfo, typeOfCard: e.value
                  });
                }}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Type</label>
              <SelectContainer
                id="cardType"
                width="100%"
                options={[
                  { label: 'Master Card', value: 'MasterCard' },
                  { label: 'Visa', value: 'Visa' },
                  { label: 'Other', value: 'Other' }
                ]}
                defaultValue={
                  budgetlyCardInfo.type === 'MasterCard'
                    ? { label: 'Master Card', value: 'MasterCard' }
                    : budgetlyCardInfo.type === 'Visa'
                      ? { label: 'Visa', value: 'Visa' }
                      : { label: 'Other', value: 'Other' }
                }
                // value={budgetlyCardInfo.type}
                onChange={(e) => {
                  setBudgetlyCardInfo({
                    ...budgetlyCardInfo, type: e.value
                  });
                }}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                type="text"
                placeholder="Name"
                name="name"
                defaultValue={budgetlyCardInfo.name}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Name on Card</label>
              <input
                id="nameOnCard"
                required
                type="text"
                placeholder="Name on Card"
                name="nameOnCard"
                defaultValue={budgetlyCardInfo.nameOnCard}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Card Number (16 digit)</label>
              <input
                id="cardNo"
                required
                type="text"
                placeholder="Card Number"
                name="cardNo"
                defaultValue={budgetlyCardInfo.cardNo}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">CVV Number (3 digit)</label>
              <input
                id="cvv"
                required
                type="text"
                placeholder="CVV Number"
                name="cvv"
                defaultValue={budgetlyCardInfo.cvv}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="name">Expiry Date</label>
              <input
                id="expiryDate"
                required
                type="month"
                min="2022-01"
                placeholder="Expiry Date"
                name="expiryDate"
                defaultValue={`${budgetlyCardInfo.expiryDate.split('-')[0]}-${budgetlyCardInfo.expiryDate.split('-')[1]}`}
                onChange={handleChangeBudgetlyCard}
              />
            </div>
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
    </>
  );
};
AdminClientFinance.propTypes = {
  client: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdminClientFinance;
