/** @format */
import moment from 'moment';

const getUserDetails = () => {
  const userDetails = localStorage.getItem('user-details');
  if (userDetails) {
    return JSON.parse(userDetails);
  }
  return null;
};

const checkIfHouseAdmin = () => {
  const userDetails = getUserDetails();
  const roles = userDetails && userDetails.roles && userDetails.roles;
  const isHouseAdmin = roles.some(
    (role) => ['HOUSE ADMIN'].indexOf(role) > -1
  );
  return isHouseAdmin;
};

const checkIfAdmin = () => {
  const userDetails = getUserDetails();
  const roles = userDetails && userDetails.roles && userDetails.roles;
  const isAdmin = roles.some(
    (role) => ['ADMIN'].indexOf(role) > -1
  );
  return isAdmin;
};

const checkIfHouseAdminOnly = () => (
  checkIfHouseAdmin() && !checkIfAdmin()
);

const checkIfAdminOnly = () => (
  !checkIfHouseAdminOnly() && checkIfAdmin()
);

const checkValidationAddClients = (clientDetails, setValidationMessage) => {
  if (clientDetails.name === '') {
    setValidationMessage('Name cannot be empty');
    return false;
  }
  if (clientDetails.gender === '') {
    setValidationMessage('Gender cannot be empty');
    return false;
  }
  if (clientDetails.dob === '') {
    setValidationMessage('Date of Birth cannot be empty');
    return false;
  }
  if (clientDetails.email === '') {
    setValidationMessage('Email cannot be empty');
    return false;
  }
  if (clientDetails.email !== '') {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!re.test(String(clientDetails.email))) {
      setValidationMessage('Enter a valid Email Address');
      return false;
    }
  }
  if (clientDetails.phone === '') {
    setValidationMessage('Please enter phone number');
    return false;
  }
  if (clientDetails.phone !== '') {
    const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
    if (!re.test(String(clientDetails.phone))) {
      setValidationMessage('Enter a valid phone number');
      return false;
    }
  }
  if (clientDetails.addrLine1 === '') {
    setValidationMessage('Address Line1 cannot be empty');
    return false;
  }
  if (clientDetails.city === '') {
    setValidationMessage('City cannot be empty');
    return false;
  }
  if (clientDetails.state === '') {
    setValidationMessage('State cannot be empty');
    return false;
  }
  if (clientDetails.postCode === '') {
    setValidationMessage('Postcode cannot be empty');
    return false;
  }
  if (clientDetails.postCode !== '') {
    const re = /^[0-9]{4}$/;
    if (!re.test(String(clientDetails.postCode))) {
      setValidationMessage('Enter a valid PostCode');
      return false;
    }
  }
  setValidationMessage('');
  return true;
};

const checkValidationAddXuser = (clientDetails, setValidationMessage) => {
  if (clientDetails.firstName === '') {
    setValidationMessage('firstName');
    return false;
  }
  if (clientDetails.lastName === '') {
    setValidationMessage('lastName');
    return false;
  }
  if (clientDetails.phone !== '') {
    const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
    if (!re.test(String(clientDetails.phone))) {
      setValidationMessage('phone');
      return false;
    }
  }
  setValidationMessage('');
  return true;
};

const checkValidationUpdateMedicare = (clientDetails, setValidationMessage) => {
  if (clientDetails.medicareNo === '' || clientDetails.medicareNo === 0) {
    setValidationMessage('');
    return true;
  }
  if (clientDetails.medicareNo !== '' || clientDetails.medicareNo > 0) {
    if (`${clientDetails.medicareNo}`.length === 10) {
      setValidationMessage('');
      return true;
    }
    if (`${clientDetails.medicareNo}`.length !== 10) {
      setValidationMessage('Medicare number should contain 10 digits');
    }
    return false;
  }
  setValidationMessage('');
  return true;
};

const checkValidationAdditionalDetails = (clientDetails, setValidationMessage) => {
  if (clientDetails.identity === '') {
    setValidationMessage('Identity cannot be empty');
    return false;
  }
  if (clientDetails.culture === '') {
    setValidationMessage('Culture cannot be empty');
    return false;
  }
  if (clientDetails.language === '') {
    setValidationMessage('Language cannot be empty');
    return false;
  }
  if (clientDetails.diagnosis === '') {
    setValidationMessage('Diagnosis cannot be empty');
    return false;
  }
  if (clientDetails.mobility === '') {
    setValidationMessage('Mobility cannot be empty');
    return false;
  }
  if (clientDetails.communication === '') {
    setValidationMessage('Communication cannot be empty');
    return false;
  }
  if (clientDetails.medicationSupport === '') {
    setValidationMessage('Medication Support cannot be empty');
    return false;
  }
  if (clientDetails.transportation === '') {
    setValidationMessage('Transportation cannot be empty');
    return false;
  }
  if (clientDetails.justiceOrders === '') {
    setValidationMessage('Justice Orders cannot be empty');
    return false;
  }
  if (clientDetails.supportRatio === '') {
    setValidationMessage('Support Ratio cannot be empty');
    return false;
  }
  if (clientDetails.shiftTimes === '') {
    setValidationMessage('Shift Times cannot be empty');
    return false;
  }
  if (clientDetails.supportWorkerSpecs === '') {
    setValidationMessage('Support Worker Specs cannot be empty');
    return false;
  }
  setValidationMessage('');
  return true;
};

const checkValidationAddUsers = (userDetails, setValidationMessage) => {
  if (userDetails.firstName === '') {
    setValidationMessage('First Name cannot be empty');
    return false;
  }
  if (userDetails.lastName === '') {
    setValidationMessage('Last Name cannot be empty');
    return false;
  }
  if (userDetails.email === '') {
    setValidationMessage('Email cannot be empty');
    return false;
  }
  if (userDetails.email !== '') {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!re.test(String(userDetails.email))) {
      setValidationMessage('Enter a valid Email Address');
      return false;
    }
  }
  if (userDetails.phone !== '') {
    const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
    if (!re.test(String(userDetails.phone))) {
      setValidationMessage('Enter a valid phone number');
      return false;
    }
  }
  if (userDetails.mobile !== '') {
    const re = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    if (!re.test(String(userDetails.mobile))) {
      setValidationMessage('Enter a valid mobile number');
      return false;
    }
  }
  setValidationMessage('');
  return true;
};

const checkValidationEditUsers = (userDetails, setValidationMessage) => {
  if (userDetails.firstName === '') {
    setValidationMessage('First Name cannot be empty');
    return false;
  }
  if (userDetails.lastName === '') {
    setValidationMessage('Last Name cannot be empty');
    return false;
  }
  if (userDetails.email === '') {
    setValidationMessage('Email cannot be empty');
    return false;
  }
  if (userDetails.email !== '') {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!re.test(String(userDetails.email))) {
      setValidationMessage('Enter a valid Email Address');
      return false;
    }
  }
  if (userDetails.phone !== '') {
    const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
    if (!re.test(String(userDetails.phone))) {
      setValidationMessage('Enter a valid phone number');
      return false;
    }
  }
  if (userDetails.mobile !== '') {
    const re = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    if (!re.test(String(userDetails.mobile))) {
      setValidationMessage('Enter a valid mobile number');
      return false;
    }
  }
  setValidationMessage('');
  return true;
};

const checkValidationEditExternalUsers = (userDetails, setValidationMessage) => {
  if (userDetails.firstName === '') {
    setValidationMessage('First Name cannot be empty');
    return false;
  }
  if (userDetails.lastName === '') {
    setValidationMessage('Last Name cannot be empty');
    return false;
  }
  setValidationMessage('');
  return true;
};

const compare = (firstItem, secondItem) => {
  if (firstItem.houseCode < secondItem.houseCode) {
    return -1;
  }
  if (firstItem.houseCode > secondItem.houseCode) {
    return 1;
  }
  return 0;
};

const checkForSpecificShift = (shift) => {
  if (shift === 'ACTIVENIGHT') {
    return 'ACTIVE NIGHT';
  }
  if (shift === 'SLEEPOVER') {
    return 'SLEEP OVER';
  }
  return shift;
};

const getAgeFromDOB = (dob) => {
  const today = new Date();
  let year;
  let month;
  let day;
  if (!dob) {
    return 'NA';
  }
  if (Array.isArray(dob)) {
    [year, month, day] = dob;
  } else {
    const dobArr = dob.split('-');
    [year, month, day] = dobArr;
  }
  let age = today.getFullYear() - Number(year);
  if (
    today.getMonth() < Number(month)
  || (today.getMonth() === Number(month) && today.getDate() < Number(day))
  ) {
    age -= 1;
  }
  return age;
};

const checkIfFirstSecRevTabEmpty = (review) => {
  if (
    review.factorHouseDesign
    || review.factorHouseEquipment
    || review.factorHouseFumes
    || review.factorHouseGuards
    || review.factorHouseOther
    || review.factorHouseUnsuitable
    || review.factorPersonAlcohol
    || review.factorPersonComplacency
    || review.factorPersonLineOfFire
    || review.factorPersonFrustration
    || review.factorPersonLackTraining
    || review.factorPersonLossBalance
    || review.factorPersonManualHandling
    || review.factorPersonMedicalCondition
    || review.factorPersonNoPPE
    || review.factorPersonRushing
    || review.factorPersonThreatening
    || review.factorPersonFatigue
    || review.factorPersonUnsafePractice
    || review.factorPersonWorkingAlone
    || review.factorEnvAnimals
    || review.factorEnvConfine
    || review.factorEnvNight
    || review.factorEnvOdours
    || review.factorEnvOther
    || review.factorEnvObstructions
    || review.factorEnvRaining
    || review.factorEnvSunGlare
    || review.factorEnvSurface
    || review.factorEnvTemperature
    || review.factorEnvVegetation
    || review.factorEnvHeight
  ) {
    return false;
  }
  return true;
};

const getHousesFromUser = (email, list) => {
  const houses = list.find((user) => user.email === email);
  return houses;
};

const removeHouse = () => {
  const userDetails = getUserDetails();
  localStorage.setItem('user-details', JSON.stringify({
    ...userDetails,
    houseCode: null
  }));
};

// capitalize first letter of a string

const capitalize = (str) => str[0].toUpperCase() + str.substring(1);

const newDate = new Date();
const utcOffset = newDate.getTimezoneOffset();

const getLocalTime = (utcDateTime) => {
  const modifiedDate = moment(utcDateTime, 'YYYY-MM-DD hh:mm:ss')
    .subtract(utcOffset, 'minutes')
    .format('DD/MM/YYYY hh:mm:ss A');
  return modifiedDate;
};

const getLocalTimeOnly = (utcDateTime) => {
  const modifiedTime = moment(utcDateTime, 'YYYY-MM-DD hh:mm:ss')
    .subtract(utcOffset, 'minutes')
    .format('HH:mm:ss');
  return modifiedTime;
};

const getUtcDateTime = (time) => {
  const dateTime = `${moment(newDate).format('YYYY-MM-DD')} ${time}`;
  const modifiedDateTime = moment(dateTime, 'YYYY-MM-DD hh:mm:ss')
    .add(utcOffset, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');
  return modifiedDateTime;
};

const getUtcDateTimeIfDateIsPresent = (dateTime) => {
  const modifiedDateTime = moment(dateTime, 'YYYY-MM-DD hh:mm')
    .add(utcOffset, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');
  return modifiedDateTime;
};

const getTodayDate = (date = moment(), para) => {
  const time = Number(moment().format('HHmm'));
  if (time > 0 && time < 1159) {
    if (para === 'forApi') {
      return moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    }
    if (para === 'forDisplay') {
      return moment(date).subtract(1, 'days').format('DD/MM/YYYY');
    }
  }
  if (para === 'forApi') {
    return moment(date).format('YYYY-MM-DD');
  }
  return moment(date).format('DD/MM/YYYY');
};

const getUserHouseCode = () => {
  const userDetails = getUserDetails();
  return userDetails.houseCode;
};

export {
  getLocalTime,
  getUtcDateTimeIfDateIsPresent,
  getLocalTimeOnly,
  getUtcDateTime,
  getUserDetails,
  checkValidationAddClients,
  checkValidationAddUsers,
  checkValidationEditUsers,
  checkValidationAdditionalDetails,
  compare,
  getAgeFromDOB,
  checkIfHouseAdminOnly,
  checkIfHouseAdmin,
  checkIfAdmin,
  checkIfAdminOnly,
  checkIfFirstSecRevTabEmpty,
  getHousesFromUser,
  removeHouse,
  capitalize,
  getUserHouseCode,
  getTodayDate,
  checkForSpecificShift,
  checkValidationUpdateMedicare,
  checkValidationEditExternalUsers,
  checkValidationAddXuser
};
