import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, Spinner
} from 'react-bootstrap';
import { BsUpload } from 'react-icons/bs';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import defaultPic from '../../assets/images/default_user.png';
import {
  getHouseList,
  getClient,
  updateClient,
  updateClientMedicare,
  addClientToHouse,
  uploadClientPhoto,
  updateClientAdditionalDetails
} from '../../services/result.services';
import CustomModal from '../CustomModal';
import { clientDetailsObj } from '../../lookups/lookups';
import {
  checkValidationAddClients, getUserDetails,
  checkValidationUpdateMedicare, checkValidationAdditionalDetails
} from '../../helpers/utils';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  ContentSection,
  HealthCareSection,
  GeneralSection,
  InfoSection,
  DetailsHeader,
  DetailsSub,
  FormWrapper,
  AssignHouseWrapper,
  AdditionalDetailsSection,
  StyledSelect,
  PhotoWrapper,
  UploadButton
} from './index.style';
import {
  DetailsWrapper, DetailBoxContainer
} from '../../styledComponents/common/page.style';
import userIcon from '../../assets/logo/user.svg';

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? '#F8F1EB' : styles.color,
    '&:hover': { color: 'black', backgroundColor: '#F8F1EB' }
  })
};
const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate() + 1).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};

const identityOptions = [
  {
    label: 'Aboriginal',
    value: 'Aboriginal'
  },
  {
    label: 'Torres Strait Islander',
    value: 'Torres Strait Islander'
  },
  {
    label: 'Both Aboriginal and Torres Strait Islander',
    value: 'Both Aboriginal and Torres Strait Islander'
  },
  {
    label: 'Neither Aboriginal or Torres Straight Islander',
    value: 'Neither Aboriginal or Torres Straight Islander'
  }
];

const AdminClientDetails = ({ client }) => {
  const { id } = client;
  const [clientDetails, setClientDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [listHouses, setListHouses] = useState([]);
  const [updateHouseClient, setUpdateHouseClient] = useState(null);
  const [updatableHouses, setUpdatableHouses] = useState([]);
  const [selectedHouseCode, setSelectedHouseCode] = useState(null);
  const [showCustomModalUpdateClient, setShowCustomModalUpdateClient] = useState(false);
  const [showCustomModalUpdateMediCare, setShowCustomModalUpdateMediCare] = useState(false);
  const [showCustomModalAddHouse, setShowCustomModalAddHouse] = useState(false);
  const [showCustomModalAdditionalDetails, setShowCustomModalAdditionalDetails] = useState(false);
  const [validationMsgUpdateClient, setValidationMsgUpdateClient] = useState('');
  const [validationMsgUpdateMedicare, setValidationMsgUpdateMedicare] = useState('');
  const [validationMsgAdditionalDetails, setValidationMsgAdditionalDetails] = useState('');
  const [editableClient, setEditableClient] = useState(null);
  const history = useHistory();
  const uploadPic = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = (link) => history.push(link);
  const handlePic = () => uploadPic.current.click();

  const handleShowCustomModal = (operation) => {
    if (operation === 'update-client') {
      setShowCustomModalUpdateClient(true);
    } else if (operation === 'update-medicare') {
      setShowCustomModalUpdateMediCare(true);
    } else if (operation === 'add-house') {
      setShowCustomModalAddHouse(true);
    } else if (operation === 'additional-details') {
      setShowCustomModalAdditionalDetails(true);
    }
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'update-client') {
      setShowCustomModalUpdateClient(false);
    } else if (operation === 'update-medicare') {
      setShowCustomModalUpdateMediCare(false);
    } else if (operation === 'add-house') {
      setShowCustomModalAddHouse(false);
    } else if (operation === 'additional-details') {
      setShowCustomModalAdditionalDetails(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableClient((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdateHouseChange = (e) => {
    const { value } = e.target;
    setSelectedHouseCode(value);
  };

  const handleRadioChange = async (e) => {
    const { name, value } = e.target;
    if (value === 'yes') {
      setEditableClient((prevState) => ({
        ...prevState,
        [name]: true
      }));
    } else if (value === 'no') {
      setEditableClient((prevState) => ({
        ...prevState,
        [name]: false
      }));
    }
  };

  const handleUpdateClient = () => {
    setValidationMsgUpdateClient('');
    setEditableClient(clientDetails);
    handleShowCustomModal('update-client');
  };

  const handleAdditionalDetails = () => {
    setValidationMsgAdditionalDetails('');
    setEditableClient(clientDetails);
    handleShowCustomModal('additional-details');
  };

  const handleUpdateMedicare = () => {
    setValidationMsgUpdateMedicare('');
    setEditableClient(clientDetails);
    handleShowCustomModal('update-medicare');
  };
  const handleUpdateHouse = (updtClient) => {
    setLoading(true);
    if (updtClient.house === null) {
      setUpdatableHouses(listHouses);
      setLoading(false);
    } else {
      const houses = listHouses.filter((ele) => ele.houseCode !== client.house);
      setUpdatableHouses(houses);
      setLoading(false);
    }
    setUpdateHouseClient(updtClient.id);
    handleShowCustomModal('add-house');
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      setListHouses(filteredHouses);
    } else {
      setListHouses([]);
    }
  };

  const getClientHandler = async (clientId) => {
    setLoading(true);
    const resp = await getClient(clientId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setClientDetails(resp.data);
    } else {
      setLoading(false);
    }
  };
  const updateClientsHandler = async () => {
    if (!checkValidationAddClients(editableClient
      && editableClient, setValidationMsgUpdateClient)) {
      return;
    }
    setLoading(true);
    const resp = await updateClient(editableClient);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientHandler(editableClient.id);
      handleCloseCustomModal('update-client');
      swal({
        title: 'Client updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setEditableClient(clientDetailsObj);
      setValidationMsgUpdateClient('');
    } else {
      setLoading(false);
      swal({
        title: 'Client updation failed!',
        icon: 'error',
        timer: 3000
      });
      setEditableClient(clientDetailsObj);
      setValidationMsgUpdateClient('');
    }
  };

  const uploadClientImage = async (event) => {
    const image = event.target.files[0];
    if (image.size > 10e6) {
      swal({
        title: 'Image size should be less than 1MB',
        icon: 'error',
        timer: 3000
      });
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', image);
      formData.append('clientId', new Blob([JSON.stringify(id)], { type: 'application/json' }));
      formData.append('name', new Blob([JSON.stringify(client.name)], { type: 'application/json' }));
      const resp = await uploadClientPhoto(id, formData);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Client Image updated successfully!',
          icon: 'success',
          timer: 3000
        });
      } else {
        setLoading(false);
        swal({
          title: 'Client Image updation failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const updateClientsAdditionalDetails = async () => {
    if (!checkValidationAdditionalDetails(editableClient
      && editableClient, setValidationMsgAdditionalDetails)) {
      return;
    }
    setLoading(true);
    const resp = await updateClientAdditionalDetails(id, editableClient);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientHandler(editableClient.id);
      handleCloseCustomModal('additional-details');
      swal({
        title: 'Client updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setEditableClient(clientDetailsObj);
      setValidationMsgAdditionalDetails('');
    } else {
      setLoading(false);
      swal({
        title: 'Client updation failed!',
        icon: 'error',
        timer: 3000
      });
      setEditableClient(clientDetailsObj);
      setValidationMsgAdditionalDetails('');
    }
  };

  const updateMedicareHandler = async () => {
    if (!checkValidationUpdateMedicare(editableClient
      && editableClient, setValidationMsgUpdateMedicare)) {
      return;
    }
    setLoading(true);
    let resp = null;
    if (editableClient.expiryDate !== null) {
      resp = await updateClientMedicare(id,
        {
          medicareNo: editableClient.medicareNo,
          expiryDate: editableClient.expiryDate,
          healthFund: editableClient.healthFund,
          centerLinkNo: editableClient.centerLinkNo,
          individualReferenceNumber: editableClient.individualReferenceNumber,
          medicareCardName: editableClient.medicareCardName
        });
    } else {
      resp = await updateClientMedicare(id,
        {
          medicareNo: editableClient.medicareNo,
          healthFund: editableClient.healthFund,
          centerLinkNo: editableClient.centerLinkNo,
          individualReferenceNumber: editableClient.individualReferenceNumber,
          medicareCardName: editableClient.medicareCardName
        });
    }
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientHandler(id);
      handleCloseCustomModal('update-medicare');
      swal({
        title: 'Client Medicare updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setEditableClient(clientDetailsObj);
      setValidationMsgUpdateMedicare('');
    } else {
      setLoading(false);
      swal({
        title: 'Client Medicare updation failed!',
        icon: 'error',
        timer: 3000
      });
      setEditableClient(clientDetailsObj);
      setValidationMsgUpdateMedicare('');
    }
  };

  const addHouseToClientHandler = async () => {
    handleCloseCustomModal('add-house');
    setLoading(true);
    const resp = await addClientToHouse(
      {
        clientId: updateHouseClient,
        houseCode: selectedHouseCode
      }
    );
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      await getClientHandler(id);
      setSelectedHouseCode(null);
      setUpdateHouseClient(null);
    } else {
      setLoading(false);
      setSelectedHouseCode(null);
      setUpdateHouseClient(null);
    }
  };

  useEffect(() => {
    setLoading(true);
    getHousesHandler();
    getClientHandler(id);
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <DetailsHeader>
        <h2>Details</h2>
        <button onClick={() => handleClick('/admin/clients')} type="button"> Back </button>
      </DetailsHeader>
      <DetailsWrapper>
        {
          clientDetails
            ? (
              <>
                <ContentSection>
                  <GeneralSection>
                    <DetailsSub>
                      <h2>General</h2>
                      <button type="button" onClick={() => handleUpdateHouse(clientDetails)}> Change House </button>
                    </DetailsSub>
                    <DetailBoxContainer caller="general">
                      <div className="w-33" style={{ display: 'flex' }}>
                        {clientDetails.photo !== '' ? (
                          <PhotoWrapper>
                            <img
                              className="profile-image"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultPic;
                              }}
                              src={clientDetails.photo}
                              width="90%"
                              height="90%"
                              alt="Profile pic"
                            />
                          </PhotoWrapper>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              width: '85%',
                              height: '100%',
                              marginTop: '10px'
                            }}
                            onClick={() => handlePic()}
                          >
                            <img
                              src={defaultPic}
                              width="95%"
                              height="95%"
                              alt="Profile pic"
                            />

                          </div>
                        )}
                        <UploadButton onClick={() => handlePic()}>
                          <BsUpload />
                        </UploadButton>
                        <input
                          type="file"
                          ref={uploadPic}
                          accept="image/*"
                          name="image"
                          id="profileimg"
                          onChange={(event) => uploadClientImage(event)}
                          hidden
                        />
                      </div>
                      <div className="d-flex w-67">
                        <div className="flex w-90">
                          <span className="dot"> </span>
                          <p className="label">Name</p>
                          <p className="detail">{clientDetails.name}</p>
                        </div>
                        <div className="flex w-33">
                          <span className="dot"> </span>
                          <p className="label">Gender</p>
                          <p className="detail">{clientDetails.gender}</p>
                        </div>
                        <div className="flex w-62">
                          <span className="dot"> </span>
                          <p className="label">Date Of Birth</p>
                          <p className="detail">{clientDetails.dob}</p>
                        </div>
                      </div>
                      <div className="d-flex w-95">
                        <div className="flex w-50">
                          <span className="dot"> </span>
                          <p className="label">House</p>
                          <p className="detail">{clientDetails.house}</p>
                        </div>
                        <div className="flex w-52">
                          <span className="dot"> </span>
                          <p className="label">NDIS No.</p>
                          <p className="detail">{clientDetails.ndisNumber}</p>
                        </div>
                      </div>
                    </DetailBoxContainer>
                  </GeneralSection>
                  <InfoSection>
                    <DetailsSub>
                      <h2>Contact Information</h2>
                      <button type="button" onClick={() => handleUpdateClient()}>
                        Edit
                      </button>
                    </DetailsSub>
                    <DetailBoxContainer caller="general">
                      <div className="flex w-50">
                        <span className="dot"> </span>
                        <p className="label">Phone Number</p>
                        <p className="detail">
                          {clientDetails.phone}
                        </p>
                      </div>
                      <div className="flex w-50">
                        <span className="dot"> </span>
                        <p className="label">Email</p>
                        <p className="detail">{clientDetails.email}</p>
                      </div>
                      <div className="flex w-50">
                        <span className="dot"> </span>
                        <p className="label">Address1</p>
                        <p className="detail">{clientDetails.addrLine1}</p>
                      </div>
                      <div className="flex w-50">
                        <span className="dot"> </span>
                        <p className="label">Address2</p>
                        <p className="detail">{clientDetails.addrLine2}</p>
                      </div>
                      <div className="flex w-30">
                        <span className="dot"> </span>
                        <p className="label">City</p>
                        <p className="detail">{clientDetails.city}</p>
                      </div>
                      <div className="flex w-30">
                        <span className="dot"> </span>
                        <p className="label">State</p>
                        <p className="detail">{clientDetails.state}</p>
                      </div>
                      <div className="flex w-30">
                        <span className="dot"> </span>
                        <p className="label">PostCode</p>
                        <p className="detail">{clientDetails.postCode}</p>
                      </div>
                    </DetailBoxContainer>
                  </InfoSection>
                </ContentSection>
                <ContentSection>
                  <AdditionalDetailsSection>
                    <DetailsSub>
                      <h2>Additional Details</h2>
                      <button type="button" onClick={() => handleAdditionalDetails()}>Edit</button>
                    </DetailsSub>
                    <DetailBoxContainer caller="general">
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Identity</p>
                        <p className="detail">{clientDetails.identity}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Culture</p>
                        <p className="detail">{clientDetails.culture}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Language</p>
                        <p className="detail">{clientDetails.language}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Diagnosis</p>
                        <p className="detail">{clientDetails.diagnosis}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Mobility</p>
                        <p className="detail">{clientDetails.mobility}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Communication</p>
                        <p className="detail">{clientDetails.communication}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Medication Support</p>
                        <p className="detail">{clientDetails.medicationSupport}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Transportation</p>
                        <p className="detail">{clientDetails.transportation}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Justice Orders</p>
                        <p className="detail">{clientDetails.justiceOrders}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Support Ratio</p>
                        <p className="detail">{clientDetails.supportRatio}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Shift Times</p>
                        <p className="detail">{clientDetails.shiftTimes}</p>
                      </div>
                      <div className="flexDetails w-33">
                        <span className="dot"> </span>
                        <p className="label">Support Worker Specs</p>
                        <p className="detail">{clientDetails.supportWorkerSpecs}</p>
                      </div>
                    </DetailBoxContainer>
                  </AdditionalDetailsSection>
                </ContentSection>
                <ContentSection>
                  <HealthCareSection>
                    <DetailsSub>
                      <h2>CenterLink/DVA/HealthCare</h2>
                      <button type="button" onClick={() => handleUpdateMedicare()}> Edit </button>
                    </DetailsSub>
                    <DetailBoxContainer caller="general">
                      <div className="flexhealth w-33">
                        <div className="flex w-100">
                          <span className="dot"> </span>
                          <p className="label">Medicare Card Name</p>
                          <p className="detail">
                            {clientDetails.medicareCardName}
                          </p>
                        </div>
                        <div className="flex w-100">
                          <span className="dot"> </span>
                          <p className="label">Medicare Card Number</p>
                          <p className="detail">
                            {clientDetails.medicareNo}
                          </p>
                        </div>
                        <div className="flex w-100">
                          <span className="dot"> </span>
                          <p className="label">Medicare Expiry Date</p>
                          <p className="detail">
                            {clientDetails.expiryDate}
                          </p>
                        </div>
                      </div>
                      <div className="flexhealth w-33">
                        <div className="flex w-100">
                          <span className="dot"> </span>
                          <p className="label">Health Fund</p>
                          <p className="detail">
                            {clientDetails.healthFund === true ? 'Yes' : 'No'}
                          </p>
                        </div>
                        <div className="flex w-100">
                          <span className="dot"> </span>
                          <p className="label">Individual Reference number(IRN)</p>
                          <p className="detail">
                            {clientDetails.individualReferenceNumber}
                          </p>
                        </div>
                      </div>
                      <div className="flexhealth w-33">
                        <div className="flex w-100">
                          <span className="dot"> </span>
                          <p className="label">Centerlink Number</p>
                          <p className="detail">
                            {clientDetails.centerLinkNo}
                          </p>
                        </div>
                      </div>
                    </DetailBoxContainer>
                  </HealthCareSection>
                </ContentSection>
              </>
            )
            : null
        }
      </DetailsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalUpdateClient}
        setClose={() => {
          handleCloseCustomModal('update-client');
        }}
        title="Update Client"
        width="850px"
        onSubmit={updateClientsHandler}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleChange}
                defaultValue={editableClient && editableClient.name}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="gender">Gender</label>
                <select
                  onChange={handleChange}
                  required
                  id="gender"
                  name="gender"
                  defaultValue={editableClient && editableClient.gender}
                  data-testid="gender"
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div className="input-with-label">
                <label htmlFor="dob">DOB</label>
                <input
                  type="date"
                  id="dob"
                  placeholder="DOB"
                  name="dob"
                  defaultValue={editableClient && editableClient.dob && editableClient.dob}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                defaultValue={editableClient && editableClient.email && editableClient.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone">2 digit area code, 8 digit phone number</label>
              <input
                placeholder="Phone Number"
                id="phone"
                name="phone"
                defaultValue={editableClient && editableClient.phone && editableClient.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="Address1">Address 1</label>
              <input
                id="address1"
                type="text"
                placeholder="Address 1"
                name="addrLine1"
                defaultValue={editableClient && editableClient.addrLine1}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                id="address2"
                placeholder="Address 2"
                name="addrLine2"
                value={editableClient && editableClient.addrLine2}
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  defaultValue={editableClient && editableClient.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state"
                  defaultValue={editableClient && editableClient.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="zip">Postcode</label>
              <input
                type="text"
                id="zip"
                placeholder="Postcode"
                name="postCode"
                defaultValue={editableClient && editableClient.postCode}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="ndisNumber">NDIS number</label>
              <input
                type="text"
                id="ndisNumber"
                placeholder="NDIS number"
                name="ndisNumber"
                defaultValue={editableClient && editableClient.ndisNumber}
                onChange={handleChange}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgUpdateClient !== '' && <h6 className="text-danger" data-testid="validation-update-client">{validationMsgUpdateClient}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAdditionalDetails}
        setClose={() => {
          handleCloseCustomModal('additional-details');
        }}
        title="Update Additional Details"
        width="850px"
        onSubmit={updateClientsAdditionalDetails}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="identity">Identity</label>
              <StyledSelect
                styles={customStyles}
                options={identityOptions}
                value={editableClient
                  && identityOptions.filter((item) => item.value === editableClient.identity)}
                name="selected"
                placeholder="Identity"
                onChange={(event) => handleChange({ target: { name: 'identity', value: event.value } })}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="culture">Culture</label>
              <input
                type="text"
                id="culture"
                placeholder="Culture"
                name="culture"
                value={editableClient && editableClient.culture}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="language">Language</label>
              <input
                id="language"
                type="text"
                placeholder="Language"
                name="language"
                defaultValue={editableClient && editableClient.language}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <p className="label">Diagnosis</p>
              <textarea
                className="input-like"
                type="text"
                id="diagnosis"
                placeholder="Diagnosis"
                name="diagnosis"
                value={editableClient && editableClient.diagnosis}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="mobility">Mobility</label>
              <textarea
                className="input-like"
                id="mobility"
                type="text"
                placeholder="Mobility"
                name="mobility"
                defaultValue={editableClient && editableClient.mobility}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="communication">Communication</label>
              <textarea
                className="input-like"
                type="text"
                id="communication"
                placeholder="Communication"
                name="communication"
                value={editableClient && editableClient.communication}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="medicationSupport">Medication Support</label>
              <textarea
                className="input-like"
                id="medicationSupport"
                type="text"
                placeholder="Medication Support"
                name="medicationSupport"
                defaultValue={editableClient && editableClient.medicationSupport}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="transportation">Transportation</label>
              <textarea
                className="input-like"
                type="text"
                id="transportation"
                placeholder="Transportation"
                name="transportation"
                value={editableClient && editableClient.transportation}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="justiceOrders">Justice Orders</label>
              <textarea
                className="input-like"
                id="justiceOrders"
                type="text"
                placeholder="Justice Orders"
                name="justiceOrders"
                defaultValue={editableClient && editableClient.justiceOrders}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="supportRatio">Support Ratio</label>
              <textarea
                className="input-like"
                type="text"
                id="supportRatio"
                placeholder="Support Ratio"
                name="supportRatio"
                value={editableClient && editableClient.supportRatio}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="shiftTimes">Shift Times</label>
              <textarea
                className="input-like"
                id="shiftTimes"
                type="text"
                placeholder="Shift Times"
                name="shiftTimes"
                defaultValue={editableClient && editableClient.shiftTimes}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="supportWorkerSpecs">Support Worker Specs</label>
              <textarea
                className="input-like"
                type="text"
                id="supportWorkerSpecs"
                placeholder="Support Worker Specs"
                name="supportWorkerSpecs"
                value={editableClient && editableClient.supportWorkerSpecs}
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          { validationMsgAdditionalDetails !== '' && <h6 className="text-danger" data-testid="validation-update-client">{validationMsgAdditionalDetails}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateMediCare}
        setClose={() => {
          handleCloseCustomModal('update-medicare');
        }}
        title="Update Client Medicare"
        width="850px"
        onSubmit={updateMedicareHandler}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="medicareCardName">Medicare Card Name</label>
              <input
                id="medicareCardName"
                required
                type="text"
                placeholder="Card Name"
                name="medicareCardName"
                onChange={handleChange}
                defaultValue={editableClient
                  && editableClient.medicareCardName}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="medicareNo">Medicare Card No. </label>
              <input
                id="medicareNo"
                type="text"
                placeholder="Card No"
                name="medicareNo"
                defaultValue={(editableClient && editableClient.medicareNo !== 0)
                  ? editableClient.medicareNo
                  : null}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="expiryDate">Expiry Date</label>
              <input
                type="date"
                id="expiryDate"
                placeholder="Expiry Date"
                name="expiryDate"
                min={disablePastDate()}
                defaultValue={editableClient && editableClient.expiryDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className="label"> HealthFund </div>
            <div className="input-with-half-width">
              <div>
                <label
                  className="container"
                  htmlFor="yes"
                  key="yes"
                >
                  <span>Yes</span>
                  <input
                    type="radio"
                    id="yes"
                    defaultChecked={editableClient && editableClient.healthFund === true}
                    onChange={handleRadioChange}
                    value="yes"
                    name="healthFund"
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="centered-line" />
              <div>
                <label
                  className="container"
                  htmlFor="no"
                  key="no"
                >
                  <span>No</span>
                  <input
                    type="radio"
                    id="no"
                    defaultChecked={editableClient && editableClient.healthFund === false}
                    value="no"
                    onChange={handleRadioChange}
                    name="healthFund"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="individualReferenceNumber">Individual Reference No.</label>
              <input
                id="individualReferenceNumber"
                type="text"
                placeholder="Individual Reference No"
                name="individualReferenceNumber"
                defaultValue={editableClient && editableClient.individualReferenceNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="centerLinkNo"> CenterLink No.</label>
              <input
                placeholder="CenterLink No"
                id="centerLinkNo"
                name="centerLinkNo"
                defaultValue={(editableClient && editableClient.centerLinkNo !== '0')
                  ? editableClient.centerLinkNo
                  : null}
                onChange={handleChange}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgUpdateMedicare !== '' && <h6 className="text-danger">{validationMsgUpdateMedicare}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddHouse}
        setClose={() => {
          handleCloseCustomModal('add-house');
        }}
        title="Assign House"
        onSubmit={addHouseToClientHandler}
        operationName="Assign"
        isDisabled={!selectedHouseCode}
      >
        <AssignHouseWrapper>
          {clientDetails
            && (
            <div className="input-label-wrapper">
              <p className="label">Client</p>
              <div className="icon-name-wrapper">
                <img className="icon" src={userIcon} alt="user icon" />
                <span>{clientDetails.name}</span>
              </div>
            </div>
            )}
          <div className="input-label-wrapper">
            <label htmlFor="house">House Code</label>
            <select onChange={(event) => handleUpdateHouseChange(event)} id="house">
              <option disabled selected value="">Select House</option>
              { updatableHouses
              && updatableHouses.length > 0
              && updatableHouses.map((house) => (
                <option
                  key={house.id}
                  value={house.houseCode}
                >
                  {house.houseCode}
                </option>
              ))}
            </select>
          </div>
        </AssignHouseWrapper>
      </CustomModal>
    </>
  );
};
AdminClientDetails.propTypes = {
  client: PropTypes.string.isRequired
};

export default AdminClientDetails;
