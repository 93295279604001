/** @format */

import React, { useState, useEffect } from 'react';
import { Dropdown, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import logoPic from '../../assets/logo/logo.png';
import homeIcon from '../../assets/logo/home-white.svg';
import adminSearchIcon from '../../assets/logo/admin_search.svg';
import adminStraightIcon from '../../assets/logo/admin_straightLine.svg';
import emergencyIcon from '../../assets/logo/emergency.svg';
import noticeIcon from '../../assets/logo/notice.svg';
import clientIcon from '../../assets/logo/client.svg';
import impContactIcon from '../../assets/logo/imp-contact.svg';
import {
  getUserDetails,
  compare,
  checkIfAdminOnly,
  checkIfHouseAdminOnly
} from '../../helpers/utils';
import {
  DropdownToggle, HeaderRow, DropdownMenu, DropdownHouseMenu, StyledTable,
  SearchWrapper, Heading, SearchSelect, AdminSearch, SearchInputWrapper, TooltipCustom
} from './index.style';
import { getClientListByHouse, getHouseList, getSearchClient } from '../../services/result.services';
import {
  AdminClientIcon,
  AdminHomeIcon,
  AdminUserIcon,
  AdminIncidentIcon,
  AdminSetupIcon,
  AdminHandoverIcon
} from '../svg_icons/indexs';
import AdminRosterIcon from '../svg_icons/AdminRosterIcon';

const Header = ({ houseChanged, houseListChanged }) => {
  const [active, setActive] = React.useState();
  const history = useHistory();
  const [clientList, setClientList] = useState([]);
  const [selected, setSelected] = useState();
  const [houseList, setHouseList] = useState([...getUserDetails().houseList]);
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (pathname === '/home') {
      setActive('none');
    }
    if (pathname === '/admin/houses') {
      setActive('houses');
    }
    if (pathname === '/admin/clients') {
      setActive('clients');
    }
    if (pathname === '/admin/users') {
      setActive('user');
    }
    if (pathname === '/admin/incidents') {
      setActive('incidents');
    }
    if (pathname === '/admin/handovers') {
      setActive('handovers');
    }
    if (pathname === '/admin/setup') {
      setActive('setup');
    }
    if (pathname === '/admin/roster') {
      setActive('roster');
    }
  }, [pathname]);
  const handleClick = (link, params) => {
    history.push(link, params);
  };
  const handleLogout = () => {
    localStorage.setItem('token', null);
    localStorage.setItem('user-details', null);
    history.push('/login');
  };
  const getUserHouseCode = () => {
    const userDetails = getUserDetails();
    return userDetails.houseCode;
  };
  const getUserTitle = () => {
    const userDetails = getUserDetails();
    const firstNameFirstLetter = userDetails.firstName && userDetails.firstName.substring(0, 1);
    const lastNameFirstLetter = userDetails.lastName && userDetails.lastName.substring(0, 1);
    return `${firstNameFirstLetter} ${lastNameFirstLetter}`;
  };
  const getUserRoles = () => {
    const userDetails = getUserDetails();
    return userDetails.roles;
  };

  const getClientsByHouse = async (isHouseChanged) => {
    const houseCode = getUserHouseCode();
    if (!houseCode) return;
    const resp = await getClientListByHouse(houseCode);
    if (!resp) return;
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      setClientList(filteredResp);
      if (isHouseChanged) {
        history.push('/home');
      }
    }
  };
  const getClientSearch = async (name) => {
    const payload = {
      pageNumber: 0,
      pageSize: 1000,
      nameOrHouse: name
    };
    const resp = await getSearchClient(payload);
    const filteredClients = resp.data.clients.filter(
      (client) => getUserDetails().houseList.some(
        (houseCode) => ((houseCode === client.house))
      )
    );
    const clientNames = filteredClients.map((item) => ({
      value: item.name,
      label: `${item.name}`,
      house: `${item.house}`,
      id: item,
      adminSearchIcon: <AdminSearch><img src={adminSearchIcon} alt="edit" /></AdminSearch>,
      adminStraightIcon: <AdminSearch><img src={adminStraightIcon} alt="edit" /></AdminSearch>
    }));
    return clientNames;
  };
  const changeHouse = (houseCode) => {
    const userDetails = getUserDetails();
    if (houseCode === 'home') {
      localStorage.setItem('user-details', JSON.stringify({
        ...userDetails,
        houseCode: ''
      }));
      setClientList([]);
      history.push('/home');
      return;
    }
    if (houseCode === userDetails.houseCode) return;
    localStorage.setItem('user-details', JSON.stringify({
      ...userDetails,
      houseCode
    }));
    getClientsByHouse(true);
  };
  const renderTooltip = (props) => (
    <TooltipCustom className="tool">
      {props}
    </TooltipCustom>
  );

  const goToHome = () => {
    history.push('/home');
  };

  const goToEmergencyPage = () => {
    history.push('/emergencyplan');
  };
  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) return;
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const houseCodeList = filteredActiveHouses.sort(compare).map((house) => house.houseCode);
      localStorage.setItem('user-details', JSON.stringify({
        ...getUserDetails(),
        houseList: houseCodeList
      }));
      setHouseList(houseCodeList);
    }
  };

  useEffect(() => {
    getClientsByHouse(false);
  }, [houseChanged]);

  useEffect(() => {
    if (checkIfAdminOnly()) {
      getHousesHandler();
    }
  }, [houseListChanged]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? 'black' : styles.color,
      backgroundColor: state.isSelected ? '#AFD8D0' : styles.color,
      '&:hover': { color: 'black', backgroundColor: '#AFD8D0' }
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 'none' : 'none',
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 'none' : 'none'
      }
    })

  };

  return (
    <HeaderRow>
      <div>
        <span>
          <button type="button" className="button" onClick={() => handleClick('/home')}>
            <img data-testid="Logo" className="logo" alt="logo" src={logoPic} />
          </button>
        </span>
      </div>
      <div className="links-section">
        {!getUserRoles().some(
          (role) => ['HOUSE ADMIN', 'ADMIN'].indexOf(role) > -1
        ) ? (
          <>
            <div className={(checkIfAdminOnly() || checkIfHouseAdminOnly()) ? 'admin-left' : 'left'}>
              <Dropdown>
                <DropdownToggle
                  id="dropdown-basic"
                  variant="borderless-dark"
                >
                  <span className="house-icon"><img alt="home icon" src={homeIcon} /></span>
                  { getUserHouseCode()
                    ? <span className="select-house-title"> </span>
                    : <span className="select-house-title">Select House</span>}
                  <span className="house-num">
                    { getUserHouseCode() }
                  </span>
                </DropdownToggle>
                <DropdownHouseMenu>
                  {
                houseList.map((houseCode) => (
                  <Dropdown.Item
                    as="div"
                    key={houseCode}
                  >
                    <button type="button" className="nav-link" onClick={() => changeHouse(houseCode)}>
                      <span className="house-num">{houseCode}</span>
                    </button>
                  </Dropdown.Item>
                ))
                }
                </DropdownHouseMenu>
              </Dropdown>
            </div>
            <div className="left">
              <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
                <img src={noticeIcon} alt="notice icon" />
                <button type="button" disabled={!getUserHouseCode()} className="button" onClick={goToHome}>Notice Board</button>
              </div>
              <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
                <img src={emergencyIcon} alt="emergency icon" />
                <button type="button" disabled={!getUserHouseCode()} className="button" onClick={goToEmergencyPage}>Emergency Plan</button>
              </div>
              <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
                <img src={impContactIcon} alt="important contacts icon" />
                <button type="button" disabled={!getUserHouseCode()} className="button" onClick={() => history.push('/importantcontact')}>Important Contacts</button>
              </div>
              <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
                <img src={noticeIcon} alt="notice icon" />
                <Dropdown>
                  <DropdownToggle
                    id="dropdown-basic"
                    variant="borderless-dark"
                    disabled={!getUserHouseCode()}
                  >
                    Forms
                  </DropdownToggle>
                  <DropdownMenu>
                    <Dropdown.Item
                      as="div"
                    >
                      <Link
                        className="nav-link"
                        to={{
                          pathname: '/handover'
                        }}
                      >
                        Handover
                      </Link>
                    </Dropdown.Item>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className={`icon-wrapper ${!getUserHouseCode() && 'faded'}`}>
                <img src={clientIcon} alt="client icon" />
                <Dropdown>
                  <DropdownToggle
                    id="dropdown-basic"
                    variant="borderless-dark"
                    disabled={!getUserHouseCode()}
                  >
                    Clients
                  </DropdownToggle>
                  <DropdownMenu>
                    {clientList.map((client) => (
                      <Dropdown.Item
                        key={client.id}
                        as="div"
                      >
                        <Link
                          className="nav-link"
                          to={{
                            pathname: `/client/${client.id}/${client.name}`
                          }}
                        >
                          {client.name}
                        </Link>
                      </Dropdown.Item>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </>
          ) : (
            <>
              <Heading>HIMS</Heading>
              <SearchWrapper>
                <SearchInputWrapper>
                  <div style={{ color: '#93ACAB', display: 'flex', height: '100%' }}>
                    <span style={{ alignItems: 'center', display: 'flex', marginLeft: '10px' }}>
                      {' '}
                      <FaSearch />
                    </span>
                    <SearchSelect
                      getOptionLabel={(e) => (
                        <StyledTable>
                          <tbody>
                            <tr>
                              <td style={{ width: '10px' }}>{e.adminSearchIcon}</td>
                              <td style={{ width: '250px' }}>{e.label}</td>
                              <td>{e.adminStraightIcon}</td>
                              <td style={{ width: '250px' }}>{e.house}</td>
                            </tr>
                          </tbody>
                        </StyledTable>
                      )}
                      value={selected}
                      onChange={(e) => {
                        handleClick('/admin/client/details', e.id);
                        setSelected(e.label);
                      }}
                      styles={customStyles}
                      placeholder="Search by Client Name or House"
                      loadOptions={(value) => getClientSearch(value)}
                      noOptionsMessage={(e) => (e.inputValue ? 'No options' : null)}
                    />
                  </div>
                </SearchInputWrapper>
              </SearchWrapper>
              <div className="right">
                <div className="icon-wrapper" onClick={() => handleClick('/admin/houses')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('house')}
                  >
                    <div data-testid="house-icon">
                      <AdminHomeIcon active={active === 'houses'} />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="icon-wrapper" onClick={() => handleClick('/admin/clients')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Clients')}
                  >
                    <div data-testid="client-icon">
                      <AdminClientIcon active={active === 'clients'} />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="icon-wrapper" onClick={() => handleClick('/admin/users')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('User')}
                  >
                    <div data-testid="user-icon">
                      <AdminUserIcon active={active === 'user'} />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="icon-wrapper" onClick={() => handleClick('/admin/incidents')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Incidents')}
                  >
                    <div data-testid="incident-icon">
                      <AdminIncidentIcon active={active === 'incidents'} />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="icon-wrapper" onClick={() => handleClick('/admin/setup')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('SetUp')}
                  >
                    <div data-testid="setup-icon">
                      <AdminSetupIcon active={active === 'setup'} />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="icon-wrapper" onClick={() => handleClick('/admin/handovers')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Handover')}
                  >
                    <div data-testid="handover-icon">
                      <AdminHandoverIcon active={active === 'handovers'} />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="icon-wrapper" onClick={() => handleClick('/admin/roster')}>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip('Roster')}
                  >
                    <div data-testid="roster-icon">
                      <AdminRosterIcon active={active === 'roster'} />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </>
          )}
      </div>
      <div className="section-3">
        <Dropdown>
          <DropdownToggle
            borderradius="50%"
            width="55px"
            bgcolor="od_secondary"
            color="od_neutral"
            height="55px"
            id="dropdown-basic"
            variant="borderless-dark"
            className="dropdown-toggle-logout"
          >
            {getUserTitle().toUpperCase()}
          </DropdownToggle>
          <DropdownMenu>
            <Dropdown.Item
              as="div"
            >
              <Link
                className="nav-link"
                to="/changePassword"
              >
                Change Password
              </Link>
            </Dropdown.Item>
            <Dropdown.Item
              as="div"
            >
              <button
                type="button"
                className="nav-link"
                onClick={handleLogout}
              >
                Logout
              </button>
            </Dropdown.Item>
          </DropdownMenu>
        </Dropdown>
      </div>
    </HeaderRow>
  );
};

Header.propTypes = {
  houseChanged: PropTypes.bool.isRequired,
  houseListChanged: PropTypes.bool.isRequired
};

export default Header;
