/** @format */
import styled from 'styled-components';

export const ClientDetailsWrapper = styled.div`
margin: 1rem 0; 
// padding-bottom: 1rem; 
border-radius: 14px;
background-color: ${({ theme }) => theme.colors.od_neutral};
display: flex;
justify-content:flex-start;  
`;

export const LeftWrapper = styled.div`  
  border-radius: 14px 0 0 14px;
  width: 10vw;
  background-color: #F6F9F8;
  height: 125vh;
`;

export const LeftMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    list-style-type: none; 
    font: 14px;     
    margin-left: 0;  
    height: 100%;      
    a { 
      display: flex;
      flex-direction: column;
      justify-content: center; 
      align-items: center;             
      cursor: pointer;
      text-decoration: none;
      // background-color: #F6F9F8;
      width:100%;
      height: 12.5%;
      color: ${({ theme }) => theme.colors.od_placeholder};; 
      font: 6px;    
    } 
    svg{
      fill: grey;
    }   
    img {
      height: 20px;
      width: 20px; 
      margin-bottom: 10px;                        
    }    
    .active {
      background-color: ${({ theme }) => theme.colors.od_neutral};
      color:${({ theme }) => theme.colors.od_secondary};      
    }
    .active svg{
      fill: ${({ theme }) => theme.colors.od_secondary};  
    }

`;

export const RightWrapper = styled.div`  
  border-radius: 0 14px 14px 0;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  width: 87vw;
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0.1rem 0 0 1rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;
