/** @format */

import styled from 'styled-components';

export const StatusContainer = styled.div`
  span {
    text-align: center;
    display: inline-block;
    padding: 0.5rem 0;
    width: 90px;
    border-radius: 7px;
  }
  .raised {
    color: ${({ theme }) => theme.colors.od_primary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_primary};
  }
  .closed {
    color: ${({ theme }) => theme.colors.od_secondary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .reviewed {
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_light_extra};
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0 0 0.2rem 1.5rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const AddIncident = styled.button`
  position: absolute;
  top: 205px;
  right: 25px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;

  img {
    width:100% !important;
  }
`;

export const IncidentWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex{
    display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	.incident_num{
	  color: ${({ theme }) => theme.colors.od_secondary};
	  padding: 0;
	  margin: 0 7px;
	}
	.label{
	  font-size: 12px;
	  margin: 5px 7px;
	}
	.input, .input-like{
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{
	  padding: 4px 8px;
	  margin-bottom: 0;
	}
  }
  .w-100{
	flex: 0 0 100%;
  }
  .w-25{
	flex: 0 0 24%;
  }
  .w-33{
    flex: 0 0 33%;
  }
  .w-50{
	flex: 0 0 49.5%;
  }
`;

export const RowReviewValidation = styled.div`
  width:80%;
  margin:15px 0px 0px 48px;
  color:#28e32a;
  font-size:16px;
`;
export const IncidentsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    font-weight: bold;
    margin-bottom: -5px;
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 5px;
    font-size: 15px;
    padding: 8px 10px;
    margin: 7px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
  }
`;
