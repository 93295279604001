/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Bar,
  BarChart,
  Legend
} from 'recharts';
import DatePicker from 'react-datepicker';
import {
  Modal,
  Spinner,
  Row,
  Col
} from 'react-bootstrap';
import moment from 'moment';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUserDetails, compare, checkIfAdminOnly } from '../../helpers/utils';
import {
  getIncidentList, getHouseList, getCategoryList,
  getClientIncidentsGraph, updateHouse, getClientListByHouse,
  getTypeList, getClassificationList, createIncident, editIncident, getNoticeList
} from '../../services/result.services';
import CustomModal from '../CustomModal';
import SelectContainer from '../SelectContainer';
import deleteIcon from '../../assets/logo/close_cross.svg';
import detailIcon from '../../assets/logo/detail.svg';
import {
  SliderContainer, SliderButtonContainer, FormWrapper,
  ClientIncidentsWrapper, ClientHeader, StaffHeader,
  NoticeWrapper, StaffIncidentsWrapper, NoticeHeader,
  StatusContainer, IconContainer, NoticeTableWrapper,
  IncidentWrapper, RowReviewValidation, NoticeTableContainer,
  StyledTable, TableContainer, TableWrapper, Th, Td, StyledTableStaff
} from './index.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import HouseSlider from '../Slider';
import plusIcon from '../../assets/logo/plus.svg';
import NoData from '../NoData';
import { staffIncident } from '../../lookups/lookups';
import StaffIncidentDetails from '../StaffIncidentDetails/StaffIncidentDetails';

const HomeContent = ({ setHouseChanged }) => {
  const tableBodyRef = useRef('');
  const slider = useRef('');
  const history = useHistory();
  const [incidentListToBeDisplayed, setIncidentListToBeDisplayed] = useState([]);
  const startDate = moment().subtract(4, 'days').format('YYYY-MM-DDT00:00:00.000+00:00');
  const endDate = moment().format('YYYY-MM-DDT23:59:59.000+00:00');
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffIncidentsLoading, setStaffIncidentsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomModalUpdateHouse, setShowCustomModalUpdateHouse] = useState(false);
  const [houseList, setHouseList] = useState([]);
  const [showCustomModalRaiseIncident, setShowCustomModalRaiseIncident] = useState(false);
  const [incidentCategoryOptions, setIncidentCategoryOptions] = useState([]);
  const [incidentClassificationOptions, setIncidentClassificationOptions] = useState([]);
  const [incidentTypeOptions, setIncidentTypeOptions] = useState([]);
  const [incidentValidationMsg, setIncidentValidationMsg] = useState('');
  const [incidentDetails, setIncidentDetails] = useState(staffIncident);
  const [showIncidentDetails, setShowIncidentDetails] = useState(false);
  const [isActiveNotices, setIsActiveNotices] = useState(true);
  const [noticeList, setNoticeList] = useState([]);
  const [noticeListToBeDisplayed, setNoticeListToBeDisplayed] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState({});
  const [clientList, setClientList] = useState([]);
  const [validationMsg, setValidationMsg] = useState('');
  const [houseInfo, setHouseInfo] = useState({
    houseCode: '',
    addrLine1: '',
    addrLine2: '',
    city: '',
    state: '',
    phone: '',
    postCode: ''
  });
  const handleChangeHouse = (event) => {
    const { name, value } = event.target;
    setHouseInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const clearInput = () => {
    setHouseInfo({
      houseCode: '',
      addrLine1: '',
      addrLine2: '',
      city: '',
      state: '',
      phone: '',
      postCode: ''
    });
  };
  const options = [
    { label: '', value: '' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'NA', value: 'NA' }
  ];

  const handleShowIncidentDetails = () => {
    setShowIncidentDetails((prevState) => !prevState);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    if (incidentValidationMsg) {
      setIncidentValidationMsg('');
    }
    const { name, value } = e.target;
    setIncidentDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectDate = (selectedDate) => {
    if (incidentValidationMsg) {
      setIncidentValidationMsg('');
    }
    setIncidentDetails((prevState) => ({
      ...prevState,
      dateOccurred: selectedDate
    }));
  };

  const handleSelect = (selected) => {
    if (incidentValidationMsg) {
      setIncidentValidationMsg('');
    }
    const { name, value } = selected;
    setIncidentDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleEdit = (house) => {
    setValidationMsg('');
    clearInput();
    setHouseInfo(house);
    setShowCustomModalUpdateHouse(true);
  };
  const checkValidation = () => {
    if (houseInfo.houseCode === '') {
      setValidationMsg('House Code cannot be empty');
      return false;
    }
    if (houseInfo.addrLine1 === '') {
      setValidationMsg('Addr1 cannot be empty');
      return false;
    }
    if (houseInfo.city === '') {
      setValidationMsg('City cannot be empty');
      return false;
    }
    if (houseInfo.state === '') {
      setValidationMsg('State cannot be empty');
      return false;
    }
    if (houseInfo.postCode === '') {
      setValidationMsg('Postcode cannot be empty');
      return false;
    }
    if (houseInfo.postCode !== '') {
      const re = /^[0-9]{4}$/;
      if (!re.test(String(houseInfo.postCode))) {
        setValidationMsg('Enter a valid zip');
        return false;
      }
    }
    if (houseInfo.phone !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(houseInfo.phone))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    setValidationMsg('');
    return true;
  };
  const handleCloseCustomModal = (operation) => {
    if (operation === 'update-house') {
      setShowCustomModalUpdateHouse(false);
    }
  };
  const resetForm = () => {
    setIncidentDetails(staffIncident);
  };

  const getNoticeHandler = async () => {
    setLoading(true);
    const resp = await getNoticeList({
      pageSize: 10000,
      pageNumber: 0
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      if (!isActiveNotices) {
        setIsActiveNotices(true);
      }
      setLoading(false);
      const fiteredNotices = resp.data.filter(
        (notice) => notice.houses.some((house) => (
          getUserDetails()?.houseList.some(
            (houseCode) => houseCode === house.houseCode
          )))
      );
      setNoticeList([...fiteredNotices]);
      setNoticeListToBeDisplayed([...fiteredNotices.filter((notice) => notice.noticeStatus === 'ACTIVE')]);
    } else {
      setLoading(false);
    }
  };
  const checkIncidentValidation = (incident) => {
    if (!incident.categoryId) {
      setIncidentValidationMsg('Category is not selected');
      return false;
    }
    if (!incident.typeId) {
      setIncidentValidationMsg('Type is not selected');
      return false;
    }
    if (!incident.classificationId) {
      setIncidentValidationMsg('Classification is not selected');
      return false;
    }
    if (!incident.description) {
      setIncidentValidationMsg('Description is not entered');
      return false;
    }
    if (!incident.dateOccurred) {
      setIncidentValidationMsg('Date Occured is not selected');
      return false;
    }
    if (!incident.location) {
      setIncidentValidationMsg('Location is not entered');
      return false;
    }
    if (!incident.exactLocation) {
      setIncidentValidationMsg('Exact Location is not entered');
      return false;
    }
    if (!incident.witnessName) {
      setIncidentValidationMsg('Witness Name is not selected');
      return false;
    }
    if (!incident.witnessDesignation) {
      setIncidentValidationMsg('Witness Designation is not selected');
      return false;
    }
    if (!incident.immediateAction) {
      setIncidentValidationMsg('immediateAction is not entered');
      return false;
    }
    if (!incident.beforeIncident) {
      setIncidentValidationMsg('Before Incidents is not entered');
      return false;
    }
    if (incident.policeReport === 'Yes') {
      if (!incident.policeName) {
        setIncidentValidationMsg('Police Name is not entered');
        return false;
      }
      if (!incident.policeStation) {
        setIncidentValidationMsg('Police Station is not entered');
        return false;
      }
      if (incident.policeNumber !== '') {
        const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
        if (!re.test(String(incident.policeNumber))) {
          setIncidentValidationMsg('Enter a valid phone number');
          return false;
        }
      }
    }
    setIncidentValidationMsg('');
    return true;
  };

  const getHousesHandler = async (houseCodes) => {
    const resp = await getHouseList();
    if (!resp) {
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const modifiedList = filteredActiveHouses
        .sort(compare)
        .filter((house) => (houseCodes.some((ele) => ele === house.houseCode)));
      setHouseList(modifiedList);
    }
  };
  const getHouseHandlerHome = async () => {
    const resp = await getHouseList();
    setLoading(true);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      if (checkIfAdminOnly()) {
        setHouseList(filteredActiveHouses.sort(compare));
      } else {
        const filteredHouses = filteredActiveHouses.filter(
          (house) => getUserDetails()?.houseList.some((houseCode) => houseCode === house.houseCode)
        );
        setHouseList(filteredHouses.sort(compare));
      }
    } else {
      setLoading(false);
    }
  };
  const handleHouseUpdate = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await updateHouse(houseInfo.id, houseInfo);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'House updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalUpdateHouse(false);
      getHouseHandlerHome();
      clearInput();
      setValidationMsg('');
    } else {
      swal({
        title: 'House updation failed!',
        icon: 'error',
        timer: 3000
      });
      setLoading(false);
      clearInput();
      setShowCustomModalUpdateHouse(false);
    }
  };

  const incidentListRequestObj = {
    pageNumber: 0,
    pageSize: 1000
  };

  const getIncidentListHandler = async (param) => {
    setLoading(true);
    setStaffIncidentsLoading(true);
    const resp = await getIncidentList(param);
    if (!resp) {
      setLoading(false);
      setStaffIncidentsLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setStaffIncidentsLoading(false);
      const openIncidents = resp.data.incidents.filter(
        (incident) => incident.status !== 'CLOSED'
        && incident.raisedFor === 'STAFF'
        && incident.status !== 'INACTIVE'
        && incident.reportedBy.email === getUserDetails()?.email
      );
      setIncidentListToBeDisplayed([...openIncidents]);
    } else {
      setLoading(false);
      setStaffIncidentsLoading(false);
    }
  };

  const getCategoryHandler = async () => {
    const resp = await getCategoryList();
    if (!resp) return;
    if (resp.status === 200) {
      const categoryList = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'categoryId'
      }));
      setIncidentCategoryOptions([...categoryList]);
    }
  };

  const getTypesHandler = async () => {
    const resp = await getTypeList();
    if (!resp) return;
    if (resp.status === 200) {
      const typeList = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'typeId'
      }));
      setIncidentTypeOptions([...typeList]);
    }
  };

  const getClassificationHandler = async () => {
    const resp = await getClassificationList();
    if (!resp) return;
    if (resp.status === 200) {
      const classificationList = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'classificationId'
      }));
      setIncidentClassificationOptions([...classificationList]);
    }
  };
  const getCount = (date, resp, check) => {
    const num = resp.filter((item) => item.name === date && item.status === check).length;
    if (num) {
      return num;
    }
    return 0;
  };
  const getClientGraphDetails = async () => {
    const resp = await getClientIncidentsGraph({
      start: startDate,
      end: endDate,
      raisedFor: 'CLIENT'
    });
    if (!resp) return;
    if (resp.status === 200) {
      const dates = [];
      for (let i = 0; i < 5; i += 1) {
        dates.push(moment().subtract(i, 'days').format('DD-MMM'));
      }
      const tempData = resp.data.map((item) => ({
        name: moment(item.createdAt).format('DD-MMM'),
        status: item.status
      }));
      const formattedData = dates.map((item) => ({
        name: item,
        Reviewed: getCount(item, tempData, 'REVIEWED'),
        Closed: getCount(item, tempData, 'CLOSED'),
        Raised: getCount(item, tempData, 'RAISED')
      }));
      setGraphData(formattedData);
    }
  };

  const changeHouse = (houseCode) => {
    const userDetails = getUserDetails();
    if (houseCode === userDetails.houseCode) return;
    localStorage.setItem('user-details', JSON.stringify({
      ...userDetails,
      houseCode
    }));
    setHouseChanged((prev) => !prev);
    history.push('/home');
  };

  const handleRaiseIncident = async () => {
    if (!checkIncidentValidation(incidentDetails)) return;
    const resp = await createIncident({
      ...incidentDetails,
      categoryId: incidentCategoryOptions.length > 0
      && incidentCategoryOptions.find((h) => h.value === incidentDetails.categoryId).id,
      classificationId: incidentClassificationOptions.length > 0
      && incidentClassificationOptions.find((h) => h.value === incidentDetails.classificationId).id,
      typeId: incidentTypeOptions.length > 0
      && incidentTypeOptions.find((h) => h.value === incidentDetails.typeId).id,
      dateOccurred: moment(incidentDetails.dateOccurred).format('YYYY-MM-DD hh:mm:ss')
    });
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Incident Created successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalRaiseIncident(false);
      resetForm();
      getIncidentListHandler(incidentListRequestObj);
    } else {
      swal({
        title: 'Incident Creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };
  const getClientsByHouseHandler = async (house) => {
    setSelectedHouse(house);
    setLoading(true);
    const resp = await getClientListByHouse(house && house.houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      const clientListForHouse = filteredResp && filteredResp.map((ele) => ({
        label: `${ele.name}`,
        value: `${ele.name}`,
        name: 'clientId',
        id: Number(`${ele.id}`)
      }));
      setClientList(clientListForHouse);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const handleDeleteHouse = async (house) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateHouse(house.id, { ...house, deleted: true });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        const userDetails = getUserDetails();
        const updatedHouseList = userDetails
          .houseList.filter((houseCode) => houseCode !== house.houseCode);
        localStorage.setItem('user-details', JSON.stringify({
          ...userDetails,
          houseList: updatedHouseList
        }));
        setHouseChanged((prevState) => !prevState);
        setLoading(false);
        swal({
          title: 'House deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        setSelectedHouse({});
        getHouseHandlerHome();
      } else {
        setLoading(false);
        swal({
          title: 'House deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const handleDeleteIncident = async (incident) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const {
        category, type, classification, house, client,
        reportedBy, id, escalated, escalatedTo, ...restIncidentDetails
      } = incident;
      const resp = await editIncident(id,
        {
          ...restIncidentDetails,
          id,
          categoryId: category.id,
          classificationId: classification.id,
          typeId: type.id,
          status: 'INACTIVE'
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Incident deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getIncidentListHandler(incidentListRequestObj);
      } else {
        setLoading(false);
        swal({
          title: 'Incident deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  const next = () => {
    slider.current.slickNext();
  };

  const prev = () => {
    slider.current.slickPrev();
  };

  useEffect(() => {
    getIncidentListHandler(incidentListRequestObj);
    getClassificationHandler();
    getCategoryHandler();
    getTypesHandler();
    getClientGraphDetails();
    setLoading(true);
    getHouseHandlerHome();
    getNoticeHandler();
  }, []);
  useEffect(() => {
    getHousesHandler(getUserDetails()?.houseList);
  }, [localStorage.getItem('user-details')]);

  useEffect(() => {
    if (tableBodyRef.current) {
      checkScrollbar();
    }
  });

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);
  useEffect(() => {
    if (Object.keys(selectedHouse).length > 0 && clientList.length > 0) {
      swal({
        title: 'Cannot Delete as active clients present in house!',
        icon: 'error',
        timer: 3000
      });
    }
    if (Object.keys(selectedHouse).length > 0 && clientList.length === 0) {
      handleDeleteHouse(selectedHouse && selectedHouse);
    }
  }, [selectedHouse, clientList]);

  return (
    <>
      <Row>
        <Col>
          <SliderButtonContainer>
            <h2>HOUSES</h2>
            <div>
              <button type="button" onClick={prev}>
                <BsChevronLeft />
              </button>
              <button type="button" onClick={next}>
                <BsChevronRight />
              </button>
            </div>
          </SliderButtonContainer>
          <SliderContainer>
            <HouseSlider
              list={houseList}
              changeHouse={changeHouse}
              handleEdit={handleEdit}
              getClientsByHouseHandler={getClientsByHouseHandler}
              sliderRef={slider}
            />
          </SliderContainer>
        </Col>
      </Row>
      <div style={{ width: '100%', display: 'flex', marginTop: '-20px' }}>
        <Row>
          <Col sm={12} md={6} lg={4}>
            <ClientIncidentsWrapper>
              <ClientHeader>
                <h2>CLIENT INCIDENTS</h2>
              </ClientHeader>
              <BarChart
                width={450}
                height={450}
                data={graphData}
                margin={{
                  top: 5, right: 3, left: 0, bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Raised" fill="#FED282" />
                <Bar dataKey="Reviewed" fill="#59C7AB" />
                <Bar dataKey="Closed" fill="#46BBEA" />
              </BarChart>
            </ClientIncidentsWrapper>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <NoticeWrapper>
              <NoticeHeader>
                <h2>NOTICES</h2>
              </NoticeHeader>
              <NoticeTableContainer>
                <NoticeTableWrapper>
                  <StyledTable>
                    <thead
                      className={`${
                        isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                      }`}
                    >
                      {
                  noticeList.length !== 0
                  && (
                    <tr>
                      <Th>Notice</Th>
                      <Th>
                        House
                      </Th>
                    </tr>
                  )
                }
                    </thead>
                    <tbody ref={tableBodyRef}>
                      {noticeListToBeDisplayed
                  && noticeListToBeDisplayed.map((notice) => (
                    <tr key={notice.id}>
                      <Td data-testid="notice">{notice.notice}</Td>
                      <Td>{notice.houses.map((ele) => ele.houseCode).join(', ')}</Td>
                    </tr>
                  ))}
                      {
                  noticeListToBeDisplayed.length === 0 && <NoData />
                }
                    </tbody>
                  </StyledTable>
                </NoticeTableWrapper>
              </NoticeTableContainer>
            </NoticeWrapper>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <StaffIncidentsWrapper>
              <StaffHeader>
                <h2>STAFF INCIDENTS</h2>
                <div>
                  <button type="button" onClick={() => setShowCustomModalRaiseIncident(true)}>
                    <img src={plusIcon} alt="plus icon" />
                    Add Incident
                  </button>
                </div>
              </StaffHeader>
              {
        showIncidentDetails && selectedIncident ? (
          <StaffIncidentDetails
            handleShowIncidentDetails={handleShowIncidentDetails}
            incidentDetails={selectedIncident}
          />
        ) : (
          <IncidentWrapper>
            {
              staffIncidentsLoading
            && (
            <div style={{ textAlign: 'center', margin: 'auto', marginTop: '50%' }}>
              <Spinner animation="border" variant="primary" />
            </div>
            )
            }
            <TableContainer>
              <TableWrapper>
                <StyledTableStaff>
                  <thead
                    className={`${
                      isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                    }`}

                  >
                    {
                      incidentListToBeDisplayed.length !== 0
                    && (
                    <tr>
                      <Th>No.</Th>
                      <Th>Category</Th>
                      <Th align="center">Status</Th>
                      <Th align="center"> </Th>
                    </tr>
                    )
                    }
                  </thead>
                  <tbody ref={tableBodyRef} className="staff_tbody">
                    {incidentListToBeDisplayed.length > 0
                      && incidentListToBeDisplayed.map((incident, index) => (
                        <tr key={incident.id}>
                          <Td>{index + 1}</Td>
                          <Td data-testid="staffIncidentCategory">
                            {incident.category.name}

                          </Td>
                          <Td>
                            <StatusContainer>
                              {incident.status === 'CLOSED' && (
                              <span className="closed">
                                {incident.status}
                              </span>
                              )}
                              {incident.status === 'REVIEWED' && (
                              <span className="reviewed">
                                {incident.status}
                              </span>
                              )}
                              {incident.status === 'RAISED' && (
                              <span className="raised">
                                {incident.status}
                              </span>
                              )}
                            </StatusContainer>
                          </Td>
                          <Td align="center">
                            <IconContainer
                              onClick={() => {
                                setSelectedIncident(incident);
                                setShowIncidentDetails(true);
                              }}
                            >
                              <img src={detailIcon} alt="detail icon" />
                            </IconContainer>
                            <IconContainer
                              className="delete-btn"
                              onClick={() => handleDeleteIncident(incident)}
                            >
                              <img src={deleteIcon} alt="delete icon" />
                            </IconContainer>
                          </Td>
                        </tr>
                      ))}
                    {
                        incidentListToBeDisplayed.length === 0
                        && !setStaffIncidentsLoading
                        && <NoData />
                    }
                  </tbody>
                </StyledTableStaff>
              </TableWrapper>
            </TableContainer>
            <LoadingModal
              show={show}
              onHide={handleClose}
              animation
              centered
              width="4%"
            >
              <Modal.Body className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </Modal.Body>
            </LoadingModal>
            <CustomModal
              open={showCustomModalUpdateHouse}
              setClose={() => {
                handleCloseCustomModal('update-house');
              }}
              title="Edit House"
              width="680px"
              onSubmit={handleHouseUpdate}
              operationName="Update"
            >
              <div>
                <FormWrapper>
                  <input
                    type="text"
                    value={houseInfo.houseCode}
                    name="houseCode"
                    placeholder="House Code / Name"
                    onChange={handleChangeHouse}
                    disabled
                  />
                  <div className="blank"> </div>
                  <input
                    type="text"
                    value={houseInfo.addrLine1}
                    name="addrLine1"
                    placeholder="Address 1"
                    onChange={handleChangeHouse}
                  />
                  <input
                    type="text"
                    value={houseInfo.addrLine2}
                    name="addrLine2"
                    placeholder="Address 2"
                    onChange={handleChangeHouse}
                  />
                  <input
                    type="text"
                    value={houseInfo.city}
                    name="city"
                    placeholder="City"
                    onChange={handleChangeHouse}
                  />
                  <input
                    type="text"
                    value={houseInfo.state}
                    name="state"
                    placeholder="State"
                    onChange={handleChangeHouse}
                  />
                  <input
                    type="text"
                    value={houseInfo.postCode}
                    name="postCode"
                    placeholder="Postcode"
                    onChange={handleChangeHouse}
                  />
                  <input
                    type="text"
                    value={houseInfo.phone}
                    name="phone"
                    placeholder="Phone"
                    onChange={handleChangeHouse}
                  />
                </FormWrapper>
                { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
              </div>
            </CustomModal>
            <CustomModal
              open={showCustomModalRaiseIncident}
              setClose={() => {
                setShowCustomModalRaiseIncident(false);
              }}
              title="Raise Incident (for Staff)"
              width="80%"
              operationName="Submit"
              onSubmit={handleRaiseIncident}
            >
              <IncidentWrapper>
                <div className="w-33 flex">
                  <p className="label">Category</p>
                  <SelectContainer
                    options={incidentCategoryOptions}
                    width="100%"
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.categoryId,
                      label: incidentDetails.categoryId
                    }}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Type</p>
                  <SelectContainer
                    options={incidentTypeOptions}
                    width="100%"
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.typeId,
                      label: incidentDetails.typeId
                    }}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Classification</p>
                  <SelectContainer
                    options={incidentClassificationOptions}
                    width="100%"
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.classificationId,
                      label: incidentDetails.classificationId
                    }}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">
                    Witness Name
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="witnessName"
                    placeholder=" Witness Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">
                    Witness Designation
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="witnessDesignation"
                    placeholder="Witness Designation"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Police Report</p>
                  <SelectContainer
                    options={options.filter((item) => item.value !== '')}
                    width="100%"
                    defaultValue={
                      options.find((item) => item.value === incidentDetails.policeReport)
                    }
                    onChange={(e) => {
                      handleChange({ target: { name: 'policeReport', value: e.value } });
                    }}
                  />
                </div>
                {incidentDetails.policeReport === 'Yes'
                  ? (
                    <>
                      <div className="w-33 flex">
                        <p className="label">Police Name</p>
                        <input
                          className="input"
                          type="text"
                          name="policeName"
                          placeholder="Police Name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-33 flex">
                        <p className="label">Police Number</p>
                        <input
                          className="input"
                          type="text"
                          name="policeNumber"
                          placeholder="Police Number"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-33 flex">
                        <p className="label">Police Station</p>
                        <input
                          className="input"
                          type="text"
                          name="policeStation"
                          placeholder="Police Station"
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )
                  : null}
                <div className="w-100" style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                  <div className="w-33 flex">
                    <p className="label">Before Incidents</p>
                    <textarea
                      className="input-like"
                      placeholder="Before Incidents"
                      rows="5"
                      name="beforeIncident"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-33 flex">
                    <p className="label">Description</p>
                    <textarea
                      className="input-like"
                      placeholder="Description"
                      rows="5"
                      name="description"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-33 flex">
                    <p className="label">Immediate Action</p>
                    <textarea
                      className="input-like"
                      placeholder="Immediate Action"
                      rows="5"
                      name="immediateAction"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-33 flex">
                  <p className="label">Date and Time of Occurrence</p>
                  <DatePicker
                    timeFormat="HH:mm"
                    showTimeSelect
                    className="input w-100"
                    dateFormat="dd/MM/yyyy, HH:mm:ss"
                    placeholderText="Select date"
                    selected={incidentDetails.dateOccurred}
                    onChange={(selectedDate) => handleSelectDate(selectedDate)}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Location</p>
                  <input
                    className="input"
                    type="text"
                    name="location"
                    placeholder="Location"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Exact Location</p>
                  <input
                    className="input"
                    type="text"
                    name="exactLocation"
                    placeholder="Exact Location"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-25 flex">
                  <p className="label">Affected Person First Name</p>
                  <input
                    className="input"
                    type="text"
                    name="injuredGivenName"
                    placeholder="Given Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-25 flex">
                  <p className="label">Affected Person Last Name</p>
                  <input
                    className="input"
                    type="text"
                    name="injuredFamilyName"
                    placeholder="Family Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-25 flex" />
                <div className="w-25 flex" />
              </IncidentWrapper>
              <RowReviewValidation>
                { incidentValidationMsg && <h6 className="text-danger">{incidentValidationMsg}</h6>}
              </RowReviewValidation>
            </CustomModal>
          </IncidentWrapper>
        )
      }
            </StaffIncidentsWrapper>
          </Col>
        </Row>
      </div>
    </>
  );
};

HomeContent.propTypes = {
  setHouseChanged: PropTypes.func.isRequired
};

export default HomeContent;
