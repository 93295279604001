import React from 'react';

export default function ReportIcon() {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2959 2.84364V1.64771C19.2959 1.23469 18.9612 0.9 18.5482 0.9C18.135 0.9 17.8003 1.23466 17.8003 1.64771V2.84337L8.28199 2.84363V1.6477C8.28199 1.44928 8.20299 1.25931 8.06306 1.11908L8.06298 1.119C7.92262 0.978637 7.7323 0.9 7.53402 0.9V0.899719L7.53006 0.900016C7.11887 0.902102 6.78596 1.23594 6.78596 1.64772V2.84338L4.23931 2.84364C4.2393 2.84364 4.2393 2.84364 4.2393 2.84364C3.35371 2.84364 2.50427 3.19538 1.87815 3.82176C1.25174 4.44788 0.9 5.29741 0.9 6.18322V18.2792C0.9 19.165 1.25175 20.0143 1.87812 20.6407C2.50424 21.2671 3.35377 21.6188 4.23931 21.6188H21.8426C22.7284 21.6188 23.5776 21.2671 24.204 20.6407C24.8304 20.0143 25.1822 19.1651 25.1822 18.2792V6.18322C25.1822 5.29741 24.8304 4.44789 24.204 3.82176C23.5776 3.19538 22.7284 2.84364 21.8426 2.84364H19.2959ZM21.843 4.33959H21.8744C22.3522 4.34772 22.8085 4.54113 23.1468 4.87944C23.4925 5.22517 23.687 5.6941 23.687 6.18326V18.2793C23.687 18.7684 23.4925 19.2374 23.1468 19.5831C22.8011 19.9288 22.3322 20.1232 21.843 20.1232H4.23973C3.75083 20.1232 3.28191 19.9288 2.93619 19.5831C2.59047 19.2374 2.39607 18.7684 2.39607 18.2793V6.18326C2.39607 5.69409 2.59047 5.22516 2.93619 4.87944C3.28192 4.53372 3.75085 4.33932 4.23973 4.33932H6.78638V5.53525C6.78638 5.94827 7.12134 6.28323 7.53436 6.28323C7.94738 6.28323 8.28234 5.94827 8.28234 5.53525V4.33959L17.8007 4.33959V5.53524C17.8007 5.94823 18.1353 6.28322 18.5487 6.28322C18.9617 6.28322 19.2964 5.94821 19.2964 5.53524V4.33959H21.843Z" />
      <path d="M6.19547 10.1679C6.33561 10.3083 6.52596 10.3869 6.72421 10.3869H10.6118C11.0248 10.3869 11.3595 10.0522 11.3595 9.63919C11.3595 9.22593 11.0248 8.89121 10.6118 8.89121H6.72421C6.31122 8.89121 5.97623 9.22587 5.97623 9.63919V9.67252L5.97703 9.67359C5.9856 9.85946 6.06336 10.0357 6.19547 10.1679Z" />
      <path d="M6.19547 13.7323C6.33561 13.8727 6.52596 13.9514 6.72421 13.9514H19.3586C19.7716 13.9514 20.1063 13.6167 20.1063 13.2036C20.1063 12.7904 19.7716 12.4557 19.3586 12.4557H6.72421C6.31122 12.4557 5.97623 12.7903 5.97623 13.2036V13.237L5.97703 13.238C5.9856 13.4239 6.06336 13.6002 6.19547 13.7323Z" />
      <path d="M19.3584 16.0191H6.72403C6.31103 16.0191 5.97605 16.3538 5.97605 16.7671C5.97605 17.1802 6.31106 17.5148 6.72403 17.5148H19.3584C19.7714 17.5148 20.1061 17.1801 20.1061 16.7671C20.1061 16.3539 19.7714 16.0191 19.3584 16.0191Z" />
    </svg>
  );
}
