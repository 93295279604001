/** @format */

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BreadCrumb,
  EmergencyPlanWrapper,
  EmergencyPlanHeader,
  EmergencyContentWrapper
} from './index.style';
import PdfViewPlans from '../PdfViewPlans/PdfViewPlans';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import NoData from '../NoData';

const SupportDoc = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [isPdf, setIsPdf] = useState(false);
  const handleGoBack = () => {
    history.goBack();
  };
  const showPdf = () => {
    setIsPdf(true);
  };
  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Support Document </span>
          </li>
        </ul>
      </BreadCrumb>
      <EmergencyPlanWrapper>
        <EmergencyPlanHeader>
          <h2>Support Document</h2>
          <button type="button" onClick={handleGoBack}>
            Back
          </button>
        </EmergencyPlanHeader>
        <EmergencyContentWrapper>
          <EmergencyContentWrapper>
            {/* <img src={state.supportDocument.supportDocument} alt="SupportDocument" /> */}
            {state.supportDocument.supportDocument === ''
              ? <NoData />
              : (
                <>
                  {
                  isPdf
                    ? (
                      <>
                        <PdfViewPlans
                          pdf={state.supportDocument.supportDocument}
                        />
                      </>
                    ) : (
                      <img
                        onError={(e) => {
                          e.target.onerror = showPdf();
                        }}
                        width="80%"
                        height="80%"
                        src={state.supportDocument.supportDocument}
                        alt="sample"
                      />
                    )
                }

                </>
              )}

          </EmergencyContentWrapper>
        </EmergencyContentWrapper>

      </EmergencyPlanWrapper>
    </>
  );
};

export default SupportDoc;
