/** @format */
import React, { useEffect } from 'react';
import {
  NavLink,
  Switch,
  Route,
  useLocation,
  useHistory
} from 'react-router-dom';
import {
  ClientDetailsWrapper, RightWrapper, LeftWrapper, LeftMenu
} from './index.style';
import {
  BreadCrumb
} from '../../styledComponents/common/page.style';
import AdminClientDetails from '../../components/AdminClientDetails/AdminClientDetails';
import AdminClientDailyNotes from '../../components/AdminClientDailyNotes/AdminClientDailyNotes';
import AdminClientContacts from '../../components/AdminClientContacts/AdminClientContacts';
import AdminClientIncidents from '../../components/AdminClientIncidents/AdminClientIncidents';
import AdminClientReports from '../../components/AdminClientReports/AdminClientReports';
import AdminClientSupportDocs from '../../components/AdminClientSupportDocs/AdminClientSupportDocs';
import AdminClientNotes from '../../components/AdminClientNotes/AdminClientNotes';
import AdminClientFinance from '../../components/AdminClientFinance/AdminClientFinance';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import {
  ContactIcon,
  DetailsIcon,
  IncidentIcon,
  DailyNotesIcon,
  ReportIcon,
  SupportDocIcon,
  NotesIcon,
  FinanceIcon
} from '../../components/svg_icons/indexs';

const ClientDetails = () => {
  const history = useHistory();
  const { state, pathname } = useLocation();
  if (state === undefined) {
    history.push('/admin/clients');
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Clients </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span>
              {' '}
              { state && state.name }
              {' '}
            </span>
          </li>
        </ul>
      </BreadCrumb>
      <ClientDetailsWrapper>
        <LeftWrapper>
          <LeftMenu>
            <NavLink
              data-testid="details-button"
              exact
              to={{ pathname: '/admin/client/details', state }}
              activeClassName="active"
            >
              <DetailsIcon />
              <p> Details </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/contact', state }}
              activeClassName="active"
            >
              <ContactIcon />
              <p> Contacts </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/dailynotes', state }}
              activeClassName="active"
            >
              <DailyNotesIcon />
              <p> DailyNotes </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/incidents', state }}
              activeClassName="active"
            >
              <IncidentIcon />
              <p> Incidents </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/reports', state }}
              activeClassName="active"
            >
              <ReportIcon />
              <p> Reports </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/supportdocs', state }}
              activeClassName="active"
            >
              <SupportDocIcon />
              <p>
                {' '}
                Support
                <br />
                Docs
                {' '}
              </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/notes', state }}
              activeClassName="active"
            >
              <NotesIcon />
              <p> Notes </p>
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/admin/client/details/finance', state }}
              activeClassName="active"
            >
              <FinanceIcon />
              <p> Finance </p>
            </NavLink>
          </LeftMenu>
        </LeftWrapper>
        <RightWrapper>
          <Switch>
            <Route exact path="/admin/client/details" component={() => <AdminClientDetails client={state} />} />
            <Route exact path="/admin/client/details/contact" component={() => <AdminClientContacts client={state} />} />
            <Route exact path="/admin/client/details/dailynotes" component={() => <AdminClientDailyNotes client={state} />} />
            <Route exact path="/admin/client/details/supportdocs" component={() => <AdminClientSupportDocs client={state} />} />
            <Route exact path="/admin/client/details/incidents" component={() => <AdminClientIncidents clients={state} />} />
            <Route exact path="/admin/client/details/reports" component={() => <AdminClientReports client={state} />} />
            <Route exact path="/admin/client/details/notes" component={() => <AdminClientNotes client={state} />} />
            <Route exact path="/admin/client/details/finance" component={() => <AdminClientFinance client={state} />} />
          </Switch>
        </RightWrapper>
      </ClientDetailsWrapper>
    </>
  );
};
export default ClientDetails;
