import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import CustomModal from '../../CustomModal';
import AddRosterWhite from '../../../assets/logo/roster_add_icon_white.svg';
import tickIcon from '../../../assets/logo/success-tick.svg';
import deleteIcon from '../../../assets/logo/close_cross.svg';
import {
  FormWrapper, IconContainer, ModalClientName
} from './index.style';

const ClientDayProgramModal = ({
  selectedModal,
  setSelectedModal,
  renderToolTip,
  selectedClient,
  selectedClientDayProgram,
  handleDayProgramChange,
  updateClientDayProgramme,
  newDayProgram,
  addNewDayProgram,
  handleAddNewDayProgram,
  clearNewClientDayProgram,
  handleNewDayProgram,
  handleAddNewClientDayProgram,
  setSelectedClientDayProgram
}) => (
  <CustomModal
    open={selectedModal === 'view-day-program'}
    setClose={() => {
      setSelectedModal(null);
      clearNewClientDayProgram();
      setSelectedClientDayProgram([]);
    }}
    title="Day Program"
    width="1080px"
    onSubmit={() => {
      setSelectedModal(null);
      setSelectedClientDayProgram([]);
    }}
    operationName="Done"
    disableCancel
  >
    <ModalClientName>
      {selectedClient && selectedClient.name}
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderToolTip('Add Day Programme')}
      >
        <button type="button" className="action" onClick={() => handleAddNewDayProgram()}>
          <img width="20px" height="20px" src={AddRosterWhite} alt="add-icon" />
        </button>
      </OverlayTrigger>
    </ModalClientName>
    <div>
      <FormWrapper>
        {selectedClientDayProgram.length > 0 || addNewDayProgram ? (
          <table>
            <thead>
              <tr>
                <th>Day Program</th>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Contact Name</th>
                <th>Contact Phone</th>
              </tr>
            </thead>
            <tbody>
              {selectedClientDayProgram && selectedClientDayProgram.length > 0
                                && selectedClientDayProgram.map((item, index) => (
                                  <tr key={`dayProgram-${index}`}>
                                    <td>
                                      <input
                                        type="text"
                                        className="table-input"
                                        value={item.name}
                                        name="name"
                                        placeholder="Day program"
                                        onChange={(e) => handleDayProgramChange(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="date"
                                        className="table-input"
                                        value={item.date}
                                        name="date"
                                        placeholder="Date"
                                        onChange={(e) => handleDayProgramChange(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="time"
                                        className="table-input"
                                        value={item.startTime}
                                        name="startTime"
                                        placeholder="Start Time"
                                        onChange={(e) => handleDayProgramChange(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="time"
                                        className="table-input"
                                        value={item.endTime}
                                        name="endTime"
                                        placeholder="End Time"
                                        onChange={(e) => handleDayProgramChange(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="table-input"
                                        value={item.contactName}
                                        name="contactName"
                                        placeholder="Contact Name"
                                        onChange={(e) => handleDayProgramChange(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="table-input"
                                        value={item.contactPhone}
                                        name="contactPhone"
                                        placeholder="Contact Phone"
                                        onChange={(e) => handleDayProgramChange(e, index)}
                                      />
                                    </td>
                                    <td>
                                      <IconContainer onClick={() => updateClientDayProgramme('update', item)}><img src={tickIcon} alt="edit-icon" /></IconContainer>
                                      <div className="delete-btn" onClick={() => updateClientDayProgramme('delete', item)}><img src={deleteIcon} width="25px" height="25px" alt="delete-icon" /></div>
                                    </td>
                                  </tr>
                                ))}
              {addNewDayProgram ? (
                <tr key="new-dayProgram">
                  <td>
                    <input
                      type="text"
                      className="table-input"
                      value={newDayProgram.name}
                      name="name"
                      placeholder="Day program"
                      onChange={(e) => handleNewDayProgram(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="table-input"
                      value={newDayProgram.date}
                      name="date"
                      placeholder="Date"
                      onChange={(e) => handleNewDayProgram(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      className="table-input"
                      value={moment(newDayProgram.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                      name="startTime"
                      placeholder="Start Time"
                      onChange={(e) => handleNewDayProgram(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      className="table-input"
                      value={moment(newDayProgram.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
                      name="endTime"
                      placeholder="End Time"
                      onChange={(e) => handleNewDayProgram(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="table-input"
                      value={newDayProgram.contactName}
                      name="contactName"
                      placeholder="Contact Name"
                      onChange={(e) => handleNewDayProgram(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="table-input"
                      value={newDayProgram.contactPhone}
                      name="contactPhone"
                      placeholder="Contact Phone"
                      onChange={(e) => handleNewDayProgram(e)}
                    />
                  </td>
                  <td>
                    <IconContainer onClick={handleAddNewClientDayProgram}><img src={tickIcon} alt="edit-icon" /></IconContainer>
                    <div className="delete-btn" onClick={() => clearNewClientDayProgram()}><img src={deleteIcon} width="25px" height="25px" alt="delete-icon" /></div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        ) : <h4>No Day Programs</h4>}
      </FormWrapper>
    </div>
  </CustomModal>
);

ClientDayProgramModal.defaultProps = {
  selectedModal: null
};

ClientDayProgramModal.propTypes = {
  selectedModal: PropTypes.string,
  selectedClient: PropTypes.shape.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  renderToolTip: PropTypes.func.isRequired,
  newDayProgram: PropTypes.shape.isRequired,
  selectedClientDayProgram: PropTypes.func.isRequired,
  handleDayProgramChange: PropTypes.func.isRequired,
  updateClientDayProgramme: PropTypes.func.isRequired,
  addNewDayProgram: PropTypes.bool.isRequired,
  clearNewClientDayProgram: PropTypes.func.isRequired,
  handleNewDayProgram: PropTypes.func.isRequired,
  handleAddNewClientDayProgram: PropTypes.func.isRequired,
  handleAddNewDayProgram: PropTypes.func.isRequired,
  setSelectedClientDayProgram: PropTypes.func.isRequired
};

export default ClientDayProgramModal;
