/** @format */

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import moment from 'moment';
import {
  getHouseList,
  getIncidentList,
  getCategoryList,
  getTypeList,
  getClassificationList,
  getClientListByHouse,
  createIncident,
  editIncident
} from '../../services/result.services';
import { getUserDetails } from '../../helpers/utils';
import detailIcon from '../../assets/logo/detail.svg';
import plusIcon from '../../assets/logo/plus.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import {
  StyledTable, TableContainer, TableWrapper, Th, Td
} from '../../styledComponents/common/table.style';
import { reportableToNDISList } from '../../lookups/lookups';
import {
  StatusContainer,
  IconContainer,
  IncidentsHeader,
  IncidentWrapper,
  RowReviewValidation
} from './index.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import NoData from '../NoData';
import CustomModal from '../CustomModal';
import SelectContainer from '../SelectContainer';
import ClientIncidentDetails from '../ClientIncidentDetails/ClientIncidentDetails';
import { DetailsWrapper } from '../../styledComponents/common/page.style';

const AdminClientIncidents = ({ clients }) => {
  const { pathname } = useLocation();
  const initialReport = { label: 'ALL', value: 'ALL' };
  const params = pathname && pathname.split('/');
  const clientName = params[3];
  const tableBodyRef = useRef('');
  const [incidentList, setIncidentList] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomModalRaiseIncident, setShowCustomModalRaiseIncident] = useState(false);
  const [listHouses, setListHouses] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [incidentCategoryOptions, setIncidentCategoryOptions] = useState([]);
  const [incidentClassificationOptions, setIncidentClassificationOptions] = useState([]);
  const [incidentTypeOptions, setIncidentTypeOptions] = useState([]);
  const [showIncidentDetails, setShowIncidentDetails] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [selectedReport, setSelectedReport] = useState(initialReport);
  const [incidentDetails, setIncidentDetails] = useState({
    houseId: '',
    clientId: '',
    categoryId: '',
    classificationId: '',
    typeId: '',
    status: 'RAISED',
    raisedFor: 'CLIENT',
    description: '',
    dateOccurred: '',
    location: '',
    exactLocation: '',
    injuredGivenName: '',
    injuredFamilyName: '',
    policeReport: '',
    policeName: '',
    policeNumber: '',
    policeStation: '',
    reportableToNDIS: 'No',
    reportableToWorksafe: 'No'
  });
  const [incidentValidationMsg, setIncidentValidationMsg] = useState('');
  const history = useHistory();

  const handleClick = (link) => history.push(link);
  const incidentListRequestObj = {
    pageNumber: 0,
    pageSize: 1000,
    clientName: clients.name,
    houseCode: clients.houseCode
  };

  const handleShowIncidentDetails = () => {
    setShowIncidentDetails((prevState) => !prevState);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIncidentDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const options = [
    { label: '', value: '' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'NA', value: 'NA' }
  ];
  const handleSelectDate = (selectedDate) => {
    setIncidentDetails((prevState) => ({
      ...prevState,
      dateOccurred: selectedDate
    }));
  };

  const handleSelect = (selected) => {
    const { name, value } = selected;
    setIncidentDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getIncidentListHandler = async (param) => {
    setLoading(true);
    const resp = await getIncidentList(param);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const incidentsForClient = resp.data.incidents.filter((incident) => incident.raisedFor === 'CLIENT' && incident.status !== 'INACTIVE');
      setIncidents([...incidentsForClient]);
      setIncidentList([...incidentsForClient]);
      setSelectedReport(initialReport);
    } else {
      setLoading(false);
    }
  };

  const getHousesHandler = async () => {
    setLoading(true);
    const resp = await getHouseList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses && filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const housesList = filteredHouses.map((ele) => ({
        label: `${ele.houseCode}`,
        value: `${ele.houseCode}`,
        id: Number(`${ele.id}`),
        name: 'houseId'
      }));
      setListHouses(housesList);
      setSelectedReport(initialReport);
      setLoading(false);
    } else {
      setListHouses([]);
      setLoading(false);
    }
  };

  const getClientsByHouse = async () => {
    setLoading(true);
    if (!incidentDetails.houseId) {
      setLoading(false);
      return;
    }
    const resp = await getClientListByHouse(incidentDetails.houseId && incidentDetails.houseId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      const clientListForHouse = filteredResp && filteredResp.map((ele) => ({
        label: `${ele.name}`,
        value: `${ele.name}`,
        name: 'clientId',
        id: Number(`${ele.id}`)
      }));
      setClientList(clientListForHouse);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getCategoryHandler = async () => {
    const resp = await getCategoryList();
    if (!resp) return;
    if (resp.status === 200) {
      const categoryList = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'categoryId'
      }));
      setIncidentCategoryOptions([...categoryList]);
    }
  };
  const getTypesHandler = async () => {
    const resp = await getTypeList();
    if (!resp) return;
    if (resp.status === 200) {
      const typeList = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'typeId'
      }));
      setIncidentTypeOptions([...typeList]);
    }
  };
  const getClassificationHandler = async () => {
    const resp = await getClassificationList();
    if (!resp) return;
    if (resp.status === 200) {
      const classificationList = resp.data && resp.data.map((ele) => ({
        id: Number(`${ele.id}`),
        label: ele.name,
        value: ele.name,
        name: 'classificationId'
      }));
      setIncidentClassificationOptions([...classificationList]);
    }
  };

  const checkIncidentValidation = (incident) => {
    if (!incident.houseId) {
      setIncidentValidationMsg('House Code is not selected');
      return false;
    }
    if (!incident.clientId) {
      setIncidentValidationMsg('Client is not selected');
      return false;
    }
    if (!incident.categoryId) {
      setIncidentValidationMsg('Category is not selected');
      return false;
    }
    if (!incident.typeId) {
      setIncidentValidationMsg('Type is not selected');
      return false;
    }
    if (!incident.classificationId) {
      setIncidentValidationMsg('Classification is not selected');
      return false;
    }
    if (!incident.witnessName) {
      setIncidentValidationMsg('Witness Name is not selected');
      return false;
    }
    if (!incident.witnessDesignation) {
      setIncidentValidationMsg('Witness Designation is not selected');
      return false;
    }
    if (!incident.description) {
      setIncidentValidationMsg('Description is not entered');
      return false;
    }
    if (!incident.immediateAction) {
      setIncidentValidationMsg('immediateAction is not entered');
      return false;
    }
    if (!incident.dateOccurred) {
      setIncidentValidationMsg('Date Occured is not selected');
      return false;
    }
    if (!incident.beforeIncident) {
      setIncidentValidationMsg('Before Incidents is not entered');
      return false;
    }
    if (!incident.policeReport) {
      setIncidentValidationMsg('Police Report is not entered');
      return false;
    }
    if (incident.policeReport === 'Yes') {
      if (!incident.policeName) {
        setIncidentValidationMsg('Police Name is not entered');
        return false;
      }
      if (!incident.policeStation) {
        setIncidentValidationMsg('Police Station is not entered');
        return false;
      }
      if (incident.policeNumber !== '') {
        const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
        if (!re.test(String(incident.policeNumber))) {
          setIncidentValidationMsg('Enter a valid phone number');
          return false;
        }
      }
    }
    if (!incident.location) {
      setIncidentValidationMsg('Location is not entered');
      return false;
    }
    if (!incident.exactLocation) {
      setIncidentValidationMsg('Exact Location is not entered');
      return false;
    }
    setIncidentValidationMsg('');
    return true;
  };

  const resetForm = () => {
    setIncidentDetails({
      ...incidentDetails,
      houseId: '',
      clientId: '',
      categoryId: '',
      classificationId: '',
      typeId: '',
      description: '',
      dateOccurred: '',
      location: '',
      exactLocation: '',
      injuredGivenName: '',
      injuredFamilyName: '',
      policeReport: '',
      policeName: '',
      policeNumber: '',
      policeStation: '',
      reportableToNDIS: ''
    });
  };

  async function handleRaiseIncident() {
    if (!checkIncidentValidation(incidentDetails)) { return; }
    const resp = await createIncident({
      ...incidentDetails,
      houseId: listHouses.length > 0
      && listHouses.find((h) => h.value === incidentDetails.houseId).id,
      clientId: clientList.length > 0
      && clientList.find((h) => h.value === incidentDetails.clientId).id,
      categoryId: incidentCategoryOptions.length > 0
      && incidentCategoryOptions.find((h) => h.value === incidentDetails.categoryId).id,
      classificationId: incidentClassificationOptions.length > 0
      && incidentClassificationOptions.find((h) => h.value === incidentDetails.classificationId).id,
      typeId: incidentTypeOptions.length > 0
      && incidentTypeOptions.find((h) => h.value === incidentDetails.typeId).id,
      dateOccurred: moment(incidentDetails.dateOccurred).format('YYYY-MM-DD hh:mm:ss')
    });
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Incident Created successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalRaiseIncident(false);
      resetForm();
      getIncidentListHandler(incidentListRequestObj);
    } else {
      swal({
        title: 'Incident Creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  }

  const handleDeleteIncident = async (incident) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const {
        category, type, classification, house, client,
        reportedBy, id, escalated, escalatedTo, ...restIncidentDetails
      } = incident;
      const resp = await editIncident(id,
        {
          ...restIncidentDetails,
          houseId: house.id,
          clientId: client.id,
          categoryId: category.id,
          classificationId: classification.id,
          typeId: type.id,
          status: 'INACTIVE'
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Incident deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getIncidentListHandler(incidentListRequestObj);
      } else {
        setLoading(false);
        swal({
          title: 'Incident deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleReportChange = (e) => {
    const { value } = e;
    if (value === 'ALL') {
      setSelectedReport(initialReport);
      setIncidentList([...incidents.filter(
        (incident) => (
          incident.reportableToNDIS !== 'ALL'
        )
      )]);
    } else {
      setSelectedReport({ label: value, value });
      setIncidentList([...incidents.filter(
        (incident) => incident.reportableToNDIS === value
      )
      ]);
    }
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    if (tableBodyRef.current) {
      checkScrollbar();
    }
  });

  useEffect(() => {
    getHousesHandler();
    getClassificationHandler();
    getCategoryHandler();
    getTypesHandler();
  }, []);

  useEffect(() => {
    getClientsByHouse();
  }, [incidentDetails.houseId]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getIncidentListHandler(incidentListRequestObj);
  }, [clientName]);

  return (
    <>
      <IncidentsHeader>
        <h2>Incidents</h2>
        <div>
          <button type="button" onClick={() => setShowCustomModalRaiseIncident(true)}>
            <img src={plusIcon} alt="plus icon" />
            Add Incident
          </button>
          <button onClick={() => handleClick('/admin/clients')} type="button"> Back </button>
        </div>
      </IncidentsHeader>
      {
        showIncidentDetails && selectedIncident ? (
          <ClientIncidentDetails
            handleShowIncidentDetails={handleShowIncidentDetails}
            incidentDetails={selectedIncident}
          />
        ) : (
          <>
            <DetailsWrapper>
              <TableContainer>
                <TableWrapper>
                  <StyledTable>
                    <thead
                      className={`${
                        isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                      }`}
                    >
                      {
                      incidentList.length !== 0
                    && (
                    <tr>
                      <Th width="40px">No.</Th>
                      <Th width="160px">Type</Th>
                      <Th width="100px">
                        <span>Reportable</span>
                        <div className="select-wrapper">
                          <SelectContainer
                            options={reportableToNDISList.length > 0 && reportableToNDISList}
                            placeholderText="Reportable To NDIS"
                            className="select-container"
                            isMultiple={false}
                            defaultValue={selectedReport}
                            onChange={handleReportChange}
                          />
                        </div>
                      </Th>
                      <Th width="120px">Reported By</Th>
                      <Th width="120px">Reviewed By</Th>
                      <Th width="120px">Responsibilty Of</Th>
                      <Th width="120px">Closed By</Th>
                      <Th width="100px">Status</Th>
                      <Th width="100px">Action </Th>
                    </tr>
                    )
                    }
                    </thead>
                    <tbody ref={tableBodyRef}>
                      {incidentList
                      && incidentList.map((incident, index) => (
                        <tr key={incident.id}>
                          <Td width="40px">{index + 1}</Td>
                          <Td width="160px">{incident.type.name}</Td>
                          <Td width="100px">{incident.reportableToNDIS}</Td>
                          <Td width="120px">{incident.reportedBy.firstName}</Td>
                          <Td width="120px">{incident.reviewedBy}</Td>
                          <Td width="120px">{incident.followUpResponsibility}</Td>
                          <Td width="120px">{incident.closedBy}</Td>
                          <Td width="100px">
                            <StatusContainer>
                              {incident.status === 'CLOSED' && <span className="closed">{incident.status}</span>}
                              {incident.status === 'REVIEWED' && <span className="reviewed">{incident.status}</span>}
                              {incident.status === 'RAISED' && <span className="raised">{incident.status}</span>}
                            </StatusContainer>
                          </Td>
                          <Td width="100px" align="center">
                            <IconContainer
                              onClick={() => {
                                setSelectedIncident(incident);
                                setShowIncidentDetails(true);
                              }}
                            >
                              <img src={detailIcon} alt="detail icon" />
                            </IconContainer>
                            <IconContainer
                              className="delete-btn"
                              onClick={() => handleDeleteIncident(incident)}
                            >
                              <img src={deleteIcon} alt="delete icon" />
                            </IconContainer>
                          </Td>
                        </tr>
                      ))}
                      {/* {
                        incidents.length === 0 && <NoData />
                    } */}
                      {
                        incidentList.length === 0 && (
                          <>
                            <tr>
                              <Th width="40px">No.</Th>
                              <Th width="160px">Type</Th>
                              <Th width="160px">
                                <span>Reportable To NDIS</span>
                                <div className="select-wrapper">
                                  <SelectContainer
                                    options={reportableToNDISList.length > 0
                                      && reportableToNDISList}
                                    placeholderText="Reportable To NDIS"
                                    className="select-container"
                                    isMultiple={false}
                                    defaultValue={selectedReport}
                                    onChange={handleReportChange}
                                  />
                                </div>
                              </Th>
                              <Th width="120px">Reported By</Th>
                              <Th width="120px">Reviewed By</Th>
                              <Th width="120px">Responsibilty Of</Th>
                              <Th width="120px">Closed By</Th>
                              <Th width="100px">Status</Th>
                              <Th width="100px" align="center">Action </Th>
                            </tr>
                            <NoData />
                          </>
                        )
                    }
                    </tbody>
                  </StyledTable>
                </TableWrapper>
              </TableContainer>
            </DetailsWrapper>
            <LoadingModal
              show={show}
              onHide={handleClose}
              animation
              centered
              width="4%"
            >
              <Modal.Body className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </Modal.Body>
            </LoadingModal>
            <CustomModal
              open={showCustomModalRaiseIncident}
              setClose={() => {
                setShowCustomModalRaiseIncident(false);
              }}
              title="Raise Incident (for Client)"
              width="80%"
              operationName="Submit"
              onSubmit={handleRaiseIncident}
            >
              <IncidentWrapper>
                <div className="w-25 flex">
                  <p className="label">
                    House
                  </p>

                  <SelectContainer
                    options={listHouses.length > 0 && listHouses}
                    placeholderText="House"
                    className="select-container"
                    isMultiple={false}
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.houseId,
                      label: incidentDetails.houseId
                    }}
                  />
                </div>
                <div className="w-25 flex">
                  <p className="label">
                    Client Name
                  </p>
                  <SelectContainer
                    options={clientList.length > 0 && clientList}
                    placeholderText="Client Name"
                    className="select-container"
                    isMultiple={false}
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.clientId,
                      label: incidentDetails.clientId
                    }}
                    disabled={!incidentDetails.houseId || !clientList.length}
                  />
                </div>
                <div className="w-25 flex">
                  <p className="label">
                    Witness Name
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="witnessName"
                    placeholder=" Witness Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-25 flex">
                  <p className="label">
                    Witness Designation
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="witnessDesignation"
                    placeholder="Witness Designation"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Category</p>
                  <SelectContainer
                    options={incidentCategoryOptions}
                    width="100%"
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.categoryId,
                      label: incidentDetails.categoryId
                    }}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Type</p>
                  <SelectContainer
                    options={incidentTypeOptions}
                    width="100%"
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.typeId,
                      label: incidentDetails.typeId
                    }}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Classification</p>
                  <SelectContainer
                    options={incidentClassificationOptions}
                    width="100%"
                    onChange={handleSelect}
                    defaultValue={{
                      value: incidentDetails.classificationId,
                      label: incidentDetails.classificationId
                    }}
                  />
                </div>

                <div className="w-100" style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                  <div className="w-33 flex">
                    <p className="label">Before Incidents</p>
                    <textarea
                      className="input-like"
                      placeholder="Before Incidents"
                      rows="5"
                      name="beforeIncident"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-33 flex">
                    <p className="label">Description</p>
                    <textarea
                      className="input-like"
                      placeholder="Description"
                      rows="5"
                      name="description"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-33 flex">
                    <p className="label">Immediate Action</p>
                    <textarea
                      className="input-like"
                      placeholder="Immediate Action"
                      rows="5"
                      name="immediateAction"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-33 flex">
                  <p className="label">Police Report</p>
                  <SelectContainer
                    options={options.filter((item) => item.value !== '')}
                    width="100%"
                    defaultValue={
                      options.find((item) => item.value === incidentDetails.policeReport)
                    }
                    onChange={(e) => {
                      handleChange({ target: { name: 'policeReport', value: e.value } });
                    }}
                  />
                </div>
                {incidentDetails.policeReport === 'Yes'
                  ? (
                    <>
                      <div className="w-33 flex">
                        <p className="label">Police Name</p>
                        <input
                          className="input"
                          type="text"
                          name="policeName"
                          placeholder="Police Name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-33 flex">
                        <p className="label">Police Number</p>
                        <input
                          className="input"
                          type="text"
                          name="policeNumber"
                          placeholder="Police Number"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-33 flex">
                        <p className="label">Police Station</p>
                        <input
                          className="input"
                          type="text"
                          name="policeStation"
                          placeholder="Police Station"
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )
                  : null}
                <div className="w-33 flex">
                  <p className="label">Date and Time of Occurrence</p>
                  <DatePicker
                    timeFormat="HH:mm"
                    showTimeSelect
                    className="input w-100"
                    dateFormat="dd/MM/yyyy, HH:mm:ss"
                    placeholderText="Select date"
                    selected={incidentDetails.dateOccurred}
                    onChange={(selectedDate) => handleSelectDate(selectedDate)}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Location</p>
                  <input
                    className="input"
                    type="text"
                    name="location"
                    placeholder="Location"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Exact Location</p>
                  <input
                    className="input"
                    type="text"
                    name="exactLocation"
                    placeholder="Exact Location"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Affected Person First Name</p>
                  <input
                    className="input"
                    type="text"
                    name="injuredGivenName"
                    placeholder="Given Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-33 flex">
                  <p className="label">Affected Person Last Name</p>
                  <input
                    className="input"
                    type="text"
                    name="injuredFamilyName"
                    placeholder="Family Name"
                    onChange={handleChange}
                  />
                </div>
              </IncidentWrapper>
              <RowReviewValidation>
                { incidentValidationMsg && `${incidentValidationMsg}`}
              </RowReviewValidation>
            </CustomModal>
          </>
        )
      }
    </>
  );
};
AdminClientIncidents.propTypes = {
  clients: PropTypes.string.isRequired
};

export default AdminClientIncidents;
