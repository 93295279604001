import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CardWrapper,
  ProfilePic,
  UserName,
  ButtonsWrapper,
  Button,
  TooltipCustom
} from './index.style';
import {
  Profile, Calender, EditShift, RosterAllocate
} from '../svg_icons/indexs';

function ClientCard({ client, handleClientCardDetails }) {
  const renderTooltip = (props) => (
    <TooltipCustom className="tool">
      {props}
    </TooltipCustom>
  );
  return (
    <CardWrapper>
      <ProfilePic>
        <Profile />
      </ProfilePic>
      <UserName>
        {client && client.name}
      </UserName>
      <ButtonsWrapper>
        <Button onClick={() => handleClientCardDetails('view-day-program', client)}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip('Day Program')}
          >
            <div>
              <Calender />
            </div>
          </OverlayTrigger>

        </Button>
        <Button onClick={() => handleClientCardDetails('edit-shift', client)}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(' Client Shift')}
          >
            <div>
              <EditShift />
            </div>
          </OverlayTrigger>

        </Button>
        <Button onClick={() => handleClientCardDetails('new-client-allocation', client)}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip('Create Shift')}
          >
            <div>
              <RosterAllocate />
            </div>
          </OverlayTrigger>
        </Button>

      </ButtonsWrapper>
    </CardWrapper>
  );
}

ClientCard.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.number,
    addrLine1: PropTypes.string,
    addrLine2: PropTypes.string,
    city: PropTypes.string,
    deleted: PropTypes.bool,
    dob: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    house: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    postCode: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  handleClientCardDetails: PropTypes.func.isRequired
};

export default ClientCard;
