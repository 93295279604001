/** @format */

import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import MealTracker from './MealTracker';
import { meal } from '../../../../lookups/lookups';
import NoData from '../../../NoData';
import {
  HeadingWrapper,
  RowWrapper,
  RowBoxWrapper
} from './index.style';
import { getMealParticular } from '../../../../services/result.services';
import { LoadingModal } from '../../../../styledComponents/common/loadingModal.style';
import { getLocalTimeOnly } from '../../../../helpers/utils';
import generatePDFFoodDrinkTracker from './DownloadFDT';

const DailyFoodDrinkTracker = ({ clientId, reportDate }) => {
  const [isNonEmpty, setIsNonEmpty] = useState(false);
  const [breakFastDetails, setBreakFastDetails] = useState(meal);
  const [morningSnackDetails, setMorningSnackDetials] = useState(meal);
  const [lunchDetails, setLunchDetials] = useState(meal);
  const [afterNoonSnackDetails, setAfterSnackDetials] = useState(meal);
  const [eveningSnackDetails, setEveningSnackDetials] = useState(meal);
  const [dinnerDetails, setDinnerDetials] = useState(meal);
  const [reportDetails, setReportDetails] = useState({
    clientName: '',
    date: ''
  });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getMealDetailsHandler = async (mealType) => {
    const resp = await getMealParticular({
      clientId,
      reportDate: moment(reportDate).format('YYYY-MM-DD'),
      mealType
    });
    if (!resp) {
      return;
    } if (resp.status === 200) {
      setIsNonEmpty(true);
      setReportDetails({
        clientName: resp.data.clientName,
        date: resp.data.reportDate
      });
      if (mealType === 'BREAKFAST') {
        setBreakFastDetails(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'MORN_SNACK') {
        setMorningSnackDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'LUNCH') {
        setLunchDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'AFTER_SNACK') {
        setAfterSnackDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'DINNER') {
        setDinnerDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      } else if (mealType === 'EVE_SNACK') {
        setEveningSnackDetials(
          {
            ...resp.data,
            mealDateTime: resp.data.mealTime,
            mealTime: getLocalTimeOnly(resp.data.mealTime)
          }
        );
      }
    } else if (resp.status !== 200) {
      // if no data is available set meal record to blank
      if (mealType === 'BREAKFAST') {
        setBreakFastDetails(meal);
      } else if (mealType === 'MORN_SNACK') {
        setMorningSnackDetials(meal);
      } else if (mealType === 'LUNCH') {
        setLunchDetials(meal);
      } else if (mealType === 'AFTER_SNACK') {
        setAfterSnackDetials(meal);
      } else if (mealType === 'DINNER') {
        setDinnerDetials(meal);
      } else if (mealType === 'EVE_SNACK') {
        setEveningSnackDetials(meal);
      }
    }
  };
  useEffect(() => {
    Promise.all([
      getMealDetailsHandler('BREAKFAST'),
      getMealDetailsHandler('MORN_SNACK'),
      getMealDetailsHandler('LUNCH'),
      getMealDetailsHandler('AFTER_SNACK'),
      getMealDetailsHandler('DINNER'),
      getMealDetailsHandler('EVE_SNACK')
    ]).then(() => setLoading(false), setIsNonEmpty(false));
  }, [clientId, reportDate]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      { !isNonEmpty ? <NoData /> : (
        <>
          <HeadingWrapper>
            <div className="first-heading">
              <span>TIME</span>
            </div>
            <div className="second-heading">
              <span>FOOD</span>
            </div>
            <div className="third-heading">
              <span>DRINKS</span>
            </div>
            <div className="fourth-heading">
              <span>COMMENTS</span>
            </div>
            <div className="fifth-heading">
              <span>ACTION</span>
              <span>
                <button
                  type="button"
                  onClick={() => generatePDFFoodDrinkTracker(breakFastDetails, morningSnackDetails,
                    lunchDetails,
                    afterNoonSnackDetails,
                    dinnerDetails,
                    eveningSnackDetails, reportDetails)}
                >
                  Download
                </button>

              </span>
            </div>
          </HeadingWrapper>
          <RowWrapper>
            <RowBoxWrapper>
              <MealTracker
                title="Breakfast"
                mealDetails={breakFastDetails}
              />
            </RowBoxWrapper>
            <RowBoxWrapper>
              <MealTracker
                title="Morning Snacks"
                mealDetails={morningSnackDetails}
              />
            </RowBoxWrapper>
            <RowBoxWrapper>
              <MealTracker
                title="Lunch"
                mealDetails={lunchDetails}
              />
            </RowBoxWrapper>
            <RowBoxWrapper>
              <MealTracker
                title="Afternoon Snacks"
                mealDetails={afterNoonSnackDetails}
              />
            </RowBoxWrapper>
            <RowBoxWrapper>
              <MealTracker
                title="Dinner"
                mealDetails={dinnerDetails}
              />
            </RowBoxWrapper>
            <RowBoxWrapper>
              <MealTracker
                title="Evening Snacks"
                mealDetails={eveningSnackDetails}
              />
            </RowBoxWrapper>
          </RowWrapper>
          <LoadingModal
            show={show}
            onHide={handleClose}
            animation
            centered
            width="4%"
          >
            <Modal.Body className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </Modal.Body>
          </LoadingModal>
        </>
      )}
    </>
  );
};

DailyFoodDrinkTracker.propTypes = {
  clientId: PropTypes.string.isRequired,
  reportDate: PropTypes.shape.isRequired
};

export default DailyFoodDrinkTracker;
