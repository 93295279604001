/* eslint-disable import/prefer-default-export */
import { createGlobalStyle } from 'styled-components';
import normalize from './normalize';

export const GlobalStyles = createGlobalStyle`
${normalize}
body {
    background: ${({ theme }) => theme.colors.od_bg_secondary};
    color: ${({ theme }) => theme.colors.od_text_color};
    font-family: 'Roboto', sans-serif;
    font-size: ${({ theme }) => theme.fonts.font_size.heavy_font};
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 0.4em;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
    background: #88888861;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #88888861;
    }
    a:link{
      text-decoration: none;
    }
    .form-control:focus {
      border-color: #0408ab !important;
      box-shadow: none !important;
    }
  }
`;
