/** @format */

import React, { useState } from 'react';
import {
  Spinner
} from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Wrapper, LowerCurve, UpperCurve, FormContainer,
  InputRow, CredentialIcon, ErrorMsg, Button
} from './index.style';
import srcLogo from '../../assets/logo/one_doorway_logo.png';
import upperCurve from '../../assets/logo/upper_curve.svg';
import lowerCurve from '../../assets/logo/lower_curve.svg';
import passwordLogo from '../../assets/logo/password_logo.svg';
import backIcon from '../../assets/logo/back.svg';
import tickIcon from '../../assets/logo/success-tick.svg';
import { resetPassword } from '../../services/result.services';

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  const [validityMsgForEmail, setValidityMsgForEmail] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [passwordDetails, setPasswordDetails] = useState({
    newPassword: '',
    confirmNewPassword: ''
  });
  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validRespMsg, setValidRespMsg] = useState('');

  const handleChange = (event) => {
    if (validRespMsg) {
      setValidRespMsg('');
    }
    const { name, value } = event.target;
    setPasswordDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const forgotPasswordHandler = async () => {
    setLoading(true);
    if (passwordDetails.newPassword !== passwordDetails.confirmNewPassword) {
      setValidRespMsg('Password mismatch');
      setLoading(false);
      return;
    }
    if (!token) {
      setLoading(false);
      setValidRespMsg('reset token not found!');
      return;
    }
    const resp = await resetPassword({
      newPassword: passwordDetails.newPassword,
      confirmPassword: passwordDetails.confirmNewPassword,
      token
    });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setSuccessMsg('Reset password successfully!');
      setValidityMsgForEmail('');
      setIsPasswordResetSuccessful(true);
    } else if (resp.status === 400) {
      setLoading(false);
      setValidRespMsg('Reset password failed!');
    } else {
      setLoading(false);
      setValidRespMsg('Something went wrong!, please try again');
      setValidityMsgForEmail('');
    }
  };

  return (
    <Wrapper>
      <UpperCurve src={upperCurve} />
      <LowerCurve src={lowerCurve} />
      <div className="form-wrapper">
        <div className="w-100 flex">
          <img data-testid="img-icon" className="logo" src={srcLogo} alt="one doorway logo" />
        </div>
        <FormContainer>
          {
            !isPasswordResetSuccessful
              ? (
                <>
                  <button className="button back-btn" type="button" onClick={() => history.push('/login')}>
                    <img src={backIcon} alt="back icon" />
                    Back
                  </button>
                  <h3 data-testid="reset-password" className="title">Set New Password</h3>
                  <InputRow>
                    <label htmlFor="newPass">
                      <CredentialIcon src={passwordLogo} />
                    </label>
                    <input
                      id="newPass"
                      type="password"
                      placeholder="Password"
                      name="newPassword"
                      value={passwordDetails.newPassword}
                      onChange={handleChange}
                      data-testid="new-pass"
                    />
                  </InputRow>
                  <InputRow>
                    <label htmlFor="confirmNewPass">
                      <CredentialIcon src={passwordLogo} />
                    </label>
                    <input
                      id="confirmNewPass"
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmNewPassword"
                      value={passwordDetails.confirmNewPassword}
                      onChange={handleChange}
                      data-testid="confirm-pass"
                    />
                  </InputRow>
                  <Button
                    data-testid="forget-password"
                    type="submit"
                    onClick={forgotPasswordHandler}
                    disabled={passwordDetails.newPassword === ''
                      || passwordDetails.confirmNewPassword === ''
                      || validRespMsg !== ''
                      || passwordDetails.newPassword === ''
                      || loading}
                  >
                    <span>
                      {loading ? 'Saving' : 'Save New Password'}
                    </span>
                    {loading && (
                    <span className="spinner">
                      <Spinner animation="border" variant="primary" />
                    </span>
                    )}
                  </Button>
                  <p className="return-login">
                    <Link className="label" to="/login">Return to Login</Link>
                  </p>
                  <ErrorMsg color="warning">
                    {validRespMsg}
                  </ErrorMsg>
                  <ErrorMsg color="warning">
                    {validityMsgForEmail}
                  </ErrorMsg>
                  <ErrorMsg color="success">
                    {successMsg}
                  </ErrorMsg>
                </>
              ) : (
                <>
                  <h3 className="title text-center">Password Successfully Changed</h3>
                  <div className="tick-icon-container">
                    <img src={tickIcon} alt="tick icon" />
                  </div>
                  <Button
                    type="submit"
                    onClick={() => history.push('/login')}
                  >
                    <span>
                      Login to the System
                    </span>
                  </Button>
                </>
              )
          }
        </FormContainer>
      </div>
    </Wrapper>
  );
};

export default ResetPassword;
