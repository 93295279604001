/** @format */

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  getSleepTrackerNote
} from '../../../../services/result.services';
import {
  HeadingsBoxWrapper,
  RowBoxWrapper,
  TimeBoxWrapper,
  NameBox,
  RowsWrapper,
  RoundedDot
} from './index.style';
import { LoadingModal } from '../../../../styledComponents/common/loadingModal.style';
import timerIcon from '../../../../assets/logo/timerIcon.svg';
import userIcon from '../../../../assets/logo/userIcon.svg';
import NoData from '../../../NoData';
import generatePDFSleepTracker from './DownloadST';

const SleepTracker = ({ clientId, reportDate }) => {
  const [sleepTrackerData, setSleepTrackerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [reportDetails, setReportDetails] = useState({
    clientName: '',
    date: ''
  });

  function handleShowUserName(reported) {
    return sleepTrackerData[reported];
  }

  const getSleepTrackerNoteHandler = async (payload) => {
    setLoading(true);
    const resp = await getSleepTrackerNote(payload);
    if (!resp) return;
    if (resp.status === 200) {
      setReportDetails({
        clientName: resp.data.clientName,
        date: moment(reportDate).format('YYYY-MM-DD')
      });
      setLoading(false);
      setSleepTrackerData(resp.data);
    } else {
      setSleepTrackerData([]);
      setLoading(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getSleepTrackerNoteHandler({ clientId, date: moment(reportDate).format('YYYY-MM-DD') });
  }, [clientId, reportDate]);

  return (
    <>
      {sleepTrackerData.length === 0 ? <NoData /> : (
        <>
          <HeadingsBoxWrapper>
            <button type="button" onClick={() => generatePDFSleepTracker(sleepTrackerData, reportDetails)} className="download">Download</button>
          </HeadingsBoxWrapper>

          <HeadingsBoxWrapper>
            <div className="first-heading time">TIME</div>
            <div className="second-heading">COMMENTS</div>
            <div className="third-heading">SIGN</div>
          </HeadingsBoxWrapper>
          <RowsWrapper>
            <div className="dotted-lines" />
            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">06:00 Pm</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">08:00 Pm</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="firstSlot"
                  data-testid="firstSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.firstSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('firstUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="firstUpdatedBy"
                    checked={sleepTrackerData.firstUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>
            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>
            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">08:00 Pm</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">10:00 Pm</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="secondSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.secondSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('secondUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="secondUpdatedBy"
                    checked={sleepTrackerData.secondUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>

            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>

            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">10:00 Pm</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">12:00 Am</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="thirdSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.thirdSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('thirdUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="thirdUpdatedBy"
                    checked={sleepTrackerData && sleepTrackerData.thirdUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>

            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>

            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">12:00 Am</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">02:00 Am</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="fourthSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.fourthSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('fourthUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="fourthUpdatedBy"
                    checked={sleepTrackerData && sleepTrackerData.fourthUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>

            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>

            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">02:00 Am</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">04:00 Am</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="fifthSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.fifthSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('fifthUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="fifthUpdatedBy"
                    checked={sleepTrackerData && sleepTrackerData.fifthUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>

            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>

            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">04:00 Am</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">06:00 Am</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="sixthSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.sixthSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('sixthUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="sixthUpdatedBy"
                    checked={sleepTrackerData && sleepTrackerData.sixthUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>

            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>

            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">06:00 Am</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">08:00 Am</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="seventhSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.seventhSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('seventhUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="seventhUpdatedBy"
                    checked={sleepTrackerData && sleepTrackerData.seventhUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>

            <RoundedDot>
              <div className="inside-roll" />
            </RoundedDot>

            <RowBoxWrapper>
              <div className="first-heading">
                <TimeBoxWrapper>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">08:00 Am</div>
                  <div className="dots">...</div>
                  <img src={timerIcon} alt="timer icon" />
                  <div className="text">10:00 Am</div>
                </TimeBoxWrapper>
              </div>
              <div className="second-heading">
                <textarea
                  name="eighthSlot"
                  value={Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.eighthSlot : ''}
                  disabled
                />
              </div>
              <div className="third-heading">
                <label
                  className="container disabled"
                >
                  <NameBox>
                    <div className="icon-name-wrapper">
                      <img className="icon" src={userIcon} alt="user icon" />
                    </div>
                    <span>
                      {handleShowUserName('eighthUpdatedBy')}
                    </span>
                  </NameBox>
                  <input
                    type="checkbox"
                    name="eighthUpdatedBy"
                    checked={sleepTrackerData && sleepTrackerData.eighthUpdatedBy}
                    disabled
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </RowBoxWrapper>
          </RowsWrapper>

          <LoadingModal
            show={show}
            onHide={handleClose}
            animation
            centered
            width="4%"
          >
            <Modal.Body className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </Modal.Body>
          </LoadingModal>
        </>
      )}
    </>
  );
};

SleepTracker.propTypes = {
  clientId: PropTypes.string.isRequired,
  reportDate: PropTypes.shape.isRequired
};

export default SleepTracker;
