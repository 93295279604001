import React, { useState, useEffect } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import {
  Modal, Spinner
} from 'react-bootstrap';
import ClientCard from '../../components/ClientCard/ClientCard';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import {
  RosterHeader, RosterWrapper, RosterNavLink, DateHeader, RosterBody, StyledInputDiv,
  ModalClientName, TooltipCustom,
  FormWrapper,
  StyledSelect,
  // AddUserText,
  // UserImage
  // AllocateButton
  // UserSelect,
  RemoveModalBody,
  StyledSelectDropdown,
  SelectStyledTable
} from './index.style';
import CustomModal from '../../components/CustomModal';
import InputIcon from '../../assets/logo/roster_input.svg';
import UserCard from '../../components/UserCard/UserCard';
import AdminRosterUserAllocation from '../../components/AdminRosterUserAllocation/AdminRosterUserAllocation';
import {
  getUserListPerPage, getSearchClient, updateUserAllocation,
  listDayProgram, addNewClientDayProgram, updateDayProgram,
  listClientShifts, createClientShift, updateClientShift, getUsersList,
  createClientShifts, deallocateUser,
  removeShift, allocateUser,
  createUserAllocation,
  listShifts
  // fetchAvailableUsersForShift
} from '../../services/result.services';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { PaginationRoaster } from '../../styledComponents/common/page.style';
import caretLeftSmallIcon from '../../assets/logo/caret_left_small.svg';
import caretRightSmallIcon from '../../assets/logo/caret_right_small.svg';
import RemoveImage from '../../assets/images/remove_confirm.png';
// import closeIcon from '../../assets/logo/close.svg';
import AdminRosterUnavailabilityTime from '../../components/AdminRosterUnavailabilityTime/AdminRosterUnavailabilityTime';
import ClientDayProgramModal from '../../components/RosterModals/ClientDayProgramModal/clientDayProgramModal';
import ClientShiftModal from '../../components/RosterModals/ClientShiftModal/clientShiftModal';
import AdminRosterClientShifts from '../../components/AdminRosterClientShifts/AdminRosterClientShifts';
import ClientAllocateUserModal from '../../components/RosterModals/ClientAllocateUserModal/clientAllocateUserModal';

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? '#F8F1EB' : styles.color,
    '&:hover': { color: 'black', backgroundColor: '#F8F1EB' }
  })
};

const customSelectStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? '#AFD8D0' : styles.color,
    '&:hover': { color: 'black', backgroundColor: '#AFD8D0' }
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 'none' : 'none',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 'none' : 'none'
    }
  })

};

const AdminRoster = () => {
  const [selectedTab, setSelectedTab] = useState('Users');
  const history = useHistory();
  const [selectedExternalUser, setSelectedExternalUser] = useState();
  const [startDate, setStartDate] = useState(moment().startOf('week').subtract(1, 'days').format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const [userListToBeDisplayed, setUserListToBeDisplayed] = useState([]);
  const [clientListToBeDisplayed, setClientListToBeDisplayed] = useState([]);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedAllocation, setSelectedAllocation] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [clientListOptions, setClientListOptions] = useState(null);
  const [actualDates, setActualDates] = useState({
    actualStartTime: '',
    actualEndTime: ''
  });
  const [pageCount, setPageCount] = useState(10);
  const [pageCountClient, setPageCountClient] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [newClientShift, setNewClientShift] = useState({
    name: '',
    startTime: '',
    endTime: '',
    noOfUsers: '1',
    sharedShift: false
  });
  const [currentClientPage, setCurrentClientPage] = useState(0);
  const [searchNameOrEmail, setSearchNameOrEmail] = useState('');
  const [searchClientName, setSearchClientName] = useState('');
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState();
  const [selectedClientDayProgram, setSelectedClientDayProgram] = useState([]);
  const [newDayProgram, setNewDayProgram] = useState({
    name: '',
    date: '',
    startTime: '',
    endTime: '',
    contactName: '',
    contactPhone: ''
  });
  const [addNewDayProgram, setAddNewDayProgram] = useState(false);
  const [selectedClientShifts, setSelectedClientShifts] = useState([]);
  const [addNewClientShift, setAddNewClientShift] = useState(false);
  const [initialAssignedUsers, setInitialAssignedUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState();
  const [clientShifts, setClientShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState();
  const [shiftStartDate, setShiftStartDate] = useState();
  const [shiftEndDate, setShiftEndDate] = useState();
  const [availableUserSelected, setAvailableUserSelected] = useState([]);
  const [selectedClientShift, setSelectedClientShift] = useState();
  const [selectedCurrentDate, setSelectedCurrentDate] = useState();
  const [initialAvailableUsers, setInitialAvailableUsers] = useState();
  const [selectedShiftId, setSelectedShiftId] = useState();
  const [saveButtonActive, setSaveButtonActive] = useState(false);

  const clearNewClientShift = () => {
    setAddNewClientShift(false);
    setNewClientShift(
      {
        name: '',
        startTime: '',
        endTime: '',
        noOfUsers: '1',
        sharedShift: false
      }
    );
  };
  const getDateRange = (start) => {
    let now = moment(start).format('DD-MMM-YYYY');
    const dates = [];
    for (let i = 0; i < 7; i += 1) {
      dates.push(now);
      now = moment(now).add(1, 'days').format('DD-MMM-YYYY');
    }
    return dates;
  };
  const PER_PAGE = 10;
  const getAvailableUsers = async () => {
    let result;
    try {
      const resp = await getUsersList();
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        const formatedData = resp.data.map((user) => ({
          userId: user.id,
          userFirstName: user.firstName,
          userLastName: user.lastName,
          userEmail: user.email,
          label: `${user.firstName} ${user.lastName}`,
          value: `${user.firstName} ${user.lastName}`
        }));
        result = formatedData;
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
    return result;
  };
  const clearNewClientDayProgram = () => {
    setNewDayProgram(
      {
        name: '',
        date: '',
        startTime: '',
        endTime: '',
        contactName: '',
        contactPhone: ''
      }
    );
    setAddNewDayProgram(false);
  };

  const handleAddNewClientShift = () => {
    setAddNewClientShift(true);
    setNewClientShift({ ...newClientShift, clientId: selectedClient.id });
  };
  const handleSelectAllocation = (selected) => {
    setSelectedAllocation(selected);
    setActualDates({
      actualStartTime: selected.actualStartTime,
      actualEndTime: selected.actualEndTime
    });
  };
  const handleAvailableUserSelected = async (clientId, users, shiftId) => {
    setSelectedShiftId(shiftId);
    const tempUsers = users.map((item) => ({
      userId: item.id,
      userFirstName: item.firstName,
      userLastName: item.lastName,
      userEmail: item.email
    }));
    const tempUserId = users.map((item) => item.id);
    const available = await getAvailableUsers(shiftId);
    if (available) {
      const tempData = available.filter((item) => !tempUserId.includes(item.userId));
      setAvailableUserSelected(tempUsers);
      setInitialAvailableUsers(tempUsers);
      setInitialAssignedUsers(available);
      setAssignedUsers(tempData);
    }
  };
  const getListSelectedClientShift = async () => {
    setLoading(true);
    const payload = {
      startDate: moment(getDateRange(startDate)[0]).format('YYYY-MM-DD'),
      endDate: moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')
    };
    try {
      const resp = await listShifts(selectedClientId, payload);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        const filteredClientShiftData = resp.data
          .find((item) => item.shiftId === selectedShiftId).users.map((item) => ({
            userId: item.id,
            userFirstName: item.firstName,
            userLastName: item.lastName,
            userEmail: item.email
          }));
        const externalFilteredData = resp.data
          .find((item) => item.shiftId === selectedShiftId).externalUsers.map((item) => ({
            userId: item.id,
            userFirstName: item.firstName,
            userLastName: item.lastName
          }));
        setAvailableUserSelected([...filteredClientShiftData, ...externalFilteredData]);
        setInitialAvailableUsers([...filteredClientShiftData, ...externalFilteredData]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      error.data = `Error msg is ${error.data}`;
    }
  };
  const getUsersHandler = async (payload) => {
    setLoading(true);
    try {
      const resp = await getUserListPerPage(payload);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        const { users, totalUsers } = resp.data;
        const filteredResp = users.filter((user) => !user.deleted);
        setUserListToBeDisplayed(filteredResp);
        setPageCount(Math.ceil(totalUsers / PER_PAGE));
      } else if (resp.status === 500) {
        setLoading(false);
        swal({
          title: 'Internal Server Error, Try again later',
          icon: 'error',
          timer: 3000
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const getClientHandler = async (payload) => {
    setLoading(true);
    try {
      const resp = await getSearchClient(payload);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        const { clients, totalClients } = resp.data;
        const filteredResp = clients.filter((client) => !client.deleted);
        setClientListToBeDisplayed(filteredResp);
        setClientListOptions(filteredResp.map((item) => ({
          value: item.name,
          label: item.name
        })));
        setPageCountClient(Math.ceil(totalClients / PER_PAGE));
      } else if (resp.status === 500) {
        setLoading(false);
        swal({
          title: 'Internal Server Error, Try again later',
          icon: 'error',
          timer: 3000
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const getClientDayProgram = async (clientId) => {
    const payload = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(startDate).add(6, 'days').format('YYYY-MM-DD')
    };
    try {
      const resp = await listDayProgram(clientId, payload);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        setSelectedClientDayProgram(resp.data.map((item) => ({
          ...item,
          startTime: moment(item.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
          endTime: moment(item.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })));
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };
  const getClientShifts = async (clientId) => {
    try {
      const resp = await listClientShifts(clientId);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setSelectedClientShifts(resp.data.map((item) => ({
          ...item,
          startTime: moment(item.startTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
          endTime: moment(item.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })));
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const getListClientShift = async () => {
    try {
      const resp = await listClientShifts(selectedClientId);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        const newArr = resp.data.map((data) => ({
          value: `${data.id}`,
          label: `${data.name}`,
          time: `${moment(data.startTime).format('HH:mm:ss A')} ${moment(data.endTime).format('HH:mm:ss A')}`
        }));
        setClientShifts(newArr);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  };

  const handleAddNewClientDayProgram = async () => {
    setLoading(true);
    try {
      const resp = await addNewClientDayProgram(newDayProgram);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Created Successfully',
          icon: 'success',
          timer: 3000
        });
        getClientDayProgram(newDayProgram.clientId);
        clearNewClientDayProgram();
      } else {
        setLoading(false);
        swal({
          title: 'Failed',
          icon: 'error',
          timer: 3000
        });
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoading(false);
    }
  };
  const handleCreateNewClientShift = async () => {
    setLoading(true);
    const payload = {
      ...newClientShift,
      startTime: newClientShift.startTime,
      endTime: newClientShift.endTime
    };
    try {
      const resp = await createClientShift(payload);
      if (!resp) {
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Created Successfully',
          icon: 'success',
          timer: 3000
        });
        getClientShifts(newClientShift.clientId);
        getListClientShift();
        clearNewClientShift();
      } else {
        setLoading(false);
        swal({
          title: 'Failed',
          icon: 'error',
          timer: 3000
        });
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoading(false);
    }
  };
  const updateClientDayProgramme = async (operation, dayProgram) => {
    if (operation === 'update') {
      setLoading(true);
      const payload = {
        name: dayProgram.name,
        date: dayProgram.date,
        startTime: moment(dayProgram.startTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(dayProgram.endTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        contactName: dayProgram.contactName,
        contactPhone: dayProgram.contactPhone,
        lastUpdatedBy: dayProgram.lastUpdatedBy,
        deleted: dayProgram.deleted
      };
      try {
        const resp = await updateDayProgram(dayProgram.dayProgramId, payload);
        if (!resp) {
          return;
        }
        if (resp.status === 200) {
          setLoading(false);
          swal({
            title: 'Updated Successfully',
            icon: 'success',
            timer: 3000
          });
          getClientDayProgram(selectedClient.id);
          clearNewClientDayProgram();
        } else {
          setLoading(false);
          swal({
            title: `${resp.data.error}`,
            icon: 'error',
            timer: 3000
          });
        }
      } catch (error) {
        error.data = `Error msg is ${error.data}`;
        setLoading(false);
      }
    }
    if (operation === 'delete') {
      const value = await swal({
        text: `Are you sure you want to delete ${dayProgram.name}?`,
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: { text: 'Delete', value: 'confirm' }
        }
      });
      if (value) {
        setLoading(true);
        try {
          const payload = {
            ...dayProgram,
            startTime: moment(dayProgram.startTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
            endTime: moment(dayProgram.endTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
            deleted: true
          };
          const resp = await updateDayProgram(dayProgram.dayProgramId, payload);
          if (!resp) {
            return;
          }
          if (resp.status === 200) {
            setLoading(false);
            swal({
              title: 'Deleted Successfully',
              icon: 'success',
              timer: 3000
            });
            getClientDayProgram(selectedClient.id);
            clearNewClientDayProgram();
          } else {
            setLoading(false);
            swal({
              title: `${resp.data.error}`,
              icon: 'error',
              timer: 3000
            });
          }
        } catch (error) {
          error.data = `Error msg is ${error.data}`;
          setLoading(false);
        }
      }
    }
  };
  const handleNewClientShift = (e) => {
    const data = { ...newClientShift };
    if (e.target.name === 'startTime' || e.target.name === 'endTime') {
      data[e.target.name] = moment(e.target.value, 'HH:mm').format('YYYY-MM-DD HH:mm:ss');
    } else {
      data[e.target.name] = e.target.value;
    }
    setNewClientShift(data);
  };
  const handleClientCardDetails = (modal, client) => {
    setSelectedModal(modal);
    setSelectedClient(client);
    setSelectedClientId(client.id);
    if (modal === 'edit-shift') {
      getClientShifts(client.id);
      setNewClientShift({ ...newClientShift, clientId: client.id });
    }
    if (modal === 'view-day-program') {
      getClientDayProgram(client.id);
      setNewDayProgram({ ...newDayProgram, clientId: client.id });
    }
  };

  const handleClientPageChange = (data) => {
    const { selected } = data;
    setCurrentClientPage(selected);
    if (searchClientName.length > 2) {
      getClientHandler({
        pageSize: PER_PAGE,
        pageNumber: selected,
        nameOrEmail: searchClientName
      });
    } else if (searchClientName.length > 2) {
      getClientHandler({
        pageSize: PER_PAGE,
        pageNumber: selected,
        nameOrEmail: searchClientName
      });
    } else if (searchClientName === '') {
      getClientHandler({ pageSize: PER_PAGE, pageNumber: selected });
    } else {
      getClientHandler({ pageSize: PER_PAGE, pageNumber: selected });
    }
  };
  const handlePageChange = (data) => {
    const { selected } = data;
    setCurrentPage(selected);
    if (searchNameOrEmail.length > 2) {
      getUsersHandler({
        pageSize: PER_PAGE,
        pageNumber: selected,
        nameOrEmail: searchNameOrEmail

      });
    } else if (searchNameOrEmail.length > 2) {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: selected, nameOrEmail: searchNameOrEmail });
    } else if (searchNameOrEmail === '') {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: selected });
    } else {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: selected });
    }
  };

  const handleAddNewDayProgram = () => {
    setAddNewDayProgram(true);
    setNewDayProgram({ ...newDayProgram, clientId: selectedClient.id });
  };

  const handleDayProgramChange = (e, index) => {
    const tempData = [...selectedClientDayProgram];
    tempData[index][e.target.name] = e.target.value;
    setSelectedClientDayProgram(tempData);
  };
  const handleNewDayProgram = (e) => {
    const tempData = { ...newDayProgram };
    if (e.target.name === 'startTime' || e.target.name === 'endTime') {
      tempData[e.target.name] = moment(e.target.value, 'HH:mm').format('YYYY-MM-DD HH:mm:ss');
    } else {
      tempData[e.target.name] = e.target.value;
    }
    setNewDayProgram(tempData);
  };
  const clientShiftUpdate = async (operation, shift) => {
    if (operation === 'update') {
      const payload = {
        name: shift.name,
        startTime: moment(shift.startTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(shift.endTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        noOfUsers: shift.noOfUsers,
        sharedShift: shift.sharedShift,
        lastUpdatedBy: shift.lastUpdatedBy,
        deleted: shift.deleted
      };
      try {
        const resp = await updateClientShift(shift.id, payload);
        if (!resp) {
          return;
        }
        if (resp.status === 200) {
          setLoading(false);
          swal({
            title: 'Updated Successfully',
            icon: 'success',
            timer: 3000
          });
          getClientShifts(selectedClient.id);
          getListClientShift();
          clearNewClientShift();
        } else {
          setLoading(false);
          swal({
            title: `${resp.data.message ? resp.data.message : resp.data.error}`,
            icon: 'error',
            timer: 3000
          });
        }
      } catch (error) {
        error.data = `Error msg is ${error.data}`;
        setLoading(false);
      }
    }
    if (operation === 'delete') {
      const value = await swal({
        text: `Are you sure you want to delete ${shift.name}?`,
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: { text: 'Delete', value: 'confirm' }
        }
      });
      if (value) {
        setLoading(true);
        try {
          const payload = {
            ...shift,
            startTime: moment(shift.startTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
            endTime: moment(shift.endTime, 'HH:mm').format('YYYY-MM-DD HH:mm:ss'),
            deleted: true
          };
          const resp = await updateClientShift(shift.id, payload);
          if (!resp) {
            return;
          }
          if (resp.status === 200) {
            setLoading(false);
            swal({
              title: 'Deleted Successfully',
              icon: 'success',
              timer: 3000
            });
            getClientShifts(selectedClient.id);
            getListClientShift();
            clearNewClientShift();
          } else {
            setLoading(false);
            swal({
              title: `${resp.data.message ? resp.data.message : resp.data.error}`,
              icon: 'error',
              timer: 3000
            });
          }
        } catch (error) {
          error.data = `Error msg is ${error.data}`;
          setLoading(false);
        }
      }
    }
  };

  const handleRemoveClientShift = async (shift, users) => {
    if (users.length > 0) {
      swal({
        title: 'First deallocate all users from shift',
        icon: 'warning',
        timer: 3000
      });
    } else {
      const value = await swal({
        text: 'Are you sure you want to delete ?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: { text: 'Delete', value: 'confirm' }
        }
      });
      if (value) {
        setLoading(true);
        try {
          const payload = {
            clientShiftId: shift.shiftClientShiftsId,
            startDate: moment(shift.date).format('YYYY-MM-DD'),
            endDate: moment(shift.date).format('YYYY-MM-DD'),
            deleted: true
          };
          const resp = await removeShift(payload);
          if (!resp) {
            return;
          }
          if (resp.status === 200) {
            swal({
              title: 'Removed Successfully',
              icon: 'success',
              timer: 3000
            });
            setLoading(false);
          } else {
            setLoading(false);
            swal({
              title: `${resp.data.message ? resp.data.message : resp.data.error}`,
              icon: 'error',
              timer: 3000
            });
          }
        } catch (error) {
          error.data = `Error msg is ${error.data}`;
          setLoading(false);
        }
      }
    }
  };

  const handleEditClientShift = (e, index) => {
    const tempData = [...selectedClientShifts];
    tempData[index][e.target.name] = e.target.value;
    setSelectedClientShifts(tempData);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const renderToolTip = (props) => (
    <TooltipCustom className="tool">
      {props}
    </TooltipCustom>
  );
  const handleActualTimeSpent = async () => {
    setLoading(true);
    try {
      const resp = await updateUserAllocation(selectedAllocation.userId,
        selectedAllocation.shiftId, actualDates);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Modified successfully',
          icon: 'success',
          timer: 3000
        });
      } else {
        setLoading(false);
        swal({
          title: `${resp.data.message ? resp.data.message : resp.data.error}`,
          icon: 'error',
          timer: 300
        });
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoading(false);
    }
  };

  const handleCreateShift = async () => {
    setLoading(true);
    try {
      const payload = {
        clientId: selectedClientId,
        clientShiftId: selectedShift?.value,
        startDate: shiftStartDate,
        endDate: shiftEndDate
      };
      const resp = await createClientShifts(payload);
      setSelectedShift();
      setShiftStartDate();
      setShiftEndDate();
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Added Successfully',
          icon: 'success',
          timer: 3000
        });
      } else {
        setLoading(false);
        swal({
          title: `${resp.data.message ? resp.data.message : resp.data.error}`,
          icon: 'error',
          timer: 3000
        });
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoading(false);
    }
  };
  const handleRemoveUserAllocation = async () => {
    setLoading(true);
    try {
      const payload = {
        deleted: true
      };
      const clientPayload = {
        userId: selectedAllocation.userId,
        clientShiftId: selectedAllocation.shiftClientShiftsId,
        startDate: selectedAllocation.date,
        endDate: selectedAllocation.date

      };
      const resp = await updateUserAllocation(selectedAllocation.userId,
        selectedAllocation.shiftId, payload);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        swal({
          title: 'Removed Successfully',
          icon: 'success',
          timer: 3000
        });
        setLoading(false);
        const clientResp = await deallocateUser(clientPayload);
        if (!clientResp) {
          setLoading(false);
        }
        if (clientResp.status === 200) {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoading(false);
    }
  };
  useEffect(() => {
    if (searchClientName.length > 2) {
      getClientHandler({
        pageSize: PER_PAGE,
        pageNumber: 0,
        nameOrHouse: searchClientName
      });
      setCurrentClientPage(0);
    } else if (searchClientName.length > 2) {
      getClientHandler({ pageSize: PER_PAGE, pageNumber: 0, nameOrEmail: searchClientName });
      setCurrentClientPage(0);
    } else if (searchClientName === '') {
      getClientHandler({ pageSize: PER_PAGE, pageNumber: 0, houseCode: '' });
      setCurrentClientPage(0);
    } else if (searchClientName === '') {
      getClientHandler({ pageSize: PER_PAGE, pageNumber: 0 });
      setCurrentClientPage(0);
    }
  }, [searchClientName]);
  useEffect(() => {
    if (searchNameOrEmail.length > 2) {
      getUsersHandler({
        pageSize: PER_PAGE,
        pageNumber: 0,
        nameOrEmail: searchNameOrEmail
      });
      setCurrentPage(0);
    } else if (searchNameOrEmail.length > 2) {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0, nameOrEmail: searchNameOrEmail });
      setCurrentPage(0);
    } else if (searchNameOrEmail === '') {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0 });
      setCurrentPage(0);
    } else if (searchNameOrEmail === '') {
      getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0 });
      setCurrentPage(0);
    }
  }, [searchNameOrEmail]);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getUsersHandler({ pageSize: PER_PAGE, pageNumber: 0 });
    getClientHandler({ pageSize: PER_PAGE, pageNumber: 0 });
  }, []);

  const handleClick = (link, params) => {
    history.push(link, params);
  };

  const addAssignedUser = async (user, dates) => {
    setLoading(true);
    try {
      const addAllocate = async (item) => {
        setLoading(true);
        const payload = ('agencyName' in item)
          ? {
            externalUserId: item.userId,
            clientShiftId: selectedClientShift.shiftClientShiftsId,
            startDate: dates.startDate,
            endDate: dates.endDate
          }
          : {
            userId: item.userId,
            clientShiftId: selectedClientShift.shiftClientShiftsId,
            startDate: dates.startDate,
            endDate: dates.endDate
          };
        if (!Object.keys(item).includes('agencyName')) {
          const resp = await allocateUser(payload);
          if (!resp) {
            return;
          }
          if (resp.status === 200) {
            const resp2 = await createUserAllocation(payload);
            if (!resp2) {
              return;
            }
            if (resp2.status === 200) {
              swal({
                title: 'User Added Successfully',
                icon: 'success',
                timer: 3000
              });
              setLoading(false);
              getListSelectedClientShift();
            } else {
              getListSelectedClientShift();
              setLoading(false);
              swal({
                title: `${resp2.data.message ? resp2.data.message : resp2.data.error}`,
                icon: 'error',
                timer: 3000
              });
            }
          } else {
            setLoading(false);
            swal({
              title: `${resp.data.message ? resp.data.message : resp.data.error}`,
              icon: 'error',
              timer: 3000
            });
          }
        } else {
          const resp = await allocateUser(payload);
          if (!resp) {
            return;
          }
          if (resp.status === 200) {
            swal({
              title: 'External User Added Successfully',
              icon: 'success',
              timer: 3000
            });
            setLoading(false);
            getListSelectedClientShift();
          } else {
            setLoading(false);
            swal({
              title: `${resp.data.message ? resp.data.message : resp.data.error}`,
              icon: 'error',
              timer: 3000
            });
          }
        }
      };
      user.forEach((item) => addAllocate(item));
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoading(false);
    }
  };
  const deleteAssignedUser = async (assignedUser, dates) => {
    if (initialAvailableUsers.includes(assignedUser)) {
      const value = await swal({
        text: `Are you sure you want to remove ${assignedUser.userFirstName} ${assignedUser.userLastName}?`,
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: { text: 'Delete', value: 'confirm' }
        }
      });
      if (value) {
        setLoading(true);
        const payload = {
          userId: assignedUser.userId,
          clientShiftId: selectedClientShift.shiftClientShiftsId,
          startDate: dates.startDate,
          endDate: dates.endDate
        };
        const payload2 = {
          deleted: true,
          actualStartTime: moment(dates.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
          actualEndTime: moment(dates.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
        };
        try {
          const resp = await deallocateUser(payload);
          if (!resp) {
            setLoading(false);
            return;
          }
          if (resp.status === 200) {
            const resp2 = await updateUserAllocation(assignedUser.userId,
              selectedClientShift.shiftId, payload2);
            if (resp2.status === 200) {
              swal({
                title: 'User Removed Successfully',
                icon: 'success',
                timer: 3000
              });
              const newList = availableUserSelected.filter((x) => x !== assignedUser);
              setAvailableUserSelected(newList);
              getListSelectedClientShift();
              setLoading(false);
            } else {
              getListSelectedClientShift();
              setLoading(false);
              swal({
                title: `${resp2.data.message ? resp2.data.message : resp2.data.error}`,
                icon: 'error',
                timer: 3000
              });
            }
          }
        } catch (error) {
          error.data = `Error msg is ${error.data}`;
          setLoading(false);
        }
      }
    } else {
      const newList = availableUserSelected.filter((x) => x !== assignedUser);
      setAvailableUserSelected(newList);
    }
  };

  useEffect(async () => {
    getListClientShift();
  }, [selectedClientId]);

  useEffect(() => {
    if (initialAvailableUsers && (availableUserSelected.length > initialAvailableUsers.length)) {
      setSaveButtonActive(false);
    } else {
      setSaveButtonActive(true);
    }
  }, [initialAvailableUsers, availableUserSelected]);

  useEffect(() => {
    if (availableUserSelected.length >= 0 && assignedUsers.length > 0) {
      const tempUserId = availableUserSelected.map((item) => item.userId);
      const tempData = initialAssignedUsers.filter((item) => !tempUserId.includes(item.userId));
      setAssignedUsers(tempData);
    }
  }, [availableUserSelected, initialAssignedUsers]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Roster </span>
          </li>
        </ul>
      </BreadCrumb>
      <RosterWrapper>
        <RosterHeader>
          <div>
            <RosterNavLink selected={selectedTab === 'Users'} onClick={() => setSelectedTab('Users')}>Users</RosterNavLink>
            <RosterNavLink selected={selectedTab === 'Clients'} onClick={() => setSelectedTab('Clients')}>Clients</RosterNavLink>
          </div>
          <div
            style={{ marginTop: '5px' }}
            onClick={() => handleClick('/roster/externalusers')}
          >
            <button type="button" onClick={() => setSelectedModal('add-user')}>
              External User
            </button>
          </div>
        </RosterHeader>
        <DateHeader>
          <button type="button" onClick={() => setStartDate(moment(startDate).subtract(7, 'days'))}>
            <BsChevronLeft />
          </button>
          <h6>
            {moment(startDate).format('DD-MMM-YYYY')}
            {' '}
            -
            {' '}
            {moment(startDate).add(6, 'days').format('DD-MMM-YYYY')}
          </h6>
          <button type="button" onClick={() => setStartDate(moment(startDate).add(7, 'days'))}>
            <BsChevronRight />
          </button>
        </DateHeader>
        <RosterBody>
          {selectedTab === 'Users' ? (
            <div style={{ height: '100%', width: '100%' }}>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderTop: 'none', padding: '8px' }}>
                      <PaginationRoaster
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        forcePage={currentPage}
                        previousLabel={<img className="caret-icon" src={caretLeftSmallIcon} alt="caret icon" />}
                        nextLabel={<img className="caret-icon" src={caretRightSmallIcon} alt="caret icon" />}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                      />
                      <StyledInputDiv>
                        <img src={InputIcon} width="30px" height="30px" alt="input-icon" />
                        <input
                          label="input"
                          type="text"
                          placeholder="Name or Email"
                          onChange={(e) => {
                            setSearchNameOrEmail(e.target.value);
                          }}
                          value={searchNameOrEmail}
                        />
                      </StyledInputDiv>
                    </th>
                    <th>{moment(getDateRange(startDate)[0]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[1]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[2]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[3]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[4]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[5]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[6]).format('DD-MMM - dddd')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                userListToBeDisplayed.map((user) => (
                  <tr key={`${Math.random(200) + user.id}`}>
                    <td>
                      <UserCard
                        userId={user.id}
                        setUserId={setUserId}
                        userName={`${user.firstName}
                        ${user.lastName}`}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        setLoading={setLoading}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[1]).format('YYYY-MM-DD')}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[2]).format('YYYY-MM-DD')}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[3]).format('YYYY-MM-DD')}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[4]).format('YYYY-MM-DD')}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[5]).format('YYYY-MM-DD')}
                      />
                    </td>
                    <td>
                      <AdminRosterUserAllocation
                        userName={user.firstName}
                        userId={user.id}
                        setSelectedModal={setSelectedModal}
                        setSelectedUser={setSelectedUser}
                        setLoading={setLoading}
                        handleSelectAllocation={handleSelectAllocation}
                        startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                        endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                        currentDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                      />
                    </td>
                  </tr>
                ))
                }
                </tbody>
              </table>
            </div>
          ) : (
            <div style={{ height: '100%', width: '100%' }}>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderTop: 'none', padding: '8px' }}>
                      <PaginationRoaster
                        pageCount={pageCountClient}
                        onPageChange={handleClientPageChange}
                        forcePage={currentClientPage}
                        previousLabel={<img className="caret-icon" src={caretLeftSmallIcon} alt="caret icon" />}
                        nextLabel={<img className="caret-icon" src={caretRightSmallIcon} alt="caret icon" />}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                      />
                      <StyledInputDiv>
                        <img src={InputIcon} width="30px" height="30px" alt="input-icon" />
                        <input
                          label="input"
                          type="text"
                          placeholder="Search By Name"
                          onChange={(e) => {
                            setSearchClientName(e.target.value);
                          }}
                          value={searchClientName}
                        />
                      </StyledInputDiv>
                    </th>
                    <th>{moment(getDateRange(startDate)[0]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[1]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[2]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[3]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[4]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[5]).format('DD-MMM - dddd')}</th>
                    <th>{moment(getDateRange(startDate)[6]).format('DD-MMM - dddd')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
            clientListToBeDisplayed.map((client) => (
              <tr key={`${Math.random(200) + client.id}`}>
                <td>
                  <ClientCard client={client} handleClientCardDetails={handleClientCardDetails} />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                    currentDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                  />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    currentDate={moment(getDateRange(startDate)[1]).format('YYYY-MM-DD')}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                  />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    currentDate={moment(getDateRange(startDate)[2]).format('YYYY-MM-DD')}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                  />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                    currentDate={moment(getDateRange(startDate)[3]).format('YYYY-MM-DD')}
                  />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                    currentDate={moment(getDateRange(startDate)[4]).format('YYYY-MM-DD')}
                  />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                    currentDate={moment(getDateRange(startDate)[5]).format('YYYY-MM-DD')}
                  />
                </td>
                <td>
                  <AdminRosterClientShifts
                    clientName={client.name}
                    clientId={client.id}
                    setSelectedModal={setSelectedModal}
                    setSelectedUser={setSelectedUser}
                    setSelectedClientId={setSelectedClientId}
                    setSelectedClientShift={setSelectedClientShift}
                    setSelectedCurrentDate={setSelectedCurrentDate}
                    handleAvailableUserSelected={handleAvailableUserSelected}
                    setLoading={setLoading}
                    handleRemoveClientShift={handleRemoveClientShift}
                    startDate={moment(getDateRange(startDate)[0]).format('YYYY-MM-DD')}
                    endDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                    currentDate={moment(getDateRange(startDate)[6]).format('YYYY-MM-DD')}
                  />
                </td>
              </tr>
            ))
            }
                </tbody>
              </table>

            </div>
          )}
        </RosterBody>
      </RosterWrapper>
      <ClientDayProgramModal
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        renderToolTip={renderToolTip}
        selectedClient={selectedClient}
        selectedClientDayProgram={selectedClientDayProgram}
        handleDayProgramChange={handleDayProgramChange}
        newDayProgram={newDayProgram}
        addNewDayProgram={addNewDayProgram}
        updateClientDayProgramme={updateClientDayProgramme}
        clearNewClientDayProgram={clearNewClientDayProgram}
        handleNewDayProgram={handleNewDayProgram}
        handleAddNewClientDayProgram={handleAddNewClientDayProgram}
        handleAddNewDayProgram={handleAddNewDayProgram}
        setSelectedClientDayProgram={setSelectedClientDayProgram}
      />
      <ClientShiftModal
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        clearNewClientShift={clearNewClientShift}
        selectedClient={selectedClient}
        addNewClientShift={addNewClientShift}
        renderToolTip={renderToolTip}
        selectedClientShifts={selectedClientShifts}
        setSelectedClientShifts={setSelectedClientShifts}
        clientShiftUpdate={clientShiftUpdate}
        handleNewClientShift={handleNewClientShift}
        newClientShift={newClientShift}
        handleCreateNewClientShift={handleCreateNewClientShift}
        handleEditClientShift={handleEditClientShift}
        handleAddNewClientShift={handleAddNewClientShift}
        customSelectStyles={customSelectStyles}
      />
      <ClientAllocateUserModal
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        setAssignedUsers={setAssignedUsers}
        setAvailableUserSelected={setAvailableUserSelected}
        assignedUsers={assignedUsers}
        availableUserSelected={availableUserSelected}
        addAssignedUser={addAssignedUser}
        deleteAssignedUser={deleteAssignedUser}
        selectedExternalUser={selectedExternalUser}
        setSelectedExternalUser={setSelectedExternalUser}
        customSelectStyles={customSelectStyles}
        selectedClientShift={selectedClientShift}
        selectedCurrentDate={selectedCurrentDate}
        initialAvailableUsers={initialAvailableUsers}
        saveButtonActive={saveButtonActive}
      />
      <CustomModal
        open={selectedModal === 'actual-time-spent'}
        setClose={() => {
          setSelectedModal(null);
        }}
        title="Actual Time Spent"
        width="680px"
        onSubmit={() => {
          setSelectedModal(null);
          handleActualTimeSpent();
        }}
        operationName="Done"
        disableCancel
      >
        <ModalClientName>
          {selectedUser}
          {selectedAllocation && selectedAllocation.shiftClientShiftsName ? (
            <span>{selectedAllocation && selectedAllocation.shiftClientShiftsName}</span>
          ) : null}
        </ModalClientName>
        <div>
          <FormWrapper>
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Shift start Time</label>
                <input
                  type="time"
                  id="shiftStartTime"
                  name="shiftStartTime"
                  placeholder="Shift start Time"
                  required
                  defaultValue={moment(selectedAllocation && selectedAllocation.shiftClientShiftsStartTime).format('HH:mm')}
                  onChange=""
                />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="dob">Shift End Time</label>
                <input
                  type="time"
                  id="shiftEndTime"
                  name="shiftEndTime"
                  placeholder="Shift End Time"
                  required
                  defaultValue={moment(selectedAllocation && selectedAllocation.shiftClientShiftsEndTime).format('HH:mm')}
                  onChange=""
                />
              </div>
            </div>
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Actual Start Time</label>
                <input
                  type="time"
                  id="actualStartTime"
                  name="actualStartTime"
                  placeholder="Actual Start Time"
                  required
                  value={moment(actualDates && actualDates.actualStartTime).format('HH:mm')}
                  onChange={(e) => setActualDates(
                    { ...actualDates, [e.target.name]: moment(e.target.value, 'HH:mm').format('YYYY-MM-DD HH:mm:ss') }
                  )}
                />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="dob">Actual End Time</label>
                <input
                  type="time"
                  id="actualEndTime"
                  name="actualEndTime"
                  placeholder="Actual End Time"
                  required
                  value={moment(actualDates && actualDates.actualEndTime).format('HH:mm')}
                  onChange={(e) => setActualDates(
                    { ...actualDates, [e.target.name]: moment(e.target.value, 'HH:mm').format('YYYY-MM-DD HH:mm:ss') }
                  )}
                />
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={selectedModal === 'remove-modal'}
        setClose={() => {
          setSelectedModal(null);
        }}
        title="Confirmation"
        width="680px"
        onSubmit={() => {
          setSelectedModal(null);
          handleRemoveUserAllocation();
        }}
        operationName="Remove"
      >
        <RemoveModalBody>
          <img width="70px" height="70px" src={RemoveImage} alt="remove_confirm" />
          <h5>Are you sure to remove this allocation ?</h5>
        </RemoveModalBody>
      </CustomModal>
      <CustomModal
        open={selectedModal === 'user-allocate'}
        setClose={() => {
          setSelectedModal(null);
        }}
        title="Allocate"
        width="680px"
        onSubmit={() => {
          setSelectedModal(null);
        }}
        operationName="Allocate"
      >
        <ModalClientName>
          {selectedUser}
          <span>Date</span>
        </ModalClientName>
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <label htmlFor="dob">Select Client</label>
              <input
                type="select"
                id="shiftStartTime"
                name="shiftStartTime"
                placeholder="Select Client"
                required
                onChange=""
              />
            </div>
            <div className="input-label-wrapper">
              <label htmlFor="dob">Select Shift</label>
              <input
                type="select"
                id="shiftEndTime"
                name="shiftEndTime"
                placeholder="Select Shift"
                required
                onChange=""
              />
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={selectedModal === 'new-user-allocation'}
        setClose={() => {
          setSelectedModal(null);
        }}
        title="Allocate User"
        width="680px"
        onSubmit={() => {
          setSelectedModal(null);
        }}
        operationName="Allocate User"
      >
        <ModalClientName noSeperator>
          {selectedUser}
        </ModalClientName>
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <label htmlFor="dob">Select Client</label>
              <StyledSelect
                styles={customStyles}
                options={clientListOptions}
                placeholder="Select Client"
                name="odCar"
                onChange=""
              />
            </div>
            <div className="input-label-wrapper">
              <label htmlFor="dob">Select Shift</label>
              <input
                type="text"
                id="selectShift"
                name="selectShift"
                placeholder="Select Shift"
                required
                onChange=""
              />
            </div>
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Allocated From</label>
                <input
                  type="date"
                  id="actualStartDate"
                  name="actualStartDate"
                  placeholder="Start Date"
                  required
                  onChange=""
                />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="dob">Allocated To</label>
                <input
                  type="date"
                  id="actualEndDate"
                  name="actualEndDate"
                  placeholder="End Date"
                  required
                  onChange=""
                />
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={selectedModal === 'new-client-allocation'}
        setClose={() => {
          setSelectedModal(null);
        }}
        title="Create Shift"
        width="680px"
        onSubmit={() => {
          setSelectedModal(null);
          handleCreateShift();
        }}
        operationName="Save"
      >
        <ModalClientName noSeperator>
          {selectedClient && selectedClient.name}
        </ModalClientName>
        <div>
          <FormWrapper>
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Select Shift</label>
                <StyledSelectDropdown
                  getOptionLabel={(e) => (
                    <SelectStyledTable>
                      <tbody>
                        <tr>
                          <td>{e.label}</td>
                          <td>{e.time}</td>
                        </tr>
                      </tbody>
                    </SelectStyledTable>
                  )}
                  value={selectedShift}
                  onChange={(e) => setSelectedShift({
                    value: e.value, label: e.label, time: e.time
                  })}
                  styles={customSelectStyles}
                  placeholder="Select Shift"
                  options={clientShifts}
                />
              </div>
            </div>
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Start Date</label>
                <input
                  type="date"
                  id="actualStartDate"
                  name="actualStartDate"
                  placeholder="Start Date"
                  required
                  value={shiftStartDate}
                  onChange={(e) => setShiftStartDate(e.target.value)}
                />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="dob">End Date</label>
                <input
                  type="date"
                  id="actualEndDate"
                  name="actualEndDate"
                  placeholder="End Date"
                  required
                  value={shiftEndDate}
                  onChange={(e) => setShiftEndDate(e.target.value)}
                />
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <CustomModal
        open={selectedModal === 'new-client-shift'}
        setClose={() => {
          setSelectedModal(null);
        }}
        title="Allocation"
        width="680px"
        onSubmit={() => {
          setSelectedModal(null);
        }}
        operationName="Save"
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <label htmlFor="dob">Select Client</label>
              <input
                type="text"
                id="selectClient"
                name="selectClient"
                placeholder="Select Client"
                required
                onChange=""
              />
            </div>
            <div className="input-label-wrapper">
              <label htmlFor="dob">Select Shift</label>
              <input
                type="text"
                id="selectShift"
                name="selectShift"
                placeholder="Select Shift"
                required
                onChange=""
              />
            </div>
            <div className="inline-field">
              <div className="input-label-wrapper">
                <label htmlFor="dob">Start Date</label>
                <input
                  type="date"
                  id="actualStartDate"
                  name="actualStartDate"
                  placeholder="Start Date"
                  required
                  onChange=""
                />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="dob">End Date</label>
                <input
                  type="date"
                  id="actualEndDate"
                  name="actualEndDate"
                  placeholder="End Date"
                  required
                  onChange=""
                />
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <AdminRosterUnavailabilityTime
        open={selectedModal === 'unavailability-time'}
        selectedUser={selectedUser}
        userId={userId}
        setUserId={setUserId}
        setSelectedModal={setSelectedModal}
      />
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminRoster;
