/** @format */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectContainer from '../../SelectContainer';
import {
  getUsersList
} from '../../../services/result.services';
import { ButtonWrapper, ReviewWrapper } from './index.style';

const IncidentReviewFirst = ({
  editReviewData,
  handleChange,
  handleSelectChange,
  reviewData,
  handleSelectedIndex,
  reviewValidationMsg,
  handleChangeEditReview,
  handleSelectChangeEdit,
  incident,
  handleChangeIncident
}) => {
  const optionsNDIS = [
    { label: '', name: 'reportableToNDIS', value: '' },
    { label: 'Yes', name: 'reportableToNDIS', value: 'Yes' },
    { label: 'No', name: 'reportableToNDIS', value: 'No' },
    { label: 'NA', name: 'reportableToNDIS', value: 'NA' }
  ];
  const optionsWorksafe = [
    { label: '', name: 'reportableToWorksafe', value: '' },
    { label: 'Yes', name: 'reportableToWorksafe', value: 'Yes' },
    { label: 'No', name: 'reportableToWorksafe', value: 'No' },
    { label: 'NA', name: 'reportableToWorksafe', value: 'NA' }
  ];
  const optionsBreach = [
    { label: '', name: 'codeBreach', value: '' },
    { label: 'Yes', name: 'codeBreach', value: 'Yes' },
    { label: 'No', name: 'codeBreach', value: 'No' },
    { label: 'NA', name: 'codeBreach', value: 'NA' }
  ];
  const optionsSupport = [
    { label: '', name: 'supportPlanUpdate', value: '' },
    { label: 'Yes', name: 'supportPlanUpdate', value: 'Yes' },
    { label: 'No', name: 'supportPlanUpdate', value: 'No' },
    { label: 'NA', name: 'supportPlanUpdate', value: 'NA' }
  ];
  const optionsFurther = [
    { label: '', name: 'furtherSupport', value: '' },
    { label: 'Yes', name: 'furtherSupport', value: 'Yes' },
    { label: 'No', name: 'furtherSupport', value: 'No' },
    { label: 'NA', name: 'furtherSupport', value: 'NA' }
  ];
  const getUsersHandler = async () => {
    const resp = await getUsersList();
    const filteredResp = resp.data.filter((user) => !user.deleted);
    if (!resp) return;
    if (resp.status === 200) {
      // eslint-disable-next-line
      const userList = filteredResp && filteredResp.map((ele) => {
        return {
          label: `${ele.firstName} ${ele.lastName}`,
          value: `${ele.firstName} ${ele.lastName}`,
          name: 'allocatedTo'
        };
      });
    }
  };

  useEffect(() => {
    getUsersHandler();
  }, []);

  return (
    <>
      <ReviewWrapper>
        <div className="review-row">
          <div className="w-25 mr">
            <p className="label">
              Is the incident reportable to NDIS ?
            </p>
            <SelectContainer
              options={optionsNDIS.filter((item) => item.value !== '')}
              isMultiple={false}
              defaultValue={
              optionsNDIS.find((item) => item.value === incident.reportableToNDIS)
              }
              onChange={handleChangeIncident}
            />
          </div>
          <div className="w-25 mr">
            <p className="label">
              Is the incident reportable to WorkSafe ?
            </p>
            <SelectContainer
              options={optionsWorksafe.filter((item) => item.value !== '')}
              isMultiple={false}
              defaultValue={
                optionsWorksafe.find((item) => item.value === incident.reportableToWorksafe)
              }
              onChange={handleChangeIncident}
            />
          </div>
          <div className="w-25 mr">
            <p className="label">
              Has there been a breach of Code of Conduct (NDIS)?
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={optionsBreach.filter((item) => item.value !== '')}
                  isMultiple={false}
                  defaultValue={
                    optionsBreach.find((item) => item.value === reviewData.codeBreach)
                  }
                  onChange={handleSelectChange}
                />
              ) : (
                <SelectContainer
                  options={optionsBreach.filter((item) => item.value !== '')}
                  isMultiple={false}
                  defaultValue={
                    optionsBreach.find((item) => item.value === editReviewData.codeBreach)
                  }
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
        </div>
        <div className="review-row">
          <div className="w-25 mr">
            <p className="label">
              Does the participants behaviour Support Plan or Risk Assessment require updating?
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={optionsSupport.filter((item) => item.value !== '')}
                  isMultiple={false}
                  defaultValue={
                    optionsSupport.find((item) => item.value === reviewData.supportPlanUpdate)
                  }
                  onChange={handleSelectChange}
                />
              ) : (
                <SelectContainer
                  options={optionsSupport.filter((item) => item.value !== '')}
                  isMultiple={false}
                  defaultValue={
                    optionsSupport.find((item) => item.value === editReviewData.supportPlanUpdate)
                  }
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Impact on the participant. Does the participant require any counselling or therapy?
              Does the particpants family require further support or information?
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={optionsFurther.filter((item) => item.value !== '')}
                  isMultiple={false}
                  defaultValue={
                    optionsFurther.find((item) => item.value === reviewData.furtherSupport)
                  }
                  onChange={handleSelectChange}
                />
              ) : (
                <SelectContainer
                  options={optionsFurther.filter((item) => item.value !== '')}
                  isMultiple={false}
                  defaultValue={
                    optionsFurther.find((item) => item.value === editReviewData.furtherSupport)
                  }
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
        </div>
        <div className="review-row">
          <div className="w-50 flex">
            <p className="label">
              What follow up is required for the support worker and other perople involved?
              Was anyone injured ? Do they require EAP ?
            </p>
            {
              !Object.keys(editReviewData).length > 0
                ? (
                  <textarea
                    className="input-like"
                    rows="4"
                    placeholder="Follow Up Description"
                    name="followUp"
                    onChange={handleChange}
                    value={reviewData.followUp}
                  />
                )
                : (
                  <textarea
                    className="input-like"
                    placeholder="Follow Up Description"
                    rows="4"
                    value={editReviewData.followUp}
                    name="followUp"
                    onChange={handleChangeEditReview}
                  />
                )
            }
          </div>
          <div className="w-50 flex">
            <p className="label">How could the incident have been prevented or the impact of the incident reduced ? </p>
            {
              !Object.keys(editReviewData).length > 0
                ? (
                  <textarea
                    className="input-like"
                    rows="4"
                    placeholder="How Prevented Description"
                    name="howPrevented"
                    onChange={handleChange}
                    value={reviewData.howPrevented}
                  />
                )
                : (
                  <textarea
                    className="input-like"
                    placeholder="How Prevented Description"
                    rows="4"
                    value={editReviewData.howPrevented}
                    name="howPrevented"
                    onChange={handleChangeEditReview}
                  />
                )
            }
          </div>
        </div>
        <div className="row-review">
          <h4 className="mx-3 text-danger">
            { reviewValidationMsg && `${reviewValidationMsg}`}
          </h4>
        </div>
      </ReviewWrapper>
      <ButtonWrapper>
        <button
          type="button"
          className="next-btn"
          onClick={() => handleSelectedIndex(2)}
        >
          Next
        </button>
      </ButtonWrapper>
    </>
  );
};
IncidentReviewFirst.propTypes = {
  editReviewData: PropTypes.shape.isRequired,
  reviewData: PropTypes.shape.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSelectChangeEdit: PropTypes.func.isRequired,
  handleChangeEditReview: PropTypes.func.isRequired,
  reviewValidationMsg: PropTypes.string.isRequired,
  incident: PropTypes.string.isRequired,
  handleChangeIncident: PropTypes.func.isRequired,
  handleSelectedIndex: PropTypes.func.isRequired
};

export default IncidentReviewFirst;
