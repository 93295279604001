import React from 'react';
import propTypes from 'prop-types';

export default function AdminUser({ active }) {
  return (
    <svg width="30" height="30" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0059 0C10.2863 0.0829167 10.579 0.102426 10.8619 0.180466C12.8543 0.721863 14.3444 2.48018 14.5443 4.53603C14.8102 7.26009 12.9006 9.68905 10.2034 10.0622C9.73517 10.128 9.2645 10.089 8.79382 10.1329C6.96722 10.3085 5.36985 11.0108 4.01392 12.2424C2.73115 13.413 1.89955 14.8518 1.52886 16.5565C1.40936 17.1101 1.75566 17.5369 2.32876 17.5369C4.77968 17.5369 7.23304 17.5369 9.68396 17.5369C10.1668 17.5369 10.5107 17.8661 10.4839 18.3148C10.4668 18.6367 10.2936 18.8538 9.99855 18.9782C9.97173 18.9904 9.93515 18.9806 9.92783 19.0221C7.25255 19.0221 4.57727 19.0221 1.90199 19.0221C1.85809 18.9562 1.78005 18.9708 1.71908 18.9562C0.538739 18.6587 -0.217266 17.4832 0.0558716 16.3004C0.782612 13.1423 2.61166 10.8572 5.54788 9.47201C5.84784 9.33056 6.16244 9.22326 6.48435 9.09888C6.44045 9.0623 6.41363 9.03792 6.3868 9.01353C4.97478 7.8405 4.33096 6.34312 4.49923 4.51408C4.68945 2.42897 6.29413 0.61212 8.34022 0.141446C8.54751 0.0926716 8.76212 0.0853554 8.95966 0.00243873C9.31327 0 9.65957 0 10.0059 0ZM5.95759 5.04328C5.95271 7.03816 7.54764 8.6087 9.58397 8.61845C11.4764 8.62821 13.0835 7.00158 13.0909 5.07255C13.0982 3.09962 11.5008 1.49006 9.53032 1.48762C7.56471 1.48274 5.96246 3.08011 5.95759 5.04328Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M18.9973 12.6328C18.7802 12.9937 18.4559 13.0888 18.0462 13.0864C16.283 13.0718 14.5198 13.0815 12.7566 13.0791C12.2103 13.0791 11.8494 12.5962 12.0152 12.0987C12.1176 11.7914 12.3957 11.5939 12.7395 11.5939C13.6687 11.5915 14.5954 11.5939 15.5245 11.5939C16.3659 11.5939 17.2073 11.6085 18.0486 11.5866C18.4583 11.5768 18.7827 11.6768 18.9997 12.0377C18.9973 12.2353 18.9973 12.4328 18.9973 12.6328Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M18.9976 15.603C18.7756 15.9737 18.4415 16.059 18.0269 16.0541C16.2759 16.0395 14.5249 16.0493 12.7739 16.0468C12.3447 16.0468 12.0277 15.7834 11.9789 15.3957C11.9228 14.947 12.2667 14.5641 12.7373 14.5616C13.8884 14.5592 15.0395 14.5616 16.1881 14.5616C16.8076 14.5616 17.427 14.5787 18.044 14.5543C18.4537 14.5373 18.7781 14.6446 18.9951 15.0055C18.9976 15.2079 18.9976 15.4054 18.9976 15.603Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M12.4957 19.022C12.4762 18.9562 12.4103 18.9586 12.364 18.9343C12.0689 18.766 11.9153 18.4319 11.9933 18.1173C12.0762 17.7783 12.3518 17.5417 12.6981 17.5393C14.5662 17.5344 16.4367 17.5369 18.3047 17.5369C18.6291 17.5369 18.8266 17.7368 18.9973 17.9832C18.9973 18.1685 18.9973 18.3538 18.9973 18.5416C18.8949 18.7782 18.7022 18.9147 18.4779 19.022C16.483 19.022 14.4906 19.022 12.4957 19.022Z" fill={active ? '#E86B55' : '#74BCA9'} />
    </svg>
  );
}

AdminUser.propTypes = {
  active: propTypes.bool.isRequired
};
