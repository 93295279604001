/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BsUpload } from 'react-icons/bs';
import swal from 'sweetalert';
import { Modal, Spinner } from 'react-bootstrap';
import {
  BreadCrumb,
  EmergencyPlanWrapper,
  EmergencyPlanHeader,
  EmergencyContentWrapper,
  FormWrapper,
  EmergencyBoxHeadingPlan,
  EmergencyBoxWrapper
} from './index.style';
import CustomModal from '../../components/CustomModal';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
// import plans from '../../assets/logo/plans.svg';
import uploadImage from '../../assets/logo/upload.svg';
import PdfViewPlans from '../../components/PdfViewPlans/PdfViewPlans';
import { getEmergencyPlanUrl, uploadPlan } from '../../services/result.services';
import { getUserHouseCode } from '../../helpers/utils';

const AdminNotices = () => {
  const { houseCode } = useParams();
  const history = useHistory();
  const [showCustomModalUploadHandout, setShowCustomModalUploadHandout] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [urlData, setUrlData] = useState();
  const [labelUploadFileName, setLabelUploadFileName] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleUploadHandoutModal = () => {
    setShowCustomModalUploadHandout(true);
  };

  const clearInput = () => {
    setUploadFile(null);
    setLabelUploadFileName('');
  };

  const handleGoBack = () => {
    history.push('/admin/setup');
  };
  const handleFileChange = (e) => {
    if (e.currentTarget) {
      setLabelUploadFileName(
        e.currentTarget.files[0].name || ''
      );
      setUploadFile(e.currentTarget.files[0]);
    }
  };

  const getEmergencyPlanUrlHandler = async () => {
    setLoading(true);
    const resp = await getEmergencyPlanUrl(houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setUrlData(resp.data);
    } else {
      setLoading(false);
    }
  };
  const handleCloseCustomModal = (operation) => {
    if (operation === 'upload-handout') {
      setShowCustomModalUploadHandout(false);
    }
  };
  const handleUpload = async (fileType) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('houseCode', houseCode);
    formData.append('fileType', fileType);
    const resp = await uploadPlan(formData);
    if (fileType === 'HANDOUT') {
      handleCloseCustomModal('upload-handout');
    }
    if (!resp) {
      clearInput();
      setLoading(false);
    } else if (resp.status === 200) {
      clearInput();
      setLoading(false);
      swal({
        title: `${fileType === 'HANDOUT' ? 'HandoutT uploaded successfully!' : null}`,
        icon: 'success',
        timer: 3000
      });
    } else {
      clearInput();
      swal({
        title: `${fileType === 'HANDOUT' ? 'Handout failed to upload!' : null}`,
        icon: 'error',
        timer: 3000
      });
    }
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getEmergencyPlanUrlHandler(getUserHouseCode() && getUserHouseCode());
  }, []);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Set Up </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Emergency Plan </span>
          </li>
        </ul>
      </BreadCrumb>
      <EmergencyPlanWrapper>
        <EmergencyPlanHeader>
          <h2>Emergency Plan</h2>
          <button type="button" onClick={() => handleUploadHandoutModal()}>
            Upload New Handout
          </button>
        </EmergencyPlanHeader>
        <EmergencyContentWrapper>
          <EmergencyBoxWrapper>
            {urlData ? (
              <>
                <EmergencyBoxHeadingPlan>
                  <button type="button" onClick={handleGoBack}>
                    Back
                  </button>
                  <a href={urlData && urlData.emergencyHandoutUrl} download>
                    <img src={uploadImage} alt="upload" className="upload-img" />
                  </a>
                </EmergencyBoxHeadingPlan>
              </>
            ) : null}

            <PdfViewPlans
              pdf={urlData
                ? urlData && urlData.emergencyHandoutUrl
                : urlData && urlData.emergencyPlanUrl}
            />
          </EmergencyBoxWrapper>
        </EmergencyContentWrapper>

      </EmergencyPlanWrapper>
      <CustomModal
        open={showCustomModalUploadHandout}
        setClose={() => {
          handleCloseCustomModal('upload-handout');
          clearInput();
        }}
        title="Upload Emergency Handout"
        width="680px"
        onSubmit={() => handleUpload('HANDOUT')}
        operationName="Upload"
        isDisabled={!uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <p className="label">Select File</p>
              <div className="icon-name-wrapper">
                <label htmlFor="uploadEH">
                  <BsUpload />
                  <input
                    type="file"
                    id="uploadEH"
                    name="uploadEH"
                    hidden
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  <span className="ellipsis">
                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminNotices;
