import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import editIcon from '../../assets/logo/edit.svg';
import plusIcon from '../../assets/logo/plus.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import {
  getClientContacts,
  getPowerOfAttorney,
  getServiceProvider,
  createServiceProvider,
  createClientContact,
  updateClientContact,
  updateServiceProvider,
  createPowerOfAttorney,
  updatePowerOfAttorney
} from '../../services/result.services';
import { serviceProviderOptions, relations, attorneyType } from '../../lookups/lookups';
import CustomModal from '../CustomModal';
import {
  ContactHeader,
  ContactPersonDiv,
  FormWrapper,
  LoadingModal,
  StyledSelect,
  Td,
  Th,
  TableWrapper,
  StyledTable,
  TableHeader,
  IconContainer
} from './index.style';
import { DetailsWrapper } from '../../styledComponents/common/page.style';

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'black' : styles.color,
    backgroundColor: state.isSelected ? '#F8F1EB' : styles.color,
    '&:hover': { color: 'black', backgroundColor: '#F8F1EB' }
  })
};

const AdminClientContacts = ({ client }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [clientContacts, setClientContacts] = useState();
  const [serviceProviders, setServiceProviders] = useState();
  const [powerOfAttorney, setPowerOfAttorney] = useState();
  const [showCustomModalEditContact, setShowCustomModalEditContact] = useState(false);
  const [showCustomModalEditService, setShowCustomModalEditService] = useState(false);
  const [showCustomModalEditAttorney, setShowCustomModalEditAttorney] = useState(false);
  const [showCustomModalAddContact, setShowCustomModalAddContact] = useState(false);
  const [showCustomModalAddService, setShowCustomModalAddService] = useState(false);
  const [showCustomModalAddAttorney, setShowCustomModalAddAttorney] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [serviceInfo, setServiceInfo] = useState({
    name: '',
    service: '',
    email: '',
    phone: ''
  });
  const [contactInfo, setContactInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    relation: '',
    designation: '',
    phone1: '',
    phone2: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postCode: '',
    notes: '',
    status: '',
    lastUpdatedBy: ''
  });
  const [attorneyInfo, setAttorneyInfo] = useState({
    type: '',
    name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postCode: ''
  });
  const history = useHistory();

  const handleClick = (link) => history.push(link);
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'selected' && value !== 'Other') {
      setContactInfo((prevState) => ({
        ...prevState,
        relation: value,
        selected: ''
      }));
    } else if (name === 'selected' && value === 'Other') {
      setContactInfo((prevState) => ({
        ...prevState,
        [name]: value,
        relation: ''
      }));
    } else {
      setContactInfo((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleChangeService = (event) => {
    const { name, value } = event.target;
    setServiceInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeAttorney = (event) => {
    const { name, value } = event.target;
    setAttorneyInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const clearInput = () => {
    setContactInfo({
      firstName: '',
      lastName: '',
      email: '',
      relation: '',
      designation: '',
      phone1: '',
      phone2: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postCode: '',
      notes: '',
      status: '',
      lastUpdatedBy: ''
    });
    setServiceInfo({
      name: '',
      service: '',
      phone: '',
      email: ''
    });
    setAttorneyInfo({
      type: '',
      name: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postCode: ''
    });
  };

  const checkValidationContact = () => {
    if (contactInfo.firstName === '') {
      setValidationMsg('First Name cannot be empty');
      return false;
    }
    if (contactInfo.lastName === '') {
      setValidationMsg('Last Name cannot be empty');
      return false;
    }

    if (contactInfo.relation === '') {
      setValidationMsg('Relation cannot be empty');
      return false;
    }
    if (contactInfo.email !== '') {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!re.test(String(contactInfo.email))) {
        setValidationMsg('Enter a valid Email Address');
        return false;
      }
    }
    if (contactInfo.postCode !== '') {
      const re = /^[0-9]{4}$/;
      if (!re.test(String(contactInfo.postCode))) {
        setValidationMsg('Enter a valid zip');
        return false;
      }
    }
    if (contactInfo.phone1 !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(contactInfo.phone1))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    if (contactInfo.phone2 !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(contactInfo.phone2))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    setValidationMsg('');
    return true;
  };
  const checkValidationService = () => {
    if (serviceInfo.name === '') {
      setValidationMsg('Name cannot be empty');
      return false;
    }
    if (serviceInfo.service === '') {
      setValidationMsg('Service cannot be empty');
      return false;
    }
    if (serviceInfo.email !== '') {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!re.test(String(serviceInfo.email))) {
        setValidationMsg('Enter a valid Email Address');

        return false;
      }
    }
    if (serviceInfo.phone !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(serviceInfo.phone))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    setValidationMsg('');
    return true;
  };
  const checkValidationAttorney = () => {
    if (attorneyInfo.name === '') {
      setValidationMsg('Name cannot be empty');
      return false;
    }
    if (attorneyInfo.email === '') {
      setValidationMsg('Email cannot be empty');
      return false;
    }
    if (attorneyInfo.email !== '') {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!re.test(String(attorneyInfo.email))) {
        setValidationMsg('Enter a valid Email Address');
        return false;
      }
    }
    if (attorneyInfo.address1 === '') {
      setValidationMsg('Addr1 cannot be empty');
      return false;
    }
    if (attorneyInfo.address2 === '') {
      setValidationMsg('Addr2 cannot be empty');
      return false;
    }
    if (attorneyInfo.state === '') {
      setValidationMsg('State cannot be empty');
      return false;
    }
    if (attorneyInfo.postCode === '') {
      setValidationMsg('Postcode cannot be empty');
      return false;
    }
    if (attorneyInfo.postCode !== '') {
      const re = /^[0-9]{4}$/;
      if (!re.test(String(attorneyInfo.postCode))) {
        setValidationMsg('Enter a valid zip');
        return false;
      }
    }
    if (attorneyInfo.phone !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(attorneyInfo.phone))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    setValidationMsg('');
    return true;
  };
  const getDetails = async () => {
    const respContact = await getClientContacts(client.id);
    if (respContact.status === 200) {
      setClientContacts(respContact.data);
    }
    const respService = await getServiceProvider(client.id);
    if (respService.status === 200) {
      setServiceProviders(respService.data);
    }
    const respAttorney = await getPowerOfAttorney(client.id);
    if (respAttorney.status === 200) {
      setPowerOfAttorney(respAttorney.data);
    }
  };
  const handleEditContact = async () => {
    if (!checkValidationContact()) return;
    setLoading(true);
    const resp = await updateClientContact(contactInfo.id, contactInfo);
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditContact(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditContact(false);
      swal({
        title: 'Contact Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditContact(false);
      swal({
        title: 'Contact Updation Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleDeleteContact = async (contact) => {
    const value = await swal({
      text: `Are you sure you want to delete ${contact.firstName}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateClientContact(contact.id, { ...contact, deleted: true, status: 'INACTIVE' });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${contact.firstName} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Client deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleEditService = async () => {
    if (!checkValidationService()) return;
    setLoading(true);
    const resp = await updateServiceProvider(serviceInfo.id, serviceInfo);
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditService(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditService(false);
      swal({
        title: 'Service Provider Updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditService(false);
      swal({
        title: 'Service Provider Updation Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleDeleteService = async (contact) => {
    const value = await swal({
      text: `Are you sure you want to delete ${contact.name}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateServiceProvider(contact.id, { ...contact, deleted: true, status: 'INACTIVE' });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${contact.name} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Client deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleAddContact = async () => {
    if (!checkValidationContact()) return;
    setLoading(true);
    const resp = await createClientContact({
      ...contactInfo,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddContact(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddContact(false);
      swal({
        title: 'Contact Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddContact(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleAddService = async () => {
    if (!checkValidationService()) return;
    setLoading(true);
    const resp = await createServiceProvider({
      ...serviceInfo,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddService(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddService(false);
      swal({
        title: 'Service Provider Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddService(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleEditAttorney = async () => {
    if (!checkValidationAttorney()) return;
    setLoading(true);
    const resp = await updatePowerOfAttorney(attorneyInfo.id, attorneyInfo);
    if (!resp) {
      setLoading(false);
      setShowCustomModalEditAttorney(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalEditAttorney(false);
      swal({
        title: 'Power Of Attorney updated Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalEditAttorney(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleDeleteAttorney = async (contact) => {
    const value = await swal({
      text: `Are you sure you want to delete ${contact.name}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updatePowerOfAttorney(contact.id, { ...contact, deleted: true, status: 'INACTIVE' });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${contact.name} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        getDetails();
        clearInput();
      } else {
        setLoading(false);
        swal({
          title: 'Client deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };
  const handleAddAttorney = async () => {
    if (!checkValidationAttorney()) return;
    setLoading(true);
    const resp = await createPowerOfAttorney({
      ...attorneyInfo,
      clientId: client.id
    });
    if (!resp) {
      setLoading(false);
      setShowCustomModalAddAttorney(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setShowCustomModalAddAttorney(false);
      swal({
        title: 'Power Of Attorney Added Successfully!',
        icon: 'success',
        timer: 3000
      });
      getDetails();
      clearInput();
    } else {
      setLoading(false);
      setShowCustomModalAddAttorney(false);
      swal({
        title: 'Failed!',
        icon: 'error',
        timer: 3000
      });
      clearInput();
    }
  };
  const handleAdd = (caller) => {
    if (caller === 'contact') {
      clearInput();
      setShowCustomModalAddContact(true);
    }
    if (caller === 'service') {
      clearInput();
      setShowCustomModalAddService(true);
    }
    if (caller === 'powerOfAttorney') {
      clearInput();
      setShowCustomModalAddAttorney(true);
    }
  };
  const handleEdit = (caller, item) => {
    if (caller === 'contact') {
      clearInput();
      setContactInfo(item);
      setShowCustomModalEditContact(true);
    }
    if (caller === 'service') {
      clearInput();
      setServiceInfo(item);
      setShowCustomModalEditService(true);
    }
    if (caller === 'powerOfAttorney') {
      clearInput();
      setAttorneyInfo(item);
      setShowCustomModalEditAttorney(true);
    }
  };
  const handleModalClose = (caller) => {
    if (caller === 'contact') {
      setShowCustomModalAddContact(false);
      setShowCustomModalEditContact(false);
    }
    if (caller === 'service') {
      setShowCustomModalEditService(false);
      setShowCustomModalAddService(false);
    }
    if (caller === 'powerOfAttorney') {
      setShowCustomModalEditAttorney(false);
      setShowCustomModalAddAttorney(false);
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      <ContactHeader>
        <h2>Contacts</h2>
        <button onClick={() => handleClick('/admin/clients')} type="button"> Back </button>
      </ContactHeader>
      <DetailsWrapper>
        <ContactPersonDiv>
          <TableHeader>
            <h3>Contact Person</h3>
            <button type="button" onClick={() => handleAdd('contact')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Client</Th>
                  <Th>Relationship</Th>
                  <Th>Home Phone</Th>
                  <Th>Work Phone</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {clientContacts && clientContacts.filter((item) => item.status !== 'INACTIVE').map((user) => (
                  <tr key={user.id}>
                    <Td>
                      {user.firstName}
                      {' '}
                      {user.lastName}
                    </Td>
                    <Td>{user.clientName}</Td>
                    <Td>{user.relation}</Td>
                    <Td>{user.phone1}</Td>
                    <Td>{user.phone2}</Td>
                    <Td>
                      <IconContainer
                        onClick={() => handleEdit('contact', user)}
                      >
                        <img src={editIcon} alt="edit icon" />
                      </IconContainer>

                      <IconContainer
                        className="delete-btn"
                        onClick={() => handleDeleteContact(user)}
                      >
                        <img src={deleteIcon} alt="delete icon" />
                      </IconContainer>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
        </ContactPersonDiv>
        <ContactPersonDiv>
          <TableHeader>
            <h3>Service Providers</h3>
            <button type="button" onClick={() => handleAdd('service')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <Th>No.</Th>
                  <Th>Name</Th>
                  <Th>Phone</Th>
                  <Th>Email</Th>
                  <Th>Service Provided</Th>
                  <Th>Action</Th>

                </tr>
              </thead>
              <tbody>
                {serviceProviders
                                  && serviceProviders.filter((item) => item.status !== 'INACTIVE').map((user, index) => (
                                    <tr key={user.id}>
                                      <Td>{index + 1}</Td>
                                      <Td>{user.name}</Td>
                                      <Td>{user.phone}</Td>
                                      <Td>{user.email}</Td>
                                      <Td>{user.service}</Td>
                                      <Td>
                                        <IconContainer
                                          onClick={() => handleEdit('service', user)}
                                        >
                                          <img src={editIcon} alt="edit icon" />
                                        </IconContainer>

                                        <IconContainer
                                          className="delete-btn"
                                          onClick={() => handleDeleteService(user)}
                                        >
                                          <img src={deleteIcon} alt="delete icon" />
                                        </IconContainer>
                                      </Td>
                                    </tr>
                                  ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
        </ContactPersonDiv>
        <ContactPersonDiv>
          <TableHeader>
            <h3>Power of Attorney</h3>
            <button type="button" onClick={() => handleAdd('powerOfAttorney')}>
              {' '}
              <img src={plusIcon} alt="plus icon" />
              Add New
              {' '}
            </button>
          </TableHeader>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Type</Th>
                  <Th>Phone</Th>
                  <Th>Email</Th>
                  <Th>Address</Th>
                  <Th>Action</Th>

                </tr>
              </thead>
              <tbody>
                {powerOfAttorney && powerOfAttorney.map((user) => (
                  <tr key={user.id}>
                    <Td>
                      {user.name}
                    </Td>
                    <Td>{user.type}</Td>
                    <Td>{user.phone}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      {user.address1}
                      {' '}
                      {user.address2}
                      {' '}
                      <br />
                      {' '}
                      {user.city}
                      {' '}
                    </Td>
                    <Td>
                      <IconContainer
                        onClick={() => handleEdit('powerOfAttorney', user)}
                      >
                        <img src={editIcon} alt="edit icon" />
                      </IconContainer>

                      <IconContainer
                        className="delete-btn"
                        onClick={() => handleDeleteAttorney(user)}
                      >
                        <img src={deleteIcon} alt="delete icon" />
                      </IconContainer>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
        </ContactPersonDiv>
      </DetailsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalEditContact}
        setClose={() => {
          handleModalClose('contact');
        }}
        title="Edit Contact"
        width="680px"
        onSubmit={handleEditContact}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={contactInfo.firstName}
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.lastName}
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.email}
              name="email"
              placeholder="Email"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.designation}
              name="designation"
              placeholder="Designation"
              onChange={handleChange}
            />
            <StyledSelect
              styles={customStyles}
              options={relations}
              value={relations.filter((item) => item.value === contactInfo.relation)}
              name="selected"
              placeholder="Relation"
              onChange={(event) => handleChange({ target: { name: 'selected', value: event.value } })}
            />
            {contactInfo.selected === 'Other' ? (
              <input
                type="text"
                value={contactInfo.relation}
                name="relation"
                placeholder="Other relation type here.."
                onChange={handleChange}
              />
            ) : null}
            <input
              type="text"
              value={contactInfo.phone1}
              name="phone1"
              placeholder="Phone 1"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.phone2}
              name="phone2"
              placeholder="Phone 2"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.address1}
              name="address1"
              placeholder="Address 1"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.address2}
              name="address2"
              placeholder="Address 2"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.city}
              name="city"
              placeholder="City"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.state}
              name="state"
              placeholder="State"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.postCode}
              name="postCode"
              placeholder="Post Code"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.notes}
              name="notes"
              placeholder="Notes"
              onChange={handleChange}
            />
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddContact}
        setClose={() => {
          handleModalClose('contact');
        }}
        title="Add Contact"
        width="680px"
        onSubmit={handleAddContact}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={contactInfo.firstName}
              name="firstName"
              placeholder="First Name"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.lastName}
              name="lastName"
              placeholder="Last Name"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.email}
              name="email"
              placeholder="Email"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.designation}
              name="designation"
              placeholder="Designation"
              onChange={handleChange}
            />
            <StyledSelect
              styles={customStyles}
              options={relations}
              value={relations.filter((item) => item.value === contactInfo.relation)}
              name="selected"
              placeholder="Relation"
              onChange={(event) => handleChange({ target: { name: 'selected', value: event.value } })}
            />
            {contactInfo.selected === 'Other' ? (
              <input
                type="text"
                value={contactInfo.relation}
                name="relation"
                placeholder="Other relation type here.."
                onChange={handleChange}
              />
            ) : null}
            <input
              type="text"
              value={contactInfo.phone1}
              name="phone1"
              placeholder="Phone 1"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.phone2}
              name="phone2"
              placeholder="Phone 2"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.address1}
              name="address1"
              placeholder="Address 1"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.address2}
              name="address2"
              placeholder="Address 2"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.city}
              name="city"
              placeholder="City"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.state}
              name="state"
              placeholder="State"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.postCode}
              name="postCode"
              placeholder="Post Code"
              onChange={handleChange}
            />
            <input
              type="text"
              value={contactInfo.notes}
              name="notes"
              placeholder="Notes"
              onChange={handleChange}
            />
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditService}
        setClose={() => {
          handleModalClose('service');
        }}
        title="Edit Service Provider"
        width="680px"
        onSubmit={handleEditService}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={serviceInfo.name}
              name="name"
              placeholder="Name"
              onChange={handleChangeService}
            />
            <StyledSelect
              styles={customStyles}
              options={serviceProviderOptions}
              value={serviceProviderOptions.filter((item) => item.value === serviceInfo.service)}
              name="service"
              placeholder="Service"
              onChange={(event) => handleChangeService({ target: { name: 'service', value: event.value } })}
            />
            <input
              type="text"
              value={serviceInfo.email}
              name="email"
              placeholder="Email"
              onChange={handleChangeService}
            />
            <input
              type="text"
              value={serviceInfo.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChangeService}
            />
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddService}
        setClose={() => {
          handleModalClose('service');
        }}
        title="Add Service Provider"
        width="680px"
        onSubmit={handleAddService}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={serviceInfo.name}
              name="name"
              placeholder="Name"
              onChange={handleChangeService}
            />
            <StyledSelect
              styles={customStyles}
              options={serviceProviderOptions}
              value={serviceProviderOptions.filter((item) => item.value === serviceInfo.service)}
              name="service"
              placeholder="Service"
              onChange={(event) => handleChangeService({ target: { name: 'service', value: event.value } })}
            />
            <input
              type="text"
              value={serviceInfo.email}
              name="email"
              placeholder="Email"
              onChange={handleChangeService}
            />
            <input
              type="text"
              value={serviceInfo.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChangeService}
            />
          </FormWrapper>
          {validationMsg !== '' && (
            <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalAddAttorney}
        setClose={() => {
          handleModalClose('powerOfAttorney');
        }}
        title="Add Power Of Attorney"
        width="680px"
        onSubmit={handleAddAttorney}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={attorneyInfo.name}
              name="name"
              placeholder="Name"
              onChange={handleChangeAttorney}
            />
            <StyledSelect
              styles={customStyles}
              options={attorneyType}
              value={attorneyType.filter((item) => item.value === attorneyInfo.type)}
              name="type"
              placeholder="Type"
              onChange={(event) => handleChangeAttorney({ target: { name: 'type', value: event.value } })}
            />
            <input
              type="text"
              value={attorneyInfo.email}
              name="email"
              placeholder="Email"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.address1}
              name="address1"
              placeholder="Address 1"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.address2}
              name="address2"
              placeholder="Address 2"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.city}
              name="city"
              placeholder="City"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.state}
              name="state"
              placeholder="State"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.postCode}
              name="postCode"
              placeholder="Post Code"
              onChange={handleChangeAttorney}
            />
          </FormWrapper>
          {validationMsg !== '' && (
          <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalEditAttorney}
        setClose={() => {
          handleModalClose('powerOfAttorney');
        }}
        title="Edit Power Of Attorney"
        width="680px"
        onSubmit={handleEditAttorney}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={attorneyInfo.name}
              name="name"
              placeholder="Name"
              onChange={handleChangeAttorney}
            />
            <StyledSelect
              styles={customStyles}
              options={attorneyType}
              value={attorneyType.filter((item) => item.value === attorneyInfo.type)}
              name="type"
              placeholder="Type"
              onChange={(event) => handleChangeAttorney({ target: { name: 'type', value: event.value } })}
            />
            <input
              type="text"
              value={attorneyInfo.email}
              name="email"
              placeholder="Email"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.address1}
              name="address1"
              placeholder="Address 1"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.address2}
              name="address2"
              placeholder="Address 2"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.city}
              name="city"
              placeholder="City"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.state}
              name="state"
              placeholder="State"
              onChange={handleChangeAttorney}
            />
            <input
              type="text"
              value={attorneyInfo.postCode}
              name="postCode"
              placeholder="Post Code"
              onChange={handleChangeAttorney}
            />
          </FormWrapper>
          {validationMsg !== '' && (
          <h6 className="m-3 text-danger">{validationMsg}</h6>
          )}
        </div>
      </CustomModal>
    </>
  );
};

AdminClientContacts.propTypes = {
  client: PropTypes.objectOf(PropTypes.any).isRequired
};

export default AdminClientContacts;
