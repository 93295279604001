import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

export const ContactHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 2px solid #e6e9f4;

  h3 {
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const ContactPersonDiv = styled.div`
  margin: 1rem 0 0 2rem;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.od_bg_secondary};
  padding: 10px 20px;
  height: 18vw;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
`;

export const StyledTable = styled.table`

::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
background: #88888861;
}

::-webkit-scrollbar-thumb:hover {
background: #88888861;
}

  border-collapse: unset;
  font-size: 16px;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  min-height: calc(20vw - 100px);
  max-height: calc(26vw - 100px);
  table{
    width: 100%;
  }
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead,
  tbody tr {
    display: table;
    overflow-y: auto;
    overflow-x: auto;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
    margin: 5px 0;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 100%;
   
  }

  tbody {
    display: block;
   
    min-height: calc(10vw - 100px);
    max-height: calc(16vw - 100px);
    margin-top: -4px;
    tr {
      box-sizing: border-box;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      margin-top: -3px;
      td {
        border: none;
        word-break: break-word;
      }
    }
    tr:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;
export const InputWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.od_placeholder};
  display: flex;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border-radius: 4px;
  align-items: center;
  padding: 0px 5px;
`;

export const StyledInput = styled.input`
  margin: 0px 5px;
  width: 100%;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border-radius: 4px;
  border: none;
  :focus {
    outline: none;
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top: 5px;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0.1rem 0 0 1rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const LoadingModal = styled(Modal)`
  .modal-dialog {
    max-width: ${(props) => props.width};
  }
  .modal-content {
    background: rgba(255, 255, 255, 0.01);
    border: none;
    width: auto;
    height: auto;
  }
`;

export const StyledSelect = styled(Select)`
  border: none;
  width: 48%;
  padding: 0.4rem 0px 0.4rem 5px;
  border-radius: 5px;
  .css-yk16xz-control {
    border: none;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-26l3qy-menu {
    margin-top: -3px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.blank {
    width: 47%;
  }
  input {
    border: none;
    width: 47%;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 0.4rem 0.5rem;
  }
  input:focus {
    outline: none;
  }
  div.input-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
`;
