import styled from 'styled-components';
import Select from 'react-select';

export const ExternalUser = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px;
`;
export const User = styled.text`
  border-radius: 20px;
  padding: 7px 18px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  margin-right: 10px;
  img {
      cursor: pointer;
  }
`;

export const AddUser = styled.div`
  border-radius: 13px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #f2e4d8;
  margin-top: 0.5rem;
`;
export const TextDataDiv = styled.div`
  display: flex;
  margin-top: ${({ marginTop }) => (marginTop ? '0.875rem' : '0px')};
  border: 1px solid ${({ theme }) => theme.colors.od_extra_color};
  border-radius: 13px;
  background-color: ${({ theme }) => theme.colors.od_light_extra};
`;
export const AddExternalUser = styled.div`
  display: flex;
  input {
    width: 197px;
    border-radius: 13px;
    border: 1px solid #f5ddc9;
    height: 40px;
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  .input-failed {
    border: 2px solid red;
    animation: shake 300ms;

    @keyframes shake {
      25% {
        transform: translateX(4px);
      }
      50% {
        transform: translateX(-4px);
      }
      75% {
        transform: translateX(4px);
      }
    }
  }
`;
export const LeftText = styled.div`
  padding: 9px;
  float: left;
  width: 50%;
  border-right: 1px solid ${({ theme }) => theme.colors.od_extra_color};
  color: ${({ theme }) => theme.colors.od_extra_color};
`;
export const AddNew = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const RightText = styled.div`
  float: left;
  width: 50%;
  padding: 9px;
  color: ${({ theme }) => theme.colors.od_extra_color};
`;
export const AssignedUser = styled.div`
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;
    img {
      margin: 0 10px 2px 0;
    }
  }
`;
export const AssignedUserData = styled.div`
  border-radius: 13px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #f2e4d8;
  padding: 16px;
  margin-top: 0.5rem;
`;
export const StyledSelectDropdown = styled(Select)`
  width: 100%;
  padding: 10px 0px;
  margin-left: 8px;
  // height: 20px;
  .css-1wa3eu0-placeholder {
    color: #93acab;
    font-weight: 400;
    font-size: 18px;
    font-family: Arial, FontAwesome;
  }
  .css-yk16xz-control {
    height: 40px;
    // border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .css-g1d714-ValueContainer {
    height: 40px;
  }
  .css-1uccc91-singleValue {
    height: 30px;
  }
  .css-4ljt47-MenuList {
    background-color: ${({ theme }) => theme.colors.od_light_grey};
    padding: 5px;
  }
  .css-yutw0f-option {
    padding: 4px;
  }
  .css-tlfecz-indicatorContainer {
    display: none;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-26l3qy-menu {
    // width: 110%;
    margin-top: -10px;
    // left: -33px;
  }
  .css-q8s328-control {
    border: none;
    background-color: transparent;
  }

  .custom__control {
    height: -40px;
    border: 1px solid #d1e3e2;

    .custom__single-value {
      font-size: 14px;
      color: red;
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

export const SelectStyledTable = styled.table`
  width: 100%;
  tbody {
    width: 100%;
    tr {
      width: 100%;
      td {
        background-color: white;
        height: 20%;
        width: 50%;
        padding: 5px;
      }
    }
  }
`;

export const RepeatWrapper = styled.div`
  display: flex;
  height: 100px;
  button {
    border: none;
    border-radius: 5px;
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
    height: 50px;
    margin: auto 0;
  }
  button.action {
    color: #fff;
    background-color: #e86b55;
  }
  .inline-field {
    display: flex;
    width: 100%;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.big_font};
      margin-bottom: 0px;
      padding: 0 0.5rem;
      margin: 0 0.5rem;
    }
    input {
        width: 100%;
      padding: 15px 8px;
      border: none;
      padding: 0.5rem;
      border-radius: 5px;
      margin: 0 0.5rem;
    }
    .input-label-wrapper {
      display: block;
      flex: 1;
      margin: 10px;
    }
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  border: none;
  background: none !important;
  margin: 0 !important;
  padding: 0 !important;

  img {
    width: 25px;
    margin: 0 !important;
  }
`;
