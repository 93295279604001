import styled from 'styled-components';
import Select from 'react-select';

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.blank {
    width: 47%;
  }
  h4 {
    text-align: center;
  }
  table {
    width: 100%;
  }
  input {
    border: none;
    width: 80%;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 0.4rem 0.5rem;
  }
  input:focus {
    outline: none;
  }
  div.input-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.big_font};
      margin: 0;
      padding: 0 0.5rem;
    }
    input,
    select {
      width: 98%;
      padding: 1rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
    textarea {
      border: none;
      margin: 5px 9px;
      border-radius: 5px;
      padding: 10px;
    }
  }
  th {
    padding-left: 10px;
  }
  .table-input {
    width: 93%;
    padding: 18px 10px;
  }
  .inline-field {
    display: flex;
    width: 100%;
    justify-content: space-between;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.big_font};
      padding-left: 10px;
      margin-bottom: 0px;
    }
    .input {
      width: 300px;
      input {
        width: 95%;
        padding: 15px 8px;
      }
    }
  }
  .delete-btn {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  border: none;
  width: auto;
  border-radius: 5px;
  margin: 0 3px 0 0;
  .css-yk16xz-control {
    border: none;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-26l3qy-menu {
    margin-top: -3px;
  }
  .css-g1d714-ValueContainer {
    padding: 10px 8px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
`;

export const ModalClientName = styled.div`
  border: 1px solid rgba(134, 81, 137, 0.35);
  padding: 10px;
  background-color: rgba(134, 81, 137, 0.1);
  border-radius: 10px;
  color: #865189;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: ${({ noSeperator }) => (noSeperator ? 'none' : 'linear-gradient(rgba(134, 81, 137, 0.35), rgba(134, 81, 137, 0.35))')};
  background-size: ${({ noSeperator }) => (noSeperator ? 'none' : '1px 100%')};
  background-repeat: ${({ noSeperator }) => (noSeperator ? 'none' : 'no-repeat')};
  background-position: ${({ noSeperator }) => (noSeperator ? 'none' : 'center center')};
  button{
    border: none;
    border-radius: 50%;
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
  }
  button.action{
    color: #fff;
    background-color: #E86B55;
  }
`;
export const IconContainer = styled.div`
  // margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border: none;
  height: 30px;
  text-align: center;
  cursor: pointer;
  img {
    width: 19px !important;
    height: auto;
    margin-left: 0;
  }
`;

export const StyledSelectDropdown = styled(Select)`
  width: 100%;
  padding: 10px 0px;
  margin-left: 8px;
  // height: 20px;
  .css-1wa3eu0-placeholder{
    color: #93ACAB;
    font-weight: 400;
    font-size: 18px;
    font-family: Arial, FontAwesome;
  }
  .css-yk16xz-control{
    height: 40px;
    // border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .css-g1d714-ValueContainer{
    height: 40px;
  }
  .css-1uccc91-singleValue{
    height: 30px;
  }
  .css-4ljt47-MenuList{
    background-color: ${({ theme }) => theme.colors.od_light_grey};
    padding: 5px;
  }
  .css-yutw0f-option{
    padding: 4px;
  }
  .css-tlfecz-indicatorContainer{
    display: none;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none;
  }
  .css-26l3qy-menu{
    // width: 110%;
    margin-top: -10px;
    // left: -33px;
  }
  .css-q8s328-control {
    border: none;
    background-color: transparent;
  }
  
  .custom__control {
    height: -40px;
    border: 1px solid  #D1E3E2;
    
    .custom__single-value {
      font-size: 14px;
      color: red;
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
