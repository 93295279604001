import React from 'react';
import propTypes from 'prop-types';

export default function AdminHandoverIcon({ active }) {
  return (
    <svg width="30" height="30" viewBox="0 0 22 17" fill={active ? '#E86B55' : '#74BCA9'} xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.99" d="M10.1068 7.97201C10.1902 7.97201 10.2736 7.97192 10.3569 7.97209C10.3823 7.97209 10.399 7.99263 10.3938 8.01677C10.3906 8.03155 10.3777 8.04308 10.3629 8.04441C10.36 8.04466 10.3571 8.04466 10.3543 8.04466C10.1893 8.04466 10.0706 8.04466 9.90568 8.04466C9.88432 8.04466 9.86997 8.03448 9.86617 8.01677C9.86106 7.99305 9.87739 7.97226 9.90189 7.97217C9.9597 7.97192 9.97135 7.97209 10.0292 7.97209C10.055 7.97201 10.0809 7.97201 10.1068 7.97201Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M9.74552 8.50902C9.7442 8.50493 9.73991 8.50493 9.73711 8.50293C9.72787 8.49616 9.72218 8.48739 9.72136 8.4757C9.72119 8.47311 9.72119 8.47061 9.72119 8.46802C9.72119 8.12276 9.72119 7.87001 9.72119 7.52476C9.72119 7.50288 9.73051 7.4891 9.74857 7.48459C9.7527 7.48359 9.75707 7.4835 9.76136 7.4835C9.92673 7.48342 10.0458 7.4835 10.2111 7.48334C10.2238 7.48334 10.234 7.48751 10.2428 7.49653C10.3368 7.59174 10.4308 7.68695 10.5249 7.78207C10.5344 7.79159 10.5389 7.80237 10.5389 7.81606C10.5387 8.06419 10.5387 8.21981 10.539 8.46793C10.539 8.48856 10.5293 8.50076 10.5124 8.50911C10.2413 8.50902 10.0165 8.50902 9.74552 8.50902ZM9.79295 7.99547C9.79295 8.15415 9.79303 8.26649 9.7927 8.42517C9.7927 8.43419 9.79493 8.43645 9.80392 8.43645C10.0368 8.4362 10.2234 8.4362 10.4563 8.43636C10.4642 8.43636 10.4672 8.43511 10.4672 8.42593C10.4668 8.20578 10.4668 8.07813 10.4672 7.85799C10.4672 7.84855 10.4638 7.8478 10.4562 7.84788C10.3782 7.84813 10.3002 7.84805 10.2223 7.84805C10.1922 7.84805 10.1786 7.8346 10.1786 7.80437C10.1786 7.72545 10.1785 7.64644 10.1788 7.56752C10.1788 7.55867 10.1767 7.556 10.1677 7.55608C10.0313 7.55633 9.94092 7.55633 9.80433 7.556C9.7946 7.556 9.7927 7.55858 9.79278 7.56802C9.79303 7.72587 9.79295 7.83754 9.79295 7.99547ZM10.2511 7.61011C10.2511 7.61312 10.2511 7.61554 10.2511 7.61796C10.2511 7.66707 10.2513 7.71618 10.2508 7.76529C10.2507 7.7738 10.2533 7.77539 10.261 7.77531C10.3092 7.77497 10.3573 7.77514 10.4055 7.77506C10.408 7.77506 10.4112 7.77664 10.4128 7.77405C10.3592 7.71969 10.3057 7.6654 10.2511 7.61011Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path opacity="0.99" d="M10.04 8.20394C9.99226 8.20394 9.94451 8.2041 9.89675 8.20385C9.87902 8.20377 9.86483 8.19249 9.86096 8.17646C9.85691 8.15967 9.86434 8.14272 9.87918 8.13495C9.88537 8.13169 9.89213 8.13086 9.89898 8.13086C9.99267 8.13086 10.0865 8.13086 10.1802 8.13094C10.2039 8.13094 10.2197 8.14573 10.2197 8.16744C10.2196 8.18915 10.2037 8.20394 10.1801 8.20394C10.1334 8.20394 10.0867 8.20394 10.04 8.20394Z" fill={active ? '#E86B55' : '#74BCA9'} />
      <path d="M21.6739 10.5326C21.3509 9.94138 20.8166 9.51039 20.1894 9.33568C19.5497 9.15718 18.868 9.25763 18.2999 9.61488L15.3568 11.6877C15.1712 11.7963 14.9624 11.8532 14.75 11.8532H14.3393C14.627 11.3305 14.7304 10.7177 14.6307 10.1227C14.531 9.52793 14.235 8.98887 13.7947 8.60059C13.3543 8.21232 12.7978 7.99975 12.2231 8H4.91436C4.45607 8.00153 4.00744 8.13858 3.61961 8.39539C3.525 8.15587 3.30239 8 3.05582 8H0.611211C0.449092 7.99975 0.293508 8.06739 0.179054 8.18791C0.0643592 8.30843 0 8.47193 0 8.64229V16.3487C0 16.5191 0.0643654 16.6825 0.179054 16.8028C0.293508 16.9233 0.449092 16.991 0.611211 16.991H3.05582C3.30239 16.991 3.52499 16.8349 3.61961 16.5956C4.00748 16.8521 4.4561 16.9892 4.91436 16.991H15.2156C16.0538 17.0525 16.8852 16.7977 17.5605 16.2727L20.7791 14.0417V14.042C21.3398 13.7002 21.7489 13.1391 21.9166 12.4812C22.0843 11.8234 21.9969 11.1227 21.6739 10.5323L21.6739 10.5326ZM2.4447 15.7067H1.2223L1.22255 9.28432H2.44495L2.4447 15.7067ZM20.1371 12.9497L16.8879 15.2007C16.412 15.587 15.8138 15.768 15.2155 15.7067H4.91421C4.58345 15.7062 4.26622 15.5679 4.03251 15.322C3.79879 15.0761 3.66714 14.7428 3.6669 14.3952V10.5959C3.66714 10.2483 3.79877 9.91496 4.03251 9.66911C4.26625 9.42348 4.58347 9.2849 4.91421 9.28439H12.223C12.6595 9.28439 13.063 9.52925 13.2813 9.92668C13.4998 10.3241 13.4998 10.8138 13.2813 11.2112C13.0631 11.6087 12.6595 11.8533 12.223 11.8533H9.16715C8.8296 11.8533 8.55594 12.1408 8.55594 12.4956C8.55594 12.8503 8.8296 13.1378 9.16715 13.1378H14.7501C15.1871 13.1353 15.616 13.013 15.9943 12.7829L18.9456 10.705H18.9458C19.2263 10.5346 19.5599 10.4883 19.8731 10.5766C20.1862 10.6645 20.4531 10.8799 20.6151 11.1749C20.7775 11.4764 20.8184 11.8337 20.7284 12.167C20.6386 12.5006 20.4257 12.7823 20.137 12.9496L20.1371 12.9497Z" />
      <path d="M6.03606 5.99995C6.73531 5.999 7.41321 5.75979 7.95505 5.32244C8.49687 4.88536 8.86944 4.27703 9.0103 3.60001H16.3573V5.39992C16.3573 5.7313 16.6289 5.99996 16.9642 5.99996C17.2996 5.99996 17.5714 5.7313 17.5714 5.39992V3.60001H18.7858V5.39992C18.7858 5.7313 19.0575 5.99996 19.3928 5.99996C19.7281 5.99996 20 5.7313 20 5.39992V2.99998C20.0002 2.84082 19.9363 2.68808 19.8224 2.57572C19.7084 2.46312 19.5539 2.39993 19.3928 2.39993H9.01016C8.85608 1.6545 8.42123 0.994872 7.79356 0.554168C7.1659 0.113464 6.39235 -0.0748707 5.62941 0.0270501C4.86646 0.128722 4.17102 0.513309 3.68426 1.10269C3.19725 1.69204 2.95516 2.4422 3.00686 3.20119C3.05854 3.96037 3.40012 4.67162 3.96235 5.19138C4.52484 5.7109 5.26596 6 6.03586 6L6.03606 5.99995ZM6.03606 1.20006C6.51899 1.20006 6.98245 1.38963 7.32404 1.72718C7.66563 2.06474 7.85745 2.52248 7.85745 2.99998C7.85745 3.47745 7.66563 3.9352 7.32404 4.27277C6.98246 4.61033 6.51901 4.79989 6.03606 4.79989C5.55289 4.79989 5.08967 4.61033 4.74808 4.27277C4.40649 3.93522 4.21443 3.47747 4.21443 2.99998C4.21491 2.52274 4.40721 2.06524 4.74856 1.72767C5.09014 1.39035 5.5531 1.20055 6.03605 1.20008L6.03606 1.20006Z" fill={active ? '#E86B55' : '#74BCA9'} />
    </svg>
  );
}

AdminHandoverIcon.propTypes = {
  active: propTypes.bool.isRequired
};
