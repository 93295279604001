import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const GreenCell = styled.div`
background-color: ${({ theme }) => theme.colors.od_primary_transparent};
border-left: 3px solid ${({ theme }) => theme.colors.od_primary};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
height: 50px;`;

export const GreenCellBorder = styled.div`
background-color: ${({ theme }) => theme.colors.od_primary_transparent};
border-left: 3px solid ${({ theme }) => theme.colors.od_primary};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
border: 1.5px dashed ${({ theme }) => theme.colors.od_secondary};
height: 50px;`;

export const PurpleCell = styled.div`
background-color: ${({ theme }) => theme.colors.od_light_extra};
border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
height: 50px;`;

export const PurpleCellBorder = styled.div`
background-color: ${({ theme }) => theme.colors.od_light_extra};
border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
border: 1.5px dashed ${({ theme }) => theme.colors.od_secondary};
margin: 2px;
height: 50px;`;

export const OrangeCell = styled.div`
background-color: ${({ backgroundColor, theme }) => (backgroundColor ? theme.colors.od_bg_secondary : theme.colors.od_light_extra)};
border-left: 3px solid ${({ theme }) => theme.colors.od_secondary};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
height: 50px;`;

export const EmptyCell = styled.div`
height: 50px;`;

export const TextWrapper = styled.div`
padding: 1px 5px;
`;

export const ShiftText = styled.div`
text-align: left;
font-weight: 700;
font-size: 11px;
display: flex;
justify-content: space-between;
div {
  width: 90%;
}
`;

export const TimeText = styled.div`
text-align: left;
font-weight: 600;
font-size: 10px;
color: ${({ theme }) => theme.colors.od_placeholder}`;

export const TextUserWrapper = styled.div`
padding: 1px 5px;
margin-top: -10px;
`;

export const ShiftUserText = styled.div`
text-align: left;
font-weight: 800;
font-size: 13px;
img{
  width: 23px;
}
`;

export const TimeUserText = styled.div`
text-align: left;
font-weight: 700;
font-size: 11px;
color: ${({ theme }) => theme.colors.od_placeholder}`;

export const UserDiv = styled.div`
text-align: left;
font-size: 10px`;

export const UserText = styled.text`
border-radius: 10px;
padding: 1px 3px;
background-color: ${({ theme }) => theme.colors.od_neutral};
margin-right: 1px`;

export const DropdownWrapper = styled(Dropdown)`
  display: flex;
  height: 15px;
  width: 10%;
  justify-content: flex-end;
  flex: 1;
`;
export const DropdownToggle = styled(Dropdown.Toggle)`
  padding: 0px;
  background-color: transparent;
  height: 25px;
  width: 25px;
  // font-weight: 700;
  // width: 40px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
  img {
    padding-top: -10px;
    height: 25px;
    width: 25px;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border-radius: 10px;
  width: auto !important;
  .dropdown-item {
    padding: 5px 10px;
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    }
  }
`;
