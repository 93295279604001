import styled from 'styled-components';
import { Modal, Popover } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

export const FinanceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  border-bottom: 2px solid #e6e9f4;

  h3 {
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const FinanceDetails = styled.div`
  margin: 1rem 0 0 2rem;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.od_bg_secondary};
  padding: 10px 20px;
  height: 18vw;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  
`;

export const StyledTable = styled.table`

::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
background: #88888861;
}

::-webkit-scrollbar-thumb:hover {
background: #88888861;
}

  border-collapse: unset;
  font-size: 16px;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  min-height: calc(16vw - 100px);
  max-height: calc(20vw - 100px);
  table{
    width: 100%;
  }
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead,
  tbody tr {
    display: table;
    overflow-y: auto;
    overflow-x: auto;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
    margin: 5px 0;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 100%;
   
  }

  tbody {
    display: block;
   
    min-height: calc(10vw - 100px);
    max-height: calc(16vw - 100px);
    margin-top: -4px;
    tr {
      box-sizing: border-box;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      margin-top: -3px;
      td {
        border: none;
        word-break: break-word;
      }
    }
    tr:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.od_placeholder};
  display: flex;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border-radius: 4px;
  align-items: center;
  padding: 0px 5px;
`;

export const StyledInput = styled.input`
  margin: 0px 5px;
  width: 100%;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border-radius: 4px;
  border: none;
  :focus {
    outline: none;
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top: 5px;
  }

  .disabledatag {
    pointer-events: none;
    color: #EBEBE4;
    opacity: 0.5;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0.1rem 0 0 1rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;
export const LoadingModal = styled(Modal)`
  .modal-dialog {
    max-width: ${(props) => props.width};
  }
  .modal-content {
    background: rgba(255, 255, 255, 0.01);
    border: none;
    width: auto;
    height: auto;
  }
`;

export const StyledSelect = styled(Select)`
  border: none;
  width: 100%;
  padding: 0.4rem 0px 0.4rem 5px;
  border-radius: 5px;
  .css-yk16xz-control {
    border: none;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-26l3qy-menu {
    margin-top: -3px;
  }
  // .css-1hb7zxy-IndicatorsContainer {
  //   display: none;
  // }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.input-with-label{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      // margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  .label {
    font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0;
  }

  div.input-with-full-width {
    width:100%;
     display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
      margin-bottom:1px;
    }
    input, select, textarea {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
    textarea {
      height:100px;
    }
    textarea:focus {
      outline: none;
    }
  }
  div.input-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }

  div.input-with-half-width {
    width:34%;
    height:22%;
    display:flex;
    justify-content:space-between;    
    margin-left: 7px;
    margin-top:15px;
    background: ${({ theme }) => theme.colors.od_neutral};
    border: 1px solid #F2E4D8;
    box-sizing: border-box;
    border-radius: 10px;
    div:first-child {
      padding-top:8px;
      padding-right:5px;
    }
    div.centered-line {
     background: #F2E4D8;
     border: 1px solid #F2E4D8;
    }
    div:last-child {
      padding-top:8px;
      padding-right:8px;
    }
  }

  div.form-control-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type="date"]{
    color: gray;
  }
  select:invalid {
    color: gray;
  }
  input:focus, select:focus{
    outline: none;
  }
  .container {
    display: inline-block;
    position: relative;
    width: fit-content;
    padding-left: 35px;
    margin-bottom: 10px;    
    margin-left: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size:16px;
  }
  .container > span:first-child {
    position: relative;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color:${({ theme }) => theme.colors.od_bg_primary};
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background:  ${({ theme }) => theme.colors.od_neutral};
  }    
  }  
`;
export const FinanceDatePicker = styled(DatePicker)`
  border: none;
  // outline: none;
  width: 1900px;
`;
export const RowReviewValidation = styled.div`
  width:80%;
  color:red;
  font-size:16px;
`;
export const Button = styled.button`
  min-width: 32px;
  height: 32px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  color: none;
  // margin-right: 1rem;
  &:focus {
    outline: none;
  }
`;
export const UploadButton = styled(Button)`
background-color: white;
  svg{
    color: ${({ theme }) => theme.colors.od_secondary};
    font-size: 25px;
  }

  &:disabled {
    opacity: 0.5;
    color: #EBEBE4;
    
  }
`;
export const DisabledUploadButton = styled(Button)`
  svg{
    color: red;
    font-size: 25px;
  }
`;
export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;
