import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const NotesHeadingBoxWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: end;
`;

export const NotesToolBox = styled.div`
  display: flex;
  margin-left: auto;
`;

export const NotesButton = styled.button`
  width: 92px;
  height: 32px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 20px;
  font-size: 13px;
  font-weight: 700;
  border: none;
  background: ${({ theme }) => theme.colors.od_primary};
  color: ${({ theme }) => theme.colors.od_neutral};
`;

export const NotesDatePicker = styled(DatePicker)`
  border: none;
  outline: none;
  width: 190px;
`;

export const NotesSubmitButton = styled(NotesButton)`
  border: none;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;

export const NotesSearchBarWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 5px;
  width: 262px;
  height: 37px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #74bca9;
  box-sizing: border-box;
  border-radius: 3px;
  margin: auto;
  margin-top: 10px;
  margin-right: 20px;
  display: flex;
`;

export const NotesHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    font-weight: bold;
    margin-bottom: -5px;
    color: #101010;
  }
`;
export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  font-size: 15px;
  padding: 0px 10px;
  margin: 10px 5px;
  border: none;
  height: 32px;
  img {
    margin: 0 10px 2px 0;
  }
`;

export const Downloadbutton = styled.button`
      width: 100px;
      margin: 5px 0;
      color: ${({ theme }) => theme.colors.od_neutral};
      background-color: ${({ theme }) => theme.colors.od_secondary};
      border-radius: 10px;
      font-size: 15px;
      padding: 10px 10px;
      border: none;
      margin-left:1200px;
  `;
