/** @format */

import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  BreadCrumb,
  EmergencyPlanWrapper,
  EmergencyPlanHeader,
  EmergencyContentWrapper
} from './index.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import NoData from '../NoData';
import PdfViewPlans from '../PdfViewPlans/PdfViewPlans';

const OtherDoc = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [isPdf, setIsPdf] = useState(false);

  const handleGoBack = () => {
    history.goBack();
  };
  const showPdf = () => {
    setIsPdf(true);
  };
  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Other Document</span>
          </li>
        </ul>
      </BreadCrumb>
      <EmergencyPlanWrapper>
        <EmergencyPlanHeader>
          <h2>Other Document</h2>
          <button type="button" onClick={handleGoBack}>
            Back
          </button>
        </EmergencyPlanHeader>
        <EmergencyContentWrapper>
          {state.otherDocument.otherDocument === ''
            ? <NoData />
            : (
              <>
                {
                  isPdf
                    ? (
                      <>
                        <PdfViewPlans
                          pdf={state.otherDocument.otherDocument}
                        />
                      </>
                    ) : (
                      <img
                        onError={(e) => {
                          e.target.onerror = showPdf();
                        }}
                        src={state.otherDocument.otherDocument}
                        alt="sample"
                      />
                    )
                }

              </>
            )}
        </EmergencyContentWrapper>

      </EmergencyPlanWrapper>
    </>
  );
};

export default OtherDoc;
