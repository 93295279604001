import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

export const CardWrapper = styled.div`
    width: 178px !important;
    border: 1px solid black;
    margin: 15px auto;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 11px;
`;

export const ProfilePic = styled.div`
    width: 60px !important;
    height: 60px;
    border-radius: 50%;
    background: #E7E7E7;
    margin: auto;
    margin-top: 12px;

    svg {
        margin-top: 9px;
    }
`;

export const UserName = styled.h1`
    font-weight: 700;
    font-size: 13px;
    line-height: 11px;
    text-align: center;
    color: #323130;
    margin-top: 8px;
`;

export const Time = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 7px;
    text-align: center;
    color: #323130;
    margin-top: 8px;
`;

export const ButtonsWrapper = styled.div`
    margin-top: 7px;
    height: 32px;
    border-top: 1px solid #E7E7E7;
    display: flex;
`;

export const Line = styled.div`
    height: 100%;
    width: 1px;
    background: #E7E7E7;
`;

export const Button = styled.div`
    width: 50%;
    cursor: pointer;
    border-right: 1px solid #E7E7E7;

    :last-child {
        border-right: none;
    }
`;
export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;
