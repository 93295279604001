/** @format */
import styled from 'styled-components';
import SelectContainer from '../../components/SelectContainer';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};;
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const NoticesWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};

  img {
      width: 12px !important;
      height: auto;
    }
`;
export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.od_neutral};
`;
export const ImportantPageWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: -30px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
`;
export const NoticesPageWrapper = styled.div`
  margin: 1rem 0;
  margin-left: 10px;
  width: 70%;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
`;
export const EmergencyPlanWrapper = styled.div`
  margin: 1rem 0;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 1rem;
  border-radius: 10px;
  heigth: 50px;
  background-color: white;
`;
export const EmergencyHandoutWrapper = styled.div`
  margin: 1rem 0;
  margin-left: 10px;
  margin-right: 10px;
  heigth: 50px;
  padding-bottom: 1rem;
  border-radius: 10px;
  background-color: White;
`;

export const EmergencyPageWrapper = styled.div`
  margin: 1rem 0;
  margin-left: 10px;
  width: 30%;
  margin-right: 10px;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
`;
export const AdminEmergencyPlanCircle = styled.div`

  img {
    height: 90px;
    width: 60px !important;
    margin-left: 40px;
    margin-top: 20px;
  }
`;
export const AdminEmergencyPlanButton = styled.div`
  width: 275px;
  height: 53px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border-radius: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.od_extra_color};
  cursor: pointer;
`;

export const AdminEmergencyHandoutButton = styled(AdminEmergencyPlanButton)`
  color: ${({ theme }) => theme.colors.od_secondary};
`;
export const AdminEmergencyContentWrapper = styled.div`
     height: 40%;
`;
export const AdminEmergencyBoxPlan = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  cursor: pointer;
`;

export const AdminEmergencyBoxWrapper = styled.div`
  height: 120px;
  width: 421px;
  margin: 20px;
  display: flex;
  background: white;
  border-radius: 10px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }
`;
export const NoticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
  }
  
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 5px;
    font-size: 15px;
    padding: 8px 10px;
    margin: 7px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;
export const EmergencyHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 18px;
    padding: 20px;
    margin-bottom: -5px;
  }
  }
`;
export const ContactHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 5px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 7px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
  }
`;
export const ImportantContactWrapper = styled.div`
  margin: 1rem 0;
  margin-left: 10px;
  margin-right: 10px;
  width: 98%;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.select-house {
    margin-left:0.4rem;
    width:100.1%;
  }
  div.input-with-label{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin-top: 5px;
      padding: 0 1rem;
      margin-bottom: -2px;
    }
    input, select {
      width: 100%;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  div.input-with-full-width {
    width:100%;
     display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
      margin-bottom:1px;
    }
    input, select, textarea {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
    textarea {
      height:100px;
    }
    textarea:focus {
      outline: none;
    }
  }

  div.input-with-half-width {
    width:49%;
    display:flex;
    justify-content:space-between;
    padding:2px;
    margin-left: 7px;
    background: ${({ theme }) => theme.colors.od_neutral};
    border: 1px solid #F2E4D8;
    box-sizing: border-box;
    border-radius: 10px;
    div:first-child {
      padding-top:15px;
      padding-right:5px;
    }

    div.centered-line {
     background: #F2E4D8;
     border: 1px solid #F2E4D8;

    }

    div:last-child {
      padding-top:15px;
      padding-right:8px;
    }

  }
  div.form-control-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type="date"]{
    color: gray;
  }

  select:invalid {
    color: gray;
  }

  input:focus, select:focus{
    outline: none;
  }

  .container {
    display: inline-block;
    position: relative;
    width: fit-content;
    padding-left: 35px;
    margin-bottom: 15px;
    margin-left: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size:16px;
  }
  .container > span:first-child {
    position: relative;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color:${({ theme }) => theme.colors.od_bg_primary};
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background:  ${({ theme }) => theme.colors.od_neutral};
  }
`;

export const StyledSelectContainer = styled(SelectContainer)`
`;

export const IconButton = styled.button`
  background: #F8F1EB;
  border-radius: 6px;
  border:none;
  height:33px;
  width:33px;
`;

export const NoticeHeadingBoxWrapper = styled.div`
  height: 77px;
  margin-top: 25px;
  margin-left: 60%;
`;

export const NoticeToolBox = styled.div`
  display:flex;
  margin-left:20px;
`;

export const NoticeButton = styled.button`
color: ${({ theme }) => theme.colors.od_neutral};
background-color: ${({ theme }) => theme.colors.od_secondary};
border-radius: 5px;
font-size: 15px;
padding: 8px 10px;
border: none;

  `;

export const NoticeSearchBarWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 10px;
  width:262px;
  height:42px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #DADFEF;
  box-sizing: border-box;
  border-radius: 10px;
  margin:auto;
  margin-right:20px;
  display:flex;
`;

export const IconImage = styled.div`
  height: 20px;
  display:flex;
  font-size:16px;
`;

export const NoticeSubmitButton = styled(NoticeButton)`
  background: ${({ theme }) => theme.colors.od_primary};
  color: ${({ theme }) => theme.colors.od_neutral};
  border:none;
  font-weight: bold;
  font-size: 16px;
  cursor:pointer;
`;

export const NoticeInput = styled.input`
  border:none;
  outline:none;
  margin:auto;
  width:90%;

  &::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.od_bg_primary};
  }
`;
export const TableContainer = styled.div`
  margin: 0 2.5rem 1rem;
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  
`;

export const StyledTable = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    border-bottom: 1px solid #E6E9F4;
    background-color: white;
    th {
      border: none;
      word-break: break-word;
    }
  }
  .sc-iktFfs eiXXSa {
      background-color: white;
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
  }

  tbody{
    min-height: calc(58vh - 120px);
    max-height: calc(60vh - 120px);
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    tr {
      border-bottom: 1px solid #E6E9F4;
    //   border-radius: 10px;
      box-sizing: border-box;
      margin: 0 5px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
    tr:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top:5px;
  }
`;
export const SliderButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  h2{
    color: #020202;
    font-size: 20px;
    font-weight: 800;
  }
  button{
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: none;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    margin: 0 0.25rem;
    vertical-align: middle;
  }
  button:hover{
    background-color: ${({ theme }) => theme.colors.od_primary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }
`;

export const SliderContainer = styled.div`
   margin: 1rem 0 2rem -1rem;
  .slick-arrow {
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 10px;
    height: 10px;
    color: ${({ theme }) => theme.colors.od_primary};
  }
 
`;
