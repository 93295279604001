import React from 'react';

export default function ContactIcon() {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.26889 1.99856L4.26909 20.3912H3.37065C3.00669 20.3912 2.65741 20.2465 2.40006 19.9891C2.14271 19.7318 1.99817 19.3827 1.99817 19.0185V3.37104C1.99817 3.00707 2.1427 2.658 2.40006 2.40065L2.40007 2.40063C2.65742 2.1431 3.00668 1.99856 3.37065 1.99856H4.26889ZM16.8843 20.3912H5.31736V1.9987H16.8843C17.2483 1.9987 17.5974 2.14323 17.8547 2.40077L17.8547 2.40079C18.1123 2.65815 18.2568 3.00722 18.2568 3.37118L18.257 18.8989L18.2568 18.8987V19.0186C18.2568 19.3827 18.1123 19.7318 17.8547 19.9891L17.8547 19.9892C17.5974 20.2465 17.2483 20.3912 16.8843 20.3912ZM16.8847 0.95H3.3709C2.72876 0.95 2.11314 1.20499 1.65906 1.65906L1.65906 1.65906C1.20519 2.11293 0.95 2.72875 0.95 3.3707V19.0182C0.95 19.6604 1.20519 20.2762 1.65906 20.73C2.11313 21.1841 2.72876 21.4391 3.3709 21.4391H16.8847C17.5267 21.4391 18.1425 21.1841 18.5966 20.73C19.0505 20.2762 19.3056 19.6604 19.3056 19.0182V3.3707C19.3056 2.7287 19.0505 2.11294 18.5966 1.65906C18.1425 1.20499 17.5267 0.95 16.8847 0.95Z" />
      <path d="M11.4319 11.0996H11.4319C12.13 11.0996 12.7994 10.8223 13.293 10.3287C13.7866 9.83516 14.0639 9.16577 14.0639 8.46766C14.0639 7.76975 13.7866 7.10016 13.293 6.60658L13.2577 6.64193L13.293 6.60658C12.7994 6.113 12.13 5.83574 11.4319 5.83574C10.734 5.83574 10.0646 6.11303 9.57106 6.60658L9.57106 6.60658C9.07748 7.10016 8.80022 7.76975 8.80022 8.46766L8.80022 8.4677C8.80082 9.1656 9.0783 9.83457 9.57166 10.3279C10.0652 10.8215 10.7342 11.099 11.4319 11.0996ZM10.3129 7.3486C10.6078 7.05376 11.0068 6.887 11.4234 6.88417H11.4319C11.852 6.88417 12.2547 7.05099 12.5517 7.34806L12.5517 7.34807C12.8488 7.64493 13.0156 8.04776 13.0156 8.46764C13.0156 8.88772 12.8488 9.29035 12.5517 9.58741L10.3129 7.3486ZM12.5517 9.58742C12.2547 9.88429 11.852 10.0511 11.4319 10.0511C11.012 10.0511 10.6092 9.88428 10.3124 9.58742C10.0153 9.29036 9.84847 8.88772 9.84847 8.46764H9.79847L12.5517 9.58742Z" />
      <path d="M7.01006 14.1802C7.00985 14.1025 7.0267 14.0256 7.06 13.9549L7.06119 13.9524L7.06126 13.9524C7.48532 13.1685 8.11842 12.5174 8.89028 12.0718C9.66171 11.6263 10.5415 11.4032 11.4318 11.4274L15.8035 14.1802H15.8535V14.1803C15.8535 14.1803 15.8535 14.1804 15.8535 14.1804L15.8535 16.1291C15.8535 16.268 15.7984 16.4014 15.7001 16.4996C15.6019 16.598 15.4685 16.6531 15.3295 16.6531H7.53428C7.39532 16.6531 7.2619 16.598 7.16371 16.4996L7.1991 16.4643L7.16378 16.4997C7.06541 16.4015 7.01006 16.2681 7.01006 16.1291V14.1802ZM7.01006 14.1802V14.1802H7.06006L7.01006 14.1803C7.01006 14.1803 7.01006 14.1803 7.01006 14.1802ZM8.00851 14.2987C8.36071 13.71 8.86368 13.226 9.46558 12.8967C10.0673 12.5674 10.7463 12.4049 11.4319 12.4258C12.1175 12.4045 12.7961 12.5668 13.398 12.8957C13.9999 13.2245 14.5029 13.7081 14.8554 14.2963M8.00851 14.2987V15.6048H8.05851M8.00851 14.2987L8.05142 14.3243C8.05377 14.3204 8.05614 14.3165 8.05851 14.3125M8.00851 14.2987H8.05851V14.3125M8.05851 15.6048V15.6548H14.8055L14.8055 15.6048M8.05851 15.6048H14.8055M8.05851 15.6048V14.3125M14.8055 15.6048H14.8555L14.8554 14.2963M14.8055 15.6048L14.8054 14.3102M14.8554 14.2963L14.8054 14.2963L14.8054 14.3102M14.8554 14.2963L14.8126 14.322C14.8102 14.3181 14.8078 14.3142 14.8054 14.3102M14.8054 14.3102C14.4578 13.7349 13.964 13.2619 13.374 12.9396L13.374 12.9396C12.7799 12.615 12.1101 12.4548 11.4334 12.4758L11.4303 12.4759L11.4304 12.4758C10.7536 12.4551 10.0835 12.6155 9.48958 12.9406L9.48957 12.9406C8.89951 13.2634 8.40578 13.7369 8.05851 14.3125" />
    </svg>
  );
}
