import styled from 'styled-components';
import Select from 'react-select';

export const ContentSection = styled.div`
    display: flex;    
    justify-content: space-between;
`;

export const GeneralSection = styled.div`
    width: 70vw;
    height: 18vw;
    margin: 1rem 0.2rem 1rem 2rem;
    margin-top:5px;
    padding-bottom: 0.5rem;
    padding-left: 2rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.od_bg_secondary};
`;

export const InfoSection = styled.div`
    width: 70vw;
    height: 18vw;
    margin: 1rem 0.5rem 1rem 2rem;    
    margin-top:5px;
    padding-left: 2rem;
    border-radius: 4px;
    background:  ${({ theme }) => theme.colors.od_bg_secondary};
`;

export const HealthCareSection = styled.div` 
    width: 85vw;
    height: 18vw;
    // height: auto;
    margin: 1rem 0.2rem 0rem 2rem;    
    padding-left: 2rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.od_bg_secondary};
`;

export const AdditionalDetailsSection = styled.div` 
    width: 85vw;
    height: 18vw;
    margin: 1rem 0.5rem 1rem 2rem;    
    padding-left: 2rem;
    padding-bottom: 1rem;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.od_bg_secondary};
`;

export const StyledSelect = styled(Select)`
  border: none;
  width: 100%;
  padding: 0.4rem 0px 0.4rem 5px;
  border-radius: 5px;
  .css-yk16xz-control {
    border: none;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-26l3qy-menu {
    margin-top: -3px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 13px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};    
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const DetailsSub = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 20px;
    padding: 13px 0 13px 26px;
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.od_dark_bg};    
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;
export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 10px;
  margin: 25% 0 0 25%;
  border: none;

  img {
    margin: 0 10px 2px 0;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.input-with-label{
    width: 49%;
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  .label {
    font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0;
  }

  div.input-with-full-width {
    width:100%;
     display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
      margin-bottom:1px;
    }
    input, select, textarea {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
    textarea:focus {
      outline: none;
    }
  }

  div.input-with-half-width {
    width:34%;
    height:22%;
    display:flex;
    justify-content:space-between;    
    margin-left: 7px;
    margin-top:15px;
    background: ${({ theme }) => theme.colors.od_neutral};
    border: 1px solid #F2E4D8;
    box-sizing: border-box;
    border-radius: 10px;
    div:first-child {
      padding-top:8px;
      padding-right:5px;
    }
    div.centered-line {
     background: #F2E4D8;
     border: 1px solid #F2E4D8;
    }
    div:last-child {
      padding-top:8px;
      padding-right:8px;
    }
  }

  div.form-control-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type="date"]{
    color: gray;
  }
  select:invalid {
    color: gray;
  }
  input:focus, select:focus{
    outline: none;
  }
  .input, .input-like{
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
    width:103%;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{
	  padding: 4px 8px;
	  margin-bottom: 0;
	}
  .container {
    display: inline-block;
    position: relative;
    width: fit-content;
    padding-left: 35px;
    margin-bottom: 10px;    
    margin-left: 1rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size:16px;
  }
  .container > span:first-child {
    position: relative;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color:${({ theme }) => theme.colors.od_bg_primary};
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background:  ${({ theme }) => theme.colors.od_neutral};
  }    
  }  
`;

export const AssignHouseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.input-label-wrapper{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label, label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper{
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      .icon{
        width: 25px;
        margin-right: 10px;
      }
    }
  }
 
  select:invalid {
    color: gray;
  }
  select:focus{
    outline: none;
  }
`;

export const PhotoWrapper = styled.div`
    width: 85%;
    height: 100%;
    margin-left: -5px;
`;
export const UploadButton = styled.button`
  // color: ${({ theme }) => theme.colors.od_neutral};
  background-color: white;
  border-radius: 10px;
   border: none;
   margin-left: -25px;
   margin-top: 30px;
    height: 30px; 
    weight: 30px;

`;
