/** @format */

import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { BsUpload } from 'react-icons/bs';
import swal from 'sweetalert';
import CustomModal from '../../components/CustomModal';
import {
  compare, checkIfAdminOnly, getUserDetails
} from '../../helpers/utils';
import {
  getHouseList,
  createHouse,
  updateHouse,
  uploadHouseImage,
  getClientListByHouse
} from '../../services/result.services';
import { TableContainer } from '../../styledComponents/common/table.style';
import plusIcon from '../../assets/logo/plus.svg';
import editIcon from '../../assets/logo/edit.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import contactIcon from '../../assets/logo/contact.svg';
import addressIcon from '../../assets/logo/location.svg';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import homeIcon from '../../assets/logo/home.svg';
import {
  HouseWrapper, HouseCard, FormWrapper,
  LoadingModal, HouseList, HouseManagementHeader
} from './index.style';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import NoData from '../../components/NoData';

const AdminHouses = ({ setHouseListChanged }) => {
  const [houseList, setHouseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomModalAddHouse, setShowCustomModalAddHouse] = useState(false);
  const [showCustomModalUpdateHouse, setShowCustomModalUpdateHouse] = useState(false);
  const [showCustomModalUploadImage, setShowCustomModalUploadImage] = useState(false);
  const [labelUploadFileName, setLabelUploadFileName] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [validationMsg, setValidationMsg] = useState('');
  const [clientList, setClientList] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState({});
  const [houseInfo, setHouseInfo] = useState({
    houseCode: '',
    addrLine1: '',
    addrLine2: '',
    city: '',
    state: '',
    phone: '',
    postCode: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setHouseInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const clearInput = () => {
    setHouseInfo({
      houseCode: '',
      addrLine1: '',
      addrLine2: '',
      city: '',
      state: '',
      phone: '',
      postCode: ''
    });
  };

  const clearInputFile = () => {
    setUploadFile(null);
    setLabelUploadFileName('');
    clearInput();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileChange = (e) => {
    if (e.currentTarget) {
      setLabelUploadFileName(
        e.currentTarget.files[0].name || ''
      );
      setUploadFile(e.currentTarget.files[0]);
    }
  };

  const handleUploadImage = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('houseCode', houseInfo.houseCode);
    formData.append('lastUploadedBy', getUserDetails().firstName);
    const resp = await uploadHouseImage(formData);
    if (!resp) {
      clearInputFile();
      setLoading(false);
    } else if (resp.status === 200) {
      clearInputFile();
      setLoading(false);
      swal({
        title: 'House Image uploaded successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalUploadImage(false);
    } else {
      clearInputFile();
      setLoading(false);
      swal({
        title: 'House Image failed to upload!',
        icon: 'error',
        timer: 3000
      });
      setShowCustomModalUploadImage(false);
    }
  };

  const getClientsByHouseHandler = async (house) => {
    setSelectedHouse(house);
    setLoading(true);
    const resp = await getClientListByHouse(house && house.houseCode);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const filteredResp = resp.data.filter((user) => !user.deleted);
      const clientListForHouse = filteredResp && filteredResp.map((ele) => ({
        label: `${ele.name}`,
        value: `${ele.name}`,
        name: 'clientId',
        id: Number(`${ele.id}`)
      }));
      setClientList(clientListForHouse);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-house') {
      setShowCustomModalAddHouse(false);
    } else if (operation === 'update-house') {
      setShowCustomModalUpdateHouse(false);
    }
  };

  const handleEdit = (house) => {
    setValidationMsg('');
    clearInput();
    setHouseInfo(house);
    setShowCustomModalUpdateHouse(true);
  };

  const handleAdd = () => {
    clearInput();
    setShowCustomModalAddHouse(true);
  };
  const checkValidation = () => {
    if (houseInfo.houseCode === '') {
      setValidationMsg('House Code cannot be empty');
      return false;
    }
    if (houseInfo.addrLine1 === '') {
      setValidationMsg('Addr1 cannot be empty');
      return false;
    }
    if (houseInfo.city === '') {
      setValidationMsg('City cannot be empty');
      return false;
    }
    if (houseInfo.state === '') {
      setValidationMsg('State cannot be empty');
      return false;
    }
    if (houseInfo.postCode === '') {
      setValidationMsg('Postcode cannot be empty');
      return false;
    }
    if (houseInfo.postCode !== '') {
      const re = /^[0-9]{4}$/;
      if (!re.test(String(houseInfo.postCode))) {
        setValidationMsg('Enter a valid zip');
        return false;
      }
    }
    if (houseInfo.phone !== '') {
      const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      if (!re.test(String(houseInfo.phone))) {
        setValidationMsg('Enter a valid phone number');
        return false;
      }
    }
    setValidationMsg('');
    return true;
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    setLoading(true);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      if (checkIfAdminOnly()) {
        setHouseList(filteredActiveHouses.sort(compare));
      } else {
        const filteredHouses = filteredActiveHouses.filter(
          (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
        );
        setHouseList(filteredHouses.sort(compare));
      }
    } else {
      setLoading(false);
    }
  };
  const handleDeleteHouse = async (house) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await updateHouse(house.id, { ...house, deleted: true });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        const userDetails = getUserDetails();
        const updatedHouseList = userDetails
          .houseList.filter((houseCode) => houseCode !== house.houseCode);
        localStorage.setItem('user-details', JSON.stringify({
          ...userDetails,
          houseList: updatedHouseList
        }));
        setHouseListChanged((prevState) => !prevState);
        setLoading(false);
        swal({
          title: 'House deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        setSelectedHouse({});
        getHousesHandler();
      } else {
        setLoading(false);
        swal({
          title: 'House deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const handleHouseAdd = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await createHouse(houseInfo);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const userDetails = getUserDetails();
      const updatedHouseList = [...userDetails.houseList, houseInfo.houseCode];
      localStorage.setItem('user-details', JSON.stringify({
        ...userDetails,
        houseList: updatedHouseList
      }));
      setHouseListChanged((prevState) => !prevState);
      setLoading(false);
      swal({
        title: 'House created successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalAddHouse(false);
      getHousesHandler();
      clearInput();
      setValidationMsg('');
    } else if (resp.status === 400 && !resp.data.success) {
      setLoading(false);
      setShowCustomModalAddHouse(false);
      swal({
        title: resp.data.message,
        icon: 'error',
        timer: 3000
      });
    } else {
      setLoading(false);
      setShowCustomModalAddHouse(false);
      clearInput();
      swal({
        title: 'House creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleHouseUpdate = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await updateHouse(houseInfo.id, houseInfo);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'House updated successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalUpdateHouse(false);
      getHousesHandler();
      clearInput();
      setValidationMsg('');
    } else {
      swal({
        title: 'House updation failed!',
        icon: 'error',
        timer: 3000
      });
      setLoading(false);
      clearInput();
      setShowCustomModalUpdateHouse(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getHousesHandler();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    if (Object.keys(selectedHouse).length > 0 && clientList.length > 0) {
      swal({
        title: 'Cannot Delete as active clients present in house!',
        icon: 'error',
        timer: 3000
      });
    }
    if (Object.keys(selectedHouse).length > 0 && clientList.length === 0) {
      handleDeleteHouse(selectedHouse && selectedHouse);
    }
  }, [selectedHouse, clientList]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <img className="home-icon" src={homeIcon} alt="home icon" />
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Admin </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Houses </span>
          </li>
        </ul>
      </BreadCrumb>
      <HouseWrapper>
        <HouseManagementHeader>
          <h2>HOUSE MANAGEMENT</h2>
          {
            checkIfAdminOnly()
             && (
             <button type="button" onClick={() => handleAdd()}>
               <img src={plusIcon} alt="plus icon" />
               Add House
             </button>
             )
          }
        </HouseManagementHeader>
        <TableContainer>
          <HouseList>
            {houseList.length > 0
              && houseList.map((house) => (
                <HouseCard key={house.houseCode}>
                  <div className="house-header">
                    <p data-testid="house-code">
                      {house.houseCode}
                    </p>
                    <div>
                      <button
                        className="upload-btn"
                        type="button"
                        onClick={() => {
                          setShowCustomModalUploadImage(true);
                          setHouseInfo(house);
                        }}
                      >
                        <BsUpload />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleEdit(house)}
                      >
                        <img src={editIcon} alt="edit icon" />
                      </button>
                      { checkIfAdminOnly()
                        && (
                          <button
                            type="button"
                            className="delete-btn"
                            onClick={() => getClientsByHouseHandler(house)}
                          >
                            <img src={deleteIcon} alt="delete icon" />
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="house-body">
                    <div>
                      <img src={addressIcon} alt="address icon" />
                      <div>
                        {`${house.addrLine1}, `}
                        {house.addrLine2 && ` ${house.addrLine2}, `}
                        {` ${house.city}`}
                        ,
                        {` ${house.state}`}
                        ,
                        {` ${house.postCode}`}
                      </div>
                    </div>
                    <div>
                      <img src={contactIcon} alt="contact icon" />
                      <div>
                        {house.phone}
                      </div>
                    </div>
                  </div>
                </HouseCard>
              ))}
          </HouseList>
        </TableContainer>
        {
          houseList.length === 0 && <NoData />
          }
      </HouseWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddHouse}
        setClose={() => {
          handleCloseCustomModal('add-house');
        }}
        title="Add House"
        width="680px"
        onSubmit={handleHouseAdd}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={houseInfo.houseCode}
              name="houseCode"
              placeholder="House Code / Name"
              onChange={handleChange}
            />
            <div className="blank"> </div>
            <input
              type="text"
              value={houseInfo.addrLine1}
              name="addrLine1"
              placeholder="Address 1"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.addrLine2}
              name="addrLine2"
              placeholder="Address 2"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.city}
              name="city"
              placeholder="City"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.state}
              name="state"
              placeholder="State"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.postCode}
              name="postCode"
              placeholder="Postcode"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
            />
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateHouse}
        setClose={() => {
          handleCloseCustomModal('update-house');
        }}
        title="Edit House"
        width="680px"
        onSubmit={handleHouseUpdate}
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <input
              type="text"
              value={houseInfo.houseCode}
              name="houseCode"
              placeholder="House Code / Name"
              onChange={handleChange}
              disabled
            />
            <div className="blank"> </div>
            <input
              type="text"
              value={houseInfo.addrLine1}
              name="addrLine1"
              placeholder="Address 1"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.addrLine2}
              name="addrLine2"
              placeholder="Address 2"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.city}
              name="city"
              placeholder="City"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.state}
              name="state"
              placeholder="State"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.postCode}
              name="postCode"
              placeholder="Postcode"
              onChange={handleChange}
            />
            <input
              type="text"
              value={houseInfo.phone}
              name="phone"
              placeholder="Phone"
              onChange={handleChange}
            />
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUploadImage}
        setClose={() => {
          setShowCustomModalUploadImage(false);
          clearInputFile();
        }}
        title="Upload House Image"
        width="580px"
        onSubmit={() => handleUploadImage()}
        operationName="Upload"
        isDisabled={!uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <p className="label">Select File</p>
              <div className="icon-name-wrapper">
                <label htmlFor="upload_house_image">
                  <BsUpload />
                  <input
                    type="file"
                    id="upload_house_image"
                    name="upload_house_image"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleFileChange}
                  />
                  <span className="ellipsis">
                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
    </>
  );
};

AdminHouses.propTypes = {
  setHouseListChanged: PropTypes.func.isRequired
};

export default AdminHouses;
