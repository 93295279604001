/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Modal, Spinner
} from 'react-bootstrap';
import swal from 'sweetalert';
import CustomModal from '../../components/CustomModal';
import {
  getUserDetails, checkValidationAddClients, getAgeFromDOB, checkIfHouseAdmin
} from '../../helpers/utils';
import { clientDetailsObj } from '../../lookups/lookups';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  TableWrapper, StyledTable, Th, Td, TableContainer
} from '../../styledComponents/common/table.style';
import {
  FormWrapper, IconContainer
} from './index.style';
import {
  BreadCrumb, PageWrapper, PageHeader, PageSubHeader,
  Pagination
} from '../../styledComponents/common/page.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import caretLeftSmallIcon from '../../assets/logo/caret_left_small.svg';
import caretRightSmallIcon from '../../assets/logo/caret_right_small.svg';
import detailIcon from '../../assets/logo/detail.svg';
import plusIcon from '../../assets/logo/plus.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import {
  addClient,
  updateClient,
  getClientList
} from '../../services/result.services';
import NoData from '../../components/NoData';

const AdminClients = () => {
  const history = useHistory();
  const tableBodyRef = useRef('');
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomModalAddClient, setShowCustomModalAddClient] = useState(false);
  const [validationMsgAddClient, setValidationMsgAddClient] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [editableClientList, setEditableClientList] = useState([]);
  const [clientDetails, setClientDetails] = useState(clientDetailsObj);
  const [clientListToBeDisplayed, setClientListToBeDisplayed] = useState([]);
  const [pageCount, setPageCount] = useState(10);
  const [totalClientCount, setTotalClientCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  let PER_PAGE = 10;
  if (checkIfHouseAdmin()) {
    PER_PAGE = 1000;
  }

  const clearInput = () => {
    setClientDetails(clientDetailsObj);
  };

  const handleShowCustomModal = (operation) => {
    if (operation === 'add-client') {
      setShowCustomModalAddClient(true);
    }
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-client') {
      setShowCustomModalAddClient(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const getClientsHandler = async (payload) => {
    setLoading(true);
    const resp = await getClientList(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const { clients, totalClients } = resp.data;
      setEditableClientList(clients);
      const modifiedResp = clients.map((ele) => (
        { ...ele, dob: ele.dob && getAgeFromDOB(ele.dob) }
      ));
      let filteredClients;
      if (checkIfHouseAdmin()) {
        filteredClients = modifiedResp.filter(
          (client) => getUserDetails().houseList.some(
            (houseCode) => ((houseCode === client.house))
          )
        );
      } else {
        filteredClients = modifiedResp;
      }
      setClientsList(filteredClients);
      setClientListToBeDisplayed(filteredClients);
      setTotalClientCount(totalClients);
      setPageCount(Math.ceil(totalClients / PER_PAGE));
    } else {
      setLoading(false);
    }
  };
  const handlePageChange = (data) => {
    const { selected } = data;
    setCurrentPage(selected);
    getClientsHandler({ pageSize: PER_PAGE, pageNumber: selected });
  };

  const handleAddClient = () => {
    handleShowCustomModal('add-client');
    clearInput();
  };

  const addClientsHandler = async () => {
    if (!checkValidationAddClients(clientDetails, setValidationMsgAddClient)) return;
    setLoading(true);
    const resp = await addClient(clientDetails);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      if (Number.isInteger(totalClientCount / PER_PAGE)) {
        await getClientsHandler({ pageSize: PER_PAGE, pageNumber: pageCount });
        setCurrentPage(pageCount);
      } else {
        await getClientsHandler({ pageSize: PER_PAGE, pageNumber: pageCount - 1 });
        setCurrentPage(pageCount - 1);
      }
      handleCloseCustomModal('add-client');
      swal({
        title: 'Client created successfully!',
        icon: 'success',
        timer: 3000
      });
      clearInput();
    } else {
      setLoading(false);
      swal({
        title: `${resp.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: { confirm: 'ok' }
      });
    }
  };

  const handleDeleteClient = async (client) => {
    const res = editableClientList.filter((item) => item.id === client.id);
    const value = await swal({
      text: `Are you sure you want to delete ${client.name}?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    const { house, ...rest } = res;
    if (value) {
      setLoading(true);
      const resp = await updateClient({ ...rest[0], deleted: true });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: `${client.name} deleted successfully!`,
          icon: 'success',
          timer: 3000
        });
        await getClientsHandler({ pageSize: PER_PAGE, pageNumber: currentPage });
      } else {
        setLoading(false);
        swal({
          title: 'Client deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const handleClientDetails = (client) => {
    history.push('/admin/client/details', client);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    setLoading(true);
    getClientsHandler({ pageSize: PER_PAGE, pageNumber: 0 });
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);
  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Clients </span>
          </li>
        </ul>
      </BreadCrumb>
      <PageWrapper>
        <PageHeader>
          <h2>CLIENTS</h2>
          <button type="button" onClick={() => handleAddClient()}>
            <img src={plusIcon} alt="plus icon" />
            Add Client
          </button>
        </PageHeader>
        <PageSubHeader>
          {checkIfHouseAdmin() ? null
            : (
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePageChange}
                forcePage={currentPage}
                previousLabel={<img className="caret-icon" src={caretLeftSmallIcon} alt="caret icon" />}
                nextLabel={<img className="caret-icon" src={caretRightSmallIcon} alt="caret icon" />}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
              />
            )}
        </PageSubHeader>
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${
                  isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                {
                  clientsList.length !== 0
                && (
                <tr>
                  <Th width="40px">No.</Th>
                  <Th width="100px">Name</Th>
                  <Th width="40px">M / F</Th>
                  <Th width="40px">Age</Th>
                  <Th width="160px">Address</Th>
                  <Th width="120px">Phone</Th>
                  <Th width="140px">Email</Th>
                  <Th width="70px">House</Th>
                  <Th width="60px" align="right">Action</Th>
                  <Th width="40px" align="left" />
                </tr>
                )
                }
              </thead>
              <tbody ref={tableBodyRef}>
                { clientListToBeDisplayed.length > 0
                  && clientListToBeDisplayed.map((client, index) => (
                    <tr key={client.id}>
                      <Td width="40px">{index + 1}</Td>
                      <Td width="100px" data-testid="clientName">{client.name}</Td>
                      <Td width="40px">{client.gender}</Td>
                      <Td width="40px">{client.dob}</Td>
                      <Td width="160px">
                        {`${client.addrLine1}, `}
                        {client.addrLine2 && `${client.addrLine2}, `}
                        {client.city}
                      </Td>
                      <Td width="120px">{client.phone}</Td>
                      <Td width="140px">{client.email}</Td>
                      <Td width="90px">{client.house}</Td>
                      <Td width="40px" align="right">
                        <IconContainer onClick={() => handleClientDetails(client)}>
                          <img src={detailIcon} alt="detail icon" />
                        </IconContainer>
                      </Td>
                      <Td width="40px" align="left">
                        <IconContainer
                          className="delete-btn"
                          onClick={() => handleDeleteClient(client)}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </IconContainer>
                      </Td>
                    </tr>
                  ))}
                {
                  clientListToBeDisplayed.length === 0 && <NoData />
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        </TableContainer>
      </PageWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddClient}
        setClose={() => {
          handleCloseCustomModal('add-client');
        }}
        title="Add Client"
        width="850px"
        onSubmit={addClientsHandler}
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-label">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                required
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleChange}
                value={clientDetails.name}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="gender">Gender</label>
                <select
                  onChange={handleChange}
                  required
                  id="gender"
                  name="gender"
                  value={clientDetails.gender}
                  data-testid="gender"
                  placeholder="gender"
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>
              <div className="input-with-label">
                <label htmlFor="dob">DOB</label>
                <input
                  type="date"
                  id="dob"
                  placeholder="DOB"
                  name="dob"
                  value={clientDetails.dob}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                name="email"
                value={clientDetails.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone">2 digit area code, 8 digit phone number</label>
              <input
                placeholder="Phone Number"
                id="phone"
                name="phone"
                value={clientDetails.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="Address1">Address 1</label>
              <input
                id="address1"
                type="text"
                placeholder="Address 1"
                name="addrLine1"
                value={clientDetails.addrLine1}
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                id="address2"
                placeholder="Address 2"
                name="addrLine2"
                value={clientDetails.addrLine2}
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={clientDetails.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state"
                  value={clientDetails.state}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="zip">Postcode</label>
              <input
                type="text"
                id="zip"
                placeholder="Postcode"
                name="postCode"
                value={clientDetails.postCode}
                onChange={handleChange}
                required
              />
            </div>
          </FormWrapper>
          { validationMsgAddClient !== '' && <h6 className="m-3 text-danger" data-testid="validation-add-client">{validationMsgAddClient}</h6>}
        </div>
      </CustomModal>
    </>
  );
};

export default AdminClients;
