import jsPDF from 'jspdf';
import moment from 'moment';

const generatePDFIncident = (incident, reviewData, editReviewData, isOpen) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.setFontSize(16);
  doc.setFont('helvetica');
  doc.text(
    'INCIDENT',
    340,
    45,
    null
  );
  doc.setFontSize(12);
  doc.setFont('helvetica');
  doc.text(
    'Incident Number',
    30,
    75,
    null
  );
  doc.text(
    `${incident.id}`,
    30,
    100,
    null
  );
  doc.text(
    'Category',
    30,
    135,
    null
  );
  doc.text(
    'Type',
    285,
    135,
    null
  );
  doc.text(
    'Classification',
    540,
    135,
    null
  );
  doc.roundedRect(
    30,
    145,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.category.name}`,
    35,
    170,
    null
  );
  doc.roundedRect(
    285,
    145,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.type.name}`,
    290,
    170,
    null
  );
  doc.roundedRect(
    540,
    145,
    220,
    40,
    null,
    null
  );
  if (incident.classification?.name?.length > 20) {
    doc.setFontSize(10);
  }
  doc.text(
    `${incident.classification.name}`,
    545,
    170,
    null
  );
  doc.setFontSize(12);
  doc.text(
    'Client Name',
    30,
    220,
    null
  );
  doc.text(
    'Witness Name',
    285,
    220,
    null
  );
  doc.text(
    'Witness Designation',
    540,
    220,
    null
  );
  doc.roundedRect(
    30,
    230,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.client.name}`,
    35,
    255,
    null
  );
  doc.roundedRect(
    285,
    230,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.witnessName}`,
    290,
    255,
    null
  );
  doc.roundedRect(
    540,
    230,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.witnessDesignation}`,
    545,
    255,
    null
  );
  doc.text(
    'Reportable to NDIS',
    30,
    305,
    null
  );
  doc.text(
    'Reportable to Worksafe',
    285,
    305,
    null
  );
  doc.text(
    'Date and Time of Occurence',
    540,
    305,
    null
  );
  doc.roundedRect(
    30,
    315,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.reportableToNDIS}`,
    35,
    340,
    null
  );
  doc.roundedRect(
    285,
    315,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.reportableToWorksafe}`,
    290,
    340,
    null
  );
  doc.roundedRect(
    540,
    315,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${moment(incident.dateOccurred).format('DD/MM/YYYY, hh:mm:ss')}`,
    545,
    340,
    null
  );
  doc.text(
    'Before Incident',
    30,
    390,
    null
  );
  doc.text(
    'Description',
    285,
    390,
    null
  );
  doc.text(
    'Immediate Action',
    540,
    390,
    null
  );
  doc.roundedRect(
    30,
    400,
    220,
    80,
    null,
    null
  );
  doc.text(
    `${incident.beforeIncident}`,
    35,
    425,
    {
      maxWidth: '200'
    },
    null
  );
  doc.roundedRect(
    285,
    400,
    220,
    80,
    null,
    null
  );
  doc.text(
    `${incident.beforeIncident}`,
    295,
    425,
    {
      maxWidth: '200'
    },
    null
  );
  doc.roundedRect(
    540,
    400,
    220,
    80,
    null,
    null
  );
  doc.text(
    `${incident.beforeIncident}`,
    545,
    425,
    {
      maxWidth: '200'
    },
    null
  );
  doc.text(
    'Police Report',
    30,
    515,
    null
  );
  doc.text(
    'Police Name',
    285,
    515,
    null
  );
  doc.text(
    'Police Number',
    540,
    515,
    null
  );
  doc.roundedRect(
    30,
    525,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.policeReport}`,
    35,
    550,
    {
      maxWidth: '200'
    },
    null
  );
  doc.roundedRect(
    285,
    525,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.policeReport === 'Yes' ? incident.policeName : ' '}`,
    295,
    550,
    {
      maxWidth: '200'
    },
    null
  );
  doc.roundedRect(
    540,
    525,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.policeReport === 'Yes' ? incident.policeNumber : ' '}`,
    545,
    550,
    {
      maxWidth: '200'
    },
    null
  );
  doc.text(
    'Police Station',
    30,
    600,
    null
  );
  doc.text(
    'Reporting Date',
    285,
    600,
    null
  );
  doc.text(
    'Location',
    540,
    600,
    null
  );
  doc.roundedRect(
    30,
    610,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.policeReport === 'Yes' ? incident.policeStation : ' '}`,
    35,
    635,
    null
  );
  doc.roundedRect(
    285,
    610,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${moment(incident.createdAt).format('DD/MM/YYYY')}`,
    290,
    635,
    null
  );
  doc.roundedRect(
    540,
    610,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.location}`,
    545,
    635,
    null
  );
  doc.text(
    'Exact Location',
    30,
    685,
    null
  );
  doc.text(
    'Reporting Person First Name',
    285,
    685,
    null
  );
  doc.text(
    'Reporting Person Last Name',
    540,
    685,
    null
  );
  doc.roundedRect(
    30,
    695,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.exactLocation}`,
    35,
    720,
    null
  );
  doc.roundedRect(
    285,
    695,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.reportedBy.firstName}`,
    290,
    720,
    null
  );
  doc.roundedRect(
    540,
    695,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.reportedBy.lastName}`,
    545,
    720,
    null
  );
  doc.text(
    'Affected Person First Name',
    30,
    770,
    null
  );
  doc.text(
    'Affected Person Last Name',
    285,
    770,
    null
  );
  doc.text(
    'Escalated To',
    540,
    770,
    null
  );
  doc.roundedRect(
    30,
    780,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.injuredGivenName}`,
    35,
    805,
    null
  );
  doc.roundedRect(
    285,
    780,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.injuredFamilyName}`,
    290,
    805,
    null
  );
  doc.roundedRect(
    540,
    780,
    220,
    40,
    null,
    null
  );
  doc.text(
    `${incident.escalatedTo ? incident.escalatedTo.email : 'Not Escalated'}`,
    545,
    805,
    null
  );
  if (isOpen) {
    doc.addPage();
    doc.setFontSize(16);
    doc.setFont('helvetica');
    doc.text(
      'REVIEW - 1',
      340,
      65,
      null
    );
    doc.setFontSize(12);
    doc.setFont('helvetica');
    doc.text(
      'Is the incident reportable to NDIS ?',
      30,
      135,
      {
        maxWidth: '200'
      },
      null
    );
    doc.text(
      'Is the incident reportable to WorkSafe ?',
      285,
      135,
      {
        maxWidth: '200'
      }
    );
    doc.text(
      'Has there been a breach of Code of Conduct (NDIS)?',
      540,
      135,
      {
        maxWidth: '200'
      }
    );
    doc.roundedRect(
      30,
      165,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${incident.reportableToNDIS}`,
      35,
      190,
      {
        maxWidth: '200'
      },
      null
    );
    doc.roundedRect(
      285,
      165,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${incident.reportableToWorksafe}`,
      290,
      190,
      {
        maxWidth: '200'
      },
      null
    );
    doc.roundedRect(
      540,
      165,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.codeBreach : editReviewData.codeBreach}`,
      545,
      190,
      {
        maxWidth: '200'
      },
      null
    );
    doc.text(
      'Does the participants behaviour Support Plan or Risk Assessment require updating?',
      30,
      230,
      {
        maxWidth: '330'
      },
      null
    );
    doc.text(
      'Impact on the participant. Does the participant require any counselling or therapy? Does the particpants family require further support or information?',
      410,
      230,
      {
        maxWidth: '330'
      }
    );
    doc.roundedRect(
      30,
      300,
      350,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.supportPlanUpdate : editReviewData.supportPlanUpdate}`,
      35,
      325,
      {
        maxWidth: '200'
      },
      null
    );
    doc.roundedRect(
      410,
      300,
      350,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.furtherSupport : editReviewData.furtherSupport}`,
      415,
      325,
      {
        maxWidth: '200'
      },
      null
    );
    doc.text(
      'What follow up is required for the support worker and other perople involved? Was anyone injured ? Do they require EAP ?',
      30,
      370,
      {
        maxWidth: '330'
      },
      null
    );
    doc.text(
      'How could the incident have been prevented or the impact of the incident reduced ?',
      410,
      370,
      {
        maxWidth: '330'
      }
    );
    doc.roundedRect(
      30,
      420,
      355,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.followUp : editReviewData.followUp}`,
      35,
      445,
      {
        maxWidth: '330'
      },
      null
    );
    doc.roundedRect(
      410,
      420,
      355,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.howPrevented : editReviewData.howPrevented}`,
      415,
      445,
      {
        maxWidth: '330'
      },
      null
    );
    doc.setFontSize(16);
    doc.setFont('helvetica');
    doc.text(
      'REVIEW - 2',
      340,
      530,
      null
    );
    doc.setFontSize(12);
    doc.setFont('helvetica');
    doc.text(
      'Risk Rating',
      30,
      570,
      {
        maxWidth: '200'
      },
      null
    );
    doc.text(
      'Consequences',
      285,
      570,
      {
        maxWidth: '200'
      }
    );
    doc.text(
      'Likelihood',
      540,
      570,
      {
        maxWidth: '200'
      }
    );
    doc.roundedRect(
      30,
      580,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.riskRating : editReviewData.riskRating}`,
      35,
      605,
      null
    );
    doc.roundedRect(
      285,
      580,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? (reviewData.consequences === '' ? reviewData.consequences : reviewData.consequences[0].toUpperCase()
      + reviewData.consequences.substring(1)) : (editReviewData.consequences[0].toUpperCase()
      + editReviewData.consequences.substring(1))}`,
      290,
      605,
      null
    );
    doc.roundedRect(
      540,
      580,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${
        !Object.keys(editReviewData).length > 0 ? (reviewData.likelihood === '' ? reviewData.likelihood : reviewData.likelihood[0].toUpperCase()
      + reviewData.likelihood.substring(1)) : (editReviewData.likelihood[0].toUpperCase()
      + editReviewData.likelihood.substring(1))}`,
      545,
      605,
      null
    );
    doc.text(
      'Corrective Action',
      30,
      655,
      {
        maxWidth: '200'
      },
      null
    );
    doc.text(
      'Due Date',
      285,
      655,
      {
        maxWidth: '200'
      }
    );
    doc.text(
      'Follow Up Responsibility',
      540,
      655,
      {
        maxWidth: '200'
      }
    );
    doc.roundedRect(
      30,
      665,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.correctiveAction : editReviewData.correctiveAction}`,
      35,
      690,
      null
    );
    doc.roundedRect(
      285,
      665,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? '' : moment(editReviewData.dueDate).format('DD/MM/YYYY, hh:mm:ss')}`,
      290,
      690,
      null
    );
    doc.roundedRect(
      540,
      665,
      220,
      40,
      null,
      null
    );
    doc.text(
      `${incident.followUpResponsibility}`,
      545,
      690,
      null
    );
    doc.text(
      'Description (Actions to be implemented)',
      30,
      740,
      {
        maxWidth: '300'
      },
      null
    );
    doc.roundedRect(
      30,
      750,
      355,
      80,
      null,
      null
    );
    doc.text(
      `${!Object.keys(editReviewData).length > 0 ? reviewData.actionsToImplement : editReviewData.actionsToImplement}`,
      35,
      775,
      null
    );
  }
  // doc.output('dataurlnewwindow');
  doc.save(`Incident-${incident.id}`);
};

export default generatePDFIncident;
