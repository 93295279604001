import React from 'react';

export default function DetailsIcon() {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.875083 21.1667C0.871378 21.0808 0.863969 20.9943 0.863969 20.9085C0.863352 13.0215 0.864587 8.15903 0.857178 0.272712C0.857178 0.0359897 0.922007 -0.000623331 1.13502 7.928e-06C5.62303 0.00695177 10.1104 0.00632053 14.5985 0.00127046C14.7658 0.00127046 14.8818 0.0492462 15.0004 0.170448C16.284 1.48473 17.5726 2.79459 18.8636 4.1013C18.9587 4.19725 19 4.29131 19 4.42829C18.9969 10.9612 18.9976 14.4703 18.9969 21.0025C18.9969 21.0575 18.9895 21.1117 18.9858 21.1667C12.9481 21.1667 6.91159 21.1667 0.875083 21.1667ZM2.24946 10.5862C2.24946 14.0733 2.25069 16.0484 2.24514 19.5349C2.24514 19.7091 2.28218 19.7539 2.45691 19.7539C7.43763 19.7482 12.4183 19.7482 17.3991 19.7533C17.567 19.7533 17.6145 19.7198 17.6145 19.538C17.609 14.0442 17.609 11.5735 17.6152 6.07966C17.6152 5.87829 17.5454 5.85872 17.3818 5.85935C16.0574 5.86503 14.7337 5.85872 13.4093 5.86693C13.2222 5.86819 13.166 5.82905 13.1673 5.62642C13.1772 4.30393 13.1679 2.98144 13.1772 1.65959C13.1784 1.4639 13.1339 1.41655 12.9407 1.41718C9.4535 1.42413 5.96632 1.42413 2.47852 1.41718C2.28403 1.41655 2.24267 1.46642 2.24328 1.66022C2.25131 5.13972 2.24946 7.10731 2.24946 10.5862ZM17.1095 4.44596C16.2815 3.57861 15.4289 2.68538 14.5589 1.77385C14.5589 2.63741 14.5608 3.48329 14.5571 4.32918C14.5565 4.45101 14.6219 4.44723 14.7034 4.4466C15.0029 4.4447 15.3029 4.44596 15.6024 4.44596C16.1297 4.44596 16.6563 4.44596 17.1095 4.44596Z" />
      <path d="M9.92722 10.5882C11.3721 10.5882 12.817 10.5915 14.2619 10.5835C14.419 10.5828 14.4717 10.6243 14.4635 10.8251C14.4487 11.1745 14.4509 11.5258 14.4629 11.8759C14.469 12.0506 14.4294 12.0954 14.2849 12.0947C12.8675 12.0874 11.4501 12.0907 10.0327 12.0907C8.55376 12.0907 7.07482 12.0874 5.59533 12.0954C5.43821 12.0961 5.38546 12.0546 5.39371 11.8538C5.40854 11.5044 5.40634 11.1531 5.39426 10.803C5.38821 10.6283 5.42777 10.5835 5.57226 10.5842C7.02373 10.5909 8.47575 10.5882 9.92722 10.5882Z" />
      <path d="M9.93025 15.1141C8.48531 15.1141 7.04037 15.1108 5.59543 15.1188C5.4383 15.1195 5.38501 15.078 5.3938 14.8772C5.40864 14.5279 5.40644 14.1765 5.39435 13.8265C5.38831 13.6518 5.42787 13.6069 5.57236 13.6076C6.97609 13.615 8.37982 13.6116 9.78355 13.6116C11.2763 13.6116 12.769 13.615 14.2618 13.6069C14.4189 13.6063 14.4722 13.6478 14.4634 13.8485C14.4486 14.1979 14.4508 14.5493 14.4628 14.8993C14.4689 15.074 14.4293 15.1188 14.2848 15.1182C12.8339 15.1115 11.3818 15.1141 9.93025 15.1141Z" />
      <path d="M7.64334 9.06673C6.95211 9.06673 6.26088 9.06137 5.5702 9.07142C5.42415 9.07343 5.38792 9.02386 5.39341 8.85104C5.40494 8.48396 5.40109 8.11622 5.39505 7.74914C5.39286 7.62053 5.40549 7.55891 5.53396 7.55958C6.95046 7.5656 8.36696 7.56493 9.78346 7.56025C9.9026 7.55958 9.9306 7.60446 9.92786 7.74177C9.92072 8.1256 9.91962 8.51009 9.92841 8.89391C9.9317 9.03993 9.89272 9.07142 9.77962 9.07008C9.06698 9.06405 8.35543 9.06673 7.64334 9.06673Z" />
    </svg>
  );
}
