import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Spinner } from 'react-bootstrap';
import { listUserAllocation } from '../../services/result.services';
import MoreIconRoster from '../../assets/logo/roster_more_icon.svg';
import rosterUserIcon from '../../assets/logo/rosterUser.svg';
import {
  GreenCell, TextUserWrapper, ShiftUserText, DropdownWrapper, DropdownToggle, DropdownMenu,
  TimeUserText
} from './index.style';

const AdminRosterUserAllocation = ({
  userName, userId, setSelectedModal, setSelectedUser,
  handleSelectAllocation, setLoading, startDate, endDate, currentDate
}) => {
  const [userAllocation, setUserAllocation] = useState([]);
  const [load, setLoad] = useState(false);
  useEffect(async () => {
    const payload = {
      startDate,
      endDate
    };
    setLoad(true);
    try {
      const resp = await listUserAllocation(userId, payload);
      if (!resp) {
        setLoading(false);
        setLoad(false);
      }
      if (resp.status === 200) {
        const filtered = resp.data.filter((item) => item.date === currentDate);
        setUserAllocation(filtered);
        setLoad(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoad(false);
    }
  }, []);
  return (
    <>
      {' '}
      {load ? (
        <Spinner
          as="span"
          animation="border"
          variant="primary"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%'
          }}
        />
      ) : null}
      {userAllocation.length > 0 && userAllocation.map((allocation) => (
        <GreenCell key={`${Math.random(200)}-${allocation.id}`}>
          <DropdownWrapper>
            <DropdownToggle variant="borderless-dark" className="dropdown-toggle-options">
              <img width={100} height={100} src={MoreIconRoster} alt="more-icon" />
            </DropdownToggle>
            <DropdownMenu right>
              <Dropdown.Item onClick={() => { setSelectedModal('remove-modal'); handleSelectAllocation(allocation); }}>
                Remove
              </Dropdown.Item>
              <Dropdown.Item onClick={() => { setSelectedModal('actual-time-spent'); setSelectedUser(userName); handleSelectAllocation(allocation); }}>
                Actual Time Spend
              </Dropdown.Item>
            </DropdownMenu>
          </DropdownWrapper>
          <TextUserWrapper>
            <ShiftUserText>
              <img src={rosterUserIcon} alt="more-icon" />
                            &nbsp;&nbsp;
              {allocation.shiftClientShiftsClientName}

            </ShiftUserText>
            <TimeUserText>
              {moment(allocation.shiftClientShiftsStartTime).format('HH:mm:ss A')}
              {' '}
              -
              {' '}
              {moment(allocation.shiftClientShiftsEndTime).format('HH:mm:ss A')}
            </TimeUserText>
          </TextUserWrapper>
        </GreenCell>
      ))}
    </>
  );
};

AdminRosterUserAllocation.propTypes = {
  userName: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  currentDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  handleSelectAllocation: PropTypes.func.isRequired
};

export default AdminRosterUserAllocation;
