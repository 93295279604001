import styled from 'styled-components';
import { Popover, Dropdown } from 'react-bootstrap';
import Select from 'react-select';

export const RosterWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  min-height: calc(75vh - 120px);
  img {
    width: 12px;
    height: auto;
  }
  .dropdown-toggle-options::after {
    content:none;
   }
`;

export const RosterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;
  div {
    display: flex;
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    margin: 0 20px;
    border: none;
  
    img {
      margin: 0 10px 2px 0;
    }
  }
  div {
display: flex;
  }
`;

export const RosterNavLink = styled.div`
  display: flex;
  font-size: 20px;
  padding: 20px;
  padding-bottom: ${({ selected }) => (selected ? '16px' : '20px')};
  opacity: ${({ selected }) => (selected ? '1' : '0.3')};
  color: ${({ selected, theme }) => (selected ? theme.colors.od_secondary : '#1A2F50')};
  border-bottom: ${({ selected, theme }) => (selected ? `4px solid ${theme.colors.od_secondary}` : 'none')}};
 
`;

export const DateHeader = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: auto;
  background-color: ${({ theme }) => theme.colors.od_date_header};
  h6 {
    padding-top: 10px;
  }
  button {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: none;
    padding: 0px 4px;
    color: ${({ theme }) => theme.colors.od_secondary};
    background-color: ${({ theme }) => theme.colors.od_neutral};
    margin: 0.25rem 1rem;
    vertical-align: middle;
  }
  button:hover {
    background-color: ${({ theme }) => theme.colors.od_primary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }
`;

export const RosterBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 85vh;
  overflow: scroll;

  div {
    width: 100%;
  }
  thead {
    height: 85px;
  }
  table {
    width: 100%
  },
  th,
  td {
    border: 1px solid #e8e8e8;
    border-collapse: collapse;
    text-align: center;
    .add-icon {
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #74bca9;
      width: 40px;
      height: 40px;
      margin: auto;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  table {
    width: 100%;
  }
  th {
    padding: 15px 10px;
    font-size: 18px;
  }
`;

export const StyledInputDiv = styled.div`
  height: 100%;
  margin: auto;
  display: flex;
  border-radius: 5px;
  justify-content: flex-start;
  padding: 5px;
  padding-left: 14px;
  box-shadow: 0 0 3px 1px #dedede;
  img {
    width: 30px;
    height: 30px;
  }
  input {
    border: none;
    width: 100%;
    padding-left: 8px;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    opacity: 0.5;
    font-size: 18px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.blank {
    width: 47%;
  }
  h4 {
    text-align: center;
  }
  input {
    border: none;
    width: 80%;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 0.4rem 0.5rem;
  }
  input:focus {
    outline: none;
  }
  div.input-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.big_font};
      margin: 0;
      padding: 0 0.5rem;
    }
    input,
    select {
      width: 98%;
      padding: 1rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
    textarea {
      border: none;
      margin: 5px 9px;
      border-radius: 5px;
      padding: 10px;
    }
  }
  th {
    padding-left: 10px;
  }
  .table-input {
    width: 93%;
    padding: 18px 10px;
  }
  .inline-field {
    display: flex;
    width: 100%;
    justify-content: space-between;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.big_font};
      padding-left: 10px;
      margin-bottom: 0px;
    }
    .input {
      width: 300px;
      input {
        width: 95%;
        padding: 15px 8px;
      }
    }
  }
  .delete-btn {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  border: none;
  width: 99%;
  padding: 0.4rem 0px 0.4rem 5px;
  border-radius: 5px;
  .css-yk16xz-control {
    border: none;
  }
  .css-1pahdxg-control {
    border: none;
    box-shadow: none;
  }
  .css-26l3qy-menu {
    margin-top: -3px;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
`;

export const ModalClientName = styled.div`
  border: 1px solid rgba(134, 81, 137, 0.35);
  padding: 10px;
  background-color: rgba(134, 81, 137, 0.1);
  border-radius: 10px;
  color: #865189;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: ${({ noSeperator }) => (noSeperator ? 'none' : 'linear-gradient(rgba(134, 81, 137, 0.35), rgba(134, 81, 137, 0.35))')};
  background-size: ${({ noSeperator }) => (noSeperator ? 'none' : '1px 100%')};
  background-repeat: ${({ noSeperator }) => (noSeperator ? 'none' : 'no-repeat')};
  background-position: ${({ noSeperator }) => (noSeperator ? 'none' : 'center center')};
  button{
    border: none;
    border-radius: 50%;
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
  }
  button.action{
    color: #fff;
    background-color: #E86B55;
  }
`;
export const IconContainer = styled.div`
  // margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border: none;
  height: 30px;
  text-align: center;
  img {
    width: 19px !important;
    height: auto;
    margin-left: 0;
  }
`;

export const ResetIconContainer = styled.img`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  margin-left: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  }
`;

export const TableContainer = styled.div`
  margin: 0 2.5rem 1rem;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
  }

  tbody{
    min-height: calc(60vh - 120px);
    max-height: calc(80vh - 120px);
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    tr {
      border: 1px solid #E6E9F4;
      border-radius: 10px;
      box-sizing: border-box;
      margin: 0 5px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top:5px;
  }
`;
export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;

export const GreenCell = styled.div`
background-color: ${({ theme }) => theme.colors.od_primary_transparent};
border-left: 3px solid ${({ theme }) => theme.colors.od_primary};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
height: 50px;`;

export const GreenCellBorder = styled.div`
background-color: ${({ theme }) => theme.colors.od_primary_transparent};
border-left: 3px solid ${({ theme }) => theme.colors.od_primary};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
border: 1.5px dashed ${({ theme }) => theme.colors.od_secondary};
height: 50px;`;

export const PurpleCell = styled.div`
background-color: ${({ theme }) => theme.colors.od_light_extra};
border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
height: 50px;`;

export const PurpleCellBorder = styled.div`
background-color: ${({ theme }) => theme.colors.od_light_extra};
border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
border: 1.5px dashed ${({ theme }) => theme.colors.od_secondary};
margin: 2px;
height: 50px;`;

export const OrangeCell = styled.div`
background-color: ${({ theme }) => theme.colors.od_bg_secondary};
border-left: 3px solid ${({ theme }) => theme.colors.od_secondary};
border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
margin: 2px;
height: 50px;`;

export const EmptyCell = styled.div`
height: 50px;`;

export const TextWrapper = styled.div`
padding: 1px 5px;
`;

export const ShiftText = styled.div`
text-align: left;
font-weight: 700;
font-size: 11px;
display: flex;
justify-content: space-between;
div {
  width: 90%;
}
`;

export const TimeText = styled.div`
text-align: left;
font-weight: 600;
font-size: 10px;
color: ${({ theme }) => theme.colors.od_placeholder}`;

export const TextUserWrapper = styled.div`
padding: 1px 5px;
margin-top: -10px;
`;

export const ShiftUserText = styled.div`
text-align: left;
font-weight: 800;
font-size: 13px;
img{
  width: 23px;
}
`;

export const TimeUserText = styled.div`
text-align: left;
font-weight: 700;
font-size: 11px;
color: ${({ theme }) => theme.colors.od_placeholder}`;

export const UserDiv = styled.div`
text-align: left;
font-size: 10px`;

export const UserText = styled.text`
border-radius: 10px;
padding: 1px 3px;
background-color: ${({ theme }) => theme.colors.od_neutral};
margin-right: 1px`;

export const ButtonRepeat = styled.button`
border-radius: 10px;
padding: 2px 10px;
border: none;
height: 40px;
width: 100px;
color: #fff;
background-color: #E86B55;
font-family: Roboto;
font-weight: bold;
font-size: 18px;

img {
  width: 40px;
  margin: 0;
}
`;
export const DropdownWrapper = styled(Dropdown)`
  display: flex;
  height: 15px;
  width: 10%;
  justify-content: flex-end;
  flex: 1;
`;
export const DropdownToggle = styled(Dropdown.Toggle)`
  padding: 0px;
  background-color: transparent;
  height: 25px;
  width: 25px;
  // font-weight: 700;
  // width: 40px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
  }
  img {
    padding-top: -10px;
    height: 25px;
    width: 25px;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border-radius: 10px;
  width: auto !important;
  .dropdown-item {
    padding: 5px 10px;
    &:hover,
    &:active {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    }
  }
`;

export const RemoveModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 `;

export const EditIcon = styled.span`
float: right`;

export const TextDataDiv = styled.div`
display: flex;
margin-top: ${({ marginTop }) => (marginTop ? '0.875rem' : '0px')}; 
border: 1px solid ${({ theme }) => theme.colors.od_extra_color};
border-radius: 13px;
background-color: ${({ theme }) => theme.colors.od_light_extra};
`;
export const TextDataUserDiv = styled.div`
display: flex;
width: 350px;
height: 40px;
margin-top: 10px; 
margin-right: 10px; 
margin-left: 10px; 
border-radius: 13px;

  input {
    width: 160px;
    border-radius: 13px;
    border: 1px solid #f5ddc9;
  }
`;

export const LeftText = styled.div`
padding: 9px;
float: left;
width: 50%;
border-right: 1px solid ${({ theme }) => theme.colors.od_extra_color};
color: ${({ theme }) => theme.colors.od_extra_color};
`;
export const ExternalUser = styled.div`
background-color: white;
 border-radius: 10px;
  margin-top: 15px;
  padding: 10px;
`;
export const AddExternalUser = styled.div`
display: flex;
input {
  width: 197px;
  border-radius: 13px;
  border: 1px solid #f5ddc9;
  height: 40px;
  margin-top: 10px; 
  margin-right: 10px; 
  padding: 10px;
}
`;
export const AddNew = styled.div`
display: flex; 
justify-content: space-between;
 margin-top: 10px;
`;

export const RightText = styled.div`
float: left;
width: 50%;
padding: 9px;
color: ${({ theme }) => theme.colors.od_extra_color};
`;

export const AssignedUser = styled.div`
button {
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 10px;
  margin: 0 20px;
  border: none;
  img {
    margin: 0 10px 2px 0;
  }
}
`;

export const UserSelect = styled.select`
width: 100%;
border-radius: 13px;
background-color: ${({ theme }) => theme.colors.od_neutral};
border : 1px solid #F2E4D8;
margin-top: 0.5rem;
padding: 16px;

option {
  text-align: left;
  color: ${({ theme }) => theme.colors.od_placeholder};
}
`;

export const AssignedUserData = styled.div`
border-radius: 13px;
background-color: rgba(255, 255, 255, 0.5);
border : 1px solid #F2E4D8;
padding: 16px;
margin-top: 0.5rem;
`;

export const User = styled.text`
border-radius: 20px;
padding: 7px 18px;
background-color: ${({ theme }) => theme.colors.od_neutral};
margin-right: 10px`;

export const AddUser = styled.div`
border-radius: 13px;
background-color: ${({ theme }) => theme.colors.od_neutral};
border : 1px solid #F2E4D8;
margin-top: 0.5rem;
`;

export const AddUserText = styled.div`
padding: 16px;
text-align: left;
color: ${({ theme }) => theme.colors.od_placeholder}`;

export const UserImage = styled.span`
margin-left: ${({ marginLeft }) => marginLeft}px;
border-radius: 50%;
padding: 4px 8px;
background-color: ${({ theme }) => theme.colors.od_bg_secondary}`;

export const FormWrapperUser = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.input-with-label{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }
  div.bg{
    width: 100%;
  }

  input:focus{
    outline: none;
  }
`;

export const StyledSelectDropdown = styled(Select)`
  width: 100%;
  padding: 10px 0px;
  margin-left: 8px;
  // height: 20px;
  .css-1wa3eu0-placeholder{
    color: #93ACAB;
    font-weight: 400;
    font-size: 18px;
    font-family: Arial, FontAwesome;
  }
  .css-yk16xz-control{
    height: 40px;
    // border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .css-g1d714-ValueContainer{
    height: 40px;
  }
  .css-1uccc91-singleValue{
    height: 30px;
  }
  .css-4ljt47-MenuList{
    background-color: ${({ theme }) => theme.colors.od_light_grey};
    padding: 5px;
  }
  .css-yutw0f-option{
    padding: 4px;
  }
  .css-tlfecz-indicatorContainer{
    display: none;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none;
  }
  .css-26l3qy-menu{
    // width: 110%;
    margin-top: -10px;
    // left: -33px;
  }
  .css-q8s328-control {
    border: none;
    background-color: transparent;
  }
  
  .custom__control {
    height: -40px;
    border: 1px solid  #D1E3E2;
    
    .custom__single-value {
      font-size: 14px;
      color: red;
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

export const SelectStyledTable = styled.table`
  width: 100%;
  tbody{
    width: 100%;
    tr {
      width: 100%;
      td {
        background-color: white; 
        height: 20%;
        width: 50%;
        padding: 5px;
      }
    }
  }
`;
