import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import closeIcon from '../assets/logo/close_cross.svg';

const Button = styled.button`
  border-radius: 5px;
  margin: 2px 0;
  padding: 2px 10px;
  border: none;
  background: none;

  img {
    width: 40px;
    margin: 0;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: ${(props) => (props.width)};
  }
  .modal-content{
    border-radius: 10px;
  }
  .modal-title{
    font-family: Roboto;
    font-size: 20px;
  }
  .modal-body{
    background-color: #F8F1EB;
    border-radius: 20px;
    padding: 3rem 2rem;
  }
  .modal-header{
    border-bottom: none;
  }
  .modal-footer{
    border-top: none;
    button{
      width: 100px;
      border: none;
      padding: 0.75rem 0.5rem;
      border-radius: 10px;
      margin: 1rem 0.5rem;
      font-family: Roboto;
      font-weight: bold;
      font-size: 18px;
    }
    button:disabled{
      opacity: 0.6;
    }
    button.cancel{
      color : #9C9C9C;
      background-color: #fff;
    }
    button.action{
      color: #fff;
      background-color: #E86B55;
    }
  }
`;

function CustomModal(props) {
  const {
    open,
    setClose,
    title, children, onSubmit, isDisabled, operationName, width, disableCancel
  } = props;
  return (
    <>
      <StyledModal show={open} onHide={setClose} centered animation width={width}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Button onClick={() => setClose()} data-testid="close">
            <img src={closeIcon} alt="close icon" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          {disableCancel ? null : (
            <button type="button" className="cancel" onClick={setClose}>
              Cancel
            </button>
          )}
          <button type="button" className="action" onClick={onSubmit} disabled={isDisabled}>
            {operationName}
          </button>
        </Modal.Footer>
      </StyledModal>
    </>
  );
}

CustomModal.defaultProps = {
  isDisabled: false,
  width: '600px',
  disableCancel: false
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  operationName: PropTypes.string.isRequired,
  disableCancel: PropTypes.bool
};

export default CustomModal;
