/** @format */

import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import {
  ShowReportsButtonWrapper,
  ReportBoxWrapper,
  ReportWrapper,
  Heading,
  HeadingBoxWrapper,
  NightHeadingBoxWrapper,
  ReviewWrapper,
  AssignFormWrapper
} from './index.style';
import SelectContainer from '../SelectContainer';
import NightChecklist from './Reports/NightChecklist/NightChecklist';
import {
  getFormsForClient,
  listForms,
  toggleFormsForClient
} from '../../services/result.services';
import FoodDrinkTracker from './Reports/FoodDrinkTracker/FoodDrinkTracker';
import SleepTracker from './Reports/SleepTracker/SleepTracker';
import BowelMovementTracker from './Reports/BowelMovementTracker/BowelMovementTracker';
import { DetailsWrapper, DetailsHeader } from '../../styledComponents/common/page.style';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import CustomModal from '../CustomModal';

const AdminClientReports = ({ client }) => {
  const { id } = client;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedFormsData, setSelectedFormsData] = useState([]);
  const [formData, setFormData] = useState({
    houseCode: '',
    clientName: '',
    form: ''
  });
  const [listFormsData, setListFormsData] = useState([]);
  const [showCustomModalAddForms, setShowCustomModalAddForms] = useState(false);
  const [clientFormsData, setClientFormsData] = useState([]);
  const [reportDate, setReportDate] = useState('');
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();

  const handleClick = (link) => history.push(link);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowCustomModal = (operation) => {
    if (operation === 'add-forms') {
      setShowCustomModalAddForms(true);
    }
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-forms') {
      setShowCustomModalAddForms(false);
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClearSelection = () => {
    setFormData((prevState) => ({
      ...prevState,
      houseCode: '',
      clientName: '',
      form: ''
    }));
    setReportDate('');
  };

  const getFormsHandler = async () => {
    setLoading(true);
    const resp = await listForms();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setListFormsData(resp.data);
      setLoading(false);
    } else {
      setListFormsData([]);
      setLoading(false);
    }
  };

  const getClientFormsData = async () => {
    setLoading(true);
    const resp = await getFormsForClient(id);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      const clientForms = resp.data && resp.data.map((clientForm) => {
        if (clientForm.lookupId === 75) {
          return {
            label: 'Food and Drinks Diary',
            value: 'Food and Drinks Diary',
            name: 'form'
          };
        }
        if (clientForm.lookupId === 76) {
          return {
            label: 'Sleep Tracker',
            value: 'Sleep Tracker',
            name: 'form'
          };
        }
        if (clientForm.lookupId === 77) {
          return {
            label: 'Nightly Tasks Checklist',
            value: 'Nightly Tasks Checklist',
            name: 'form'
          };
        }
        if (clientForm.lookupId === 104) {
          return {
            label: 'Bowel Movement Tracker',
            value: 'Bowel Movement Tracker',
            name: 'form'
          };
        }
        return clientForm;
      });
      setClientFormsData(clientForms);
      setLoading(false);
    } else {
      setClientFormsData([]);
      setLoading(false);
    }
  };

  const getAssignClientForms = async (clientId) => {
    setLoading(true);
    const resp = await getFormsForClient(clientId);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setSelectedFormsData(resp.data);
      setLoading(false);
    } else {
      setSelectedFormsData([]);
      setLoading(false);
    }
  };

  const toggleFormsForClientHandler = async (payload) => {
    setLoading(true);
    const resp = await toggleFormsForClient(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      await getClientFormsData();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleCheckChange = async (e, lookupId) => {
    const { checked } = e.target;
    // eslint-disable-next-line no-unused-expressions
    await toggleFormsForClientHandler({
      clientId: id,
      lookupId,
      toggle: checked
    });
  };

  const handleAddForms = async () => {
    // eslint-disable-next-line no-unused-expressions
    await getAssignClientForms(id);
    handleShowCustomModal('add-forms');
  };

  const addFormToClientHandler = () => {
    handleCloseCustomModal('add-forms');
  };

  const handleShowReports = () => {
    setShowForm(true);
  };

  useEffect(() => {
    getFormsHandler();
    getClientFormsData();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <DetailsHeader>
        <h2>Client Reports</h2>
        <div>
          <button type="button" onClick={() => handleAddForms()}>
            Assign Forms
          </button>

          <button onClick={() => handleClick('/admin/clients')} type="button"> Back </button>
        </div>
      </DetailsHeader>
      <DetailsWrapper>
        <ReviewWrapper>
          <div className="review-row">
            <div className="w-25 mr z-index">
              <p className="label">Select Form Type</p>

              <SelectContainer
                options={clientFormsData.length > 0 && clientFormsData}
                placeholderText="Form"
                className="select-container"
                isMultiple={false}
                onChange={handleSelectChange}
                defaultValue={{
                  value: formData.form,
                  label: formData.form
                }}
              />
            </div>

            <div className="w-25 date-picker-wrapper">
              <p className="label">Select Date</p>

              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Report date"
                selected={reportDate}
                onChange={(date) => setReportDate(date)}
                disabled={!formData.form}
              />
            </div>
            <ShowReportsButtonWrapper>
              <button type="button" className="submit-btn" onClick={handleClearSelection}>
                Clear Selection
              </button>
              <button
                type="button"
                className="submit-btn"
                disabled={!reportDate}
                onClick={handleShowReports}
              >
                Show Reports
              </button>
            </ShowReportsButtonWrapper>
          </div>
        </ReviewWrapper>
        {showForm && reportDate ? (
          <ReportBoxWrapper>
            <NightHeadingBoxWrapper>
              <HeadingBoxWrapper>
                <Heading>{formData.form}</Heading>
              </HeadingBoxWrapper>
            </NightHeadingBoxWrapper>
            {formData.form === 'Nightly Tasks Checklist' && (
            <ReportWrapper>
              <NightChecklist clientId={id} reportDate={reportDate} />
            </ReportWrapper>
            )}
            {formData.form === 'Food and Drinks Diary' && (
            <ReportWrapper>
              <FoodDrinkTracker clientId={id} reportDate={reportDate} />
            </ReportWrapper>
            )}
            {formData.form === 'Sleep Tracker' && (
            <ReportWrapper>
              <SleepTracker clientId={id} reportDate={reportDate} />
            </ReportWrapper>
            )}
            {formData.form === 'Bowel Movement Tracker' && (
            <ReportWrapper>
              <BowelMovementTracker clientId={id} selectedDate={reportDate} />
            </ReportWrapper>
            )}
          </ReportBoxWrapper>
        ) : null}
      </DetailsWrapper>
      <LoadingModal show={show} onHide={handleClose} animation centered width="4%">
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddForms}
        setClose={() => {
          handleCloseCustomModal('add-forms');
        }}
        title="Assign/Remove Form"
        onSubmit={addFormToClientHandler}
        operationName="Done"
      >
        <AssignFormWrapper>
          {
            listFormsData && listFormsData.length > 0
            && listFormsData.map((form) => (
              <div className="input-with-full-width" key={form.id}>
                <label
                  className="container"
                  htmlFor={form.name}
                >
                  <span>{form.name}</span>
                  <input
                    name={form.name}
                    type="checkbox"
                    id={form.name}
                    onChange={(e) => handleCheckChange(e, form.id)}
                    defaultChecked={selectedFormsData.length > 0
                      && selectedFormsData.find((f) => f.lookupId === form.id)}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            ))
          }
        </AssignFormWrapper>
      </CustomModal>
    </>
  );
};

AdminClientReports.propTypes = {
  client: PropTypes.string.isRequired
};

export default AdminClientReports;
