import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { LoadingModal } from '../../../../styledComponents/common/loadingModal.style';
import { bowelHeaders, bowelSize } from '../../../../lookups/bowelLookups';
import {
  BowelMovementChart, TypeBox, NameBox, HeaderRow,
  BowelRecordRow, ColumnOne, ColumnTwo, ColumnThree, Download
} from './index.style';
import userIcon from '../../../../assets/logo/userIcon.svg';
import { listBowelMovementRecords } from '../../../../services/result.services';
import SelectContainer from '../../../SelectContainer';
import NoData from '../../../NoData';
import generatePDFBowelMovementTracker from './DownloadBMT';

const BowelMovementRecord = ({ clientId, selectedDate }) => {
  const bowelRecordRequestObject = {
    start: moment(selectedDate).subtract(14, 'days').format('YYYY-MM-DD'),
    end: moment(selectedDate).format('YYYY-MM-DD'),
    pageSize: 1000,
    pageNumber: 0
  };
  const [bowelMovementRecordList, setBowelMovementRecordList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [records, setRecords] = useState([]);
  const checkIfRecordAlreadyCreated = () => {
    if (bowelMovementRecordList.length > 0) {
      return true;
    }
    return false;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getBowelMovementRecords = async (requestObject) => {
    setLoading(true);
    const resp = await listBowelMovementRecords(requestObject);
    setRecords(resp);
    if (!resp) {
      setLoading(false);
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredResp = resp.data
        .filter((record) => !record.deleted)
        .sort((a, b) => a.id - b.id);
      setBowelMovementRecordList(filteredResp);
    } else {
      setLoading(false);
      setBowelMovementRecordList([]);
    }
  };

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getBowelMovementRecords({ ...bowelRecordRequestObject, clientId });
  }, [clientId, selectedDate]);

  return (
    <>
      <BowelMovementChart>
        {
          !checkIfRecordAlreadyCreated() && <NoData />
        }
        {
          checkIfRecordAlreadyCreated() && (
            <>
              <Download>
                <button type="button" onClick={() => generatePDFBowelMovementTracker(bowelMovementRecordList[0], records)}>Download</button>
              </Download>
              <HeaderRow>
                <ColumnOne>
                  <div className="client_details">
                    <p>{bowelMovementRecordList[0].clientName}</p>
                    <div className="dob_track_from">
                      <p className="dob">
                        DOB : &nbsp;
                        {moment(bowelMovementRecordList[0].clientDOB).format('DD MMM YYYY')}
                      </p>
                      <p className="track_from">
                        Track From : &nbsp;
                        {moment(bowelMovementRecordList[0].startDate).format('DD MMM YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className="first_three_header">
                    <p className="s_num">S.No.</p>
                    <p className="date_time">Date and Time</p>
                    <p className="size">Size</p>
                  </div>
                </ColumnOne>
                <ColumnTwo>
                  { bowelHeaders.map((header) => (
                    <TypeBox key={header.name}>
                      <img className="bowel_img" src={header.src} alt="bowel type icons" />
                      <div className="name_box">
                        <p className="type_name">{header.name}</p>
                      </div>
                    </TypeBox>
                  ))}
                </ColumnTwo>
                <ColumnThree>
                  <p className="staff_initials">Staff Initials</p>
                </ColumnThree>
              </HeaderRow>
              {
                bowelMovementRecordList.map((record, index) => (
                  <BowelRecordRow key={record.id}>
                    <ColumnOne>
                      <div className="first_three_header first_three_field">
                        <p className="s_num_data">{index + 1}</p>
                        <p className="date_time_data">
                          <DatePicker
                            timeFormat="HH:mm"
                            showTimeSelect
                            className="input w-100"
                            dateFormat="dd/MM/yyyy, HH:mm:ss"
                            placeholderText="Select Record Time"
                            selected={new Date(record.recordTime)}
                            disabled
                          />
                        </p>
                        <p className="size_data">
                          <SelectContainer
                            id="bowelSize"
                            options={bowelSize.length > 0 && bowelSize}
                            placeholderText="Select Size"
                            className="select-container"
                            isMultiple={false}
                            defaultValue={{
                              label: record.size,
                              value: record.size
                            }}
                            disabled
                          />
                        </p>
                      </div>
                    </ColumnOne>
                    <ColumnTwo>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type1${record.id}`}
                        >
                          <input
                            name="type1"
                            type="checkbox"
                            id={`type1${record.id}`}
                            checked={record.type1}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type2${record.id}`}
                        >
                          <input
                            name="type2"
                            type="checkbox"
                            id={`type2${record.id}`}
                            checked={record.type2}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type3${record.id}`}
                        >
                          <input
                            name="type3"
                            type="checkbox"
                            id={`type3${record.id}`}
                            checked={record.type3}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type4${record.id}`}
                        >
                          <input
                            name="type4"
                            type="checkbox"
                            id={`type4${record.id}`}
                            checked={record.type4}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type5${record.id}`}
                        >
                          <input
                            name="type5"
                            type="checkbox"
                            id={`type5${record.id}`}
                            checked={record.type5}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type6${record.id}`}
                        >
                          <input
                            name="type6"
                            type="checkbox"
                            id={`type6${record.id}`}
                            checked={record.type6}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                      <TypeBox height="70px">
                        <label
                          className="container disabled"
                          htmlFor={`type7${record.id}`}
                        >
                          <input
                            name="type7"
                            type="checkbox"
                            id={`type7${record.id}`}
                            checked={record.type7}
                            disabled
                          />
                          <span className="checkmark" />
                        </label>
                      </TypeBox>
                    </ColumnTwo>
                    <ColumnThree>
                      <NameBox>
                        <div className="icon-name-wrapper">
                          <img className="icon" src={userIcon} alt="user icon" />
                        </div>
                        <span>
                          { record.lastUploadedBy }
                        </span>
                      </NameBox>
                    </ColumnThree>
                  </BowelRecordRow>
                ))
              }
            </>
          )
        }
      </BowelMovementChart>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

BowelMovementRecord.propTypes = {
  clientId: PropTypes.string.isRequired,
  selectedDate: PropTypes.shape.isRequired
};

export default BowelMovementRecord;
