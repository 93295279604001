import JSPDF from 'jspdf';
import moment from 'moment';
import logoPic from '../../../../assets/logo/logo.png';

function handleShowUserName(sleepTrackerData, reported) {
  return sleepTrackerData[reported];
}

const generatePDFSleepTracker = (
  sleepTrackerData, reportDetails
) => {
  const doc = new JSPDF({
    orientation: 'p',
    unit: 'px',
    hotfixes: ['px_scaling']

  });
  doc.addImage(logoPic, 'JPEG', 25, 5, 65, 65);
  doc.setFontSize(18);
  doc.setFont('helvetica');
  doc.text(
    'SLEEP TRACKER',
    280,
    40,
    null
  );
  doc.setFontSize(13);
  doc.setFont('helvetica bold');
  doc.text(
    'Client Name: ',
    30,
    85,
    null
  );
  doc.text(
    `${reportDetails?.clientName}`,
    130,
    85,
    null
  );
  doc.text(
    'Date: ',
    620,
    85,
    null
  );
  doc.text(
    `${moment(reportDetails?.date).format('DD-MM-YYYY')}`,
    665,
    85,
    null
  );
  doc.setFontSize(12);
  doc.setFont('helvetica');
  doc.text(
    'TIME',
    100,
    115,
    null
  );
  doc.text(
    'COMMENTS',
    345,
    115,
    null
  );
  doc.text(
    'SIGN',
    650,
    115,
    null
  );
  doc.roundedRect(
    30,
    120,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '6:00Pm - 8:00Pm',
    40,
    160,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    120,
    200,
    240,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.firstSlot : ''}`,
    215,
    160,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    120,
    600,
    240,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'firstUpdatedBy')}`,
    630,
    160,
    {
      maxWidth: '100'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    240,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '8:00Pm - 10:00Pm',
    40,
    280,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    240,
    200,
    360,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.secondSlot : ''}`,
    215,
    280,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    240,
    600,
    360,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'secondUpdatedBy')}`,
    630,
    280,
    {
      maxWidth: '100'
    },
    null
  );
  doc.setFontSize(13);
  doc.roundedRect(
    30,
    360,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '10:00Pm - 12:00Am',
    40,
    400,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    360,
    200,
    480,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.thirdSlot : ''}`,
    215,
    400,
    {
      maxWidth: '300'
    },
    null
  );
  doc.line(
    600,
    360,
    600,
    480,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'thirdUpdatedBy')}`,
    630,
    400,
    {
      maxWidth: '100'
    },
    null
  );
  doc.roundedRect(
    30,
    480,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '12:00Am - 2:00Am',
    40,
    520,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    480,
    200,
    600,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.fourthSlot : ''}`,
    215,
    520,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    480,
    600,
    600,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'fourthUpdatedBy')}`,
    630,
    520,
    {
      maxWidth: '100'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    600,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '2:00Am - 4:00Am',
    40,
    640,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    600,
    200,
    720,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.fifthSlot : ''}`,
    215,
    640,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    600,
    600,
    720,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'fifthUpdatedBy')}`,
    630,
    640,
    {
      maxWidth: '100'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    720,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '4:00Am - 6:00Am',
    40,
    760,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    720,
    200,
    840,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.sixthSlot : ''}`,
    215,
    760,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    720,
    600,
    840,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'sixthUpdatedBy')}`,
    630,
    760,
    {
      maxWidth: '100'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    840,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '6:00Am - 8:00Am',
    40,
    880,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    840,
    200,
    960,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.seventhSlot : ''}`,
    215,
    880,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    840,
    600,
    960,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'seventhUpdatedBy')}`,
    630,
    880,
    {
      maxWidth: '100'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    960,
    735,
    120,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    '8:00Am - 10:00Am',
    40,
    1000,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    960,
    200,
    1080,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${Object.keys(sleepTrackerData).length > 0 ? sleepTrackerData.eighthSlot : ''}`,
    215,
    1000,
    {
      maxWidth: '300'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    600,
    960,
    600,
    1080,
    null
  );
  doc.text(
    `${handleShowUserName(sleepTrackerData, 'eighthUpdatedBy')}`,
    630,
    1000,
    {
      maxWidth: '100'
    },
    null
  );
  // doc.output('dataurlnewwindow');
  doc.save(`ST-Report-${reportDetails?.clientName}`);
};
export default generatePDFSleepTracker;
