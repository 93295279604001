/** @format */

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import SelectContainer from '../../SelectContainer';
import {
  getUsersList,
  addReview,
  closeIncident,
  editReview,
  editIncident
} from '../../../services/result.services';
import { checkIfHouseAdminOnly, getUserDetails } from '../../../helpers/utils';
import {
  riskOptions,
  consequenceOptions,
  likelihoodOptions
} from '../../../lookups/lookups';
import { ButtonWrapper, ReviewWrapper } from './index.style';

const IncidentReviewSecond = ({
  incidentId,
  editReviewData,
  handleChange,
  handleSelectChange,
  reviewData,
  handleSelectDate,
  checkReviewValidation,
  reviewValidationMsg,
  handleChangeEditReview,
  handleSelectDateEdit,
  handleSelectChangeEdit,
  incident,
  editReviewGeneral,
  modifiedReportable,
  handleChangeIncident
}) => {
  const history = useHistory();
  const [allocationList, setAllocationList] = useState([]);
  const isHouseAdmin = checkIfHouseAdminOnly();
  const [dateDue, setDateDue] = useState('');
  const getUsersHandler = async () => {
    const resp = await getUsersList();
    const filteredResp = resp.data.filter((user) => !user.deleted);
    if (!resp) return;
    if (resp.status === 200) {
      // eslint-disable-next-line
      const userList = filteredResp && filteredResp.map((ele) => {
        return {
          label: `${ele.firstName} ${ele.lastName}`,
          value: `${ele.firstName} ${ele.lastName}`,
          name: 'followUpResponsibility'
        };
      });
      const userListMod = Object.assign(userList, { 0: { label: '', value: '', name: 'followUpResponsibility' } });
      setAllocationList(userListMod);
    }
  };

  const SaveIncident = async () => {
    const modifiedIncident = {
      id: incident.id,
      classificationId: incident.classification.id,
      houseId: incident.house?.id,
      typeId: incident.type.id,
      categoryId: incident.category.id,
      clientId: incident.client?.id,
      status: incident.status,
      description: incident.description,
      dateOccurred: moment(incident.dateOccurred).format('YYYY-MM-DD HH:mm:ss'),
      location: incident.location,
      exactLocation: incident.exactLocation,
      raisedFor: incident.raisedFor,
      reportedBy: incident.reportedBy,
      injuredGivenName: incident.injuredGivenName,
      injuredFamilyName: incident.injuredFamilyName,
      witnessName: incident.witnessName,
      witnessDesignation: incident.witnessDesignation,
      beforeIncident: incident.beforeIncident,
      immediateAction: incident.immediateAction,
      policeReport: incident.policeReport,
      policeName: incident.policeName,
      policeNumber: incident.policeNumber,
      policeStation: incident.policeStation,
      reportableToNDIS: incident.reportableToNDIS,
      reportableToWorksafe: incident.reportableToWorksafe,
      followUpResponsibility: incident.followUpResponsibility
    };
    const resp = await editIncident(modifiedIncident.id, modifiedIncident);
    if (!resp) {
      swal({
        title: 'Network error, try again!',
        icon: 'error',
        timer: 3000
      });
      return false;
    }
    if (resp.status === 200) {
      swal({
        title: 'Incident updated successfully!',
        icon: 'success',
        timer: 3000
      });
      return true;
    }
    swal({
      title: 'Incident updation failed, try again!',
      icon: 'error',
      timer: 3000
    });
    return false;
  };

  const SaveReview = async () => {
    if (!checkReviewValidation(reviewData, incident)) return;
    const resp = await addReview({
      ...reviewData,
      incidentId,
      dueDate: moment(reviewData.dueDate).format('YYYY-MM-DD'),
      reviewedBy: getUserDetails().firstName,
      allocatedTo: 'Test'
    });
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Review Created successfully!',
        icon: 'success',
        timer: 3000
      });
      history.push('/admin/incidents');
    } else {
      swal({
        title: 'Review Creation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleSubmitReview = async () => {
    if (!checkReviewValidation(reviewData, incident)) return;
    let saveSuccess = false;
    if (modifiedReportable) {
      saveSuccess = await SaveIncident();
    }
    if (saveSuccess) {
      await SaveReview();
    }
  };

  const EditReview = async () => {
    if (!checkReviewValidation(editReviewData, incident)) return;
    const resp = await editReview(editReviewData.id, {
      ...editReviewData,
      incidentId: editReviewData.incidentId,
      dueDate: moment(editReviewData.dueDate).format('YYYY-MM-DD'),
      reviewedBy: getUserDetails().firstName,
      allocatedTo: 'Test'
    });
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Review Updated successfully!',
        icon: 'success',
        timer: 3000
      });
      history.push('/admin/incidents');
    } else {
      swal({
        title: 'Review Updation failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleEditReview = async () => {
    if (!checkReviewValidation(editReviewData, incident)) return;
    let saveSuccess = false;
    if (modifiedReportable) {
      saveSuccess = await SaveIncident();
    }
    if (saveSuccess) {
      await EditReview();
    }
  };

  const handleCloseIncident = async () => {
    const resp = await closeIncident(incidentId, getUserDetails().firstName);
    if (!resp) {
      swal({
        title: 'Network Error, try again!',
        icon: 'error',
        timer: 3000
      });
    }
    if (resp.status === 200) {
      swal({
        title: 'Incident closed successfully!',
        icon: 'success',
        timer: 3000
      });
      history.push('/admin/incidents');
    } else {
      swal({
        title: 'Incident close failed!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  useEffect(() => {
    getUsersHandler();
  }, []);

  return (
    <>
      <ReviewWrapper>
        <div className="review-row">
          <div className="w-25 mr">
            <p className="label">
              Risk Rating
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={riskOptions}
                  isMultiple={false}
                  placeholderText="Risk Rating"
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: reviewData.riskRating,
                    label: reviewData.riskRating
                  }}
                />
              ) : (
                <SelectContainer
                  options={riskOptions}
                  placeholderText="Risk Rating"
                  isMultiple={false}
                  defaultValue={{
                    value: editReviewData.riskRating,
                    label: editReviewData.riskRating
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Consequences
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={consequenceOptions}
                  placeholderText="Consequences"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: reviewData.consequences,
                    label: reviewData.consequences === '' ? reviewData.consequences : reviewData.consequences[0].toUpperCase()
                    + reviewData.consequences.substring(1)
                  }}
                />
              ) : (
                <SelectContainer
                  options={consequenceOptions}
                  isMultiple={false}
                  placeholderText="Consequences"
                  defaultValue={{
                    value: editReviewData.consequences,
                    label: editReviewData.consequences[0].toUpperCase()
                    + editReviewData.consequences.substring(1)
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Likelihood
            </p>
            {
              !Object.keys(editReviewData).length > 0 ? (
                <SelectContainer
                  options={likelihoodOptions}
                  placeholderText="Likelihood"
                  isMultiple={false}
                  onChange={handleSelectChange}
                  defaultValue={{
                    value: reviewData.likelihood,
                    label: reviewData.likelihood === '' ? reviewData.likelihood : reviewData.likelihood[0].toUpperCase()
                    + reviewData.likelihood.substring(1)
                  }}
                />
              ) : (
                <SelectContainer
                  options={likelihoodOptions}
                  placeholderText="Likelihood"
                  isMultiple={false}
                  defaultValue={{
                    label: editReviewData.likelihood[0].toUpperCase()
                    + editReviewData.likelihood.substring(1),
                    value: editReviewData.likelihood
                  }}
                  onChange={handleSelectChangeEdit}
                />
              )
            }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Corrective Action
            </p>
            {
              !Object.keys(editReviewData).length > 0
                ? (
                  <input
                    className="w-100 input-like"
                    type="text"
                    placeholder="Corrective Action"
                    name="correctiveAction"
                    onChange={handleChange}
                    value={reviewData.correctiveAction}
                  />
                ) : (
                  <input
                    className="w-100 input-like"
                    type="text"
                    placeholder="Corrective Action"
                    value={editReviewData.correctiveAction}
                    name="correctiveAction"
                    onChange={handleChangeEditReview}
                  />
                )
          }
          </div>
          <div className="w-25 mr">
            <p className="label">
              Due Date
            </p>
            <div className="w-100 date-picker-wrapper">
              {
                !Object.keys(editReviewData).length > 0 ? (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Due date"
                    selected={dateDue}
                    onChange={(date) => { setDateDue(date); handleSelectDate(date); }}
                  />
                ) : (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Due date"
                    selected={editReviewData.dueDate}
                    onChange={(date) => handleSelectDateEdit(date)}
                  />
                )
              }
            </div>
          </div>
          <div className="w-25 mr">
            <p className="label">
              Follow Up Responsibility
            </p>
            <SelectContainer
              options={allocationList.filter((item) => item.value !== '')}
              isMultiple={false}
              defaultValue={{
                label: incident.followUpResponsibility,
                value: incident.followUpResponsibility
              }}
              placeholderText="Follow Up Responsibility"
              onChange={handleChangeIncident}
            />
          </div>
        </div>
        <div className="review-row">
          <div className="w-50 flex">
            <p className="label">Description (Actions to be implemented) :</p>
            {
              !Object.keys(editReviewData).length > 0
                ? (
                  <textarea
                    className="input-like"
                    rows="4"
                    placeholder="Actions to Implement Description"
                    name="actionsToImplement"
                    onChange={handleChange}
                    value={reviewData.actionsToImplement}
                  />
                )
                : (
                  <textarea
                    className="input-like"
                    placeholder="Actions to Implement Description"
                    rows="4"
                    value={editReviewData.actionsToImplement}
                    name="actionsToImplement"
                    onChange={handleChangeEditReview}
                  />
                )
            }
          </div>
        </div>
        <div className="row-review">
          <h4 className="mx-3 text-danger">
            { reviewValidationMsg && `${reviewValidationMsg}`}
          </h4>
        </div>
      </ReviewWrapper>
      <ButtonWrapper>
        {
          !Object.keys(editReviewData).length > 0 ? (
            <button
              type="button"
              className="submit-btn"
              onClick={handleSubmitReview}
            >
              Submit Review
            </button>
          )
            : (
              <button
                type="button"
                className="modify-review-btn"
                onClick={handleEditReview}
                disabled={(modifiedReportable === false)
                  && (isEqual(editReviewData, editReviewGeneral))}
              >
                Modify Review
              </button>
            )
        }
        {
        !isHouseAdmin
          ? (
            <button
              type="button"
              className="close-incident-btn"
              onClick={handleCloseIncident}
              disabled={incident && incident.status !== 'REVIEWED'}
            >
              Close Incident
            </button>
          )
          : ''
      }
      </ButtonWrapper>
    </>
  );
};
IncidentReviewSecond.propTypes = {
  incidentId: PropTypes.shape.isRequired,
  editReviewData: PropTypes.func.isRequired,
  reviewData: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleSelectChangeEdit: PropTypes.func.isRequired,
  handleSelectDate: PropTypes.func.isRequired,
  handleSelectDateEdit: PropTypes.func.isRequired,
  checkReviewValidation: PropTypes.func.isRequired,
  handleChangeEditReview: PropTypes.func.isRequired,
  reviewValidationMsg: PropTypes.string.isRequired,
  modifiedReportable: PropTypes.bool.isRequired,
  incident: PropTypes.func.isRequired,
  editReviewGeneral: PropTypes.shape.isRequired,
  handleChangeIncident: PropTypes.func.isRequired
};

export default IncidentReviewSecond;
