/** @format */

import React, { useState } from 'react';
import {
  Spinner
} from 'react-bootstrap';
import { useHistory, Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import {
  Wrapper, LowerCurve, UpperCurve, FormContainer,
  InputRow, CredentialIcon, ErrorMsg, Button
} from './index.style';
import srcLogo from '../../assets/logo/one_doorway_logo.png';
import upperCurve from '../../assets/logo/upper_curve.svg';
import lowerCurve from '../../assets/logo/lower_curve.svg';
import passwordLogo from '../../assets/logo/password_logo.svg';
import backIcon from '../../assets/logo/back.svg';
import { changePassword } from '../../services/result.services';

const ChangePassword = () => {
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem('token'));
  const isAuthenticated = token;
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  const [changePasswordDetails, setChangePasswordDetails] = useState({
    oldPassword: '',
    newPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [validRespMsg, setValidRespMsg] = useState('');

  const handlePasswordChange = (event) => {
    if (validRespMsg) {
      setValidRespMsg('');
    }
    const { name, value } = event.target;
    setChangePasswordDetails((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const changePasswordHandler = async (event) => {
    event.preventDefault();
    setLoading(true);
    const resp = await changePassword(changePasswordDetails);
    if (!resp) {
      setLoading(false);
      setValidRespMsg('Server Error, please try again later');
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setChangePasswordDetails({
        oldPassword: '',
        newPassword: ''
      });
      swal({
        title: 'Password changed successfully!',
        icon: 'success',
        timer: 3000
      });
      setTimeout(() => {
        history.goBack();
      }, 3000);
    } else if (resp && resp.status === 400) {
      setLoading(false);
      setValidRespMsg('Same as old password');
    } else {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <UpperCurve src={upperCurve} />
      <LowerCurve src={lowerCurve} />
      <div className="form-wrapper">
        <div className="w-100 flex">
          <img className="logo" src={srcLogo} alt="one doorway logo" />
        </div>
        <FormContainer>
          <button className="button back-btn" type="button" onClick={() => history.goBack()}>
            <img src={backIcon} alt="back icon" />
            Back
          </button>
          <h3 className="title" data-testid="reset-pass-title">Change Password</h3>
          <InputRow>
            <label htmlFor="oldpass">
              <CredentialIcon src={passwordLogo} />
            </label>
            <input
              id="oldpass"
              type="password"
              placeholder="Enter old password"
              value={changePasswordDetails.oldPassword}
              name="oldPassword"
              data-testid="old-pass"
              onChange={handlePasswordChange}
            />
          </InputRow>
          <InputRow>
            <label htmlFor="newpass">
              <CredentialIcon src={passwordLogo} />
            </label>
            <input
              id="newpass"
              type="password"
              placeholder="Enter new password"
              value={changePasswordDetails.newPassword}
              name="newPassword"
              data-testid="new-pass"
              onChange={handlePasswordChange}
            />
          </InputRow>
          <Button
            type="submit"
            data-testid="change-pass-btn"
            onClick={changePasswordHandler}
            disabled={
              changePasswordDetails.email === ''
              || changePasswordDetails.oldPassword === ''
              || validRespMsg !== ''
              || changePasswordDetails.newPassword === ''
              || loading
            }
          >
            <span>
              {loading ? 'Changing' : 'Change Password'}
            </span>
            {loading && (
              <span className="spinner">
                <Spinner animation="border" variant="primary" />
              </span>
            )}
          </Button>
          <ErrorMsg>
            {validRespMsg}
          </ErrorMsg>
        </FormContainer>
      </div>
    </Wrapper>
  );
};

export default ChangePassword;
