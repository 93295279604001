import React from 'react';

export default function Calender() {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.39312 0.923046H2.32165V0H3.25019V0.923046H9.28576V1.84622H3.25019V2.76926H2.32165V1.84622H1.39312C1.26996 1.84622 1.15182 1.89482 1.06481 1.98131C0.977682 2.06793 0.928787 2.18524 0.928787 2.30768V3.23072H12.0713V2.30768C12.0713 2.18525 12.0224 2.06793 11.9354 1.98131C11.8483 1.89482 11.7303 1.84622 11.6071 1.84622H10.6785V2.76926H9.74994V3.14069e-05H10.6785V0.923077H11.6071C11.9765 0.923077 12.3308 1.06902 12.592 1.32862C12.8532 1.58834 13 1.94052 13 2.30771V10.6154C13 10.9825 12.8532 11.3347 12.592 11.5945C12.3308 11.8541 11.9765 12 11.6071 12H1.39287C1.0234 12 0.669126 11.8541 0.407957 11.5945C0.146691 11.3347 0 10.9826 0 10.6154V10.1538H0.928536V10.6154C0.928536 10.7378 0.977431 10.8551 1.06456 10.9417C1.15157 11.0282 1.2697 11.0768 1.39286 11.0768H11.6071C11.7303 11.0768 11.8483 11.0282 11.9354 10.9417C12.0224 10.8551 12.0713 10.7378 12.0713 10.6154V4.15392H0.928783V9.69226H0.000247002V2.30771C0.000247002 1.94055 0.146935 1.58837 0.408204 1.32862C0.669348 1.06902 1.02362 0.923077 1.39312 0.923077V0.923046Z" fill="#E86B55" />
      <rect x="4" y="7" width="5" height="1" fill="#E86B55" />
    </svg>

  );
}
