import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

export const TableContainer = styled.div`
  margin: 0 2.5rem 1rem;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
  }

  tbody{
    min-height: calc(60vh - 120px);
    max-height: calc(80vh - 120px);
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    tr {
      border: 1px solid #E6E9F4;
      border-radius: 10px;
      box-sizing: border-box;
      margin: 0 5px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top:5px;
  }
`;

export const ButtonRepeat = styled.button`
border-radius: 10px;
padding: 2px 10px;
border: none;
height: 40px;
width: 100px;
color: #fff;
background-color: #E86B55;
font-family: Roboto;
font-weight: bold;
font-size: 18px;

img {
  width: 40px;
  margin: 0;
}
`;

export const ResetIconContainer = styled.img`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  margin-left: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  }
`;

export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;

export const StyledInputDiv = styled.div`
  height: 100%;
  margin: auto;
  display: flex;
  border-radius: 5px;
  justify-content: flex-start;
  padding: 5px;
  padding-left: 14px;
  box-shadow: 0 0 3px 1px #dedede;
  border: ${({ error }) => (error ? '1px solid red' : 'none')};
  img {
    width: 30px;
    height: 30px;
  }
  input {
    border: none;
    width: 100%;
    padding-left: 8px;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    opacity: 0.5;
    font-size: 18px;
  }
`;

export const ModalClientName = styled.div`
  border: 1px solid rgba(134, 81, 137, 0.35);
  padding: 10px;
  background-color: rgba(134, 81, 137, 0.1);
  border-radius: 10px;
  color: #865189;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: ${({ noSeperator }) => (noSeperator ? 'none' : 'linear-gradient(rgba(134, 81, 137, 0.35), rgba(134, 81, 137, 0.35))')};
  background-size: ${({ noSeperator }) => (noSeperator ? 'none' : '1px 100%')};
  background-repeat: ${({ noSeperator }) => (noSeperator ? 'none' : 'no-repeat')};
  background-position: ${({ noSeperator }) => (noSeperator ? 'none' : 'center center')};
  button{
    border: none;
    border-radius: 50%;
    font-family: Roboto;
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
  }
  button.action{
    color: #fff;
    background-color: #E86B55;
  }
`;

export const IconContainer = styled.div`
  // margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  border: none;
  height: 30px;
  text-align: center;
  cursor: pointer;
  img {
    width: 19px !important;
    height: auto;
    margin-left: 0;
  }
`;
