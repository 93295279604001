import React, { useState, useEffect } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { listUserAllocation } from '../../services/result.services';
import {
  CardWrapper,
  ProfilePic,
  UserName,
  Time,
  ButtonsWrapper,
  Button,
  TooltipCustom
} from './index.style';
import { Profile, TimeIcon } from '../svg_icons/indexs';

const UserCard = ({
  userName,
  userId,
  setUserId,
  setSelectedModal,
  setSelectedUser,
  startDate,
  endDate,
  setLoading
}) => {
  const [userAllocation, setUserAllocation] = useState([]);
  const renderTooltip = (props) => (
    <TooltipCustom className="tool">
      {props}
    </TooltipCustom>
  );

  const calculateHours = (allocationList) => {
    let totalHours = 0;
    let totalMinutes = 0;
    let addHours = 0;
    let remMinutes = 0;
    let remMinutesStr = '';
    let totalHoursStr = '';
    if (allocationList.length > 0) {
      totalHours = allocationList.reduce((sum, allocation) => (sum + allocation.hours), 0);
      totalMinutes = allocationList.reduce((sum, allocation) => (sum + allocation.minutes), 0);
      if (totalMinutes !== 0) {
        addHours = Math.floor(totalMinutes / 60);
        remMinutes = totalMinutes % 60;
      }
      totalHours += addHours;
    }

    if (totalHours < 10) {
      totalHoursStr = `0${totalHours}`;
    } else totalHoursStr = `${totalHours}`;

    if (remMinutes === 0) {
      remMinutesStr = '00';
    } else if (remMinutes < 10) {
      remMinutesStr = `0${remMinutes}`;
    } else remMinutesStr = `${remMinutes}`;
    return (`${totalHoursStr}:${remMinutesStr} Hours`);
  };

  useEffect(async () => {
    const payload = {
      startDate,
      endDate
    };
    try {
      const resp = await listUserAllocation(userId, payload);
      if (!resp) {
        setLoading(false);
      }
      if (resp.status === 200) {
        setUserAllocation(resp.data);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
    }
  }, []);
  return (
    <CardWrapper>
      <ProfilePic>
        <Profile />
      </ProfilePic>
      <UserName>{userName && userName}</UserName>
      {userAllocation.length > 0 ? (
        <Time>
          {calculateHours(userAllocation)}
        </Time>
      )
        : (
          <Time>00:00 Hours</Time>
        ) }
      <ButtonsWrapper>
        <Button
          onClick={() => {
            setSelectedModal('unavailability-time');
            setUserId(userId);
            setSelectedUser(userName);
          }}
        >
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip('Unavailability Time')}
          >
            <div>
              <TimeIcon />
            </div>
          </OverlayTrigger>

        </Button>
      </ButtonsWrapper>
    </CardWrapper>
  );
};

UserCard.propTypes = {
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  setUserId: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
};
export default UserCard;
