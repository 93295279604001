/** @format */

import styled from 'styled-components';

export const BackButton = styled.button`
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  width: 77px;
  font-size: 15px;
  border: none;
  margin: 10px 0;
  height: 41px;
  font-size: 13px;

  img {
    height: 8px;
    width: 11px;
    margin-right: 5px;
    margin-left: -5px;
  }
`;

export const HeadingBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input {
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
`;

export const ButtonsBox = styled.div`
  display: flex;
  text-align: center;
  align-items: center;

  button {
    margin: 0 15px;
  }
`;

export const Heading = styled.h4`
  font-weight: 500;
  color: #74bca9;
  font-size: 18px;
  margin: 0 35px;
`;

export const DateBox = styled(Heading)`
  font-size: 16px;
`;

export const NightHeadingBoxWrapper = styled.div`
  height: 73px;
  background: ${({ theme }) => theme.colors.od_primary_transparent};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const NightToolBox = styled.div`
  display: flex;
  margin: 0 35px;
`;

export const NightButton = styled.button`
  width: 92px;
  height: 42px;
  border-radius: 10px;
  margin: auto;
  margin-right: 20px;
  font-size: 13px;
  font-weight: 700;
  border: none;
`;

export const NightInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  height: 32px;
  margin-top: -5px;
  font-size: 16px;
`;

export const NightSubmitButton = styled(NightButton)`
  background: ${({ theme }) => theme.colors.od_primary};
  color: ${({ theme }) => theme.colors.od_neutral};
  border: none;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
`;

export const NightSearchBarWrapper = styled.div`
  border-radius: 20px;
  padding: 10px;
  width: 262px;
  height: 42px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #dadfef;
  border-radius: 10px;
  margin: auto;
  margin-right: 20px;
  display: flex;
`;

export const IconImage = styled.div`
  height: 20px;
  padding-right: 10px;
`;

export const TableWrapper = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.od_extra_color};
  
`;

export const TableHeadingsWrapper = styled.div`
  display: flex;
`;

export const TableHeadingFirstBox = styled.div`
  height: 33px;
  width: 395px;
  background: #e6e9f4;
  border-radius: 5px;
  padding: 5px 15px;
  text-align: left;
  margin: 5px 30px;
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    border: none;
    text-align: right;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const TableHeadingSecondBox = styled.div`
  width: 180px;
  margin: 5px 0;
  text-align: center;
  padding: 5px;
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 10px 10px;
    border: none;
    text-align: right;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const TableRowWrapper = styled.div`
  display: flex;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
  background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
  background: #555;
  }
  
`;

export const ColumnOneBox = styled.div`
  width: 395px;
  height: 89px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
  color: ${({ theme }) => theme.colors.od_placeholder};
  margin: 5px 30px;
  display: flex;
  align-items: center;
  padding: 2px 5px 2px 18px;
  font-size: 16px;
`;

export const ColumnTwoBox = styled(TableHeadingSecondBox)`
  height: 89px;
  background: ${({ theme }) => theme.colors.od_neutral};
  border: 1px solid #d5eae1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px;



  .disabled {
    cursor: default !important;
  }
  .container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    height: 89px;
    padding: 0;
    margin: 0;
  }
  .container > span:first-child {
    position: relative;
    top: 40px;
    left: 25px;
    width: 135px;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 10px;
    left: 45%;
    height: 20px;
    width: 20px;
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    border-radius: 50%;
  }

  /* On mouse-over, add a primary background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .disabled:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }

  /* When the checkbox is checked, add a primary background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.od_primary};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const NameBox = styled.span`
  display: flex;
  border: 0.2px solid rgba(134, 81, 137, 0.24);
  border-radius: 24px;
  width: 105px;
  padding: 3px;
  font-size: 12px;

  .icon-name-wrapper {
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.od_light_extra};
    margin: auto;
    height: 25px;
    width: 25px;
    border-radius: 50%;

    .icon {
      width: 11px;
      height: 11px;
      margin: auto;
    }
  }
  span {
    margin: auto;
    margin-left: -5px;
  }
`;
