/** @format */

import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const SliderButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  h2{
    color: #020202;
    font-size: 20px;
    font-weight: 800;
  }
  button{
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: none;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    margin: 0 0.25rem;
    vertical-align: middle;
  }
  button:hover{
    background-color: ${({ theme }) => theme.colors.od_primary};
    color: ${({ theme }) => theme.colors.od_neutral};
  }
`;

export const SliderContainer = styled.div`
   margin: 1rem 0 2rem -1rem;
  .slick-arrow {
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.od_primary};
  }
  .box {
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.od_neutral};
    width: 400px !important;
    height: 220px;
    margin: auto 1rem;
    .lower-part {
      margin: 0.75rem;
    }
    .img-container {
      max-width: 50%;
      height: 150px;
      border-radius: 15px;
      margin-top: 5px;
      margin-left: 30px;
      img {
        width: 70%;
        height: 100%;
        border-radius: 15px;
       
      }
    }
    .house-phone-wrap {
      display: flex;
      justify-content: space-between;
    }
    .icon-with-code {
      display: flex;
      margin-left: 210px;
      margin-bottom: 1px;
      margin-top: -130px;
      color: #1E1E1E;
      font-weight: 800;
      font-size: 18px;
    }
    .icon-with-client {
      display: flex;
      margin-left: 210px;
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
    .client-number {
      display: flex;
      margin-left: 230px;
      margin-top: -10px;
      color: #E86B55;
    }
    .users-number {
      display: flex;
      margin-left: 80px;
      margin-top: -10px;
      color: #58C7AB;
    }
    .icon-with-dotted {
      display: flex;
      .dotted-icon {
        margin-left: 100px;
      }
    }
    .icon-with-users {
      margin-left: 30px;
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
    .icon-with-text {
      display: flex;
      margin-left: 210px;
      color: ${({ theme }) => theme.colors.od_placeholder};
      .address-icon {
        margin-top: 0.3rem;
      }
      .contact-icon {
        margin-top: 0.2rem;
        width: 22px;
      }
     
      span {
        margin-left: 10px;
      }
    }
    .house-code {
      color: ${({ theme }) => theme.colors.od_text_color};
    }
  }
`;

export const StatusContainer = styled.div`
  span {
    text-align: center;
    display: inline-block;
    padding: 0.5rem 0;
    width: 85px;
    border-radius: 7px;
  }
  .raised {
    color: ${({ theme }) => theme.colors.od_primary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_primary};
  }
  .closed {
    color: ${({ theme }) => theme.colors.od_secondary_shade1};
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .reviewed {
    color: ${({ theme }) => theme.colors.od_extra_color};
    background-color: ${({ theme }) => theme.colors.od_light_extra};
  }
`;

export const NoticeWrapper = styled.div`
  width: 100%;
  height: 550px;
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  margin-left: 0px;
`;
export const StaffIncidentsWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
  height: 550px;
  padding-bottom: 1rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  margin-left: 0px;
`;
export const ClientIncidentsWrapper = styled.div`
  width:100%;
  margin: 1rem 0;
  height: 550px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 1rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
`;

export const IconContainer = styled.button`
  padding: 0.5rem 0.5 rem;
  margin-left:10px;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 16px !important;
    height: auto;
  }
  &.delete-btn{
    margin: 0 0 0.2rem 1.5rem;
    padding: 0;
    margin-left:10px;
    img{
      width: 22px !important;
    }
  }
`;

export const ClientHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #E4EEED;
  margin-bottom: 25px;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: #0D0D0D;
  }
`;
export const StaffHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  height: 50px;
  display: flex;
  border-bottom: 1px solid #E4EEED;
  margin-bottom: 25px;
  margin-left:-2px;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: #0D0D0D;
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 5px;
    font-size: 15px;
    padding: 8px 10px;
    margin: 7px;
    border: none;
    display: flex;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;
export const NoticeHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #E4EEED;
  margin-bottom: 25px;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: #0D0D0D;
  }
`;

export const AddIncident = styled.button`
  position: absolute;
  top: 65px;
  right: -35px;
  width: 65px;
  height: 65px;
  cursor: pointer;
  border: none;
  background: none;

  img {
    width:100% !important;
  }
`;

export const IncidentWrapper = styled.div`
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow:hidden;
  max-height: 85%;
  .flex{
    display: flex;
	flex-direction: column;
	margin: 0.5rem 0;
	.incident_num{
	  color: ${({ theme }) => theme.colors.od_secondary};
	  padding: 0;
	  margin: 0 7px;
	}
	.label{
	  font-size: 12px;
	  margin: 5px 7px;
	}
	.input, .input-like{
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}
	.input-like{
	  padding: 4px 8px;
	  margin-bottom: 0;
	}
  }
  .w-100{
	flex: 0 0 100%;
  }
  .w-25{
	flex: 0 0 24%;
  }
  .w-33{
    flex: 0 0 33%;
  }
  .w-50{
	flex: 0 0 49.5%;
  }
`;

export const RowReviewValidation = styled.div`
  width:80%;
  margin:15px 0px 0px 55px;
  font-size:16px;
`;
export const EditIcon = styled.img`
  margin-top: 10px;
  padding-top: 7px;
  padding-left: 6px;
  height: 27px;
`;
export const RemoveButton = styled.div`
  background: ${({ theme }) => theme.colors.page_background};;
  width: 30px;
  height: 25px;
  margin-left: 350px;
  text-align: right;
  cursor: pointer;
`;
export const RemoveIcon = styled.img`
  margin-top: -35px;
`;
export const EditButton = styled.div`
  
  background: #F8F1EB;
  width: 30px;
  border-radius: 6px;
  height: 34px;
  margin-left: 300px;
  text-align: right;
  cursor: pointer;
`;
export const NoticeTableContainer = styled.div`
  margin-left: 15px;
  margin-right: -30px;
  margin-top: 20px;
  overflow:hidden;
  max-height: 85%;
`;

export const NoticeTableWrapper = styled.div`
  max-width: 90%;
`;
export const TableContainer = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

export const TableWrapper = styled.div`
  max-width: 90%;
`;

export const StyledTable = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
  }

  tbody{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(58vh - 120px);
    max-height: calc(60vh - 120px);
    display: block;
    width: 100%;
    tr {
      border: 1px solid #E6E9F4;
      border-radius: 10px;
      box-sizing: border-box;
      margin: 7px 3px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
  }
`;
export const StyledTableStaff = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    th {
      border: none;
      word-break: break-word;
    }
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
    
  }

  tbody{
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(58vh - 120px);
    max-height: calc(60vh - 120px);
    display: block;
    width: 110%;
    tr {
      border: 1px solid #E6E9F4;
      border-radius: 10px;
      box-sizing: border-box;
      margin: 7px 3px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
  }
`;

export const Th = styled.th`

  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top:5px;
  }
`;

export const LoadingModal = styled(Modal)`
  .modal-dialog {
    max-width: ${(props) => props.width};
  }
  .modal-content {
    background: rgba(255, 255, 255, 0.01);
    border: none;
    width: auto;
    height: auto;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.blank{
    width: 47%;
  }
  input {
    border: none;
    width: 47%;
    padding: 0.5rem;
    border-radius: 5px;
    margin: 0.4rem 0.5rem;
  }
  input:focus{
    outline: none;
  }
  div.input-label-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label,
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper {
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        margin: 0 0.5rem 0.1rem;
      }
      label {
        font-size: 16px;
        width: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
`;
