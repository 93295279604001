/** @format */

import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Spinner
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import swal from 'sweetalert';
import moment from 'moment';
import { getIncidentsListByPage, editIncident, getUsersList } from '../../services/result.services';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import {
  IncidentsWrapper, IncidentManagementHeader,
  IconContainer, StatusContainer, IncidentManagmentSubHeader, SearchInputWrapper, StyledSelect
} from './index.style';
import {
  TableWrapper, StyledTable, TableContainer, Th, Td
} from '../../styledComponents/common/table.style';
import {
  Pagination
} from '../../styledComponents/common/page.style';
import caretLeftSmallIcon from '../../assets/logo/caret_left_small.svg';
import caretRightSmallIcon from '../../assets/logo/caret_right_small.svg';
import { BreadCrumb } from '../../styledComponents/common/breadcrumb.style';
import caretRightIcon from '../../assets/logo/caret_right.svg';
import detailIcon from '../../assets/logo/detail.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import NoData from '../../components/NoData';

const reportableOptions = [
  { label: 'All', value: 'All' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
];

const AdminIncidents = () => {
  const tableBodyRef = useRef('');
  const [incidentList, setIncidentList] = useState([]);
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [isClientIncidents, setIsClientIncidents] = useState(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [searchClientName, setSearchClientName] = useState('');
  const [reportable, setReportable] = useState('All');
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userList, setUserList] = useState();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [raisedForFlag, setRaisedForFlag] = useState('CLIENT');
  const PER_PAGE = 10;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const incidentListRequestObj = {
    pageSize: PER_PAGE,
    pageNumber: currentPage,
    raisedFor: raisedForFlag
  };

  const getIncidentListHandler = async (param) => {
    setLoading(true);
    const resp = await getIncidentsListByPage(param);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setIncidentList([...resp.data.incidentsByPage.filter((incident) => incident.status !== 'INACTIVE')]);
      setPageCount(Math.ceil(resp.data.totalIncidentsByPage / PER_PAGE));
    } else {
      setLoading(false);
    }
  };

  const getUsersHandler = async () => {
    setLoading(true);
    const resp = await getUsersList();
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setUserList([{ value: 'All', label: 'All' }, ...resp.data.map((item) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`
      }))]);
    }
  };

  const handlePageChange = (data) => {
    const { selected } = data;
    setCurrentPage(selected);
    if (raisedForFlag === 'CLIENT') {
      if (searchClientName.length > 2 && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          clientName: searchClientName,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (searchClientName.length > 2 && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          clientName: searchClientName,
          raisedFor: raisedForFlag
        });
      } else if (searchClientName === '' && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (searchClientName === '' && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          raisedFor: raisedForFlag
        });
      }
    } else if (raisedForFlag === 'STAFF') {
      if (selectedUserId !== 'All' && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          reportedById: selectedUserId,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (selectedUserId !== 'All' && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          reportedById: selectedUserId,
          raisedFor: raisedForFlag
        });
      } else if (selectedUserId === 'All' && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (selectedUserId === 'All' && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: selected,
          raisedFor: raisedForFlag
        });
      }
    }
  };

  const handleDeleteIncident = async (incident) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const {
        category, type, classification, id,
        client, house, ...restIncidentDetails
      } = incident;
      const resp = await editIncident(id,
        {
          ...restIncidentDetails,
          categoryId: category.id,
          clientId: client?.id,
          houseId: house?.id,
          classificationId: classification.id,
          typeId: type.id,
          status: 'INACTIVE'
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Incident deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getIncidentListHandler(incidentListRequestObj);
      } else {
        setLoading(false);
        swal({
          title: 'Incident deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };

  useEffect(() => {
    checkScrollbar();
  });

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    getIncidentListHandler(incidentListRequestObj);
    getUsersHandler();
  }, []);

  useEffect(() => {
    setRaisedForFlag(isClientIncidents ? 'CLIENT' : 'STAFF');
    if (raisedForFlag === 'CLIENT') {
      if (searchClientName.length > 2 && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          clientName: searchClientName,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (searchClientName.length > 2 && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          clientName: searchClientName,
          raisedFor: raisedForFlag
        });
      } else if (searchClientName === '' && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (searchClientName === '' && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          raisedFor: raisedForFlag
        });
      }
    } else if (raisedForFlag === 'STAFF') {
      if (selectedUserId !== 'All' && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          reportedById: selectedUserId,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (selectedUserId !== 'All' && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          reportedById: selectedUserId,
          raisedFor: raisedForFlag
        });
      } else if (selectedUserId === 'All' && reportable !== 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          reportableToNDIS: reportable,
          raisedFor: raisedForFlag
        });
      } else if (selectedUserId === 'All' && reportable === 'All') {
        getIncidentListHandler({
          pageSize: PER_PAGE,
          pageNumber: 0,
          raisedFor: raisedForFlag
        });
      }
    }
  }, [reportable, searchClientName, selectedUserId]);
  useEffect(() => {
    setRaisedForFlag(isClientIncidents ? 'CLIENT' : 'STAFF');
    getIncidentListHandler({
      pageSize: PER_PAGE,
      pageNumber: 0,
      reportedById: selectedUserId === 'All' ? '' : selectedUserId,
      raisedFor: isClientIncidents ? 'CLIENT' : 'STAFF'
    });
  }, [isClientIncidents]);

  return (
    <>
      <BreadCrumb>
        <ul>
          <li>
            <span> Home </span>
            <img className="caret-icon" src={caretRightIcon} alt="caret icon" />
          </li>
          <li>
            <span> Incidents </span>
          </li>
        </ul>
      </BreadCrumb>
      <IncidentsWrapper>
        <IncidentManagementHeader>
          <h2>INCIDENTS</h2>
          {isClientIncidents
            ? (
              <button
                type="button"
                onClick={() => { setIsClientIncidents(false); setSearchClientName(''); setSelectedUserId(''); setReportable('All'); }}
              >
                Staff
              </button>
            )
            : (
              <button
                type="button"
                onClick={() => { setIsClientIncidents(true); setSearchClientName(''); setSelectedUserId(''); setReportable('All'); }}
              >
                Client
              </button>
            )}
        </IncidentManagementHeader>
        <IncidentManagmentSubHeader>
          <SearchInputWrapper>
            {isClientIncidents
              ? (
                <div className="client-search-wrapper">
                  <div className="client-label">Client Name</div>
                  <div className="client-search">
                    <span>
                      {' '}
                      <FaSearch />
                    </span>

                    <input
                      placeholder="Client Name"
                      onChange={(e) => {
                        setSearchClientName(e.target.value);
                      }}
                      value={searchClientName}
                    />

                    <button
                      type="button"
                      className="delete-btn"
                      onClick={() => setSearchClientName(() => '')}
                    >

                      <img src={deleteIcon} alt="delete icon" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="client-label">
                  Reported By
                  <StyledSelect
                    options={userList}
                    width="100%"
                    value={userList.filter((item) => item.value === selectedUserId)}
                    onChange={(e) => setSelectedUserId(e.value)}
                  />
                </div>
              )}
            <div className="reportable-field">
              <div className="reportable-label">Reportable</div>
              <StyledSelect
                reportable
                options={reportableOptions}
                width="100%"
                value={reportableOptions.filter((item) => item.value === reportable)}
                onChange={(e) => setReportable(e.value)}
              />
            </div>
          </SearchInputWrapper>
          <div style={{ marginLeft: '250px' }}>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              previousLabel={<img className="caret-icon" src={caretLeftSmallIcon} alt="caret icon" />}
              nextLabel={<img className="caret-icon" src={caretRightSmallIcon} alt="caret icon" />}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
            />
          </div>
        </IncidentManagmentSubHeader>
        <TableContainer>
          <TableWrapper>
            <StyledTable>
              <thead
                className={`${
                  isScrollbar ? 'thead-scroll' : 'thead-noscroll'
                }`}
              >
                {
                  incidentList.length !== 0
                && (
                <tr>
                  {
                    isClientIncidents && <Th width="100px">Client</Th>
                  }
                  {
                    !isClientIncidents && <Th width="100px">Reported Date</Th>
                  }
                  <Th width="120px"> Type </Th>
                  <Th width="100px">
                    Reportable
                  </Th>
                  <Th width="120px"> Reported By </Th>
                  <Th width="120px">Reviewed By</Th>
                  <Th width="120px"> Responsibility Of </Th>
                  <Th width="120px">Closed By</Th>
                  <Th width="100px">
                    Status
                  </Th>
                  <Th width="100px" align="center">Actions </Th>
                </tr>
                )
                }
              </thead>
              <tbody ref={tableBodyRef}>
                {incidentList
                  && incidentList.map((incident) => (
                    <tr key={incident.id}>
                      {
                        isClientIncidents && (
                        <Td width="100px" data-testid="clientName">
                          {incident?.client?.name}
                        </Td>
                        )
                      }
                      {
                        !isClientIncidents && (
                        <Td width="100px">
                          {moment(incident.createdAt).format('DD/MM/YYYY')}
                        </Td>
                        )
                      }
                      <Td width="120px">{incident.type.name}</Td>
                      <Td width="100px">{incident.reportableToNDIS}</Td>
                      <Td width="120px">{incident.reportedBy.firstName}</Td>
                      <Td width="120px">{incident.reviewedBy}</Td>
                      <Td width="120px">{incident.followUpResponsibility}</Td>
                      <Td width="120px">{incident.closedBy}</Td>
                      <Td width="100px">
                        <StatusContainer>
                          {incident.status === 'CLOSED' && <span className="closed">{incident.status}</span>}
                          {incident.status === 'REVIEWED' && <span className="reviewed">{incident.status}</span>}
                          {incident.status === 'RAISED' && <span className="raised">{incident.status}</span>}
                        </StatusContainer>
                      </Td>
                      <Td width="100px" align="center">
                        {
                          isClientIncidents && incident.status === 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/client/closed/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        {
                          isClientIncidents && incident.status !== 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/client/open/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        {
                          !isClientIncidents && incident.status === 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/staff/closed/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        {
                          !isClientIncidents && incident.status !== 'CLOSED'
                          && (
                            <Link
                              to={{ pathname: `/admin/incidents/staff/open/${incident.id}` }}
                            >
                              <IconContainer>
                                <img src={detailIcon} alt="detail icon" />
                              </IconContainer>
                            </Link>
                          )
                        }
                        <IconContainer
                          className="delete-btn"
                          onClick={() => handleDeleteIncident(incident)}
                        >
                          <img src={deleteIcon} alt="delete icon" />
                        </IconContainer>
                      </Td>
                    </tr>
                  ))}
                {
                    incidentList.length === 0 && <NoData />
                }
              </tbody>
            </StyledTable>
          </TableWrapper>
        </TableContainer>
      </IncidentsWrapper>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
    </>
  );
};

export default AdminIncidents;
