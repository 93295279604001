/** @format */
import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div.input-with-label{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  div.input-with-full-width {
    width:100%;
    border:1px solid black;
    margin: 0.4rem 0.5rem;
    background: ${({ theme }) => theme.colors.od_neutral};
    border: 1px solid #F2E4D8;
    border-radius: 10px;
    height:50px;
    display:flex;
    align-items:center;
    font-size:15px;
    color:  ${({ theme }) => theme.colors.od_text_color};

    .container {
      display: block;
      position: relative;
      width: fit-content;
      margin: 0.5rem 1.2rem;
      padding-left: 2rem;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .container > span:first-child {
      position: relative;
    }
    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
      border-radius: 50%;
    }
  
    /* On mouse-over, add a primary background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }
  
    /* When the checkbox is checked, add a primary background */
    .container input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.od_primary};
    }
  
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
  
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }
  
    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 7px;
      top: 3px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .form-checkbox {
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      vertical-align: middle;
      border: 0.5px solid rgba(116, 188, 169, 0.3);
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
      margin: 0 18px;
      display:flex;
      align-items:center;
      justify-content:center;
    }

    .form-checkbox:checked {
      background-color:  ${({ theme }) => theme.colors.od_primary};
    }
    
    .form-checkbox:checked:after {
      content: '✔';
      font-size: 12px;
      color:${({ theme }) => theme.colors.od_neutral};
      transition: all .2s;
    }
    
  }

  div.form-control-wrapper{
    width: 49%;
    display: flex;
    justify-content: space-between;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type="date"]{
    color: gray;
  }

  select:invalid {
    color: gray;
  }

  input:focus, select:focus{
    outline: none;
  }
`;
export const AssignHouseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  div.input-label-wrapper{
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label, label{
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input, select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
      background-color: ${({ theme }) => theme.colors.od_neutral};
    }
    .icon-name-wrapper{
      margin: 0.4rem 0.5rem;
      padding: 0.4rem;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.od_neutral};
      .icon{
        width: 25px;
        margin-right: 10px;
      }
    }
  }
 
  select:invalid {
    color: gray;
  }

  select:focus{
    outline: none;
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0.1rem 0 0 1rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;
