import React from 'react';

export default function DailyNotes() {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M0.95 23.2591C0.95 25.3086 2.58396 26.9832 4.61799 27.0483L4.6159 27.0503H4.7409C5.7486 27.0503 6.70074 26.6569 7.42579 25.9397L7.42616 25.9393C7.99756 25.3619 8.3637 24.6355 8.48678 23.8506H19.0329C19.3598 23.8506 19.6245 23.5859 19.6245 23.2591V4.13629C19.6245 3.80969 19.36 3.54473 19.0329 3.54473H1.54156C1.2147 3.54473 0.95 3.80944 0.95 4.13629V23.2591ZM2.1333 23.2592V4.72823L18.4413 4.72814L18.4411 22.6679H7.9406C7.61374 22.6679 7.34904 22.9326 7.34904 23.2594C7.34904 23.9535 7.07765 24.6094 6.58933 25.1031C6.09122 25.5957 5.43477 25.867 4.74093 25.867C3.30323 25.867 2.1333 24.6971 2.1333 23.2592Z" />
        <path d="M4.74224 27.0478H19.4513H19.4514C21.5425 27.0478 23.2435 25.3469 23.243 23.2564C23.243 22.9298 22.9786 22.6648 22.6515 22.6648H7.94185C7.615 22.6648 7.35029 22.9296 7.35029 23.2564C7.35029 24.6945 6.18041 25.8647 4.74224 25.8647C4.41539 25.8647 4.15068 26.1294 4.15068 26.4562C4.15068 26.7828 4.41513 27.0478 4.74224 27.0478ZM19.4513 25.8645H7.49154C8.00802 25.32 8.36553 24.6223 8.48739 23.8477H21.9921C21.7236 25.0019 20.6863 25.8645 19.4513 25.8645Z" />
        <path d="M12.8814 4.72797V4.72797L12.8841 4.67796C13.1823 4.67649 13.4232 4.4345 13.4229 4.13618H13.4729C13.4729 4.13616 13.4729 4.13615 13.4729 4.13614C13.4729 2.37909 12.0436 0.95 10.2867 0.95C8.52993 0.95 7.10057 2.37912 7.10057 4.13642C7.10057 4.46301 7.36502 4.72797 7.69213 4.72797H12.8814ZM12.2007 3.54462H8.37306C8.62598 2.72822 9.3883 2.13333 10.2868 2.13333C11.1855 2.13333 11.9478 2.72822 12.2007 3.54462Z" />
        <path d="M5.75616 7.43585H14.8185C15.1454 7.43585 15.4101 7.1709 15.4101 6.84429C15.4101 6.5177 15.1457 6.25273 14.8185 6.25273H5.75616C5.4293 6.25273 5.1646 6.51744 5.1646 6.84429C5.1646 7.17089 5.42904 7.43585 5.75616 7.43585Z" />
        <path d="M6.88806 10.8616H13.6859C13.686 10.8616 13.6861 10.8616 13.6862 10.8616C14.0132 10.8615 14.2777 10.5966 14.2777 10.2701C14.2777 9.94348 14.0133 9.67852 13.6862 9.67852H6.88806C6.5612 9.67852 6.2965 9.94323 6.2965 10.2701C6.2965 10.5967 6.56094 10.8616 6.88806 10.8616Z" />
        <path d="M5.75616 14.2874H14.8185C15.1454 14.2874 15.4101 14.0225 15.4101 13.6959C15.4101 13.3693 15.1457 13.1043 14.8185 13.1043H5.75616C5.4293 13.1043 5.1646 13.369 5.1646 13.6959C5.1646 14.0225 5.42904 14.2874 5.75616 14.2874Z" />
        <path d="M6.88806 17.7132H13.6859C13.686 17.7132 13.6861 17.7132 13.6862 17.7132C14.0132 17.7131 14.2777 17.4482 14.2777 17.1216C14.2777 16.795 14.0133 16.5301 13.6862 16.5301H6.88806C6.5612 16.5301 6.2965 16.7948 6.2965 17.1216C6.2965 17.4482 6.56094 17.7132 6.88806 17.7132Z" />
        <path d="M5.75616 21.139H14.8185C15.1454 21.139 15.4101 20.874 15.4101 20.5474C15.4101 20.2208 15.1457 19.9559 14.8185 19.9559H5.75616C5.4293 19.9559 5.1646 20.2206 5.1646 20.5474C5.1646 20.874 5.42904 21.139 5.75616 21.139Z" />
      </g>
    </svg>
  );
}
