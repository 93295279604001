/** @format */
import styled from 'styled-components';
import SelectContainer from '../../components/SelectContainer';

export const BreadCrumb = styled.div`
  margin: 1.8rem 0;
  ul {
    display: flex;
    align-items: bottom;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    li:last-child {
      color: ${({ theme }) => theme.colors.od_secondary};
    }

    img {
      width: 8px;
    }
    img.home-icon {
      width: 30px;
    }
    img.caret-icon {
      margin: 0 1rem;
    }
  }
`;

export const ContactsWrapper = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  min-height: calc(75vh - 120px);
  img {
    width: 12px;
    height: auto;
  }
`;

export const ContactManagementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-bottom: 2px solid #e6e9f4;

  h2 {
    font-size: 20px;
    padding: 20px;
    margin-bottom: -5px;
    color: ${({ theme }) => theme.colors.od_secondary};
    border-bottom: 4px solid ${({ theme }) => theme.colors.od_secondary};
  }

  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 10px;
    margin: 0 20px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
`;

export const IconContainer = styled.button`
  padding: 0.4rem 0.5rem;
  margin-top: 0.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.od_bg_secondary};
  border: none;
  img {
    width: 14px !important;
    height: auto;
    margin-left: 0;
  }
  &.delete-btn{
    margin: 0.1rem 0 0 1rem;
    padding: 0;
    img{
      width: 30px !important;
    }
  }
`;

export const IconButton = styled.button`
  background: #F8F1EB;
  border-radius: 6px;
  border:none;
  height:33px;
  width:33px;
`;
export const ContactHeader = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 2px solid #E6E9F4;

  h2{
    font-size: 18px;
    padding: 20px;
    margin-bottom: -5px;
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 5px;
    font-size: 15px;
    padding: 8px 10px;
    margin: 7px;
    border: none;

    img {
      margin: 0 10px 2px 0;
    }
  }
  }
`;
export const TableContainer = styled.div`
  margin: 0 2.5rem 1rem;
  background-color: white;
  margin-top: 10px;
  border-radius: 5px;
`;

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  
`;

export const StyledTable = styled.table`
  border-collapse: unset;
  font-size: 16px;
  thead {
    padding-right: 10px;
    vertical-align: top;
    border-bottom: 1px solid #E6E9F4;
    background-color: white;
    th {
      border: none;
      word-break: break-word;
    }
  }
  .sc-iktFfs eiXXSa {
      background-color: white;
  }
  thead.thead-scroll{
    width: calc(100% - 14px);
  }
  thead.thead-noscroll{
    width: 100%;
  }
  thead,
  tbody tr {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    padding: 0.5rem;
  }

  tbody{
    min-height: calc(58vh - 120px);
    max-height: calc(60vh - 120px);
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    tr {
      border-bottom: 1px solid #E6E9F4;
      box-sizing: border-box;
      margin: 0 5px;
      color: ${({ theme }) => theme.colors.od_table_color} !important;
      width: calc(100% - 10px);
      td {
        border: none;
        word-break: break-word;
      }
    }
    tr:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Th = styled.th`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};
`;

export const Td = styled.td`
  width: ${({ width }) => width};
  text-align: ${({ align }) => align};

  .delete-btn {
    margin-top:5px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div.select-house {
    margin-left: 0.4rem;
    width: 100.4%;
  }

  div.input-with-label {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
    }
    input,
    select {
      width: 100%;
      padding: 0.5rem;
      margin: 0.4rem 0.5rem;
      border-radius: 5px;
      border: none;
    }
  }

  div.input-with-full-width {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem 0;
    label {
      font-size: ${({ theme }) => theme.fonts.font_size.small_font};
      margin: 0;
      padding: 0 1rem;
      margin-bottom: 3px;
    }
    select {
      width: 100%;
      padding: 0.5rem 0;
      border-radius: 5px;
      border: none;
    }
  }

  div.form-control-wrapper {
    width: 49%;
    display: flex;
    justify-content: space-between;
  }

  select {
    background-color: ${({ theme }) => theme.colors.od_neutral};
  }
  input[type='date'] {
    color: gray;
  }

  select:invalid {
    color: gray;
  }

  input:focus,
  select:focus {
    outline: none;
  }
`;

export const StyledSelectContainer = styled(SelectContainer)``;
