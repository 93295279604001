/** @format */

import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { AiOutlineEdit } from 'react-icons/ai';
import plusIcon from '../../assets/logo/plus.svg';
import deleteIcon from '../../assets/logo/close_cross.svg';
import { checkIfAdminOnly, checkIfHouseAdminOnly, getUserDetails } from '../../helpers/utils';
import {
  ContactHeader, TableWrapper, StyledTable, Th, Td, TableContainer,
  FormWrapper, StyledSelectContainer, IconContainer, IconButton
} from './index.style';
import CustomModal from '../../components/CustomModal';
import {
  createImportantContact, getHouseList, getImportantContacts, updateImportantContact
} from '../../services/result.services';
import { contact as contactObj } from '../../lookups/lookups';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import NoData from '../../components/NoData';

const AdminImportantcontacts = ({ selectedHouseValue }) => {
  const tableBodyRef = useRef('');
  const [isScrollbar, setIsScrollbar] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactInfo, setContactInfo] = useState(contactObj);
  const [houseList, setHouseList] = useState([]);
  const [houseCodeList, setHouseCodeList] = useState([]);
  const [showCustomModalAddHouse, setShowCustomModalAddContact] = useState(false);
  const [showCustomModalUpdateHouse, setShowCustomModalUpdateContact] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [contactInfoUnchanged, setContactInfoUnchanged] = useState(contactObj);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSelectHouse = (selectedHouses) => {
    const isAllSelected = selectedHouses.some((house) => house.value === 'ALL');
    if (isAllSelected) {
      setContactInfo((prevState) => ({
        ...prevState,
        houseCode: [{ label: 'ALL', value: 'ALL' }]
      }));
    } else {
      setContactInfo((prevState) => ({
        ...prevState,
        houseCode: selectedHouses
      }));
    }
  };
  const checkScrollbar = () => {
    const {
      current: { clientHeight, scrollHeight }
    } = tableBodyRef;
    if (scrollHeight > clientHeight && !isScrollbar) {
      setIsScrollbar(true);
    }
    if (scrollHeight <= clientHeight && isScrollbar) {
      setIsScrollbar(false);
    }
  };
  useEffect(() => {
    checkScrollbar();
  });

  const clearInput = () => {
    setContactInfo(contactObj);
  };

  const handleCloseCustomModal = (operation) => {
    if (operation === 'add-contact') {
      setShowCustomModalAddContact(false);
    } else if (operation === 'update-contact') {
      setShowCustomModalUpdateContact(false);
    }
  };

  const getHousesHandler = async () => {
    const resp = await getHouseList();
    if (!resp) return;
    if (resp.status === 200) {
      const filteredActiveHouses = resp.data.filter((house) => !house.deleted);
      const filteredHouses = filteredActiveHouses.filter(
        (house) => getUserDetails().houseList.some((houseCode) => houseCode === house.houseCode)
      );
      const houses = filteredHouses.map((house) => ({
        label: house.houseCode,
        value: house.houseCode
      }));
      setHouseList([{ label: 'ALL', value: 'ALL' }, ...houses]);
      setHouseCodeList([...houses.map((ele) => ele.value)]);
    } else {
      setHouseList([]);
    }
  };

  const checkValidation = () => {
    if (contactInfo.houseCode.length < 1) {
      setValidationMsg('Please select a house');
      return false;
    }
    if (contactInfo.firstName === '') {
      setValidationMsg('Please enter first name');
      return false;
    }
    if (contactInfo.email === '') {
      setValidationMsg('Please enter email');
      return false;
    }
    if (contactInfo.email !== '') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(contactInfo.email).toLowerCase())) {
        setValidationMsg('Please enter a valid email');
        return false;
      }
    }
    if (contactInfo.caption === '') {
      setValidationMsg('Please add a caption');
      return false;
    }
    if (contactInfo.phone1 !== '') {
      const re = /^\({0,1}((0|\+61)(2|3|4|5|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      const tollFreeRe = /^1[3|8]00[0-9]{6}$/;
      if ((!re.test(String(contactInfo.phone1)))
      && (!tollFreeRe.test(String(contactInfo.phone1)))) {
        setValidationMsg('Enter a valid Phone1 number');
        return false;
      }
    }
    if (contactInfo.phone2 !== '') {
      const re = /^\({0,1}((0|\+61)(2|3|4|5|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/;
      const tollFreeRe = /^1[3|8]00[0-9]{6}$/;
      if ((!re.test(String(contactInfo.phone2)))
      && (!tollFreeRe.test(String(contactInfo.phone2)))) {
        setValidationMsg('Enter a valid Phone2 number');
        return false;
      }
    }
    return true;
  };

  const getHouseCode = (house) => {
    if (house.length === 1 && house[0].value === 'ALL') {
      return houseCodeList.map((ele) => ele);
    }
    return house.map((ele) => ele.value);
  };

  const resetContactInfo = () => {
    setContactInfo(contactObj);
  };

  const compareContact = () => (
    JSON.stringify(contactInfo) === JSON.stringify(contactInfoUnchanged)
  );

  const restructureHouseOption = (houses) => {
    if (houses.length === houseCodeList.length) {
      return [{ label: 'ALL', value: 'ALL' }];
    }
    return [...houses.map((ele) => ({ label: ele.houseCode, value: ele.houseCode }))];
  };

  const handleAddContact = () => {
    clearInput();
    setValidationMsg('');
    setShowCustomModalAddContact(true);
  };

  const handleEditContact = (id) => {
    const contactToBeUpdated = contactList.find((contact) => contact.id === id);
    const modifiedContact = {
      ...contactToBeUpdated,
      houseCode: restructureHouseOption(contactToBeUpdated.houses)
    };
    setSelectedContact(id);
    setContactInfo(modifiedContact);
    setContactInfoUnchanged(modifiedContact);
    setValidationMsg('');
    setShowCustomModalUpdateContact(true);
  };

  const getImportantContactsHandler = async (status) => {
    setLoading(true);
    let payload = {
      pageNumber: 0,
      pageSize: 1000
    };
    if (selectedHouseValue && selectedHouseValue !== 'ALL') {
      payload = {
        ...payload,
        houseCode: selectedHouseValue
      };
    }
    const resp = await getImportantContacts(payload);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      const filteredContacts = resp.data.filter(
        (contact) => contact.houses.some((house) => (
          getUserDetails().houseList.some(
            (houseCode) => houseCode === house.houseCode
          )))
      );
      setContactList([...filteredContacts.filter((contact) => contact.status === status)
        .sort((a, b) => a.id - b.id)]);
    } else {
      setLoading(false);
    }
  };

  const addContact = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const resp = await createImportantContact(
      {
        ...contactInfo,
        lastUpdatedBy: getUserDetails().firstName,
        houseCode: getHouseCode(contactInfo.houseCode)
      }
    );
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Contact created successfully!',
        icon: 'success',
        timer: 3000
      });
      getImportantContactsHandler('ACTIVE');
      setValidationMsg('');
      handleCloseCustomModal('add-contact');
      resetContactInfo();
    } else {
      setLoading(false);
      swal({
        title: 'Contact creation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      handleCloseCustomModal('add-contact');
      resetContactInfo();
    }
  };

  const editContact = async () => {
    if (!checkValidation()) return;
    setLoading(true);
    const {
      id, houses, lastUpdatedAt, ...restContactInfo
    } = contactInfo;
    const resp = await updateImportantContact(selectedContact,
      {
        ...restContactInfo,
        lastUpdatedBy: getUserDetails().firstName,
        houseCode: getHouseCode(contactInfo.houseCode)
      });
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Contact updated successfully!',
        icon: 'success',
        timer: 3000
      });
      getImportantContactsHandler('ACTIVE');
      setValidationMsg('');
      handleCloseCustomModal('update-contact');
      resetContactInfo();
    } else {
      setLoading(false);
      swal({
        title: 'Contact updation failed!',
        icon: 'error',
        timer: 3000
      });
      setValidationMsg('');
      handleCloseCustomModal('update-contact');
      resetContactInfo();
    }
  };

  const handleDeleteContact = async (contact) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const {
        id, houses, lastUpdatedAt, ...restContactInfo
      } = contact;
      const resp = await updateImportantContact(contact.id,
        {
          ...restContactInfo,
          status: 'INACTIVE',
          lastUpdatedBy: getUserDetails().firstName,
          houseCode: contact.houses.map((ele) => ele.houseCode)
        });
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Contact deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getImportantContactsHandler('ACTIVE');
      } else {
        setLoading(false);
        swal({
          title: 'Contact deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    getHousesHandler();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    getImportantContactsHandler('ACTIVE');
  }, [selectedHouseValue]);

  return (
    <>
      <ContactHeader>
        <h2>IMPORTANT CONTACTS </h2>
        {
            (checkIfHouseAdminOnly() || checkIfAdminOnly())
             && (
             <button type="button" onClick={() => handleAddContact()}>
               <img src={plusIcon} alt="plus icon" />
               Add New
             </button>
             )
          }
      </ContactHeader>
      <TableContainer>
        <TableWrapper>
          <StyledTable>
            <thead
              className={`${
                isScrollbar ? 'thead-scroll' : 'thead-noscroll'
              }`}
            >
              {
                   contactList.length !== 0
                && (
                <tr>
                  <Th width="100px">Caption</Th>
                  <Th width="180px">Name</Th>
                  <Th width="140px">Email</Th>
                  <Th width="140px">Phone</Th>
                  <Th width="140px">Notes</Th>
                  <Th width="120px">House</Th>
                  <Th width="60px" align="right">Action</Th>
                  <Th width="40px" align="left" />
                </tr>
                )
                }
            </thead>
            <tbody ref={tableBodyRef}>
              { contactList
              && contactList.map((contact) => (
                <tr key={contact.id}>
                  <Td width="100px" data-testid="contact">{contact.caption}</Td>
                  <Td width="180px" data-testid="user">
                    {' '}
                    {`${contact.firstName} ${contact.lastName}`}
                  </Td>
                  <Td width="140px" data-testid="user">{contact.email}</Td>
                  <Td width="140px" data-testid="user">
                    {' '}
                    {`${contact.phone1}${contact.phone1 && contact.phone2 && ', '}${contact.phone2 && `${contact.phone2}`}`}
                  </Td>
                  <Td width="140px">
                    {' '}
                    {contact.notes}
                  </Td>
                  <Td width="120px">
                    {' '}
                    {contact.houses.map((ele) => ele.houseCode).join(', ')}
                  </Td>
                  <Td width="60px" align="right">
                    <IconButton
                      data-testid="edit-button"
                      type="button"
                      onClick={() => handleEditContact(contact.id)}
                    >
                      <AiOutlineEdit color="#E86B55" />
                    </IconButton>
                  </Td>
                  <Td width="40px" align="left">
                    <IconContainer
                      className="delete-btn"
                      onClick={() => handleDeleteContact(contact)}
                    >
                      <img src={deleteIcon} alt="delete icon" />
                    </IconContainer>
                  </Td>
                </tr>
              ))}
              {
                  contactList.length === 0 && <NoData />
                }
            </tbody>
          </StyledTable>
        </TableWrapper>
      </TableContainer>
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <LoadingModal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </LoadingModal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalAddHouse}
        setClose={() => {
          handleCloseCustomModal('add-contact');
        }}
        onSubmit={addContact}
        title="Add Important Contact"
        width="850px"
        operationName="Add"
      >
        <div>
          <FormWrapper>
            <div className="input-with-full-width">
              <label htmlFor="house">Select Houses</label>
              <div className="select-house">
                <StyledSelectContainer
                  id="house"
                  options={houseList}
                  isMultiple
                  defaultValue={contactInfo.houseCode}
                  onChange={handleSelectHouse}
                />
              </div>
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="fname">First Name</label>
                <input
                  id="fname"
                  required
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={contactInfo.firstName}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="lname">Last Name</label>
                <input
                  id="lname"
                  required
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={contactInfo.lastName}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                required
                value={contactInfo.email}
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="caption">Caption</label>
              <input
                type="text"
                id="caption"
                required
                value={contactInfo.caption}
                name="caption"
                placeholder="Caption"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="note">Note</label>
              <input
                type="text"
                id="note"
                required
                value={contactInfo.notes}
                name="notes"
                placeholder="Note"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                id="address1"
                value={contactInfo.address1}
                name="address1"
                placeholder="Address 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="houses">Address 2</label>
              <input
                id="address2"
                type="text"
                value={contactInfo.address2}
                name="address2"
                placeholder="Address 2"
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  value={contactInfo.city}
                  name="city"
                  placeholder="City"
                  onChange={handleChange}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={contactInfo.state}
                  name="state"
                  placeholder="State"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="zip">Postcode</label>
              <input
                type="text"
                id="zip"
                value={contactInfo.zip}
                name="zip"
                placeholder="Postcode"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone1">Phone 1</label>
              <input
                type="text"
                id="phone1"
                value={contactInfo.phone1}
                name="phone1"
                placeholder="Phone 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone2">Phone 2</label>
              <input
                type="text"
                id="phone2"
                value={contactInfo.phone2}
                name="phone2"
                placeholder="Phone 2"
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
      <CustomModal
        open={showCustomModalUpdateHouse}
        setClose={() => {
          handleCloseCustomModal('update-contact');
        }}
        isDisabled={compareContact()}
        onSubmit={editContact}
        title="Edit Important Contact"
        width="850px"
        operationName="Update"
      >
        <div>
          <FormWrapper>
            <div className="input-with-full-width">
              <label htmlFor="house">Select Houses</label>
              <div className="select-house">
                <StyledSelectContainer
                  id="house"
                  options={checkIfHouseAdminOnly() ? houseList.slice(1) : houseList}
                  isMultiple
                  defaultValue={contactInfo.houseCode}
                  onChange={handleSelectHouse}
                />
              </div>
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="fname">First Name</label>
                <input
                  id="fname"
                  required
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={contactInfo.firstName}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="lname">Last Name</label>
                <input
                  id="lname"
                  required
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={contactInfo.lastName}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                required
                value={contactInfo.email}
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="caption">Caption</label>
              <input
                type="text"
                id="caption"
                required
                value={contactInfo.caption}
                name="caption"
                placeholder="Caption"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="note">Note</label>
              <input
                type="text"
                id="note"
                required
                value={contactInfo.notes}
                name="notes"
                placeholder="Note"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                id="address1"
                value={contactInfo.address1}
                name="address1"
                placeholder="Address 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="houses">Address 2</label>
              <input
                id="address2"
                type="text"
                value={contactInfo.address2}
                name="address2"
                placeholder="Address 2"
                onChange={handleChange}
              />
            </div>
            <div className="form-control-wrapper">
              <div className="input-with-label">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  value={contactInfo.city}
                  name="city"
                  placeholder="City"
                  onChange={handleChange}
                />
              </div>
              <div className="input-with-label">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={contactInfo.state}
                  name="state"
                  placeholder="State"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="input-with-label">
              <label htmlFor="zip">Postcode</label>
              <input
                type="text"
                id="zip"
                value={contactInfo.zip}
                name="zip"
                placeholder="Postcode"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone1">Phone 1</label>
              <input
                type="text"
                id="phone1"
                value={contactInfo.phone1}
                name="phone1"
                placeholder="Phone 1"
                onChange={handleChange}
              />
            </div>
            <div className="input-with-label">
              <label htmlFor="phone2">Phone 2</label>
              <input
                type="text"
                id="phone2"
                value={contactInfo.phone2}
                name="phone2"
                placeholder="Phone 2"
                onChange={handleChange}
              />
            </div>
          </FormWrapper>
          { validationMsg !== '' && <h6 className="m-3 text-danger">{validationMsg}</h6>}
        </div>
      </CustomModal>
    </>
  );
};
AdminImportantcontacts.propTypes = {
  selectedHouseValue: PropTypes.string.isRequired
};

export default AdminImportantcontacts;
