/* eslint-disable */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Dropdown, Spinner } from 'react-bootstrap';
import { listShifts } from '../../services/result.services';
import MoreIconRoster from '../../assets/logo/roster_more_icon.svg';
import {
  OrangeCell, TextWrapper, ShiftText, DropdownWrapper, DropdownToggle, DropdownMenu,
  TimeText, UserDiv, UserText
} from './index.style';

const AdminRosterClientShifts = ({
  clientName, clientId, setSelectedModal, setSelectedUser,
  setLoading, currentDate, startDate, endDate, setSelectedClientId,
  handleRemoveClientShift, setSelectedClientShift,
  setSelectedCurrentDate, handleAvailableUserSelected
}) => {
  const [shiftsData, setShiftsData] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(async () => {
    try {
      const payload = {
        startDate,
        endDate
      };
      setLoad(true);
      const resp = await listShifts(clientId, payload);
      if (!resp) {
        setLoading(false);
        setLoad(false);
      }
      if (resp.status === 200) {
        setShiftsData(resp.data.filter((x) => currentDate === x.date));
        setLoad(false);
      }
    } catch (error) {
      error.data = `Error msg is ${error.data}`;
      setLoad(false);
    }
  }, []);

  return (
    <>
      {load ? (
        <Spinner
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%'
          }}
          as="span"
          animation="border"
          variant="primary"
        />
      ) : null}
      {shiftsData.length > 0 && shiftsData.map((shifts, index) => (
        <OrangeCell backgroundColor={shifts.users.length > 0 || shifts.externalUsers.length > 0} key={`${shifts.shiftClientShiftsName}-${index}`}>
          <TextWrapper>
            <ShiftText>
              <div>
                {shifts.shiftClientShiftsName}
              </div>
              <DropdownWrapper>
                <DropdownToggle variant="borderless-dark" className="dropdown-toggle-options">
                  <img width={100} height={100} src={MoreIconRoster} alt="more-icon" />
                </DropdownToggle>
                <DropdownMenu right>
                  <Dropdown.Item onClick={() => handleRemoveClientShift(shifts, shifts.users)}>
                    Remove
                  </Dropdown.Item>
                  <Dropdown.Item style={{color: 'lightgrey'}} 
                  //   onClick={() => {
                  //   setSelectedModal('assign-user');
                  //   setSelectedUser(clientName);
                  //   setSelectedClientId(clientId);
                  //   setSelectedClientShift(shifts);
                  //   handleAvailableUserSelected(
                  //     clientId, [...shifts.users, ...shifts.externalUsers], shifts.shiftId
                  //   );
                  //   setSelectedCurrentDate(currentDate);
                  // }}
                  >
                    Allocate/Deallocate User
                  </Dropdown.Item>
                </DropdownMenu>
              </DropdownWrapper>
            </ShiftText>
            <TimeText>
              {moment(shifts.shiftClientShiftsStartTime).format('HH:mm:ss A')}
              {' '}
              {moment(shifts.shiftClientShiftsEndTime).format('HH:mm:ss A')}
            </TimeText>
            <UserDiv>
              {shifts.users?.map((user) => (
                <UserText>{user.firstName}</UserText>
              ))}
              {shifts.externalUsers?.map((user) => (
                <UserText>{user.firstName}</UserText>
              ))}
            </UserDiv>
          </TextWrapper>
        </OrangeCell>
      ))}
    </>
  );
};

AdminRosterClientShifts.propTypes = {
  clientName: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  currentDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSelectedModal: PropTypes.func.isRequired,
  setSelectedClientId: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  handleRemoveClientShift: PropTypes.func.isRequired,
  setSelectedClientShift: PropTypes.func.isRequired,
  handleAvailableUserSelected: PropTypes.func.isRequired,
  setSelectedCurrentDate: PropTypes.func.isRequired
};

export default AdminRosterClientShifts;
