import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import logoPic from '../../assets/logo/logo.png';

const generatePDFDailyNotes = (notesData) => {
  const col = ['Date', 'Start Time', 'End Time', 'User', 'Notes'];
  const rows = [];
  notesData.notes.forEach((item) => {
    rows.push([item.createdAt, item.startTime ? `${moment(item.startTime).format('hh:mm A')}` : null, item.endTime ? `${moment(item.endTime).format('hh:mm A')}` : null, item.createByFirstName, item.note]);
  });
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.addImage(logoPic, 'JPEG', 48, 5, 50, 50);
  doc.setFontSize(18);
  doc.setFont('helvetica');
  doc.text(
    'Daily Notes',
    340,
    35,
    null
  );
  doc.setFontSize(13);
  doc.setFont('helvetica bold');
  doc.text(
    'Client Name: ',
    50,
    75,
    null
  );
  doc.text(
    `${notesData?.clientName}`,
    150,
    75,
    null
  );
  doc.autoTable(
    col, rows, {
      startY: 90,
      theme: 'grid',
      headStyles:
    {
      fillColor: '#74BCA9',
      textColor: '#1c1c1c'
    }
    }
  );
  // doc.output('dataurlnewwindow');
  doc.save(`Daily Notes-${notesData?.clientName}`);
};

export default generatePDFDailyNotes;
