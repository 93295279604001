import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const IncidentDetailBoxContainer = styled.div`
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  height: 390px;
  overflow-y: scroll;
  overflow-x: hidden;
  .w-33 {
    flex: 0 0 32.5%;
  }
  .w-90 {
    flex: 0 0 91.5%;
  }
  .w-50 {
    flex: 0 0 45.5%;
  }
  .dot {
    display: inline-block;
    position: absolute;
    left: 1.3rem;
    top: 0.7rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.od_secondary};
  }
  .flex {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0.5rem 0.8% 0.2rem 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border: 1px solid #dddddd;
    border-radius: 10px;
    font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
    .label {
      font-size: 18px;
      padding-left: 2rem;
      padding-top: 0.2rem;
      margin: 0 7px;
      color: ${({ theme }) => theme.colors.od_placeholder};
    }
    .detail {
      font-size: 19px;
      margin: 0 7px;
      padding-left: 2rem;
      padding-bottom: 0.2rem;
      color: ${({ theme }) => theme.colors.od_text_color};
    }
  }
`;
