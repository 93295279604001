/** @format */

import styled from 'styled-components';

export const BackButton = styled.button`
  color: ${({ theme }) => theme.colors.od_neutral};
  background-color: ${({ theme }) => theme.colors.od_secondary};
  border-radius: 10px;
  width: 77px;
  font-size: 15px;
  border: none;
  margin: 10px 0;
  height: 41px;
  font-size: 13px;

  img {
    height: 8px;
    width: 11px;
    margin-right: 5px;
    margin-left: -5px;
  }
  &:disabled {
    opacity:0.6;
  }
`;

export const HeadingBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input {
	  padding: 0 8px;
	  min-height: 38px;
	  border-radius: 5px;
	  outline: 0;
	  border: 1px solid #F2E4D8;
	}

  .react-datepicker-popper {
    z-index:99;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  text-align: center;
  align-items: center;

  button {
    margin: 0 15px;
  }
`;

export const Heading = styled.h4`
  font-weight: 500;
  color: #74bca9;
  font-size: 18px;
  margin: 0 35px;
`;

export const DateBox = styled(Heading)`
  font-size: 16px;
`;

export const NightHeadingBoxWrapper = styled.div`
  height: 73px;
  background: ${({ theme }) => theme.colors.od_primary_transparent};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const HeadingsBoxWrapper = styled.div`
  display: flex;
  margin: 1rem 2rem;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;

  .time {
    margin-left:10px;
  }
  
  .download {
   margin-left:1170px;
  }
  .first-heading {
    width: 320px;
    position: relative;

    .dotted-lines {
      border-right: 2px dashed black;
      position: absolute;
      height: 100%;
      left: 16%;
    }
  }
  .second-heading { 
    flex-grow: 3;
  }
  .third-heading {
    width: 245px;
  }
  button {
    color: ${({ theme }) => theme.colors.od_neutral};
    background-color: ${({ theme }) => theme.colors.od_secondary};
    border-radius: 10px;
    border: none;
    height: 30px;
  }
`;

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .dotted-lines {
    border-right: 2px dashed ${({ theme }) => theme.colors.od_extra_color};
    height: 97%;
    width: 1px;
    position: absolute;
    margin-left: 8.7%;
    margin-bottom: 2px;
  }
`;

export const RowBoxWrapper = styled(HeadingsBoxWrapper)`
  height: 92px;
  border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
  align-items: center;
  border-radius: 10px;
  margin-bottom: 5px;

  .first-heading {
    height: 92px;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second-heading {
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;

    textarea {
      width: 100%;
      height: 92px;
      font-weight: 500;
      font-size: 16px;
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.od_bg_primary};
      padding: 30px;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }
  .third-heading {
    height: 92px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    .disabled {
      cursor: default !important;
    }

    .container {
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%;
      height: 89px;
      padding: 0;
      margin: 0;
    }

    .container > span:first-child {
      position: relative;
      top: 30px;
      left: 72px;
      width: 135px;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 38px;
      left: 16%;
      height: 20px;
      width: 20px;
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
      border-radius: 50%;
    }

    /* On mouse-over, add a primary background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    .disabled:hover input ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    }

    /* When the checkbox is checked, add a primary background */
    .container input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.od_primary};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 7px;
      top: 3px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

export const TimeBoxWrapper = styled.div`
  display: flex;
  width: 85%;
  height: 53px;
  background: #e6e9f4;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.od_placeholder};
  z-index: 1;

  img {
    height: 17px;
    width: 35px;
    margin: 0;
    padding: 0;
  }

  .text {
    width: 100px;
  }
  .dots {
    width: 20px;
    font-size: 24px;
    font-weight: 700;
    height: 45px;
  }
`;

export const NameBox = styled.span`
  display: flex;
  border: 0.2px solid rgba(134, 81, 137, 0.24);
  border-radius: 24px;
  width: 135px;
  padding: 3px;
  font-size: 12px;
  align-items: center;

  .icon-name-wrapper {
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.od_light_extra};
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;

    .icon {
      width: 12px;
      height: 12px;
      margin: auto;
    }
  }
  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.od_text_color};
  }
`;

export const RoundedDot = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.od_secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8.2%;
  margin-bottom: 9px;
  z-index: 1;

  .inside-roll {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.od_secondary};
  }

  @media only screen and (max-width: 1481px) {
    margin-left: 8%;
  }
`;
