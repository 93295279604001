/** @format */

import styled from 'styled-components';
import { Dropdown, Popover } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

export const HeaderRow = styled.div`
  width: 100%;
  height: 65px;
  background-color: ${({ theme }) => theme.colors.od_neutral};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  .logo {
    width: 80px;
    height: 65px;
    margin-left: 10px;
  }

  .links-section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .select-house-title {
    margin: 0 8px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.od_text_color};
    &:hover {
      color: ${({ theme }) => theme.colors.od_secondary};
    }
  }
  .house-num {
    color: ${({ theme }) => theme.colors.od_secondary};
  }
  .house-icon {
    display: inline-block;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.od_secondary};
    img {
      width: 30px;
      padding: 5px;
    }
  }

  .left {
    margin-right: 5rem;
  }
  .admin-left{
    margin-right: 1.5rem;
  }

  .faded {
    opacity: 0.5;
  }

  .right {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    margin-right: 250px;

    .icon-wrapper {
      margin: 0 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justiy-content: center;

      svg {
        margin-top: 5px;
      }
      img {
        width: 30px;
        height: 40px;
      }
    }
  }

  .button {
    padding: 10px;
    font-weight: 500;
    text-align: center;
    outline: none;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.od_text_color};
    &:hover {
      color: ${({ theme }) => theme.colors.od_secondary};
    }
  }
  .button:disabled {
    &:hover {
      color: ${({ theme }) => theme.colors.od_text_color};
    }
  }

  .section-3 {
    margin-left: -250px;
    margin-right: 10px;
  }

  .dropdown-toggle-logout::after {
    content:none;
   }
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
  background: ${(props) => props.theme.colors[props.bgcolor] || props.theme.colors.od_neutral};
  color: ${(props) => props.theme.colors[props.color] || props.theme.colors.od_text_color};
  border-radius: ${(props) => props.borderradius};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    background: ${(props) => props.theme.colors[props.bgcolor] || props.theme.colors.od_neutral};
    color: ${(props) => props.theme.colors[props.color] || props.theme.colors.od_secondary};
  }
  &:disabled{
    color: ${({ theme }) => theme.colors.od_text_color};
  }
`;

export const DropdownHouseMenu = styled(Dropdown.Menu)`
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 400px;
  padding: 0.75rem 0.5rem;

  .dropdown-item {
    display: block;
    width: 48%;
    font-size: 18px;
    border-radius: 5px;
    font-weight: 400;
    padding: 0.25rem;
    margin: 0.25rem 0;
    text-align: inherit;
    white-space: nowrap;
    background-color: ${({ theme }) => theme.colors.od_bg_secondary};
    color: ${({ theme }) => theme.colors.od_neutral};
    border: 0;
    & > button,
    .nav-link {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.od_text_color};
      width: 100%;
      text-align: left;
      padding: 5px 10px;
    }
    & > button.disabled-btn {
      opacity: 0.6;
    }
    & > button:hover,
    .nav-link:hover {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
      color: ${({ theme }) => theme.colors.od_text_color};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.od_bg_secondary};
      border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
    }
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

  .dropdown-item:first-child {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .dropdown-item:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    clear: both;
    font-size: 18px;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    & > button,
    .nav-link {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.colors.od_text_color};
      width: 100%;
      text-align: left;
      padding: 5px 10px;
    }
    & > button.disabled-btn {
      opacity: 0.6;
    }
    & > button:hover,
    .nav-link:hover {
      background-color: ${({ theme }) => theme.colors.od_neutral};
      color: ${({ theme }) => theme.colors.od_secondary};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.od_neutral};
      border-left: 3px solid ${({ theme }) => theme.colors.od_extra_color};
    }
  }
`;
export const SearchWrapper = styled.div`
  position:relative;
  padding: 12px 22px;
  width: 1000px;
  display: flex;
  justify-content: start;
`;

export const SearchInputWrapper = styled.div`
width: 400px;
 border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
 height: 42px; 
  `;
export const Heading = styled.div`
  font-weight: 1000;
  font-size: 25px;
  line-height: 15px;
  margin-left: 10px;
`;
export const SearchSelect = styled(AsyncSelect)`
  width: 100%;
  // padding: 0 5px;
  margin-left: 8px;
  height: 20px;
  .css-1wa3eu0-placeholder{
    color: #93ACAB;
    font-weight: 400;
    font-size: 18px;
    font-family: Arial, FontAwesome;
  }
  .css-yk16xz-control{
    height: 40px;
    // border: 1px solid ${({ theme }) => theme.colors.od_bg_secondary};
  }
  .css-g1d714-ValueContainer{
    height: 40px;
  }
  .css-1uccc91-singleValue{
    height: 30px;
  }
  .css-4ljt47-MenuList{
    background-color: ${({ theme }) => theme.colors.od_bg_primary};
    padding: 5px;
  }
  .css-yutw0f-option{
    padding: 4px;
  }
  .css-tlfecz-indicatorContainer{
    display: none;
  }
  .css-1hb7zxy-IndicatorsContainer{
    display: none;
  }
  .css-26l3qy-menu{
    width: 110%;
    margin-top: 25px;
    left: -33px;
  }
  .css-q8s328-control {
    border: none;
    background-color: transparent;
  }
  
  .custom__control {
    height: -40px;
    border: 1px solid  #D1E3E2;
    
    .custom__single-value {
      font-size: 14px;
      color: red;
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const AdminSearch = styled.div`
  border: none;
`;
export const StyledTable = styled.table`
  tbody{
    tr {
      td {
        background-color: white; 
        height: 20%;
        padding: 5px;
      }
    }
  }
`;

export const TooltipCustom = styled(Popover)`
  min-width: 60px;
  height: 30px;
  padding: 3px;
  background: #FFFDFD;
  border: 1px solid #E4EEF9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  text-align: center;
`;
