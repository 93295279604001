/** @format */

export const houseAndAssets = [
  { label: 'Design issue', name: 'factorHouseDesign', value: false },
  { label: 'Equipment failure', name: 'factorHouseEquipment', value: false },
  { label: 'Fumes', name: 'factorHouseFumes', value: false },
  { label: 'Insufficient guards', name: 'factorHouseGuards', value: false },
  {
    label: 'Unsuitable use of plants',
    name: 'factorHouseUnsuitable',
    value: false
  },
  { label: 'Other', name: 'factorHouseOther', value: false }
];

export const personFactors = [
  { label: 'Alcohol/Drugs', name: 'factorPersonAlcohol' },
  { label: 'Lack of training', name: 'factorPersonLackTraining' },
  { label: 'Loss of balance/grip', name: 'factorPersonLossBalance' },
  { label: 'Manual handling', name: 'factorPersonManualHandling' },
  { label: 'Medical condition', name: 'factorPersonMedicalCondition' },
  { label: 'No/inappropriate PPE', name: 'factorPersonNoPPE' },
  { label: 'Rushing', name: 'factorPersonRushing' },
  { label: 'Threatening behaviour', name: 'factorPersonFrustration' },
  { label: 'Tiredness/fatigue', name: 'factorPersonFatigue' },
  { label: 'Unsafe practice', name: 'factorPersonUnsafePractice' },
  { label: 'Working alone', name: 'factorPersonWorkingAlone' },
  { label: 'Other', name: 'factorPersonOther' }
];

export const environmentFactors = [
  { label: 'Animals', name: 'factorEnvAnimals' },
  { label: 'Confine/Dangerous space', name: 'factorEnvConfine' },
  { label: 'Night/after hours', name: 'factorEnvNight' },
  { label: 'Odours', name: 'factorEnvOdours' },
  { label: 'Obstruction/fences/gate', name: 'factorEnvObstructions' },
  { label: 'Raining/storm/wind', name: 'factorEnvRaining' },
  { label: 'Sun glare', name: 'factorEnvSunGlare' },
  { label: 'Surface/path/road/holes', name: 'factorEnvSurface' },
  { label: 'Temperature', name: 'factorEnvTemperature' },
  { label: 'Shrubs/tree/vegetation', name: 'factorEnvVegetation' },
  { label: 'Working at height', name: 'factorEnvHeight' },
  { label: 'Other', name: 'factorEnvOther' }
];

export const riskOptions = [
  { label: '1 (Lowest Risk)', value: 1, name: 'riskRating' },
  { label: '2', value: 2, name: 'riskRating' },
  { label: '3', value: 3, name: 'riskRating' },
  { label: '4', value: 4, name: 'riskRating' },
  { label: '5 (Highest Risk)', value: 5, name: 'riskRating' }
];

export const consequenceOptions = [
  { label: 'Low', value: 'low', name: 'consequences' },
  { label: 'Minor', value: 'minor', name: 'consequences' },
  { label: 'Moderate', value: 'moderate', name: 'consequences' },
  { label: 'Major', value: 'major', name: 'consequences' },
  { label: 'Severe', value: 'severe', name: 'consequences' }
];

export const likelihoodOptions = [
  { label: 'Rare', value: 'rare', name: 'likelihood' },
  { label: 'Unlikely', value: 'unlikely', name: 'likelihood' },
  { label: 'Possible', value: 'possible', name: 'likelihood' },
  { label: 'Likely', value: 'likely', name: 'likelihood' },
  { label: 'Almost Certain', value: 'almost certain', name: 'likelihood' }
];

export const reviewObj = {
  factorHouseDesign: false,
  factorHouseEquipment: false,
  factorHouseFumes: false,
  factorHouseGuards: false,
  factorHouseOther: false,
  factorHouseUnsuitable: false,
  factorHouseDescription: '',
  factorHouseWhatHappened: '',
  factorPersonName: '',
  factorPersonAlcohol: false,
  factorPersonComplacency: false,
  factorPersonFrustration: false,
  factorPersonLineOfFire: false,
  factorPersonLackTraining: false,
  factorPersonLossBalance: false,
  factorPersonManualHandling: false,
  factorPersonMedicalCondition: false,
  factorPersonNoPPE: false,
  factorPersonOther: false,
  factorPersonRushing: false,
  factorPersonThreatening: false,
  factorPersonFatigue: false,
  factorPersonUnsafePractice: false,
  factorPersonWorkingAlone: false,
  factorPersonDescription: '',
  factorPersonWhatHappened: '',
  factorEnvAnimals: false,
  factorEnvConfine: false,
  factorEnvNight: false,
  factorEnvOdours: false,
  factorEnvOther: false,
  factorEnvObstructions: false,
  factorEnvRaining: false,
  factorEnvSunGlare: false,
  factorEnvSurface: false,
  factorEnvTemperature: false,
  factorEnvVegetation: false,
  factorEnvHeight: false,
  factorEnvDescription: '',
  factorEnvWhatHappened: '',
  factorWorkDescription: '',
  factorWorkWhatHappened: '',
  riskRating: '',
  consequences: '',
  likelihood: '',
  correctiveAction: '',
  dueDate: new Date(),
  actionsToImplement: '',
  allocatedTo: '',
  codeBreach: 'No',
  supportPlanUpdate: 'No',
  furtherSupport: 'No',
  followUp: '',
  howPrevented: ''
};

export const incidentObj = {
  category: { name: '' },
  classification: { name: '' },
  client: { name: '' },
  dateOccurred: new Date(),
  description: '',
  escalated: undefined,
  escalatedTo: null,
  exactLocation: '',
  id: undefined,
  injuredFamilyName: '',
  injuredGivenName: '',
  location: '',
  reportedBy: {},
  raisedFor: '',
  status: '',
  type: { name: '' },
  reportableToNDIS: 'No',
  reportableToWorksafe: 'No',
  followUpResponsibility: ''
};

export const initialHandoverSummary = {
  handoverDate: new Date(),
  handoverTime: new Date(),
  handoverShift: '',
  handoverBy: '',
  handoverTo: { id: '', firstName: '', lastName: '' },
  behaviourSummary: '',
  sleepSummary: '',
  foodSummary: '',
  toiletingSummary: '',
  activitiesSummary: '',
  communications: '',
  topPriorities: '',
  comments: '',
  peopleAttended: '',
  placesVisited: '',
  thingsLater: '',
  lastUploadedBy: ''
};

export const shiftList = [
  {
    name: 'handoverShift', label: 'MORNING', value: 'MORNING'
  },
  {
    name: 'handoverShift', label: 'EVENING', value: 'EVENING'
  },
  {
    name: 'handoverShift', label: 'ACTIVE NIGHT', value: 'ACTIVENIGHT'
  },
  {
    name: 'handoverShift', label: 'SLEEP OVER', value: 'SLEEPOVER'
  }
];

export const clientDetailsObj = {
  name: '',
  gender: '',
  dob: '',
  email: '',
  phone: '',
  addrLine1: '',
  addrLine2: '',
  city: '',
  state: '',
  postCode: '',
  medicareCardNo: '',
  expiryDate: '',
  healthFund: false,
  centerLinkNo: '',
  individualReferenceNumber: '',
  medicareCardName: '',
  ndisNumber: '',
  photo: '',
  identity: '',
  culture: '',
  language: '',
  diagnosis: '',
  mobility: '',
  communication: '',
  medicationSupport: '',
  transportation: '',
  justiceOrders: '',
  supportRatio: '',
  shiftTimes: '',
  supportWorkerSpecs: ''
};

export const noticeObj = {
  noticeStatus: '',
  houseCode: [],
  notice: '',
  startDate: '',
  endDate: ''
};

export const nightChecklistData = [
  {
    id: 78, taskName: 'Clean and disinfect toilet', name: 'cleanToilet'
  },
  {
    id: 79, taskName: 'Shower and bathroom cleaned – client / staff', name: 'cleanBathroomStaff'
  },
  {
    id: 80, taskName: 'Clean and tidy staff room / staffing areas', name: 'cleanBathroomClient'
  },
  {
    id: 81, taskName: 'General tidy on furniture, floors and benches', name: 'tidyFurniture'
  },
  {
    id: 82, taskName: 'Floors vacuumed', name: 'vacuumFloors'
  },
  {
    id: 83, taskName: 'Floors mopped', name: 'mopFloors'
  },
  {
    id: 84, taskName: 'Put washing in the washing machine – Washing cycle', name: 'washingCycle'
  },
  {
    id: 85, taskName: 'Placed clothes in the dryer – Drying cycle', name: 'dryingCycle'
  },
  {
    id: 86, taskName: 'Clothes folded and put away', name: 'foldClothes'
  },
  {
    id: 87, taskName: 'Wash, dry and put away pots, dishes, cutlery and crockery', name: 'putAwayDishes'
  },
  {
    id: 88, taskName: 'Tidy Staff Room', name: 'tidyStaffRoom'
  },
  {
    id: 89, taskName: 'Charge electronics – phones, iPad etc', name: 'chargeElectronics'
  },
  {
    id: 90, taskName: 'Completion of daily documents – Daily notes, communication book, bowel chart, food diary, behaviour escalation chart etc', name: 'completedDailyReports'
  },
  {
    id: 91, taskName: 'Check medication chart and ensure each entry is completed, including date & signatures (complete incident report if missing)', name: 'checkMedicationChart'
  },
  {
    id: 92, taskName: 'Fill out any incident reports and email or message Manager regarding need for further follow up', name: 'fillIncidentReport'
  },
  {
    id: 93, taskName: 'Check food in fridge – dispose of all out-of-date items & ensure all items are labelled appropriately', name: 'checkFridge'
  },
  {
    id: 94, taskName: 'Empty and replace all inside rubbish bin bags, clean bins', name: 'emptyDustbins'
  },
  {
    id: 95, taskName: 'Rubbish bins taken out / brought in', name: 'takeOutDustbins'
  },
  {
    id: 96, taskName: 'Vehicle – vacuum floor and wipe over all surfaces', name: 'vacuumVehicle'
  },
  {
    id: 97, taskName: 'Vehicle – ensure all items in vehicle checklist is located and available within the vehicle', name: 'checklistVehicle'
  },
  {
    id: 98, taskName: 'Vehicle – Ensure child lock is on / activated', name: 'childLockVehicle'
  }
];

export const checklistObj = {
  cleanToilet: false,
  cleanToiletBy: '',
  cleanBathroomStaff: false,
  cleanBathroomStaffBy: '',
  cleanBathroomClient: false,
  cleanBathroomClientBy: '',
  tidyFurniture: false,
  tidyFurnitureBy: '',
  vacuumFloors: false,
  vacuumFloorsBy: '',
  mopFloors: false,
  mopFloorsBy: '',
  washingCycle: false,
  washingCycleBy: '',
  dryingCycle: false,
  dryingCycleBy: '',
  foldClothes: false,
  foldClothesBy: '',
  putAwayDishes: false,
  putAwayDishesBy: '',
  tidyStaffRoom: false,
  tidyStaffRoomBy: '',
  chargeElectronics: false,
  chargeElectronicsBy: '',
  completedDailyReports: false,
  completedDailyReportsBy: '',
  checkMedicationChart: false,
  checkMedicationChartBy: '',
  fillIncidentReport: false,
  fillIncidentReportBy: '',
  checkFridge: false,
  checkFridgeBy: '',
  emptyDustbins: false,
  emptyDustbinsBy: '',
  takeOutDustbins: false,
  takeOutDustbinsBy: '',
  vacuumVehicle: false,
  vacuumVehicleBy: '',
  checklistVehicle: false,
  checklistVehicleBy: '',
  childLockVehicle: false,
  childLockVehicleBy: ''
};

export const slotDetailsObj = {
  firstSlot: '',
  firstUpdatedBy: '',
  secondSlot: '',
  secondUpdatedBy: '',
  thirdSlot: '',
  thirdUpdatedBy: '',
  fourthSlot: '',
  fourthUpdatedBy: '',
  fifthSlot: '',
  fifthUpdatedBy: '',
  sixthSlot: '',
  sixthUpdatedBy: '',
  seventhSlot: '',
  seventhUpdatedBy: '',
  eighthSlot: '',
  eighthUpdatedBy: ''
};

export const meal = {
  mealType: '',
  mealFood: '',
  mealDrink: '',
  mealComments: '',
  mealUpdatedBy: '',
  mealTime: ''
};

export const clientContact = {
  relation: '',
  designation: '',
  firstName: '',
  lastName: '',
  email: '',
  phone1: '',
  phone2: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postCode: '',
  notes: ''
};

export const contact = {
  houseCode: [],
  caption: '',
  firstName: '',
  lastName: '',
  email: '',
  phone1: '',
  phone2: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  notes: ''
};

export const staffIncident = {
  categoryId: '',
  classificationId: '',
  typeId: '',
  status: 'RAISED',
  raisedFor: 'STAFF',
  description: '',
  dateOccurred: '',
  location: '',
  exactLocation: '',
  injuredGivenName: '',
  injuredFamilyName: '',
  witnessName: '',
  witnessDesignation: '',
  immediateAction: '',
  beforeIncident: '',
  policeReport: '',
  policeName: '',
  policeNumber: '',
  policeStation: '',
  reportableToNDIS: 'No',
  reportableToWorksafe: 'No'
};

export const statusList = [
  { label: 'ALL', value: 'ALL' },
  { label: 'RAISED', value: 'RAISED' },
  { label: 'REVIEWED', value: 'REVIEWED' },
  { label: 'CLOSED', value: 'CLOSED' }
];
export const ODCarOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'NA', value: 'NA' }
];
export const reportableToNDISList = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'NA', value: 'NA' }
];
export const ComprehensiveInsuranceOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'NA', value: 'NA' }
];
export const budgetTypeOfCardOptions = [
  { label: 'Bank Card', value: 'BankCard' },
  { label: 'Budgetly Card', value: 'BudgetlyCard' }
];
export const budgetTypeOptions = [
  { label: 'Master Card', value: 'MasterCard' },
  { label: 'Visa', value: 'Visa' },
  { label: 'Other', value: 'Other' }
];

export const RoadSideAssistanceCoveredOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'NA', value: 'NA' }
];
export const TravelProtocolOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'N/A', value: 'N/A' }
];
export const TypeOptions = [
  { label: 'Travel', value: 'Travel' },
  { label: 'Groceries', value: 'Groceries' },
  { label: 'Rent', value: 'Rent' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Others', value: 'Others' }
];
export const InvoiceToOptions = [
  { label: 'Plan Manager', value: 'Plan Manager' },
  { label: 'DFFH', value: 'DFFH' },
  { label: 'Family', value: 'Family' },
  { label: 'State Trustee', value: 'State Trustee' },
  { label: 'Others', value: 'Others' }
];

export const initialMiscellaneousNoteDetails = {
  noteDate: new Date(),
  category: { name: '' },
  subject: '',
  content: '',
  house: '',
  client: '',
  user: ''
};

export const serviceProviderOptions = [
  {
    label: 'SC',
    value: 'SC'
  },
  {
    label: 'BehSpec',
    value: 'BehSpec'
  },
  {
    label: 'Speech Path',
    value: 'Speech Path'
  },
  {
    label: 'Physio',
    value: 'Physio'
  },
  {
    label: '2nd Provider',
    value: '2nd Provider'
  },
  {
    label: 'GP',
    value: 'GP'
  },
  {
    label: 'Plan Manager',
    value: 'Plan Manager'
  }
];
export const attorneyType = [
  {
    label: 'Medical',
    value: 'Medical'
  },
  {
    label: 'Financial',
    value: 'Financial'
  },
  {
    label: 'Legal',
    value: 'Legal'
  }
];
export const relations = [
  {
    label: 'Father',
    value: 'Father'
  },
  {
    label: 'Mother',
    value: 'Mother'
  },
  {
    label: 'Brother',
    value: 'Brother'
  },
  {
    label: 'Sister',
    value: 'Sister'
  },
  {
    label: 'NOK',
    value: 'NOK'
  },
  {
    label: 'Emergency Contact',
    value: 'Emergency Contact'
  },
  {
    label: 'CareTeam Member',
    value: 'CareTeam Member'
  },
  {
    label: 'Guardian',
    value: 'Guardian'
  },
  {
    label: 'Cousin',
    value: 'Cousin'
  },
  {
    label: 'Niece',
    value: 'Niece'
  },
  {
    label: 'Nephew',
    value: 'Nephew'
  },
  {
    label: 'Friend',
    value: 'Friend'
  },
  {
    label: 'Justice Support',
    value: 'Justice Support'
  },
  {
    label: 'MACNI Provider',
    value: 'MACNI Provider'
  },
  {
    label: 'Other',
    value: 'Other'
  }
];
