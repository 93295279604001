import jsPDF from 'jspdf';
import moment from 'moment';
import logoPic from '../../../../assets/logo/logo.png';

const generatePDFFoodDrinkTracker = (
  breakFastDetails,
  morningSnackDetails,
  lunchDetails,
  afterNoonSnackDetails,
  dinnerDetails,
  eveningSnackDetails,
  reportDetails
) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.addImage(logoPic, 'JPEG', 25, 5, 65, 65);
  doc.setFontSize(18);
  doc.setFont('helvetica');
  doc.text(
    'FOOD AND DRINKS DIARY',
    240,
    40,
    null
  );
  doc.setFontSize(13);
  doc.setFont('helvetica bold');
  doc.text(
    'Client Name: ',
    30,
    85,
    null
  );
  doc.text(
    `${reportDetails?.clientName}`,
    130,
    85,
    null
  );
  doc.text(
    'Date: ',
    620,
    85,
    null
  );
  doc.text(
    `${moment(reportDetails?.date).format('DD-MM-YYYY')}`,
    665,
    85,
    null
  );
  doc.setFontSize(12);
  doc.setFont('helvetica');
  doc.text(
    'TIME',
    100,
    115,
    null
  );
  doc.text(
    'FOOD',
    270,
    115,
    null
  );
  doc.text(
    'DRINKS',
    445,
    115,
    null
  );
  doc.text(
    'COMMENTS',
    620,
    115,
    null
  );
  doc.roundedRect(
    30,
    120,
    735,
    150,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    'Breakfast',
    40,
    160,
    null
  );
  doc.text(
    `${breakFastDetails.mealTime !== '' ? moment(breakFastDetails.mealTime, 'hh:mm:ss').format('hh:mm a') : ''}`,
    70,
    200,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    120,
    200,
    270,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${breakFastDetails.mealFood}`,
    215,
    160,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    380,
    120,
    380,
    270,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${breakFastDetails.mealDrink}`,
    395,
    160,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    570,
    120,
    570,
    270,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${breakFastDetails.mealComments}`,
    585,
    160,
    {
      maxWidth: '150'
    },
    null
  );
  doc.text(
    `${breakFastDetails.mealUpdatedBy}`,
    585,
    250,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    270,
    735,
    150,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    'Morning Snacks',
    40,
    310,
    null
  );
  doc.text(
    `${morningSnackDetails.mealTime !== '' ? moment(morningSnackDetails.mealTime, 'hh:mm:ss').format('hh:mm a') : ''}`,
    70,
    350,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    270,
    200,
    420,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${morningSnackDetails.mealFood}`,
    215,
    310,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    380,
    270,
    380,
    420,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${morningSnackDetails.mealDrink}`,
    395,
    310,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    570,
    270,
    570,
    420,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${morningSnackDetails.mealComments}`,
    585,
    310,
    {
      maxWidth: '150'
    },
    null
  );
  doc.text(
    `${morningSnackDetails.mealUpdatedBy}`,
    585,
    400,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    420,
    735,
    150,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    'Lunch',
    40,
    460,
    null
  );
  doc.text(
    `${lunchDetails.mealTime !== '' ? moment(lunchDetails.mealTime, 'hh:mm:ss').format('hh:mm a') : ''}`,
    70,
    500,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    420,
    200,
    570,
    null
  );
  doc.text(
    `${lunchDetails.mealFood}`,
    215,
    460,
    {
      maxWidth: '150'
    },
    null
  );
  doc.line(
    380,
    420,
    380,
    570,
    null
  );
  doc.text(
    `${lunchDetails.mealDrink}`,
    395,
    460,
    {
      maxWidth: '150'
    },
    null
  );
  doc.line(
    570,
    420,
    570,
    570,
    null
  );
  doc.text(
    `${lunchDetails.mealComments}`,
    585,
    460,
    {
      maxWidth: '150'
    },
    null
  );
  doc.text(
    `${lunchDetails.mealUpdatedBy}`,
    585,
    550,
    {
      maxWidth: '150'
    },
    null
  );
  doc.roundedRect(
    30,
    570,
    735,
    150,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    'Afternoon Snacks',
    40,
    610,
    null
  );
  doc.text(
    `${afterNoonSnackDetails.mealTime !== '' ? moment(afterNoonSnackDetails.mealTime, 'hh:mm:ss').format('hh:mm a') : ''}`,
    70,
    650,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    570,
    200,
    720,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${afterNoonSnackDetails.mealFood}`,
    215,
    610,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    380,
    570,
    380,
    720,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${afterNoonSnackDetails.mealDrink}`,
    395,
    610,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    570,
    570,
    570,
    720,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${afterNoonSnackDetails.mealComments}`,
    585,
    610,
    {
      maxWidth: '150'
    },
    null
  );
  doc.text(
    `${afterNoonSnackDetails.mealUpdatedBy}`,
    585,
    700,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    720,
    735,
    150,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    'Dinner',
    40,
    760,
    null
  );
  doc.text(
    `${dinnerDetails.mealTime !== '' ? moment(dinnerDetails.mealTime, 'hh:mm:ss').format('hh:mm a') : ''}`,
    70,
    800,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    720,
    200,
    870,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${dinnerDetails.mealFood}`,
    215,
    760,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    380,
    720,
    380,
    870,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${dinnerDetails.mealDrink}`,
    395,
    760,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    570,
    720,
    570,
    870,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${dinnerDetails.mealComments}`,
    595,
    760,
    {
      maxWidth: '150'
    },
    null
  );
  doc.text(
    `${dinnerDetails.mealUpdatedBy}`,
    585,
    850,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.roundedRect(
    30,
    870,
    735,
    150,
    null,
    null
  );
  doc.setFontSize(13);
  doc.text(
    'Evening Snacks',
    40,
    910,
    null
  );
  doc.text(
    `${eveningSnackDetails.mealTime !== '' ? moment(eveningSnackDetails.mealTime, 'hh:mm:ss').format('hh:mm a') : ''}`,
    70,
    950,
    null
  );
  doc.setFontSize(12);
  doc.line(
    200,
    870,
    200,
    1020,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${eveningSnackDetails.mealFood}`,
    215,
    910,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    380,
    870,
    380,
    1020,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${eveningSnackDetails.mealDrink}`,
    395,
    910,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  doc.line(
    570,
    870,
    570,
    1020,
    null
  );
  doc.setFontSize(13);
  doc.text(
    `${eveningSnackDetails.mealComments}`,
    595,
    910,
    {
      maxWidth: '150'
    },
    null
  );
  doc.text(
    `${eveningSnackDetails.mealUpdatedBy}`,
    585,
    1000,
    {
      maxWidth: '150'
    },
    null
  );
  doc.setFontSize(12);
  // doc.output('dataurlnewwindow');
  doc.save(`FDT-Report-${reportDetails?.clientName}`);
};

export default generatePDFFoodDrinkTracker;
