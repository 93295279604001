/** @format */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import { BsUpload } from 'react-icons/bs';
import swal from 'sweetalert';
import CustomModal from '../CustomModal';
import {
  DocHeader, DocsWrapper, PdfViewerWrapper, ButtonBox, PdfWrapper,
  HeadingBox, Button, FormWrapper, DeleteButton, UploadButton
} from './index.style';
import deleteIcon from '../../assets/logo/close_cross.svg';
import downloadLogo from '../../assets/logo/upload.svg';
import PdfViewPlans from '../PdfViewPlans/PdfViewPlans';
import NoData from '../NoData';
import { LoadingModal } from '../../styledComponents/common/loadingModal.style';
import { getUserDetails } from '../../helpers/utils';
import { deleteDocument, getDocumentList, uploadDocument } from '../../services/result.services';

const SupportDocs = ({ client, selectedFolder, resetFolderSelection }) => {
  const [documentList, setDocumentList] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [showCustomModalUploadDoc, setShowCustomModalUploadDoc] = useState(false);
  const [labelUploadFileName, setLabelUploadFileName] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const [fileName, setFileName] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState('');
  const [isPdf, setIsPdf] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileChange = (e) => {
    if (e.currentTarget) {
      setLabelUploadFileName(
        e.currentTarget.files[0].name || ''
      );
      setUploadFile(e.currentTarget.files[0]);
    }
  };
  const handleChange = (e) => {
    setFileName(e.target.value);
  };

  const clearInput = () => {
    setUploadFile(null);
    setLabelUploadFileName('');
    setFileName('');
  };

  const handleShowPdf = (doc) => {
    setSelectedPdf(doc.blobUrl);
    setShowPdf(true);
    const docName = doc.blobName.slice(-3);
    if (docName === 'pdf') {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  };
  const getDocumentListHandler = async () => {
    setLoading(true);
    const resp = await getDocumentList(client.id, selectedFolder.id);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      setDocumentList([...resp.data.filter((document) => document.status === 'ACTIVE')
        .sort((a, b) => a.id - b.id)]);
    } else {
      setLoading(false);
    }
  };
  const uploadDocumentHandler = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('clientId', new Blob([JSON.stringify(client.id)], { type: 'application/json' }));
    formData.append('folderId', new Blob([JSON.stringify(selectedFolder.id)], { type: 'application/json' }));
    formData.append('docName', new Blob([fileName]), { type: 'application/json' });
    formData.append('lastUploadedBy', new Blob([getUserDetails().firstName]), { type: 'application/json' });
    const resp = await uploadDocument(formData);
    if (!resp) {
      setLoading(false);
      return;
    }
    if (resp.status === 200) {
      setLoading(false);
      swal({
        title: 'Document uploaded successfully!',
        icon: 'success',
        timer: 3000
      });
      setShowCustomModalUploadDoc(false);
      getDocumentListHandler();
      clearInput();
    } else if (resp.status === 400 && !resp.data.success) {
      setLoading(false);
      setShowCustomModalUploadDoc(false);
      swal({
        title: resp.data.message,
        icon: 'error',
        timer: 3000
      });
    } else {
      setLoading(false);
      setShowCustomModalUploadDoc(false);
      clearInput();
      swal({
        title: 'Failed to upload document!',
        icon: 'error',
        timer: 3000
      });
    }
  };

  const handleDeleteDocument = async (documentId) => {
    const value = await swal({
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: { text: 'Delete', value: 'confirm' }
      }
    });
    if (value) {
      setLoading(true);
      const resp = await deleteDocument(documentId);
      if (!resp) {
        setLoading(false);
        return;
      }
      if (resp.status === 200) {
        setLoading(false);
        swal({
          title: 'Document deleted successfully!',
          icon: 'success',
          timer: 3000
        });
        getDocumentListHandler();
      } else {
        setLoading(false);
        swal({
          title: 'Document deletion failed!',
          icon: 'error',
          timer: 3000
        });
      }
    }
  };

  useEffect(() => {
    getDocumentListHandler();
  }, []);

  useEffect(() => {
    if (loading) {
      handleShow();
    } else {
      handleClose();
    }
  }, [loading]);

  return (
    <>
      <DocHeader>
        <HeadingBox>
          <p>{selectedFolder.folderName}</p>
        </HeadingBox>
        <ButtonBox>
          <UploadButton onClick={() => setShowCustomModalUploadDoc(true)}>
            <BsUpload />
          </UploadButton>
          { showPdf ? (
            <Button onClick={() => {
              setShowPdf(false);
              setSelectedPdf('');
            }}
            >
              Back
            </Button>
          ) : (
            <Button onClick={resetFolderSelection}>Back</Button>
          )}
        </ButtonBox>
      </DocHeader>
      {documentList.length === 0 && (
        <NoData />
      )}
      {
          showPdf ? (
            <PdfWrapper>
              <PdfViewerWrapper>
                {
                  isPdf
                    ? (
                      <PdfViewPlans
                        pdf={selectedPdf}
                      />
                    ) : <img src={selectedPdf} alt="sample" />
                }
              </PdfViewerWrapper>
            </PdfWrapper>
          ) : (
            <DocsWrapper>
              {
              documentList.map((doc) => (
                <div className="doc-box" key={doc.id}>
                  <span className="link" onClick={() => handleShowPdf(doc)}>
                    {doc.docName}
                  </span>
                  <div className="download-delete-box">
                    <a href={doc.blobUrl} className="doc-link" download>
                      <img src={downloadLogo} alt="download icon" />
                    </a>
                    <DeleteButton onClick={() => handleDeleteDocument(doc.id)}>
                      <img className="cross" src={deleteIcon} alt="delete icon" />
                    </DeleteButton>
                  </div>
                </div>
              ))
            }
            </DocsWrapper>
          )
        }
      <LoadingModal
        show={show}
        onHide={handleClose}
        animation
        centered
        width="4%"
      >
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </LoadingModal>
      <CustomModal
        open={showCustomModalUploadDoc}
        setClose={() => {
          setShowCustomModalUploadDoc(false);
          clearInput();
        }}
        title="Upload Document"
        width="680px"
        onSubmit={uploadDocumentHandler}
        operationName="Upload"
        isDisabled={!fileName || !uploadFile}
      >
        <div>
          <FormWrapper>
            <div className="input-label-wrapper">
              <p className="label" htmlFor="fileName">Document Name</p>
              <input
                type="text"
                id="fileName"
                name="fileName"
                placeholder="Document Name"
                value={fileName}
                onChange={handleChange}
              />
            </div>
            <div className="input-label-wrapper">
              <p className="label">Select File</p>
              <div className="icon-name-wrapper">
                <label htmlFor="uploadFile">
                  <BsUpload />
                  <input
                    type="file"
                    id="uploadFile"
                    name="uploadFile"
                    hidden
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={handleFileChange}
                  />
                  <span className="ellipsis">

                    {labelUploadFileName === ''
                      ? 'Click here to choose a file'
                      : labelUploadFileName}
                  </span>
                </label>
              </div>
            </div>
          </FormWrapper>
        </div>
      </CustomModal>
    </>
  );
};

SupportDocs.propTypes = {
  selectedFolder: PropTypes.objectOf(PropTypes.any).isRequired,
  resetFolderSelection: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired
};

export default SupportDocs;
