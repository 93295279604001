import JSPDF from 'jspdf';
import moment from 'moment';
import 'jspdf-autotable';
import logoPic from '../../../../assets/logo/logo.png';

const generatePDFBowelMovementTracker = (
  reportDetails,
  records
) => {
  const col = ['Sr. No', 'Date and Time', 'Size', 'Type1', 'Type2', 'Type3', 'Type4', 'Type5', 'Type6', 'Type7', 'Staff'];
  const rows = [];
  records.data.forEach((item, index) => {
    rows.push([index + 1, item.startDate ? `${item.recordTime}` : null, item.size ? `${item.size}` : null, item.type1 ? '*' : '',
      item.type2 ? '*' : '', item.type3 ? '*' : '', item.type4 ? '*' : '', item.type5 ? '*' : '',
      item.type6 ? '*' : '', item.type7 ? '*' : '', item.lastUploadedBy ? `${item.lastUploadedBy}` : null]);
  });
  const doc = new JSPDF({
    orientation: 'p',
    unit: 'px',
    hotfixes: ['px_scaling']
  });
  doc.addImage(logoPic, 'JPEG', 48, 5, 52, 52);
  doc.setFontSize(16);
  doc.setFont('helvetica');
  doc.text(
    'BOWEL MOVEMENT TRACKER',
    240,
    35,
    null
  );
  doc.setFontSize(12);
  doc.setFont('helvetica bold');
  doc.text(
    'Client Name: ',
    50,
    70,
    null
  );
  doc.text(
    `${reportDetails?.clientName}`,
    150,
    70,
    null
  );
  doc.text(
    'DOB: ',
    350,
    70,
    null
  );
  doc.text(
    `${reportDetails?.clientDOB}`,
    400,
    70,
    null
  );
  doc.text(
    'Track From:',
    580,
    70,
    null
  );
  doc.text(
    `${moment(reportDetails?.startDate).format('YYYY-MM-DD')}`,
    670,
    70,
    null
  );
  doc.autoTable(
    col, rows, {
      startY: 85,
      theme: 'grid',
      headStyles:
    {
      fontStyle: 'normal',
      font: 'Helvetica',
      fillColor: '#74BCA9',
      textColor: '#1c1c1c',
      fontSize: 10
    }
    }
  );
  doc.save(`BMT-Report-${reportDetails?.clientName}`);
};
export default generatePDFBowelMovementTracker;
